'use client';

// locales
import { useState, useCallback } from 'react';

import Box from '@mui/material/Box';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useTranslate } from 'src/locales';

// components
import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';

import { IWalletProductTableFilter, IWalletProductTableFilterValue } from '../../../types/wallet';
import { useResponsive } from '../../../hooks/use-responsive';

// types

// ----------------------------------------------------------------------

type Props = {
  filters: IWalletProductTableFilter;
  onFilters: (name: string, value: IWalletProductTableFilterValue) => void;
  //
  typeOptions: string[];
  categoryOptions: string[];
  areaOptions: string[];
  handleResetFilters: Function;
  canReset: boolean;
  handleCloseAcceptingCardsModal: Function;
  сardsModal?: boolean;
  handleResetCategory?: Function;
  handleResetArea?: Function;
  handleResetTags?: Function;
  handleResetName?: Function;
  toggleDrawer?: Function;
};

export default function WalletToolBar({
  filters,
  onFilters,
  typeOptions,
  categoryOptions,
  areaOptions,
  handleResetFilters,
  canReset,
  handleCloseAcceptingCardsModal,
  handleResetCategory,
  handleResetTags,
  handleResetName,
  handleResetArea,
  сardsModal,
  toggleDrawer,
}: Props) {
  const isMobile = useResponsive('down', 'sm');
  const { t } = useTranslate();

  const popover = usePopover();

  const [valueSlider, setValue] = useState<number[]>([0, 3000]);

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterArea = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'area',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterCategory = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'category',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterType = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'type',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  return (
    <Box sx={{ direction: 'rtl' }} width={1}>
      {isMobile && (
        <Stack direction="row" justifyContent="space-between">
          <IconButton onClick={() => handleResetFilters()} disabled={!canReset}>
            <Iconify icon="basil:trash-solid" color={!canReset ? 'grey.500' : 'secondary.main'} />
          </IconButton>
          <IconButton onClick={() => toggleDrawer!(false)}>
            <Iconify icon="mdi:filter" sx={{color: 'black'}}/>
          </IconButton>

          {сardsModal && (
            <IconButton onClick={() => handleCloseAcceptingCardsModal()}>
              <Iconify icon="iconamoon:close-fill" />
            </IconButton>
          )}
        </Stack>
      )}
      <Stack
        width={1}
        spacing={2}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent="space-between"
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
          overflow: 'hidden',
          flexWrap: 'wrap',
        }}
      >
        {!isMobile && (
          <IconButton onClick={() => handleResetFilters()} disabled={!canReset}>
            <Iconify icon="basil:trash-solid" color={!canReset ? 'grey.500' : 'secondary.main'} />
          </IconButton>
        )}
        <FormControl sx={{ flexShrink: 0, width: { xs: 1, md: '20%' } }}>
          <InputLabel>{t('אזור')}</InputLabel>
          <Select
            multiple
            value={filters.area}
            onChange={handleFilterArea}
            input={<OutlinedInput label="אזור" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
          >
            <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1}>
              <Iconify
                onClick={(event) => {
                  event.stopPropagation();
                  if (handleResetArea) {
                    handleResetArea();
                  }
                }}
                icon="tabler:trash"
                sx={{
                  cursor: 'pointer',
                  color: filters.area.length === 0 ? 'gray' : 'red',
                }}
              />
              <Typography>נבחר</Typography>
              <Typography>{filters.area.length}</Typography>
            </Stack>
            {Array.isArray(areaOptions) &&
              areaOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox disableRipple size="small" checked={filters.area.includes(option)} />
                  {option}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl sx={{ flexShrink: 0, width: { xs: 1, md: '20%' } }}>
          <InputLabel>{t('קטגוריה')}</InputLabel>
          <Select
            multiple
            value={filters.category}
            onChange={handleFilterCategory}
            input={<OutlinedInput label="קטגוריה" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
          >
            <Stack
              direction="row-reverse"
              justifyContent="flex-end"
              gap={1}
              mx={1}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <Iconify
                onClick={(event) => {
                  event.stopPropagation();
                  if (handleResetCategory) {
                    handleResetCategory();
                  }
                }}
                icon="tabler:trash"
                sx={{
                  cursor: 'pointer',
                  color: filters.category.length === 0 ? 'gray' : 'red',
                }}
              />
              <Typography>נבחר</Typography>
              <Typography>{filters.category.length}</Typography>
            </Stack>
            {categoryOptions?.map((option: any, id) => (
              <MenuItem key={id} value={option?.title}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.category.includes(option.title)}
                />
                {option?.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ flexShrink: 0, width: { xs: 1, md: '20%' } }}>
          <InputLabel>{t('סוג')}</InputLabel>
          <Select
            multiple
            value={filters.type}
            onChange={handleFilterType}
            input={<OutlinedInput label="סוג" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
          >
            <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1}>
              <Iconify
                onClick={(event) => {
                  event.stopPropagation();
                  if (handleResetTags) {
                    handleResetTags();
                  }
                }}
                icon="tabler:trash"
                sx={{
                  cursor: 'pointer',
                  color: filters.type.length === 0 ? 'gray' : 'red',
                }}
              />
              <Typography>נבחר</Typography>
              <Typography>{filters.type.length}</Typography>
            </Stack>
            {Array.isArray(typeOptions) &&
              typeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox disableRipple size="small" checked={filters.type.includes(option)} />
                  {option}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          sx={{ '& input': { direction: 'ltr' }, flexShrink: 0, width: { xs: 1, md: '20%' } }}
          fullWidth
          value={filters.name}
          onChange={handleFilterName}
          placeholder={`${t('חיפוש...')}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', mx: 0.5 }} />
              </InputAdornment>
            ),
            startAdornment: filters?.name?.length ? (
              <InputAdornment position="start">
                <Iconify
                  icon="iconamoon:close-fill"
                  sx={{ color: 'text.disabled', cursor: 'pointer' }}
                  onClick={() => {
                    if (handleResetName) {
                      handleResetName();
                    }
                  }}
                />
              </InputAdornment>
            ) : null,
          }}
        />
      </Stack>
    </Box>
  );
}
