'use client';

import React, { useState } from 'react';

import { styled } from '@mui/system';
import {
  Box,
  Card,
  Grid,
  Step,
  Modal,
  Stack,
  Button,
  Divider,
  Stepper,
  MenuItem,
  StepLabel,
  TextField,
  Typography,
  InputAdornment,
  StepConnector as MUIStepConnector,
} from '@mui/material';

import Image from '../../../../components/image';
import { useTranslate } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { BlockType } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

type Props = {
  block: BlockType | undefined;
};

type StepIconProps = {
  active?: boolean;
  completed?: boolean;
};

export default function Payments({ block }: Props) {
  const { t } = useTranslate();
  const isMobile = useResponsive('down', 'sm');
  const steps = ['אימות פרטי שובר', 'תשלום', 'סיימנו'];

  const StepConnector = styled(MUIStepConnector)(({ theme }) => ({
    top: 10,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
    '& .MuiStepConnector-line': {
      borderTopWidth: 2,
      borderColor: theme.palette.divider,
    },
    '&.Mui-active, &.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function StepIcon({ active, completed }: StepIconProps) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 24,
          height: 24,
          color: 'text.disabled',
          ...(active && {
            color: 'primary.main',
          }),
        }}
      >
        {completed ? (
          <Iconify icon="eva:checkmark-fill" sx={{ color: 'primary.main' }} />
        ) : (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: 'currentColor',
            }}
          />
        )}
      </Stack>
    );
  }

  return (
    <Card sx={{ borderRadius: 1,}}>
      <Stack width={1} gap={2}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              background: 'rgb(0, 89, 210)',
              borderRadius: 1,
              width: '48px',
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Iconify icon="system-uicons:credit-card" width={30} color="white" />
          </Box>
          <Typography sx={{ fontSize: '24px', fontWeight: 1000 }}>{`${t('Payments')}`}</Typography>
        </Stack>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<StepConnector />}
            sx={{ mb: 5 }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  // eslint-disable-next-line
                  StepIconComponent={StepIcon}
                  sx={{
                    '& .MuiStepLabel-label': {
                      typography: 'subtitle2',
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && <FirstStep handleNext={handleNext} handleBack={handleBack} />}
          {activeStep === 1 && <SecondStep handleNext={handleNext} />}
          {activeStep === 2 && <ThirdStep />}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined">
              Back
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
}

type PropsStep = {
  handleNext: Function;
  handleBack?: Function;
};

const Info = () => (
  <Stack direction="row" width={1} justifyContent="space-around" alignItems="flex-end">
    <Stack>
      <Iconify icon="bx:home" color="rgb(0, 89, 210)" width="50px" />
      <Typography sx={{ fontWeight: 600 }}>הרצל 41 א’, רמת-גן</Typography>
      <Typography>דירה 3 • מספר נכס 5456322</Typography>
      <Typography>תאריך אחרון לתשלום 31.02.23</Typography>
    </Stack>
    <Stack>
      <Typography>סכום לתשלום:</Typography>
      <Typography sx={{ fontWeight: 800 }}>716.25ש”ח</Typography>
    </Stack>
  </Stack>
);

const FirstStep = ({ handleNext, handleBack }: PropsStep) => {
  const [load, setLoad] = useState(false);
  const [done, setDone] = useState(false);
  const [payingNumber, setPayingNumber] = useState('875142874');
  const [voucherNumber, setVoucherNumber] = useState('89565656554');
  const loading = () => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
      setDone(true);
    }, 3000);
  };

  const onChangePaying = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPayingNumber(e.target.value);
    setDone(false);
  };

  const onChangeVoucher = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVoucherNumber(e.target.value);
    setDone(false);
  };

  return (
    <Stack width={1} alignItems="center" gap={1}>
      <Typography sx={{ fontWeight: 600 }}>פרטי השובר</Typography>
      <Typography>זה השובר שתרצו לשלם?</Typography>
      <Stack direction="row" gap={1} justifyContent="center">
        <TextField
          label="מספר משלם"
          value={payingNumber}
          onChange={(e) => onChangePaying(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Iconify icon="lucide:info" color="blue" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="מספר שובר (מסלקה)"
          value={voucherNumber}
          onChange={(e) => onChangeVoucher(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Iconify icon="lucide:info" color="blue" />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {done && <Info />}
      {!load && !done && (
        <Button
          variant="contained"
          sx={{
            color: 'white',
            bgcolor: '#0059d2',
            borderRadius: '25px',
            px: '40px',
          }}
          onClick={() => loading()}
        >
          כן, הציגו לי את הסכום
        </Button>
      )}
      {load && <Typography>רק רגע.. כבר מגיע הסכום</Typography>}
      {done && (
        <Button
          variant="contained"
          sx={{
            color: 'white',
            bgcolor: '#0059d2',
            borderRadius: '25px',
            px: '40px',
          }}
          onClick={() => handleNext()}
        >
          לבחירת אמצעי תשלום
        </Button>
      )}
    </Stack>
  );
};

const SecondStep = ({ handleNext, handleBack }: PropsStep) => {
  const [variant, setVariant] = useState(0);
  const [done, setDone] = useState(false);
  const modal = useBoolean();
  const isMobile = useResponsive('down', 'sm');
  const check = () => {
    if (variant === 2) setDone(true);
    if (variant === 1) modal.onTrue();
  };
  return (
    <>
      {!done ? (
        <Stack width={1} alignItems="center">
          <Info />
          <Stack direction="row" my={2} gap={2}>
            <Stack
              sx={{
                cursor: 'pointer',
                width: '140px',
                height: '120px',
                alignItems: 'center',
                border: variant === 1 ? '2px solid' : '1px solid',
                borderColor: variant === 1 ? 'blue' : '#d9d9d9',
                borderRadius: '5px',
                bgcolor: '#f5f5f5',
                justifyContent: 'space-between',
                padding: '10px',
                '&:hover': {
                  border: variant === 1 ? '2px solid blue' : '2px solid grey',
                },
              }}
              onClick={() => setVariant(1)}
            >
              <Image
                src="/assets/images/bit.png"
                width="100%"
                height="100%"
                sx={{ borderRadius: '10px' }}
              />
              <Typography sx={{ fontWeight: 600 }}>תשלום בביט</Typography>
            </Stack>
            <Stack
              sx={{
                cursor: 'pointer',
                width: '140px',
                height: '120px',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: variant === 2 ? '2px solid' : '1px solid',
                borderColor: variant === 2 ? 'blue' : '#d9d9d9',
                borderRadius: '5px',
                bgcolor: '#f5f5f5',
                padding: '10px',
                '&:hover': {
                  border: variant === 2 ? '2px solid blue' : '2px solid grey',
                },
              }}
              onClick={() => setVariant(2)}
            >
              <Iconify icon="ph:credit-card-thin" width="70%" />
              <Typography sx={{ fontWeight: 600 }}>כרטיס אשראי</Typography>
            </Stack>
          </Stack>
          <Button
            disabled={variant === 0}
            variant="contained"
            sx={{
              color: 'white',
              bgcolor: '#0059d2',
              borderRadius: '25px',
              px: '40px',
            }}
            onClick={() => check()}
          >
            המשך
          </Button>
        </Stack>
      ) : (
        <Stack gap={2}>
          <Typography sx={{ fontWeight: 1000, fontSize: '18px' }}>תשלום בכרטיס אשראי</Typography>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={3.8} xs={12}>
              <Stack gap={2}>
                <Typography sx={{ fontWeight: 600 }}>פרטי עסקה מאובטחת</Typography>
                <Stack>
                  <Typography sx={{ fontWeight: 600 }}>סכום לחיוב</Typography>
                  <Typography>716.25ש”ח</Typography>
                </Stack>
                <Stack>
                  <Typography sx={{ fontWeight: 600 }}>דוא”ל הרוכש</Typography>
                  <Typography>example@onecity.co.il</Typography>
                </Stack>
                <Stack>
                  <Typography sx={{ fontWeight: 600 }}>מטבע לחיוב</Typography>
                  <Typography>ILS</Typography>
                </Stack>
                <Stack>
                  <Typography sx={{ fontWeight: 600 }}>מספר הזמנה</Typography>
                  <Typography>202110211509</Typography>
                </Stack>
              </Stack>
            </Grid>
            {!isMobile && (
              <Grid
                item
                md={0.2}
                xs={12}
                sx={{
                  borderRight: '1px solid',
                  borderColor: 'rgb(217, 217, 217)',
                }}
              />
            )}
            <Grid item md={7.8} xs={12} sx={{ px: !isMobile ? '20px' : 0 }}>
              {isMobile && <Divider sx={{ my: 2, color: '#c3cfe7' }} />}
              <Stack>
                <Typography sx={{ fontWeight: 600 }}>פרטי כרטיס אשראי לתשלום</Typography>
                <Typography sx={{ opacity: 0.5 }}>ת.ז בעל\ת הכרטיס</Typography>
                <TextField />
                <Typography sx={{ opacity: 0.5 }}>מספר כרטיס</Typography>
                <TextField />
                <Stack direction="row" width={1} justifyContent="space-between" mt={2}>
                  <Stack width={isMobile ? 0.7 : 0.5} gap={1}>
                    <Typography sx={{ opacity: 0.5 }}>תוקף כרטיס</Typography>
                    <Stack direction="row" gap={1}>
                      <TextField select fullWidth defaultValue="חודש">
                        {[
                          'חודש',
                          '1',
                          '2',
                          '3',
                          '4',
                          '5',
                          '6',
                          '7',
                          '8',
                          '9',
                          '10',
                          '11',
                          '12',
                        ].map((option: string) => (
                          <MenuItem
                            key={option}
                            value={option}
                            sx={{
                              mx: 1,
                              borderRadius: 0.75,
                              typography: 'body2',
                              textTransform: 'capitalize',
                            }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField select fullWidth defaultValue="שנה">
                        {['שנה', '2024', '2025', '2026', '2027', '2028', '2029'].map(
                          (option: string) => (
                            <MenuItem
                              key={option}
                              value={option}
                              sx={{
                                mx: 1,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                              }}
                            >
                              {option}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Stack>
                  </Stack>
                  <Stack width={0.2} gap={1} alignItems="center">
                    <Stack direction="row">
                      <Typography sx={{ opacity: 0.5 }}>Cvv</Typography>
                      <Iconify icon="ph:question-light" color="blue" />
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <TextField fullWidth defaultValue="" />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" gap={1} mt={2}>
                  <Button
                    variant="text"
                    sx={{
                      textDecoration: 'underLine',
                      px: '40px',
                    }}
                  >
                    ניקוי
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      // color: '#0059d2',
                      color: 'black',
                      borderColor: '#0059d2',
                      borderRadius: '25px',
                      px: '40px',
                      '&:hover': {
                        color: 'white',
                        borderColor: '#0059d2',
                        bgcolor: '#0059d2',
                      },
                    }}
                  >
                    ביטול
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      color: 'white',
                      bgcolor: '#0059d2',
                      borderRadius: '25px',
                      px: '40px',
                    }}
                    onClick={() => handleNext()}
                  >
                    תשלום
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}
      <Modal open={modal.value} onClose={() => modal.onFalse()}>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '500px',
            minHeight: 250,
            bgcolor: 'background.paper',
            borderRadius: !isMobile ? '20px' : '',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            onClick={() => modal.onFalse()}
            sx={{ cursor: 'pointer' }}
          >
            <Typography>חזרה</Typography>
            <Iconify icon="ep:arrow-left-bold" sx={{ cursor: 'pointer', color: 'blue' }} />
          </Stack>
          <Stack direction="row" width={1} alignItems="center" justifyContent="center" gap={1}>
            <Typography sx={{ fontWeight: 600 }}>אתם מועברים לאפליקצית</Typography>
            <Image src="/assets/images/bit.png" />
          </Stack>
          <Stack width={1} alignItems="center">
            <Image src="/assets/images/hello.png" sx={{ width: '150px' }} />
          </Stack>
          <Stack width={1} alignItems="center">
            <Typography textAlign="center">
              שימו לב ברגע שתסיימו את התשלום דרך הביט תקבלו הודעת
            </Typography>
            <Typography>תשלום דרך המייל\SMS</Typography>
            <Typography>תוכלו לחזור לפה ולבצע תשלומים נוספים</Typography>
          </Stack>
          <Stack width={1} alignItems="center" mt={2}>
            <Button
              disabled={variant === 0}
              variant="contained"
              sx={{
                color: 'white',
                bgcolor: '#0059d2',
                borderRadius: '25px',
                px: '40px',
              }}
              onClick={() => handleNext()}
            >
              מעבר לאפליקצית Bit
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

const ThirdStep = () => (
  <Stack width={1} alignItems="center">
    <Typography sx={{ fontWeight: 600 }}>תשלום בוצע בהצלחה</Typography>
    <Image src="/assets/images/v-animation.gif" />
    <Typography sx={{ fontWeight: 600 }}>תרצו לבצע פעולות נוספות?</Typography>
    <Stack direction="row" mt={2} gap={1} flexWrap="wrap">
      <Stack direction="row" sx={{ cursor: 'pointer', '&:hover': { color: 'rgb(0, 89, 210)' } }}>
        <Typography sx={{ fontWeight: 600 }}>שוברי ארנונה לנכס זה</Typography>
        <Iconify icon="ep:arrow-left-bold" />
      </Stack>
      <Stack direction="row" sx={{ cursor: 'pointer', '&:hover': { color: 'rgb(0, 89, 210)' } }}>
        <Typography sx={{ fontWeight: 600 }}>תשלום בהוראת קבע</Typography>
        <Iconify icon="ep:arrow-left-bold" />
      </Stack>
      <Stack direction="row" sx={{ cursor: 'pointer', '&:hover': { color: 'rgb(0, 89, 210)' } }}>
        <Typography sx={{ fontWeight: 600 }}>רישום לקבלת שוברים במייל</Typography>
        <Iconify icon="ep:arrow-left-bold" />
      </Stack>
      <Stack direction="row" sx={{ cursor: 'pointer', '&:hover': { color: 'rgb(0, 89, 210)' } }}>
        <Typography sx={{ fontWeight: 600 }}>הסטוריית תשלומים</Typography>
        <Iconify icon="ep:arrow-left-bold" />
      </Stack>
    </Stack>
  </Stack>
);
