'use client';

import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, TextField, InputAdornment } from '@mui/material';

import Label from '../../../components/label';
import Image from '../../../components/image';
import Iconify from '../../../components/iconify';
import { BlockType } from '../../../types/page-generator';
import { useResponsive } from '../../../hooks/use-responsive';
import SelectGiftWrapper from '../../../components/select-gift';
import { SelectCoupon } from './Coupons/SelectCoupon';

type Props = {
  block: BlockType | undefined;
};

export const WalletProducts = ({ block }: Props) => {
  const [currentTab, setCurrentTab] = useState(-1);
  const localTabs = [
    { id: 0, label: 'מתנה לבחירה', show: !!block?.settings?.enableBenefitsCoupons },
    { id: 1, label: 'הקופונים שלי' , show: !!block?.settings?.enableBenefitsGift },
    {
      id: 2,
      label: 'מוצרים מסובסדים',
      show: !!block?.settings?.enableBenefitsSubsidizedProducts,
    },
    { id: 3, label: 'אתרים', show: !!block?.settings?.enableBenefitsSites },
  ];

  useEffect(() => {
    const idx: any = localTabs.find((tab) => tab.show)?.id;
    setCurrentTab(idx);
  }, []);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };
  const isMobile = useResponsive('down', 'sm');
  const smUp = useResponsive('up', 'sm');

  const data = [
    {
      id: 1,
      head: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      title:
        'ההטבה מקנה כניסה לילד (לגילאי 2-13) לאחד מסניפי רשת Funky Monkey - רשת מתחמי חוויה, אקסטרים ומשחקים לכל המשפחה בפריסה ארצית!',
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      img: '/assets/images/happy-gift/wallet/4.png',
      brand: '/assets/images/happy-gift/products/brands/7.png',
    },
    {
      id: 2,
      head: "כניסה למתחם הנינג'ה-SWING ראשון לציון",
      title:
        "ההטבה מקנה כניסה חד פעמית ליחיד למתחם הנינג'ה- SWING ראשון לציון- אימוני נינג'ה לילדים ונוער",
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      img: '/assets/images/happy-gift/wallet/2.png',
      brand: '/assets/images/happy-gift/products/brands/1.png',
    },
    {
      id: 3,
      head: 'ארוחת בוקר זוגית ברשת קפה קפה',
      title: 'ההטבה מקנה ארוחת בוקר זוגית למימוש בסניפי רשת קפה קפה',
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      img: '/assets/images/happy-gift/wallet/3.png',
      brand: '/assets/images/happy-gift/products/brands/9.png',
    },
    {
      id: 4,
      head: 'שייט בננות- אקסטרים ים נהריה',
      title: 'ההטבה מקנה כרטיס יחיד לשייט בננות אבובים- שייט בבנה כפולה 12 אנשים',
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      img: '/assets/images/happy-gift/wallet/1.png',
      brand: '/assets/images/happy-gift/products/brands/8.png',
    },
  ];

  const secondData = [
    {
      id: 1,
      head: "עיסוי בסגנון תאילנדי ליחיד 45 דקות בספא צ'אנג מאי",
      title:
        "ההטבה מקנה עיסוי כולל שמנים בסגנון תאילנדי למשך 45 דקות ליחיד בספא צ'אנג מאי סניף בוגרוש 29, תל אביב/ סניף נגבה 24, ראשון לציון.",
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      img: '/assets/images/happy-gift/wallet/7.png',
      brand: '/assets/images/happy-gift/products/brands/12.png',
    },
    {
      id: 2,
      head: 'זרי פרחים מתנה (נגיעות של אושר, נגיעות סגולות, חלום ורוד)',
      title:
        'זרי פרחים מעוצבים (נגיעות של אושר, נגיעות סגולות, חלום ורוד) לחגים או לכל אירוע, כולל דמי משלוח*',
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      img: '/assets/images/happy-gift/wallet/8.png',
      brand: '/assets/images/happy-gift/products/brands/13.png',
    },
  ];

  const seconSecondData = [
    {
      id: 1,
      head: "פיצה משפחתית L + מנה נלווית ברשת פאפא ג'ונס",
      title: "ההטבה מקנה פיצה קלאסית משפחתית L + מנה נלווית ברשת פאפא ג'ונס",
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      img: '/assets/images/happy-gift/wallet/6.png',
      brand: '/assets/images/happy-gift/products/brands/11.png',
    },
    {
      id: 2,
      head: 'שני זוגות משקפי שמש לרכישה באתר Eye Glow',
      title: 'ההטבה מקנה שני זוגות משקפי שמש לרכישה באתר Eye Glow כולל משלוח עד הבית',
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      img: '/assets/images/happy-gift/wallet/5.png',
      brand: '/assets/images/happy-gift/products/brands/10.png',
    },
  ];

  const thirdData = [
    {
      id: 1,
      head: "עיסוי בסגנון תאילנדי ליחיד 45 דקות בספא צ'אנג מאי",
      title:
        "ההטבה מקנה עיסוי כולל שמנים בסגנון תאילנדי למשך 45 דקות ליחיד בספא צ'אנג מאי סניף בוגרוש 29, תל אביב/ סניף נגבה 24, ראשון לציון.",
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      priceTitle: 'מחיר מלא:',
      lastTitle: 'סכום מסובסד:',
      img: '/assets/images/happy-gift/wallet/7.png',
      brand: '/assets/images/happy-gift/products/brands/12.png',
    },
    {
      id: 2,
      head: 'זרי פרחים מתנה (נגיעות של אושר, נגיעות סגולות, חלום ורוד)',
      title:
        'זרי פרחים מעוצבים (נגיעות של אושר, נגיעות סגולות, חלום ורוד) לחגים או לכל אירוע, כולל דמי משלוח*',
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      priceTitle: 'מחיר מלא',
      lastTitle: 'סכום מסובסד:',
      img: '/assets/images/happy-gift/wallet/8.png',
      brand: '/assets/images/happy-gift/products/brands/13.png',
    },
    {
      id: 3,
      head: "פיצה משפחתית L + מנה נלווית ברשת פאפא ג'ונס",
      title: "ההטבה מקנה פיצה קלאסית משפחתית L + מנה נלווית ברשת פאפא ג'ונס",
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      priceTitle: 'מחיר מלא:',
      lastTitle: 'סכום מסובסד:',
      img: '/assets/images/happy-gift/wallet/6.png',
      brand: '/assets/images/happy-gift/products/brands/11.png',
    },
    {
      id: 4,
      head: 'שני זוגות משקפי שמש לרכישה באתר Eye Glow',
      title: 'ההטבה מקנה שני זוגות משקפי שמש לרכישה באתר Eye Glow כולל משלוח עד הבית',
      subTitle: 'שולח:',
      timeTitle: 'תקופת תוקף:',
      priceTitle: 'מחיר מלא:',
      lastTitle: 'סכום מסובסד:',
      img: '/assets/images/happy-gift/wallet/5.png',
      brand: '/assets/images/happy-gift/products/brands/10.png',
    },
  ];

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <Tabs
        sx={{
          mb: 3,
          '& .MuiButtonBase-root': { fontSize: '20px' },
          '& .MuiTabScrollButton-root': {
            display: !isMobile || !localTabs.find((item: any) => item.show) ? 'none' : '',
          },
        }}
        value={currentTab}
        onChange={handleChangeTab}
        scrollButtons={localTabs.filter((item: any) => item.show).length > 1 ? 'auto' : false} // Отключаем кнопки, если одна вкладка
      >
        {localTabs.map((tab, id) => (
          <Tab key={id} label={tab.label} sx={{ display: tab.show ? '' : 'none' }} />
        ))}
      </Tabs>
      {currentTab === 1 && <SelectCoupon />}
      {currentTab === 0 && <SelectGiftWrapper selectTabGiftWrapper={() => {
        setCurrentTab(0);
      }}/>}
      {currentTab === 3 && (
        <Stack gap={3}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Stack direction="row-reverse" gap={2}>
                <Stack sx={{ color: 'primary.main' }}>
                  <Typography sx={{ fontWeight: 800 }}>happy.b24chat.co.il</Typography>
                  <Typography sx={{ color: 'grey.600' }}>יום הולדת שמח!</Typography>
                  <Stack direction="row" sx={{ fontWeight: 700 }}>
                    <Typography sx={{ color: 'grey.600' }}>תקציב: </Typography> ₪225
                  </Stack>
                  <Stack direction="row" sx={{ color: 'success.dark', fontWeight: 700 }}>
                    <Typography sx={{ color: 'grey.600' }}>יתרה: </Typography>
                    ₪190
                  </Stack>
                  <Stack direction="row" sx={{ fontWeight: 700 }}>
                    <Typography sx={{ color: 'grey.600' }}>תקופת תוקף:</Typography>
                    01.01.2023 - 31.12.2023
                  </Stack>
                  <Stack direction="row" gap={2} mt={2}>
                    <Button variant="contained" color="primary" sx={{ borderRadius: '20px' }}>
                      השתמש בקופון
                    </Button>
                  </Stack>
                </Stack>
                <Image
                  src="/assets/images/happy-gift/wallet/raval.png"
                  sx={{ borderRadius: '15px', width: '40%' }}
                />
              </Stack>
            </Grid>
            <Grid item md={6} xs={12}>
              <Stack direction="row-reverse" gap={2}>
                <Stack sx={{ color: 'primary.main' }}>
                  <Typography sx={{ fontWeight: 800 }}>happy.b24chat.co.il</Typography>
                  <Typography sx={{ color: 'grey.600' }}>יום הולדת שמח!</Typography>
                  <Stack direction="row" sx={{ fontWeight: 700 }}>
                    <Typography sx={{ color: 'grey.600' }}>תקציב: </Typography> ₪225
                  </Stack>
                  <Stack direction="row" sx={{ color: 'success.dark', fontWeight: 700 }}>
                    <Typography sx={{ color: 'grey.600' }}>יתרה: </Typography>
                    ₪190
                  </Stack>
                  <Stack direction="row" sx={{ fontWeight: 700 }}>
                    <Typography sx={{ color: 'grey.600' }}>תקופת תוקף:</Typography>
                    01.01.2023 - 31.12.2023
                  </Stack>
                  <Stack direction="row" gap={2} mt={2}>
                    <Button variant="contained" color="primary" sx={{ borderRadius: '20px' }}>
                      השתמש בקופון
                    </Button>
                  </Stack>
                </Stack>
                <Image
                  src="/assets/images/happy-gift/wallet/raval.png"
                  sx={{ borderRadius: '15px', width: '40%' }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      )}
      {currentTab === 2 && (
        <Grid container spacing={2}>
          {thirdData.map((item) => (
            <Grid item md={6} xs={12} key={item?.id}>
              <Card>
                <Stack key={item.id} direction="row-reverse" gap={2} height={1}>
                  <Stack
                    sx={{
                      width: '70%',
                      color: 'primary.main',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ fontWeight: 800 }}>{item.head}</Typography>
                        <Label color="success" sx={{ mx: 1 }}>
                          תקף
                        </Label>
                      </Stack>
                      <Typography sx={{ color: 'grey.600', fontSize: '18px' }}>
                        {item.title}
                      </Typography>
                      <Stack direction="row" sx={{ fontWeight: 700 }}>
                        <Typography sx={{ color: 'grey.600' }}>{item.subTitle}</Typography>{' '}
                        BRUNOFACTORY
                      </Stack>
                    </Stack>
                    {smUp && (
                      <Stack>
                        <Stack direction="row" sx={{ fontWeight: 700 }}>
                          <Typography sx={{ color: 'grey.600' }}>{item.timeTitle}</Typography>
                          01.01.2023 - 31.12.2023
                        </Stack>
                        <Stack direction="row" sx={{ fontWeight: 700 }}>
                          <Typography sx={{ color: 'grey.600' }}>{item.priceTitle}</Typography>
                          ₪220
                        </Stack>
                        <Stack direction="row">
                          <Typography sx={{ color: 'grey.600' }}>
                            {item.lastTitle}
                            <span style={{ fontWeight: 700, color: 'green' }}> ₪187</span>
                            (5 מוצרים זמינים במחיר מסובסד)
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          gap={2}
                          my={2}
                          height={smUp ? '30px' : '40px'}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Stack direction="row" gap={1}>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ borderRadius: '20px', fontSize: '12px' }}
                            >
                              השתמש בקופון
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              sx={{ borderRadius: '20px', fontSize: '12px' }}
                            >
                              ספקים
                            </Button>
                          </Stack>
                          <TextField
                            sx={{
                              mx: 1,
                              width: '15%',
                              '& .MuiInputBase-root': {
                                padding: 0,
                                justifyContent: 'space-between',
                              },
                              '& input': { width: '10%', py: '10px' },
                            }}
                            value="1"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Iconify icon="ic:round-plus" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Iconify icon="ic:round-minus" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                  <Box sx={{ width: '30%', position: 'relative' }}>
                    <Image src={item.img} sx={{ borderRadius: '15px', width: '100%' }} />
                    <Box
                      sx={{
                        borderRadius: '10px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'secondary.contrastText',
                        m: 1,
                        width: '20%',
                      }}
                    >
                      <Image src={item.brand} sx={{ borderRadius: '15px', width: '100%' }} />
                    </Box>
                  </Box>
                </Stack>
                {!smUp && (
                  <Stack sx={{ mb: 2 }}>
                    <Stack direction="row" sx={{ fontWeight: 700 }}>
                      <Typography sx={{ color: 'grey.600' }}>{item.timeTitle}</Typography>
                      01.01.2023 - 31.12.2023
                    </Stack>
                    <Stack direction="row" sx={{ fontWeight: 700 }}>
                      <Typography sx={{ color: 'grey.600' }}>{item.priceTitle}</Typography>
                      ₪220
                    </Stack>
                    <Stack direction="row">
                      <Typography sx={{ color: 'grey.600' }}>
                        {item.lastTitle}
                        <span style={{ fontWeight: 700, color: 'green' }}> ₪187</span>
                        (5 מוצרים זמינים במחיר מסובסד)
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={2} mt={2} height={smUp ? '' : '40px'}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ borderRadius: '20px', fontSize: '12px' }}
                      >
                        השתמש בקופון
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ borderRadius: '20px', fontSize: '12px' }}
                      >
                        ספקים
                      </Button>
                      <TextField
                        sx={{
                          width: '35%',
                          '& input': { width: '10%', py: '10px' },
                        }}
                        value="1"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon="ic:round-plus" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Iconify icon="ic:round-minus" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Stack>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
