'use client';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import FormProvider from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';

import TabHistory from './tabs/tab-history';
import TabUseCode from './tabs/tab-use-code';
import TabCancelCode from './tabs/tab-cancel-code';
import { IMerchant } from '../../../../../types/merchant';
import { useAuthContext } from '../../../../../auth/hooks';
import { useAppContext } from '../../../../../contexts/AppContext';
import { getCustomerByPhoneOrMail } from '../../../../../api/customers';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import { ICustomerItem } from '../../../../../types/customer-citypeople';
import { IWalletEventOptionsItem, IWalletEventOptionsFilters } from '../../../../../types/wallet';

// ----------------------------------------------------------------------

type valueType = {
  area: string;
  category: string;
  id: string;
  img: string;
  name: string;
  price: number;
};

const defaultFilters: IWalletEventOptionsFilters = {
  event: [],
  name: '',
};

const TABS = [
  {
    value: 'Cancel code',
    label: 'Cancel code',
    icon: (
      <SvgColor
        src="/assets/icons/navbar/ic_disabled.svg"
        sx={{ width: 24, height: 24 }}
      />
    ),
  },
  {
    value: 'Use code',
    label: 'Use code',
    icon: (
      <SvgColor
        src="/assets/icons/navbar/ic_dashboard.svg"
        sx={{ width: 24, height: 24 }}
      />
    ),
  },
  {
    value: 'History',
    label: 'History',
    icon: <Iconify icon="mdi:clock-time-four" width={24} />,
  },
];

// ----------------------------------------------------------------------

const Pos: FC<ComponentTypeProps> = ({ block }) => {
  const { user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  const { t } = useTranslate();
  const settings = useSettingsContext();
  const [currentTab, setCurrentTab] = useState('Cancel code');
  const [carts, setCarts] = useState<valueType[]>([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [selectedMerchantId, setSelectedMerchantId] = useState<number | null>(null);
  const [customer, setCustomer] = useState<ICustomerItem[] | any>([]);

  // const {
  //   merchants,
  //   // loading,
  //   // count: merchantsCount,
  // } = useAppSelector((state) => state.merchantsReducer);
  // const { selectedMerchant } = useAppSelector((state) => state.walletReducer);

  useEffect(() => {
    // dispatch(getMerchants(MERCHANT_TYPES.merchant));
  }, []);

  useEffect(() => {
    if (user) {
      getCustomerByPhoneOrMail(user.phone, user.email, dispatch).then((response: any) => {
        if (response) {
          setCustomer(response[0]);
        }
      });
    }
  }, [user]);

  // const [eventOptions, setEventOptions] = useState<IWalletEventOptionsItem[]>(defaultEventOptions);

  // useEffect(() => {
  //   setCurrentTab('Wallet');
  // }, []);

  // const dataFiltered = applyFilter({
  //   inputData: eventOptions,
  //   filters,
  // });

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(newValue);
    },
    [],
  );

  const NewSchema = Yup.object().shape({
    project: Yup.string(),
    groups: Yup.string(),
    user: Yup.mixed<any>(),
  });

  const defaultValues = useMemo(
    () => ({
      project: '',
      groups: '',
      user: [],
      //
    }),
    [],
  );

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  // const addToCart = useCallback(
  //   (value: valueType) => {
  //     const array = [...carts];
  //     array.push(value);
  //     setCarts(array);
  //   },
  //   [carts],
  // );

  // const handleFilters = useCallback((name: string, filterValue: IWalletTableFilterValue) => {
  //   setFilters((prevState: any) => ({
  //     ...prevState,
  //     [name]: filterValue,
  //   }));
  // }, []);

  // const handleFilterEvent = useCallback(
  //   (event: SelectChangeEvent<string[]>) => {
  //     handleFilters('event', event.target.value);
  //   },
  //   [handleFilters],
  // );

  // const handleSearchName = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     handleFilters('name', event.target.value);
  //   },
  //   [handleFilters],
  // );

  const handleMerchantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = Number(event.target.value); // Преобразуем значение в число
    setSelectedMerchantId(selectedValue); // Сохраняем id в состояние
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <TextField
        select
        fullWidth
        sx={{ mt: 1 }}
        label="Merchant"
        value={selectedMerchantId || ''} // Связываем значение с состоянием
        onChange={handleMerchantChange} // Обработка изменения
      >
        {customer &&
          customer.merchants &&
          customer.merchants.map((option: IMerchant) => (
            <MenuItem
              key={option.id}
              value={option.id} // Сохраняем id как value
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option.title} {/* Отображаем имя мерчанта */}
            </MenuItem>
          ))}
      </TextField>
      <>
        {carts.length ? (
          <Card
            sx={{
              width: '80px',
              height: '50px',
              position: 'fixed',
              zIndex: 100,
              borderRadius: '20px',
              right: '0px',
              top: '100px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Badge badgeContent={carts.length} color="error">
              <Iconify icon="solar:cart-3-bold" width={25} ml={2} />
            </Badge>
          </Card>
        ) : null}
      </>

      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Card
          sx={{
            width: '100%',
            my: { md: 5 },
            padding: { md: 2 },
            height: {ms: '100%', xs: '100px'},
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            TabIndicatorProps={{ sx: { display: 'none' } }}
            sx={{
              width: 1,
              ml: { xs: 1 },
              my: { xs: 5 },
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                width: '100%',
                pr: { md: 3 },
                justifyContent: {
                  sm: 'space-between',
                  md: 'space-between',
                },
              },
              '& button': { width: '25%', minHeight: '30px', fontSize: { xs: '12px' } },
              '&.MuiButtonBase-root': { height: '10px' },
              '& button.Mui-selected': { background: '#EEEFF3' },
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                icon={tab.icon}
                label={t(tab.label)}
              />
            ))}
          </Tabs>
        </Card>
        {currentTab === 'Cancel code' && <TabCancelCode selectedMerchantId={selectedMerchantId} />}
        {currentTab === 'Use code' && <TabUseCode selectedMerchantId={selectedMerchantId} />}
        {currentTab === 'History' && <TabHistory selectedMerchantId={selectedMerchantId} />}
      </FormProvider>
    </Container>
  );
};

function applyFilter({
                       inputData,
                       filters,
                     }: {
  inputData: IWalletEventOptionsItem[];
  filters: IWalletEventOptionsFilters;
}) {
  const { name } = filters;

  if (name) {
    inputData = inputData.filter(
      (item) =>
        item?.name
          .toString()
          .toLowerCase()
          .indexOf(name.toString().toLowerCase()) !== -1,
    );
  }
  return inputData;
}

export default Pos;

// ----------------------------------------------------------------------
