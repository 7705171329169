import Stack from '@mui/material/Stack';

import NavList from './nav-list';
import { NavProps } from '../types';
import { useResponsive } from '../../../../hooks/use-responsive';

// ----------------------------------------------------------------------

export default function NavDesktop({ data, block }: NavProps) {
  const isMobile = useResponsive('down', 'sm');
const width =  block?.settings?.enableFullWidthLinks ? '50%' : 'auto';
const justifyContent = block?.settings?.enableFullWidthLinks ? 'space-around' : '';
  return (
    <Stack component="nav" direction="row" spacing={5}
           sx={{ mr: 2.5, height: 1, width: isMobile ? '100%' : width, justifyContent: isMobile ? 'center' : justifyContent }}>
      {data.map((list) => (
        <NavList key={list.title} data={list} />
      ))}
    </Stack>
  );
}
