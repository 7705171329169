'use client';

// types

// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

// hooks

// locales

// components
import { ITransaction } from 'src/types/club-cards';

import Label from '../../../components/label';
import { useLocales, useTranslate } from '../../../locales';
import Iconify from '../../../components/iconify';
import { getTransactionTypeIcon, getTransactionTypeColor } from '../../../utils/color-maps';

// ----------------------------------------------------------------------

type Props = {
  row: ITransaction;
  cellStyle?: any;
};

export default function TransactionsTableRow({ row, cellStyle }: Props) {
  const { t } = useTranslate();
  const { currentLang } = useLocales();
  const { amount, transactionType, createdAt } = row;

  const date = new Date(createdAt);

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',      // День в формате двух цифр
    month: '2-digit',    // Месяц в формате двух цифр
    year: 'numeric',     // Год в формате четырех цифр
    hour: '2-digit',     // Часы в формате двух цифр
    minute: '2-digit',   // Минуты в формате двух цифр
    timeZone: 'Asia/Jerusalem', // Временная зона
  };
  const zone = currentLang.value === 'he' ? 'he-IL' : 'en-US'
  const formattedDate = date.toLocaleString(zone, options);

  return (
    <TableRow hover>
      <TableCell style={cellStyle}>
        <Label
          variant="soft"
          color={getTransactionTypeColor(transactionType)}
          endIcon={<Iconify width={16} icon={getTransactionTypeIcon(transactionType)} />}
        >
          {t(transactionType)}
        </Label>
      </TableCell>

      <TableCell style={cellStyle} sx={{ whiteSpace: 'nowrap' }}>
        {formattedDate}
      </TableCell>

      <TableCell style={cellStyle} sx={{ whiteSpace: 'nowrap' }}>
        {amount}
      </TableCell>
    </TableRow>
  );
}
