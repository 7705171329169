import useSWR from 'swr';
import { useMemo } from 'react';
import { Dispatch, createSlice } from '@reduxjs/toolkit';

import { BOT_ID, RAAYONIT_BOT_ID } from 'src/config-global';

import axios, { fetcher } from '../../utils/axios';
import { ICustomer, ICustomerState } from '../../types/customers';

// ----------------------------------------------------------------------

const botId = +BOT_ID;

const initialState: ICustomerState = {
  isLoading: false,
  error: null,
  customers: [],
  customerMailing: [],
  groups: [],
  activeGroup: 'Main',
};

type GetCustomerProps = {
  limit: number;
  offset: number;
  relations?: string[];
  search?: string;
  tagsIds?: (number | null)[];
  fullName?: string
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setCustomers(state, action) {
      state.isLoading = false;
      state.customers = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setCustomerMailing(state, action) {
      state.customerMailing = action.payload;
    },

    setCustomerGroups(state, action) {
      state.groups = action.payload;
    },

    pushCustomerGroup(state, action) {
      state.groups.push(action.payload);
    },

    setActiveGroup(state, action) {
      state.activeGroup = action.payload;
    },
  },
});

export const customerActions = slice.actions;
// Reducer
export default slice.reducer;

export function changeActiveGroup(groupName: string) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.setActiveGroup(groupName));
  };
}

export function useGetCustomers(merchantId: number) {
  const URL = [`/customer/getByMerchantId`, { params: { merchantId } }];
  const { data } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });
  const memoizedValue = useMemo(
    () => ({
      customers: data?.payload,
    }),
    [data?.payload]
  );

  return memoizedValue;
}

export function useGetAllCustomers(props: GetCustomerProps) {
  const URL = [`/admin/customer`, { params: { ...props, active: true, botId: BOT_ID } }];
  const { data } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });
  const memoizedValue = useMemo(
    () => ({
      customers: data?.data,
      count: data?.count,
    }),
    [data?.data]
  );
  return memoizedValue;
}

export function getCustomers(eventId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    // const { activeGroup } = getState().customer;

    // const filter = { merchantIds: [eventId], order: 'DESC', activeGroup };

    // const PAGE = 1;
    // const LIMIT = 100;

    try {
      const { data } = await axios.get(`/customer/getByMerchantId`, {
        params: {
          botId,
          merchantId: eventId,
        },
      });

      dispatch(slice.actions.setCustomers(data.payload));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addCustomerBudget(
  fromEventId: number,
  toCustomerId: number,
  count: number | string,
  userId: number
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.put(`merchant/${RAAYONIT_BOT_ID}/budget/split/customer`, {
        fromEventId,
        toCustomerId,
        count,
        userId,
      });
      return data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateError(error: any) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.hasError(error));
  };
}

export function setCustomerMailing(customers: ICustomer[]) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.setCustomerMailing(customers));
  };
}

// export function getCustomerGroups() {
//   return async (dispatch: Dispatch) => {
//     dispatch(slice.actions.startLoading());
//     // const allCustomers = getState().customer.customers;
//     try {
//       const { data } = await axios.get(`tags/user/${RAAYONIT_BOT_ID}`);
//       if (data) {
//         const hasMain = data.some(
//           (item: { name: string }) => item.name === 'Main',
//         );
//         if (!hasMain) {
//           await API({
//             url: `tags/${RAAYONIT_BOT_ID}/client`,
//             method: 'POST',
//             data: {
//               name: 'Main',
//               clientIds: [],
//             },
//           });
//         }
//       }
//       dispatch(slice.actions.setCustomerGroups(data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//       throw error;
//     }
//   };
// }

// export function addCustomerGroup(name: string, clientIds: number[]) {
//   return async (dispatch: Dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const { data } = await API({
//         url: `tags/${RAAYONIT_BOT_ID}/client`,
//         method: 'POST',
//         data: {
//           name,
//           clientIds,
//         },
//       });
//       dispatch(slice.actions.pushCustomerGroup(data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//       throw error;
//     }
//   };
// }
