// ----------------------------------------------------------------------
/* eslint-disable import/no-cycle */
import { Ib2bProduct } from './b2b';
import { ISMBAccount } from './smbAccount';
import { DocumentImage } from './history-city-people';

export type IOrderTableFilterValue = string | Date | null;

export type IOrderTableFilters = {
  name: string;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
};

// ----------------------------------------------------------------------

export type IOrderHistory = {
  orderTime: Date;
  paymentTime: Date;
  deliveryTime: Date;
  completionTime: Date;
  timeline: {
    title: string;
    time: Date;
  }[];
};

export type IOrderShippingAddress = {
  fullAddress: string;
  phoneNumber: string;
};

export type IOrderPayment = {
  cardType: string;
  cardNumber: string;
};

export type IOrderDelivery = {
  shipBy: string;
  speedy: string;
  trackingNumber: string;
};

export type IOrderCustomer = {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  ipAddress: string;
};

export type IOrderProductItem = {
  id: string;
  sku: string;
  name: string;
  price: number;
  coverUrl: string;
  quantity: number;
};

export type IOrderItem = {
  id: string;
  taxes: number;
  status: string;
  shipping: number;
  discount: number;
  subTotal: number;
  orderNumber: string;
  totalAmount: number;
  totalQuantity: number;
  history: IOrderHistory;
  customer: IOrderCustomer;
  delivery: IOrderDelivery;
  items: IOrderProductItem[];
  createdAt: Date;
};

export type OrderItemInterface = {
  id: string;
  amount: number;
  createdAt: string;
  discountPercent: string;
  quantity: number;
  productId: string;
  products: Ib2bProduct[];
  product: Ib2bProduct;
  cards: IIssuedCard[];
};

export interface IOrder {
  id: string;
  orderNumber: string;
  botId: number;
  userId: string;
  status: string;
  documentImageUrl: string;
  transactionId?: null;
  createdAt: string;
  smbAccountId: string;
  sendGiftEventItemId?: null;
  adminId?: null;
  blocks?: any;
  additionalInformation?: any;
  activityId: string;
  orderItems?: (OrderItemsEntity)[] | null;
  documents?: any;
  smbAccount: SmbAccount;
  attributes?: any;
  attributeValues?: any;
  orderVirtualCards?: any;
  smbActivity: SmbActivity;
  attributeSets?: any;
  total: number;
  discountTotal: number;
  vatTotal: number;
  customer: Customer;
}
export interface OrderItemsEntity {
  id: string;
  productId: string;
  virtualCardTypeId?: null;
  quantity: number;
  priceAtTimeOfPurchase: number;
  vatRateAtTimeOfPurchase: number;
  discountPercent: number;
  vatIncluded: boolean;
  amount?: null;
  createdAt: string;
  deliveryOptionId?: null;
  deliveryOptionPrice?: null;
  product: Product;
  cards?: any;
  attributeValues?: any;
  deliveryOption?: null;
}
export interface Product {
  id: string;
  botId: number;
  title: string;
  description: string;
  content: string;
  active: boolean;
  type: string;
  sku: string;
  foreignId: string;
  mainImageURL: string;
  galleryImageURLs?: null;
  metaTitle?: null;
  metaDescription?: null;
  metaKeywords?: null;
  createdAt: string;
  metadata: Metadata;
  provider: string;
  stock: number;
  useStock: boolean;
  prices?: (PricesEntity)[] | null;
  attributes?: any;
  attributeSets?: any;
  attributeValues?: any;
}
export interface Metadata {
}
export interface PricesEntity {
  id: number;
  product_id: string;
  type: string;
  value: number;
  vatIncluded: boolean;
}
export interface SmbAccount {
  id: string;
  status: string;
  balance: string;
  createdAt: string;
  merchantId: number;
  merchant: Merchant;
}
export interface Merchant {
  id: number;
  botId: number;
  title: string;
  description: string;
  content?: null;
  country: string;
  city: string;
  street: string;
  house: string;
  url: string;
  phone: string;
  foreignId?: null;
  latitude: number;
  longitude: number;
  active: boolean;
  mainImageURL: string;
  bannerImage?: null;
  galleryImageURLs?: null;
  type: string;
  metaTitle?: null;
  metaDescription?: null;
  metaKeywords?: null;
  networkId?: null;
  createdAt: string;
  metadata: Metadata;
  discountAmount: number;
  assigneeIds?: any;
  assigneeEmployeeIds?: any;
  bankName: string;
  bankAccountNumber: string;
  bankDepartment: string;
  companyNumber: number;
  typeOfAgreement?: null;
  kosherType?: null;
  franchise?: null;
  attributeSets?: any;
  attributeValues?: any;
}
export interface SmbActivity {
  id: string;
  name: string;
  balance: string;
  domain: string;
  imageUrl: string;
  createdAt: string;
  updatedAt: string;
}
export interface Customer {
  id: string;
  ssoUserId: string;
  botId: number;
  firstName: string;
  lastName?: null;
  passportNumber?: null;
  type: string;
  email?: null;
  pointsBalance: string;
  gender?: null;
  birthday?: null;
  country?: null;
  city?: null;
  job?: null;
  address?: null;
  assigned?: null;
  isVerified: boolean;
  activeCustomer?: null;
  status: string;
  foreignId: string;
  createdAt: string;
  updatedAt: string;
  permissions?: any;
  cashbackProgramId?: null;
  avatar?: null;
  phones?: any;
  socialLinks?: any;
  attributes?: any;
  virtualCards?: any;
  customerGroups?: any;
  attributeSets?: any;
  attributeValues?: any;
}


export type IIssuedCard = {
  id: string;
  userId: string;
  botId: number;
  cardNumber: string;
  cardPin: string;
  type: IIssuedCardType;
  status: IIssuedCardStatus;
  balance: string;
  createdAt: string;
  product?: Ib2bProduct;
};

export enum IIssuedCardType {
  BENEFIT = 'BENEFIT',
  LOAD = 'LOAD',
  // TODO: add more
}

export enum IIssuedCardStatus {
  // TODO: check if they are really like this on the backend
  NOT_USED = 'NOT_USED',
  USED = 'USED',
  PARTIALLY_USED = 'PARTIALLY_USED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
}
