'use client'

import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Tab, Tabs, Box, Stack, Typography } from '@mui/material';
import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import Carousel from '../../../../components/carousel';
import { ICustomer } from '../../../../types/customers';
import { useGetAllCustomers } from '../../../../redux/slices/customers';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

const Customers: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const [activeTab, setActiveTab] = useState(2); // Активная вкладка ("Финансовый консультант")
  const carouselRef = useRef<Carousel>(null);
  const [activeTagId, setActiveTagId] = useState<number | null>(null);
  const { customers } = useGetAllCustomers({ limit: 15, offset: 0, tagsIds: [activeTagId] }) || [];

  const tags = block?.tags || [];

  const normalizedTags = useMemo(
    () => (Array.isArray(tags) ? tags : []),
    [tags],
  );

  useEffect(() => {
    if (Array.isArray(tags) && tags.length > 0) {
      setActiveTagId(tags[0].id);
    }
  }, [tags]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTagId(normalizedTags[newValue]?.id || null);
  };

  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: '#3c4245',
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        right: isMobile ? '-20px' : '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: isMobile ? 30 : 40,
        height: isMobile ? 30 : 40,
      }}
    >
      <Iconify icon="ep:arrow-left-bold" color="white" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: '#3c4245',
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: isMobile ? '-20px' : '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: isMobile ? 30 : 40,
        height: isMobile ? 30 : 40,
      }}
    >
      <Iconify icon="ep:arrow-right-bold" color="white" />
    </Box>
  );

  const carouselSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 5 : 10,
    slidesToScroll: 1,
    nextArrow: (
      <CustomPrevArrow onClick={() => carouselRef.current?.slickPrev()} />
    ),
    prevArrow: (
      <CustomNextArrow onClick={() => carouselRef.current?.slickNext()} />
    ),
    style: {
      gap: '16px',
    },
  };

  return (
    <Box>
      {/* Вкладки */}
      <Tabs
        value={
          Array.isArray(tags) && tags.findIndex((tag) => tag.id === activeTagId)
        }
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          '.MuiTab-root': {
            textTransform: 'none',
            fontWeight: 'bold',
            minWidth: 120,
          },
          '.MuiTabs-indicator': {
            backgroundColor: '#1976d2',
            height: 3,
          },
          '.MuiTabs-flexContainer': {
            justifyContent: 'center',
          },
        }}
      >
        {Array.isArray(tags) &&
          tags?.map((tag, index) => (
            <Tab key={index} label={capitalizeFirstLetter(tag.name)} />
          ))}
      </Tabs>

      {/* Содержимое */}
      <Stack alignItems="center" width={1}>
        <Container maxWidth="xl" sx={{ mt: 2 }}>
          <Carousel ref={carouselRef} {...carouselSettings}>
            {customers &&
              customers.map((item: ICustomer, idx: number) => (
                <Box
                  key={idx}
                  sx={{
                    position: 'relative',
                    px: 1,
                    transition: 'transform 0.3s ease',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  <Image
                    src={item.avatar || '/assets/images/user-default.png'}
                    sx={{
                      borderRadius: 1.25,
                      height: '100%',
                      width: '100%',
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      bgcolor: 'rgba(0, 0, 0, 0.6)',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      borderRadius: 1.25,
                      '&:hover': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Stack>
                      <Typography sx={{ fontSize: '12px' }}>
                        {item.firstName}
                      </Typography>
                      <Typography sx={{ fontSize: '12px' }}>
                        {item.lastName}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              ))}
          </Carousel>
        </Container>
      </Stack>
    </Box>
  );
};

export default Customers;
