/* eslint-disable import/no-cycle */
import { IOrder } from './order';
import { IGiftSelectionEvent } from './coupons';

export interface IBonusRule {
  amount: number;
  amountType: string;
  cashbackVirtualCardType: null;
  cashbackVirtualCardTypeId: null;
  createdAt: string;
  dueDate: null;
  id: string;
  ruleType: string;
  startDate: null;
}

export interface ICardType {
  id: string;
  name: string;
  description: string;
  mainImageURL?: string;
  deletedDate?: string;
  bonusRules?: IBonusRule[];
  onlyForCashback: null;
  provider: string;
}

export enum IVirtualCardProvider {
  MULTIPASS = 'MULTIPASS',
  PRAXELL = 'PRAXELL',
  INTERNAL = 'INTERNAL',
}
export interface IVirtualCard {
  id: string;
  type: string;
  balance: string;
  cardType?: ICardType;
  cardTypeId: string;
  customerId: string;
  cardNumber: string;
  cardPin: string;
  isGlobal: boolean;
  transactions: ITransaction[];
  mainImageURL: string;
  name: string;
  giftSelectionEvent: IGiftSelectionEvent;
}

export type ITransaction = {
  id: string;
  amount: string;
  virtualCardId: string;
  orderId: string;
  relatedActivityTransactionId: string;
  transactionType: ITransactionType;
  order?: IOrder;
  createdAt: string;
};

enum ITransactionType {
  'CREDIT' = 'CREDIT',
  'DEBIT' = 'DEBIT',
  'REFUND' = 'REFUND',
  // TODO: add more types
}

export type IVirtualCardsTable = {
  id: string;
  name: string;
  secondName: string;
  total: string;
  code: string;
  date: any;
  place: string;
  company: string;
  type: string;
};

export type IVirtualCardsTableFilters = {
  name: string;
  role: string[];
  type: string;
  start: Date | null;
  end: Date | null;
};

export type IVirtualCardsTableFilterValue = string | string[] | number | Date | null;

export type IVirtualCardsTabManagement = {
  id: string;
  name: string;
  secondName: string;
  total: string;
  type: string;
  date: any;
  typeOfTransfer: string;
};

export type IVirtualCardsTabManagementTableFilters = {
  name: string;
  role: string[];
  type: string;
  start: Date | null;
  end: Date | null;
};

export type IVirtualCardsTabManagementTableFilterValue = string | string[] | number | Date | null;

export type IVirtualCardsTabHistory = {
  id: string;
  name: string;
  secondName: string;
  total: string;
  type: string;
  date: any;
};

export type IVirtualCardsTabHistoryTableFilters = {
  name: string;
  role: string[];
  type: string;
  start: Date | null;
  end: Date | null;
};

export type IVirtualCardsTabHistoryTableFilterValue = string | string[] | number | Date | null;
