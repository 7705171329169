'use client';

import { FC } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

import Image from '../../../../../components/image';
import { useRouter } from '../../../../../routes/hooks';
import { IMerchant } from '../../../../../types/merchant';
import { BlockType } from '../../../../../types/generator';
// eslint-disable-next-line import/no-cycle
import { uploadProductsImage } from '../../../../../helpers/uploadProductsImage';

type Props = {
  block: BlockType | any;
  merchant: IMerchant | any;
};

export const AlternativeMerchantCarousel: FC<Props> = ({ block, merchant }) => {
  const router = useRouter();
  // Получение изображения
  const imageLink = merchant?.mainImageURL
    ? uploadProductsImage(merchant.mainImageURL)
    : '';
  const enableTitle: boolean = block?.settings?.enableTitle;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%', // уменьшенная ширина карточки
        p: 0.5, // уменьшенные внутренние отступы
      }}
    >
      <Stack width="100%" alignItems="center" sx={{ position: 'relative', cursor: 'pointer' }} onClick={() => router.push(`/merchant/${merchant?.id}`)}>
        <Image
          src={uploadProductsImage(imageLink)}
          sx={{
            width: '150px',
            height: '70px',
            '& img': {
              objectFit: 'contain',
            },
          }}
        />
        {enableTitle && <Typography sx={{textAlign: 'center', fontSize: '13px'}}>{merchant?.title}</Typography>}
      </Stack>
    </Box>
  );
};
