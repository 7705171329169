'use client';

import React from 'react';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, Link, Stack, Typography, IconButton } from '@mui/material';

import Image from '../../../../../components/image';

type Props = {
  img: string;
  title: string;
  price: string;
  link: string;
  productKey: number;
};

const ProductsList: React.FC<Props> = ({ img, title, price, link, productKey }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 4,
      position: 'relative', // Ensure relative positioning for inner absolute elements
    }}
  >
    <Link href={link} style={{ width: '100%' }}>
      <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
        <Image
          src={img}
          alt="service"
          style={{ width: '100%', borderRadius: 8 }}
        />

        {/* Heart icon positioned in the top-left corner */}
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            backgroundColor: 'rgba(255,255,255,0.7)',
            borderRadius: '50%',
            padding: '6px',
          }}
          aria-label="add to wishlist"
        >
          <FavoriteBorderIcon />
        </IconButton>

        {/* Conditional Discount or Offer Banner */}
        {/* {productKey === 1 && ( */}
        {/*  <Box */}
        {/*    sx={{ */}
        {/*      position: 'absolute', */}
        {/*      right: 8, */}
        {/*      bottom: 8, */}
        {/*      backgroundColor: 'white', */}
        {/*      padding: '8px', */}
        {/*      borderRadius: 4, */}
        {/*    }} */}
        {/*  > */}
        {/*    <Typography variant="body2" sx={{ color: 'black', textAlign: 'center' }}> */}
        {/*      <strong style={{ color: '#17ab5f' }}>35% הנחה</strong>{' '} */}
        {/*      <span>03:25:41</span> */}
        {/*    </Typography> */}
        {/*  </Box> */}
        {/* )} */}

        {/* {productKey === 2 && ( */}
        {/*  <Box */}
        {/*    sx={{ */}
        {/*      position: 'absolute', */}
        {/*      right: 8, */}
        {/*      bottom: 8, */}
        {/*      backgroundColor: 'white', */}
        {/*      padding: '8px', */}
        {/*      borderRadius: 4, */}
        {/*    }} */}
        {/*  > */}
        {/*    <Typography variant="body2" sx={{ color: 'black' }}> */}
        {/*      <strong style={{ color: '#17ab5f' }}>10$-</strong> */}
        {/*    </Typography> */}
        {/*  </Box> */}
        {/* )} */}

        {/* {productKey === 3 && ( */}
        {/*  <Box */}
        {/*    sx={{ */}
        {/*      position: 'absolute', */}
        {/*      right: 8, */}
        {/*      bottom: 8, */}
        {/*      backgroundColor: 'white', */}
        {/*      padding: '8px', */}
        {/*      borderRadius: 4, */}
        {/*    }} */}
        {/*  > */}
        {/*    <Typography variant="body2" sx={{ color: 'black', textAlign: 'center' }}> */}
        {/*      <strong style={{ color: '#17ab5f' }}>35% הנחה</strong>{' '} */}
        {/*      <span>03:25:41</span> */}
        {/*    </Typography> */}
        {/*  </Box> */}
        {/* )} */}
      </Box>
    </Link>

    {/* Text block positioned at the bottom of the image */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 2,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', textAlign: 'center', color: '#a5a6a8' }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'primary.main',
          }}
        >
          ₪{price}
        </Typography>
      </Stack>
    </Box>
  </Box>
);

export default ProductsList;
