'use client';

import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'next/navigation';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { useAppContext } from 'src/contexts/AppContext';

import { ComponentTypeProps } from 'src/types/page-generator';

import { useLocales } from '../../../../locales';
import SanitizeHTML from '../../../../utils/sanitize';
import { getContentValueFromProps } from '../../utils';
import { useAuthContext } from '../../../../auth/hooks';
import { fDateLocale } from '../../../../utils/format-time';
import { useContentContext } from '../../../../components/content';
import { getCategories } from '../../../../api/product';
import { useGetMerchantsCategories } from '../../../../api/merchant';

const TitleAndSubtitle: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch, state } = useAppContext();
  const sxWide = { color: 'secondary.main', fontSize: '13px', width: '50%', textAlign: 'center' };
  const sxNarrow = {
    color: 'secondary.main',
    fontSize: '13px',
    width: '100%',
    textAlign: 'center',
  };
  const isMobile = useResponsive('down', 'sm');
  const { currentLang } = useLocales();
  const { user } = useAuthContext();
  const { expirationDate } = useContentContext();
  const closingDate = expirationDate;
  const currentUser = user || {};
  const smDown = useResponsive('down', 'sm');
  const { id } = useParams();
  const { title } = useParams();
  const category = state.categories.find((item) => item.id === id);
  const { merchantsCategories } = useGetMerchantsCategories({});
  const merchantCategory = merchantsCategories.find((item: any) => item.id === id);
  const getFieldValue = getContentValueFromProps(block);

  useEffect(() => {
    getCategories(state.smbAccount.id, dispatch);

  }, []);

  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [getFieldValue],
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [getFieldValue],
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [getFieldValue],
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [getFieldValue],
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [getFieldValue],
  );
  const contentTitleTextAlign = useMemo(
    () => getFieldValue('settings.contentTitleTextAlign'),
    [getFieldValue],
  );

  const contentSubtitleTextColor = useMemo(
    () => getFieldValue('settings.contentSubtitleTextColor'),
    [getFieldValue],
  );
  const contentSubtitleFontSize = useMemo(
    () => getFieldValue('settings.contentSubtitleFontSize'),
    [getFieldValue],
  );
  const contentSubtitleFontWeight = useMemo(
    () => getFieldValue('settings.contentSubtitleFontWeight'),
    [getFieldValue],
  );
  const contentSubtitleFontStyle = useMemo(
    () => getFieldValue('settings.contentSubtitleFontStyle'),
    [getFieldValue],
  );
  const contentSubtitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentSubtitleTextDecoration'),
    [getFieldValue],
  );
  const contentSubtitleTextAlign = useMemo(
    () => getFieldValue('settings.contentSubtitleTextAlign'),
    [getFieldValue],
  );
  const subtitleWidth = useMemo(
    () => getFieldValue('settings.subtitleWidth'),
    [block?.settings.subtitleWidth],
  );

  const titleText = useMemo(() => {
    if (block?.titleText) {
      return block?.titleText;
    }
    return getFieldValue('titleText');
  }, [block?.titleText, getFieldValue]);

  const subTitle = useMemo(() => {
    if (block?.subtitleText) {
      return block?.subtitleText;
    }
    return getFieldValue('subtitleText');
  }, [block?.subtitleText, getFieldValue]);

  const lineHeight = useMemo(
    () => getFieldValue('settings.lineHeight'),
    [block?.settings?.lineHeight],
  );

  const mobileLineHeight = useMemo(
    () => getFieldValue('settings.lineHeight'),
    [block?.settings?.lineHeight],
  );


  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [getFieldValue]);

  const marginTop = useMemo(
    () => getFieldValue('settings.marginTop') ?? 0,
    [block?.settings.marginTop],
  );
  const marginBottom = useMemo(
    () => getFieldValue('settings.marginBottom') ?? 0,
    [block?.settings.marginBottom],
  );

  const interpolateTitleText = (template: string) => template
    .replace('{firstName}', currentUser?.firstName || '')
    .replace('{lastName}', currentUser?.lastName || '')
    .replace('{closingDate}', fDateLocale(closingDate, '', currentLang.value) || '');

  const processedTitleText = useMemo(() => interpolateTitleText(titleText), [titleText, user, closingDate]);

  const interpolateSubTitleText = (template: string) => template
    .replace('{firstName}', currentUser?.firstName || '')
    .replace('{lastName}', currentUser?.lastName || '')
    .replace('{closingDate}', fDateLocale(closingDate, '', currentLang.value) || '');

  const processedSubTitleText = useMemo(() => interpolateSubTitleText(subTitle), [subTitle, user, closingDate]);
  return (
    <>
      {title !== 'category' && title !== 'merchantCategory' ? (
        <Stack
          alignItems="center"
          gap={1}
          sx={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}
        >
          {block?.settings?.enableHeading && (
            <Typography
              sx={{
                direction: forceRtl ? 'rtl' : '',
                color: contentTitleTextColor,
                fontSize: contentTitleFontSize,
                fontWeight: contentTitleFontWeight,
                fontStyle: contentTitleFontStyle,
                textDecoration: contentTitleTextDecoration,
                textAlign: contentTitleTextAlign,
                mb: 0.5,
                '& span': {
                  '& p': {
                    margin: 0,
                  },
                },
              }}
            >
              <SanitizeHTML html={processedTitleText} />
            </Typography>
          )}
          {block?.settings?.enableTitle && (
            <Typography
              sx={{
                direction: forceRtl ? 'rtl' : '',
                color: contentSubtitleTextColor,
                fontSize: contentSubtitleFontSize,
                fontWeight: contentSubtitleFontWeight,
                fontStyle: contentSubtitleFontStyle,
                textDecoration: contentSubtitleTextDecoration,
                textAlign: contentSubtitleTextAlign,
                width: subtitleWidth || '70%',
                lineHeight: isMobile ? mobileLineHeight : lineHeight,
                '& span': {
                  '& p': {
                    margin: 0,
                  },
                },
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: processedSubTitleText }} />
              {/* <SanitizeHTML html={processedSubTitleText} /> */}
            </Typography>
          )}
        </Stack>
      ) : (
        <Typography
          sx={{
            marginTop: `${marginTop}px`,
            marginBottom: `${marginBottom}px`,
            direction: forceRtl ? 'rtl' : '',
            color: contentTitleTextColor,
            fontSize: contentTitleFontSize,
            fontWeight: contentTitleFontWeight,
            fontStyle: contentTitleFontStyle,
            textDecoration: contentTitleTextDecoration,
            textAlign: smDown ? 'center' : 'center',
            mb: 0.5,
          }}
        >
          {category?.title || merchantCategory?.title}
        </Typography>
      )}
    </>
  );
};

export default TitleAndSubtitle;
