'use client';

import React, { useMemo, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, IconButton } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';
import { useLocales, useTranslate } from 'src/locales';
import { extractPrices } from 'src/helpers/extractPrices';
import { generateOptions } from 'src/helpers/generateOptions';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { Ib2bProduct } from 'src/types/b2b';
import { BlockType } from 'src/types/page-generator';

import Label from '../../components/label';
import Delivery from '../b2c-shop/view/delivery';
import { IMerchant } from '../../types/merchant';
import { useBoolean } from '../../hooks/use-boolean';
import { useSnackbar } from '../../components/snackbar';
import { IAttributeValue } from '../../types/attribute';
import { getBasket, addToBasket } from '../../api/basket';
import { useResponsive } from '../../hooks/use-responsive';
import { getProductRestrictRule } from '../../api/product';
import { useContentContext } from '../../components/content';
import BasicModal from '../../components/modals/basic-modal';
import { ICheckoutDeliveryOption } from '../../types/checkout';
import { getContentValueFromProps } from '../generate-page/utils';
import CheckoutPaymentStraightAhead from '../checkout/checkout-payment-straight-ahead';

type openPrice = {
  price: string;
  title: string;
  value: string;
};

interface ProductOpenPrice {
  price: number;
  title: string;
  value: string;
}

type Tag = {
  image: string;
  title: string;
  value: number;
};

type Props = {
  block: BlockType;
  localSettings: BlockType;
  product: Ib2bProduct;
  mode: string;
  openPriceProducts?: openPrice[] | any;
  handleOpenPriceChange: Function;
  productOpenPrices?: ProductOpenPrice[];
  merchantId?: string | string[];
  attributes?: IAttributeValue[];
  addToLikeList: (id: string) => void;
  likedProducts: string[];
};

export const ProductCard = ({
  block,
  localSettings,
  product,
  mode,
  openPriceProducts,
  handleOpenPriceChange,
  productOpenPrices,
  merchantId,
  attributes,
  addToLikeList,
  likedProducts,
}: Props) => {
  const { currentLang } = useLocales();
  const { user } = useAuthContext();
  const getFieldValue = getContentValueFromProps(block);
  const { enqueueSnackbar } = useSnackbar();
  const { openSite } = useContentContext();
  const router = useRouter();
  const { t } = useTranslate();
  const payModal = useBoolean();
  const defaultLimit = 0;
  // const isStock = product?.provider === ProvidersEnum.STOCK;
  const isStock = product?.useStock;
  const isMobile = useResponsive('down', 'sm');
  const limit = isStock && product?.stock ? +product.stock : defaultLimit;
  const OPTIONS = generateOptions(1, limit);
  const openDeliveryModal = useBoolean();
  const asterisksInfo = useBoolean();
  const contractorInfo = useBoolean();
  const { dispatch, state } = useAppContext();
  const { cart } = state;
  const activityId = state.smbActivityId || '';
  const smbAccountId = state.smbAccount.id || '';
  const { trigger } = state;
  const [matchingTags, setMatchingTags] = useState<Tag[]>([]);
  const [count, setCount] = useState<string>('');
  const [deliveryOptions, setProductDeliveryOptions] = useState<ICheckoutDeliveryOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ICheckoutDeliveryOption>({
    id: '1',
    value: 0,
    label: 'Free',
    description: '5-7 Days delivery',
    imageUrl: '',
  });
  const localDataString: string | null =
    typeof window !== 'undefined' ? localStorage.getItem('openPrice') : '';
  const localData: any = localDataString ? JSON.parse(localDataString) : [];
  const isInCart = checkIfProductInCart(cart, product?.id);
  // const { product: localProduct } = useGetProduct(product?.id);
  const settings = () => {
    if (mode !== 'notCustom' && !localSettings?.settings?.generalSettings) return block;
    if (mode !== 'notCustom' && localSettings?.settings?.generalSettings)
      return localSettings.settings;
    return block?.settings;
  };
  const info = (value: Ib2bProduct, localBlock: BlockType | undefined) => {
    localStorage.setItem('product', JSON.stringify(value));
    if (localBlock !== undefined) {
      localStorage.setItem('settings', JSON.stringify(localBlock));
    }
    router.push(`/product/${value.id}`);
  };

  const value: number | undefined = localData.find(
    (item: ProductOpenPrice) => item.value === product?.id
  )?.price;

  const coincidence = openPriceProducts?.find((item: openPrice) => item.value === product?.id);

  useEffect(() => {
    const updatedCard = state?.openPricesProducts?.map((item: ProductOpenPrice) => {
      if (item.value === product?.id) {
        return {
          ...item,
          price: count,
        };
      }
      return item;
    });
    dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload: updatedCard });
  }, [count]);
  useEffect(() => {
    const current = localData?.find((item: openPrice) => item.value === product?.id);
    setCount(current?.price);
  }, [state]);

  const tagsImages = useMemo(() => getFieldValue('tagsImages'), [block?.tagsImages]);

  const productsTags = useMemo(() => {
    const tagsValue = getFieldValue('productsTags');
    if (Array.isArray(tagsValue)) {
      return tagsValue.map((tag: any) => ({
        ...tag,
        image: tagsImages[tag.value] ?? tag?.image,
      }));
    }
    return [];
  }, [block, tagsImages]);

  useEffect(() => {
    if (!product.tags || !block?.productsTags) return;
    if (block?.settings?.enableSmallInfo) {
      const tags = productsTags.filter((tag: any) =>
        product.tags.some((productsTag: any) => productsTag.name === tag.title)
      );
      setMatchingTags(tags);
    }
  }, [product.tags, block?.productsTags, block?.settings?.enableSmallInfo]);

  const {
    contentTextColor,
    contentFontSize,
    contentFontWeight,
    contentFontStyle,
    contentTextDecoration,
    contentAlign,
    enableButtonAdd,
    buttonBorderRadius,
    buttonBgColor,
    buttonColor,
    enableButton,
    buttonIcon,
    enableCount,
    enableAddress,
    enableContractor,
    enableAttributes,
    enableProductType,
    enablePrice,
    enableSwitch,
    enableDiscount,
    topPriceColor,
    topPriceFontSize,
    bottomPriceColor,
    bottomPriceFontSize,
    enableBalance,
    enableShare,
    enableAvailable,
    enableAvailableLimit,
    buttonLabel,
    buttonLabelNoPrice,
    enableLike,
  } = settings();

  const { businessPrice, basePrice, price, maxPrice, minPrice } = extractPrices(product?.prices);

  // const isOpenPrice = !businessPrice && !price && !!maxPrice && !!minPrice;
  const isOpenPrice = !businessPrice && !price;
  const allPrice = product?.prices ? product?.prices[0]?.value : '';

  let currentPriceView = coincidence?.price;
  if (coincidence && maxPrice && coincidence?.price > maxPrice) currentPriceView = maxPrice;
  if (coincidence && minPrice && coincidence?.price < minPrice) currentPriceView = minPrice;

  const imageLink =
    (product?.mainImageURL
      ? `${uploadProductsImage(`${product?.mainImageURL}`)}`
      : product?.merchants &&
        product?.merchants.length &&
        `${uploadProductsImage(`${product?.merchants[0].mainImageURL}`)}`) || '';

  const handleClick = () => {
    const currentPageUrl = window.location.href;
    let params = '?';
    if (enableDiscount) params += params === '?' ? 'ds' : ',ds';
    if (enableBalance) params += params === '?' ? 'ba' : ',ba';
    if (enableCount) params += params === '?' ? 'co' : ',co';
    if (enableAvailable) params += params === '?' ? 'av' : ',av';
    if (enableAvailableLimit) params += params === '?' ? 'av' : ',avl';
    const url = `${currentPageUrl}product/${product?.id}/${params}`;
    if (navigator?.share) {
      navigator
        ?.share({
          url,
        })
        .then(() => console.log(''))
        .catch((error) => console.error('Error', error));
    } else {
      navigator?.clipboard
        ?.writeText(url)
        ?.then(() => {})
        .catch((error) => {
          console.error('Failed to copy: ', error);
        });
    }
  };

  const addToCart = async (locProduct: Ib2bProduct) => {
    openDeliveryModal.onTrue();
    setProductDeliveryOptions(product?.deliveryOptions);
  };

  const handleDeliveryChange = (option: any) => {
    setSelectedOption(option);
  };

  let productMerchantAsterisks: string = '';
  if (product && product.asterisks && merchantId) {
    product.asterisks.forEach((asterisk) => {
      if (asterisk.merchants) {
        const current: IMerchant | undefined = asterisk.merchants.find(
          (item) => +item.id === +merchantId
        );

        if (current) {
          productMerchantAsterisks = asterisk.description || '';
        }
      }
    });
  }

  const openDonateModal = async () => {
    await getBasket(smbAccountId, dispatch).then(() => {
      payModal.onTrue();
    });
  };

  useEffect(() => {
    payModal.onFalse();
  }, [trigger]);

  const getCurrentAttribute = (id: string) => attributes?.find((item) => item.id === id)?.name;

  return (
    <Grid
      item
      md={block?.settings?.enableRotation ? 4 : 3}
      xs={12}
      sx={{
        height: 'auto',
        //   my: block?.settings?.enableRotation && '50px',
        //   mx: block?.settings?.enableRotation && 2,
      }}
    >
      <Card
        sx={{
          borderRadius: '5px',
          pb: 1,
          maxWidth: '360px',
          cursor: 'pointer',
          boxShadow: 5,
          maxHeight: '385px',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          position: 'relative',
          // transform: block?.settings?.enableRotation && 'rotate(-30deg)',
        }}
      >
        {block?.settings?.enableSmallInfo && (
          <Stack
            sx={{
              position: 'absolute',
              left: '0px',
              top: '0px',
              p: 1,
              zIndex: 100,
            }}
            gap={0.5}
          >
            {matchingTags.map((tag: any, index: number) => (
              <Stack key={index} direction="row" alignItems="center">
                {(block?.settings?.smallInfoViewSettings === 'all' ||
                  block?.settings?.smallInfoViewSettings === 'icon') && (
                  <Image src={tag.image} sx={{ width: '25px', height: '25px', mr: 1 }} />
                )}
                {(block?.settings?.smallInfoViewSettings === 'all' ||
                  block?.settings?.smallInfoViewSettings === 'text') && (
                  <>
                    {tag.title !== 'Sold Out' && tag.title !== 'Ready for sale' ? (
                      <Typography
                        sx={{
                          color: block?.settings?.smallInfoTextColor,
                          fontSize: block?.settings?.smallInfoFontSize,
                          fontWeight: block?.settings?.smallInfoFontWeight,
                          fontStyle: block?.settings?.smallInfoFontStyle,
                          textDecoration: block?.settings?.smallInfoTextDecoration,
                        }}
                      >
                        {tag.title}
                      </Typography>
                    ) : (
                      <>
                        {tag.title === 'Sold Out' && <Label color="error">{tag.title}</Label>}
                        {tag.title === 'Ready for sale' && (
                          <Label color="success">{tag.title}</Label>
                        )}
                      </>
                    )}
                  </>
                )}
              </Stack>
            ))}
          </Stack>
        )}
        <Box
          onClick={() => {
            info(product && product, block);
          }}
          sx={{
            backgroundSize: 'cover',
            backgroundImage: `url(${imageLink})`,
            backgroundRepeat: 'no-repeat',
            height: process.env.NEXT_PUBLIC_BOT_ID === '504' ? '190px' : '170px',
            borderRadius: '10px',
            transform: block?.settings?.enableRotation && 'rotate(-20deg)',
            mt: block?.settings?.enableRotation && '50px',
            width: block?.settings?.enableRotation ? '85%' : '100%',
            mx: block?.settings?.enableRotation && 'auto',
          }}
        >
          {enableLike && (
            <Box
              sx={{
                background: 'grey',
                width: '20px',
                height: '20px',
                float: 'right',
                opacity: 0.5,
                m: 1,
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                addToLikeList(product.id);
              }}
            >
              {likedProducts.includes(product.id) ? (
                <Iconify icon="weui:like-filled" sx={{ color: 'red' }} /> // Лайкнутый
              ) : (
                <Iconify icon="weui:like-outlined" sx={{ color: 'red' }} /> // Не лайкнутый
              )}
            </Box>
          )}

          {productMerchantAsterisks && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                asterisksInfo.onTrue();
              }}
            >
              <Iconify width={30} icon="clarity:info-line" />
            </IconButton>
          )}
        </Box>
        {product?.brand && !product?.gift && (
          <Box
            sx={{
              borderRadius: '15px',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'secondary.contrastText',
              m: 1,
            }}
          >
            <Image src={product?.brand} sx={{ borderRadius: '15px' }} />
          </Box>
        )}

        {!block?.settings?.disableName && (
          <Box height="70px">
            {enableProductType ? (
              <Stack direction="row">
                <Typography
                  sx={{
                    fontSize: contentFontSize,
                    fontWeight: contentFontWeight,
                    fontStyle: contentFontStyle,
                    textDecoration: contentTextDecoration,
                    my: 1,
                    mx: 1,
                  }}
                >
                  {product?.type} •
                </Typography>
                <Typography
                  sx={{
                    my: 1,
                    mx: enableProductType ? 1 : 2,
                    color: contentTextColor,
                    fontSize: contentFontSize,
                    fontWeight: contentFontWeight,
                    fontStyle: contentFontStyle,
                    textDecoration: contentTextDecoration,
                    flex: 1,
                    textAlign: contentAlign,
                    // transform: block?.settings?.enableRotation && 'rotate(-20deg)',
                    mt: block?.settings?.enableRotation && '60px',
                  }}
                >
                  {product?.title}
                </Typography>
              </Stack>
            ) : (
              <Typography
                sx={{
                  my: 1,
                  mx: 2,
                  color: contentTextColor,
                  fontSize: contentFontSize,
                  fontWeight: contentFontWeight,
                  fontStyle: contentFontStyle,
                  textDecoration: contentTextDecoration,
                  flex: 1,
                  textAlign: contentAlign,
                  // transform: block?.settings?.enableRotation && 'rotate(-20deg)',
                  mt: block?.settings?.enableRotation && '60px',
                }}
              >
                {product?.title}
              </Typography>
            )}
          </Box>
        )}
        {enableContractor && (
          <Stack
            alignItems="center"
            gap={1}
            direction="row"
            mx={1}
            sx={{ cursor: 'pointer' }}
            onClick={() => contractorInfo.onTrue()}
          >
            <Typography sx={{ fontSize: '10px' }}>{t('Contractor company')}</Typography>
            <Image
              sx={{ width: '50px' }}
              src={`${uploadProductsImage(`${product?.merchants[0]?.mainImageURL}`)}` || ''}
            />
          </Stack>
        )}
        {enableAddress && (
          <Stack direction="row" sx={{ mx: 1, my: 1 }}>
            <Stack direction="row" alignItems="center">
              <Iconify icon="mynaui:location" sx={{ width: '14px', height: '14px' }} />
              <Typography sx={{ fontSize: '10px' }}>{product.description}</Typography>
            </Stack>
          </Stack>
        )}
        {enableAttributes && (
          <Stack direction="row" sx={{ mx: 1 }} gap={1}>
            {product?.attributeValues?.map((item: IAttributeValue) => (
              <Stack key={item.value} direction="row" gap={0.5} alignItems="flex-start">
                {/* Выводим текст */}
                <Typography sx={{ fontSize: '10px' }}>{item.value}</Typography>
                {/* Динамически формируем название иконки */}
                <Iconify
                  icon={`mdi:${getCurrentAttribute(item.attributeId)}`}
                  width={14}
                  height={14}
                  sx={{ color: 'grey' }}
                />
              </Stack>
            ))}
          </Stack>
        )}
        {!price && !allPrice ? (
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="center"
            px={1}
            mb={1}
          >
            {enableButtonAdd && (
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  height: '34px',
                  borderRadius: buttonBorderRadius,
                  fontWeight: 400,
                  bgcolor: buttonBgColor,
                  color: buttonColor,
                  '&:hover': {
                    bgcolor: buttonBgColor,
                    color: buttonColor,
                  },
                }}
              >
                {buttonLabelNoPrice || 'כניסה לאתר'}
              </Button>
            )}
          </Stack>
        ) : (
          <Stack direction="row-reverse" justifyContent="space-between" alignItems="center" px={1}>
            {enableButton && (
              <>
                {isInCart ? (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      height: '34px',
                      borderRadius: '25px',
                      fontWeight: 400,
                    }}
                    endIcon={<Iconify icon="ep:success-filled" />}
                  >
                    בעגלה
                  </Button>
                ) : (
                  <Button
                    disabled={isStock && limit === 0}
                    onClick={() => {
                      if (!user) {
                        dispatch({
                          type: ActionTypes.SET_ACTIVE_DIALOG,
                          payload: 'login',
                        });
                      } else if (!block?.settings?.linkOnProductInfo) {
                        if (product?.deliveryOptions?.length) {
                          addToCart(product);
                        } else {
                          getProductRestrictRule(product?.id, activityId).then((it) => {
                            if (!it?.data.length) {
                              let currentPrice;
                              if (value && !coincidence) {
                                if (!isOpenPrice) {
                                  currentPrice = value;
                                  if (minPrice && value < minPrice) currentPrice = minPrice;
                                  if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                                } else {
                                  currentPrice = count;
                                }
                              } else if (isOpenPrice && coincidence) {
                                currentPrice = coincidence.price;
                                // @ts-ignore
                                if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                                // @ts-ignore
                                if (+coincidence.price < minPrice) currentPrice = minPrice;
                              } else {
                                currentPrice = (enableDiscount && basePrice) || price;
                              }
                              addToBasket(
                                state.smbAccount.id,
                                product?.id,
                                currentPrice,
                                imageLink,
                                dispatch,
                                cart,
                                []
                              );
                              if (block?.settings?.enablePayModal) {
                                openDonateModal();
                              }
                            } else if (it?.data?.length && it?.message) {
                              enqueueSnackbar(`${it?.message}`, { variant: 'error' });
                            }
                          });
                        }
                      } else info(product, block);
                    }}
                    variant={block?.settings?.buttonVariant || 'contained'}
                    sx={{
                      height: '34px',
                      borderRadius: buttonBorderRadius,
                      fontWeight: 400,
                      bgcolor: block?.settings?.buttonVariant === 'outlined' ? '' : buttonBgColor,
                      color:
                        block?.settings?.buttonVariant === 'outlined' ? buttonBgColor : buttonColor,
                      borderColor:
                        block?.settings?.buttonVariant === 'outlined' &&
                        block?.settings?.buttonBgColor,
                      '&:hover': {
                        background:
                          block?.settings?.buttonVariant === 'contained' ? buttonBgColor : '',
                        color:
                          block?.settings?.buttonVariant === 'outlined'
                            ? buttonBgColor
                            : buttonColor,
                        borderColor: block?.settings?.buttonVariant === 'outlined' && buttonBgColor,
                      },
                    }}
                    endIcon={<Iconify icon={buttonIcon} />}
                  >
                    {buttonLabel || 'לרכישה'}
                  </Button>
                )}
              </>
            )}
            {enableCount && (
              <TextField
                select
                SelectProps={{ native: true }}
                sx={{
                  '& .MuiNativeSelect-select': {
                    py: 1,
                    px: 2,
                  },
                }}
              >
                {OPTIONS.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            )}
            {enablePrice && (
              <>
                {!isOpenPrice && (
                  <Stack>
                    {!enableSwitch && enableDiscount && (
                      <Typography
                        sx={{
                          color: topPriceColor,
                          textDecoration: 'line-through',
                          fontSize: topPriceFontSize,
                        }}
                      >
                        {block?.settings?.currencyToDisplay || '₪'}{price}
                      </Typography>
                    )}
                    {!enableSwitch && enableDiscount ? (
                      <Typography
                        sx={{
                          color: bottomPriceColor,
                          fontSize: bottomPriceFontSize,
                        }}
                      >
                        {block?.settings?.currencyToDisplay || '₪'}{basePrice}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: bottomPriceColor,
                          fontSize: bottomPriceFontSize,
                        }}
                      >
                        {block?.settings?.currencyToDisplay || '₪'}{enableSwitch ? allPrice : price}
                      </Typography>
                    )}
                  </Stack>
                )}
                {isOpenPrice && (
                  <>
                    {coincidence && coincidence?.price ? (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                          sx={{
                            color: bottomPriceColor,
                            fontSize: bottomPriceFontSize,
                          }}
                        >
                          {block?.settings?.currencyToDisplay || '₪'}{coincidence && coincidence?.price && currentPriceView}
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <TextField
                          name="openPrice"
                          // placeholder={`${t('Sum')}`}
                          placeholder="הזן סכום"
                          sx={{ width: !enableCount ? '80%' : 60, '& input': { p: 1 } }}
                          value={count}
                          onChange={(e) => {
                            handleOpenPriceChange(e, product, maxPrice, minPrice);
                            setCount(e.target.value);
                          }}
                        />
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            fontSize: 16,
                          }}
                        >
                          {block?.settings?.currencyToDisplay || '₪'}
                        </Typography>
                      </Stack>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        )}
        <Grid
          container
          justifyContent="space-between"
          sx={{
            p: 1,
            // height: '50px',
            mb: 0,
            mt: 1,
            py: 0,
            display: !enableBalance && !enableAvailable && !enableShare ? 'none' : '',
          }}
        >
          <Grid item md={6} xs={6}>
            {enableBalance && isStock && limit !== 0 && (
              <Stack direction="row">
                <Typography>נשאר: {limit}</Typography>
              </Stack>
            )}
            {enableBalance && isStock && limit === 0 && (
              <Stack direction="row">
                <Typography>אין במלאי</Typography>
              </Stack>
            )}
            {enableShare && (
              <Iconify icon="solar:share-bold" width={16} sx={{ mr: 0.5 }} onClick={handleClick} />
            )}
          </Grid>
          <Grid item md={6} xs={6}>
            <Stack>
              {enableAvailable && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ mx: 1 }}>כמות</Typography>
                  <TextField
                    sx={{
                      '& .MuiNativeSelect-select': { py: 0 },
                    }}
                    select
                    fullWidth={!isMobile}
                    SelectProps={{
                      native: true,
                      sx: { textTransform: 'capitalize' },
                    }}
                    onChange={() => {}}
                  >
                    {OPTIONS.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </TextField>
                </Stack>
              )}
              {enableAvailableLimit && isStock && limit !== 0 && (
                <Typography sx={{ fontSize: enableAvailable ? '10px' : '16px', textAlign: 'end' }}>
                  ניתן לקנייה: {limit}
                </Typography>
              )}
              {enableAvailableLimit && isStock && limit === 0 && (
                <Typography sx={{ fontSize: enableAvailable ? '10px' : '16px', textAlign: 'end' }}>
                  אין במלאי
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <Modal open={openDeliveryModal.value} onClose={() => openDeliveryModal.onFalse()}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Delivery
            options={deliveryOptions || []}
            isDeliveryEnable
            onChange={(option) => handleDeliveryChange(option)}
            selectedOption={selectedOption}
          />
          <Button
            disabled={selectedOption.id === '1'}
            onClick={() => {
              if (!user) {
                dispatch({
                  type: ActionTypes.SET_ACTIVE_DIALOG,
                  payload: 'login',
                });
              } else if (!block?.settings?.linkOnProductInfo) {
                if (product?.deliveryOptions?.length) {
                  addToCart(product);
                } else {
                  getProductRestrictRule(product?.id, activityId).then((it) => {
                    if (!it?.data.length) {
                      let currentPrice;
                      if (value && !coincidence) {
                        if (!isOpenPrice) {
                          currentPrice = value;
                          if (minPrice && value < minPrice) currentPrice = minPrice;
                          if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                        } else {
                          currentPrice = count;
                        }
                      } else if (isOpenPrice && coincidence) {
                        currentPrice = coincidence.price;
                        // @ts-ignore
                        if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                        // @ts-ignore
                        if (+coincidence.price < minPrice) currentPrice = minPrice;
                      } else {
                        currentPrice = (enableDiscount && basePrice) || price;
                      }
                      addToBasket(
                        state.smbAccount.id,
                        product?.id,
                        currentPrice,
                        imageLink,
                        dispatch,
                        cart,
                        []
                      );
                    } else if (it?.data?.length && it?.message) {
                      enqueueSnackbar(`${it?.message}`, { variant: 'error' });
                    }
                  });
                }
              } else info(product, block);
            }}
            variant={block?.settings?.buttonVariant || 'contained'}
            sx={{
              mt: 1,
              height: '34px',
              borderRadius: buttonBorderRadius,
              fontWeight: 400,
              bgcolor: block?.settings?.buttonVariant === 'outlined' ? '' : buttonBgColor,
              color: block?.settings?.buttonVariant === 'outlined' ? buttonBgColor : buttonColor,
              borderColor:
                block?.settings?.buttonVariant === 'outlined' && block?.settings?.buttonBgColor,
              '&:hover': {
                background: block?.settings?.buttonVariant === 'contained' ? buttonBgColor : '',
                color: block?.settings?.buttonVariant === 'outlined' ? buttonBgColor : buttonColor,
                borderColor: block?.settings?.buttonVariant === 'outlined' && buttonBgColor,
              },
            }}
            endIcon={<Iconify icon={buttonIcon} />}
          >
            {buttonLabel || 'לרכישה'}
          </Button>
        </Box>
      </Modal>
      <Modal open={asterisksInfo.value} onClose={() => asterisksInfo.onFalse()}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography color="red">{`${productMerchantAsterisks}*`}</Typography>
        </Box>
      </Modal>
      <Modal open={contractorInfo.value} onClose={() => contractorInfo.onFalse()}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: isMobile ? '100%' : 'auto'
          }}
        >
          <IconButton onClick={() => contractorInfo.onFalse()} sx={{position: 'absolute', top: 0, left: 0}}>
            <Iconify icon='mingcute:close-fill'/>
          </IconButton>
            <Typography sx={{width: '100', textAlign: 'center'}}>Contractor info</Typography>
          <Grid container spacing={1} sx={{alignItems: 'center'}}>
            <Grid item xs={12} md={6}>
              <Image
                sx={{ width: '100%' }}
                src={`${uploadProductsImage(`${product?.merchants[0]?.mainImageURL}`)}` || ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>{product?.merchants[0]?.title}</Typography>
              <Typography>{product?.merchants[0]?.description}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <BasicModal
        open={payModal.value}
        onClose={() => {
          payModal.onFalse();
        }}
      >
        <Stack spacing={2} sx={{ padding: 2 }}>
          <CheckoutPaymentStraightAhead block={block} />
        </Stack>
      </BasicModal>
    </Grid>
  );
};
