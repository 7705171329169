import { useMemo } from 'react';

import { useSWR, endpoints } from 'src/utils/axios';

import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';
// import { LOCAL_STORAGE_KEYS } from 'src/constants/localStorageKeys';
// import { getDataFromLocaleStorage } from 'src/helpers/localStorage';

import { IProductItem } from 'src/types/product';

import { Ib2bProduct } from '../types/b2b';

export type GetProductProps = {
  limit?: number;
  offset?: number;
  minPrice?: number;
  maxPrice?: number;
  dispatch?: React.Dispatch<ActionType>;
  search?: string;
  categoriesIds?: string[];
  categoryIds?: string | string[];
  tagsIds?: string[] | number[] | any;
  priceType?: string;
  merchantTagsIds?: string[];
  smbAccount?: string;
  id?: string | string[];
  merchantId?: string | string[];
  kasherIds?: string[];
  worldSideIds?: string[];
  merchantTagIds?: string[];
  productTagIds?: string[];
  attributeValueIds?: string[];
};
export type SearchProductsProps = GetProductProps & {
  categoryIds?: string[];
  merchantIds?: string[];
  productTagIds?: string[];
  merchantTagIds?: string[];
};
export type GetProductByIdProps = {
  limit: number;
  offset: number;
  minPrice?: number;
  maxPrice?: number;
  dispatch?: React.Dispatch<ActionType>;
  search?: string;
  id: string | string[];
  categoriesIds: string[];
  kasherIds?: string[];
  worldSideIds?: string[];
};

type GetCategoriesProps = {
  limit: number;
  offset: number;
  categoryIds: string[];
  dispatch?: React.Dispatch<ActionType>;
  minPrice?: number;
  maxPrice?: number;
  search?: string;
};

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  dedupingInterval: 60000,
};

const FALLBACK_DATA = { payload: [], count: 0 };
const FALLBACK_DATA_SINGLE = { payload: null };

export const getLocalProducts = async () => {
  try {
    const local: any = typeof window !== 'undefined' ? localStorage.getItem('account') || '' : '';
    const account = local ? JSON.parse(local) : null;
    const { data } = await API({
      url: `product/getAll?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        active: true,
        relations: ['merchants', 'categories', 'deliveryOptions'],
        limit: 999,
        offset: 0,
        minPrice: 0,
        maxPrice: 3000,
        smbAccountId: account?.id,
      },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getProducts = async (
  smbAccountId: string,
  {
    limit,
    offset,
    minPrice = 0,
    maxPrice = 10000,
    dispatch,
    search,
    categoriesIds,
    priceType,
    tagsIds,
    merchantTagsIds,
    kasherIds,
    worldSideIds,
    merchantTagIds,
    productTagIds,
  }: GetProductProps,
  isSearch?: boolean
) => {
  try {
    const categories = !categoriesIds ? [] : categoriesIds;
    const array = (merchantTagIds ?? []).concat(productTagIds ?? []);
    const tags = !tagsIds ? [] : tagsIds;
    const { data } = await API({
      url: `product/getAll?botId=${BOT_ID}`,
      // url: 'product/getProductsByMerchantId',
      method: 'GET',
      params: {
        // merchantId: 3,
        // botId: BOT_ID,
        active: true,
        relations: ['merchants', 'categories', 'deliveryOptions'],
        limit,
        offset,
        minPrice,
        maxPrice,
        search,
        categoryIds: categories,
        tagsIds: tags,
        merchantTagsIds,
        attributeValueIds: array,
        priceType,
        // smbAccountId,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_PRODUCTS,
        payload: { products: data.payload, count: data.count },
      });
    }
    return data;
    // if (!isSearch) {
    //   dispatch({
    //     type: ActionTypes.SET_RESERV_PRODUCTS,
    //     payload: { products: data.payload },
    //   });
    // }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return true;
};

export const searchProducts = async (
  smbAccountId: string,
  {
    dispatch,
    limit,
    offset,
    minPrice = 0,
    maxPrice = 3000,
    search,
    categoryIds,
    merchantIds,
    productTagIds,
    merchantTagIds,
  }: SearchProductsProps
) => {
  try {
    let delayInMilliseconds;
    if (search) {
      delayInMilliseconds = 5000;
    } else if (merchantIds && merchantIds.length > 0) {
      delayInMilliseconds = 7000;
    } else {
      delayInMilliseconds = 0;
    }

    if (dispatch) {
      // dispatch({ type: ActionTypes.LOADING_SEARCH_PRODUCTS, payload: true });
    }
    // merchants.network,merchants.asterisks,merchants.network.asterisks
    const relationsQuery = '&relations=asterisks';
    const categoryIdsQuery =
      Array.isArray(categoryIds) && categoryIds.length
        ? `&categoryIds=${categoryIds.join(',')}`
        : '';
    const merchantIdsQuery =
      Array.isArray(merchantIds) && merchantIds.length
        ? `&merchantIds=${merchantIds.join(',')}`
        : '';
    const productTagIdsQuery =
      Array.isArray(productTagIds) && productTagIds.length
        ? `&productTagIds=${productTagIds.join(',')}`
        : '';
    const merchantTagIdsQuery =
      Array.isArray(merchantTagIds) && merchantTagIds.length
        ? `&merchantTagIds=${merchantTagIds.join(',')}`
        : '';

    const fetchProducts = async () => {
      const { data } = await API({
        url: `search/products?botId=${BOT_ID}${relationsQuery}${categoryIdsQuery}${merchantIdsQuery}${productTagIdsQuery}${merchantTagIdsQuery}`,
        method: 'GET',
        params: {
          active: true,
          limit,
          offset,
          minPrice,
          maxPrice,
          search,
          smbAccountId,
        },
      });
      // console.log('data.payload', data.payload);
      if (dispatch) {
        dispatch({
          type: ActionTypes.SEARCH_PRODUCTS,
          payload: {
            products: data.payload.products,
            merchants: data.payload.merchants,
            networks: data.payload.networks,
            categories: data.payload.categories,
            tags: data.payload.tags,
            count: data.count,
          },
        });
        // dispatch({ type: ActionTypes.LOADING_SEARCH_PRODUCTS, payload: false });
      }
    };
    setTimeout(fetchProducts, delayInMilliseconds);
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getBannerProducts = async (
  smbAccountId: string,
  {
    limit,
    offset,
    minPrice = 0,
    maxPrice = 1400,
    dispatch,
    search,
    categoriesIds,
    priceType,
    tagsIds,
    merchantTagIds,
  }: GetProductProps,
  isSearch?: boolean
) => {
  try {
    const array = (merchantTagIds ?? []).concat(tagsIds ?? []);
    const { data } = await API({
      url: `product/getAll?botId=${BOT_ID}`,
      // url: 'product/getProductsByMerchantId',
      method: 'GET',
      params: {
        // merchantId: 3,
        // botId: BOT_ID,
        active: true,
        relations: ['merchants', 'categories', 'deliveryOptions'],
        limit,
        offset,
        minPrice,
        maxPrice,
        search,
        categoryIds: categoriesIds,
        // kasherIds: tagsIds,
        // worldSideIds: merchantTagIds,
        attributeValueIds: array,
        priceType,
        smbAccountId,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_BANNER_PRODUCTS,
        payload: { products: data.payload, count: data.count },
      });
    }

    return data.payload;

    // if (!isSearch) {
    //   dispatch({
    //     type: ActionTypes.SET_RESERV_PRODUCTS,
    //     payload: { products: data.payload },
    //   });
    // }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return null;
};

export const getBannerProductsForMainProducts = async (
  smbAccountId: string,
  {
    limit,
    offset,
    minPrice = 0,
    maxPrice = 1400,
    dispatch,
    search,
    categoriesIds,
    priceType,
  }: GetProductProps,
  isSearch?: boolean
) => {
  try {
    const { data } = await API({
      url: `product/getAll?botId=${BOT_ID}`,
      // url: 'product/getProductsByMerchantId',
      method: 'GET',
      params: {
        // merchantId: 3,
        // botId: BOT_ID,
        active: true,
        relations: ['categories', 'deliveryOptions'],
        limit,
        offset,
        minPrice,
        maxPrice,
        search,
        categoryIds: categoriesIds,
        priceType,
        smbAccountId,
      },
    });
    return data.payload;
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return null;
};

export const getProductsByMerchantId = async (
  {
    limit,
    offset,
    minPrice = 0,
    maxPrice = 1400,
    dispatch,
    search,
    id,
    categoriesIds = [],
    kasherIds,
    worldSideIds,
  }: GetProductByIdProps,
  isSearch?: boolean
) => {
  try {
    const relations = BOT_ID === '502' ? [] : ['asterisks'];
    let array: string[] | string = [];
    if (kasherIds && worldSideIds) array = kasherIds?.concat(worldSideIds);
    // 'merchants', 'categories',
    const { data } = await API({
      url: `/product/getProductsByMerchantId?botId=${BOT_ID}`,
      // url: 'product/getProductsByMerchantId',
      method: 'GET',
      params: {
        // botId: BOT_ID,
        merchantId: id,
        active: true,
        relations,
        limit,
        offset,
        minPrice,
        maxPrice,
        search,
        categoryIds: categoriesIds,
        attributeValueIds: array,
      },
    });
    if (dispatch) {
      if (BOT_ID !== '502') {
        dispatch({
          type: ActionTypes.GET_PRODUCTS,
          payload: { products: data.payload, count: data.count },
        });
      } else {
        dispatch({
          type: ActionTypes.GET_BANNER_PRODUCTS,
          payload: { products: data.payload, count: data.count },
        });
      }
    }

    // if (!isSearch) {
    //   dispatch({
    //     type: ActionTypes.SET_RESERV_PRODUCTS,
    //     payload: { products: data.payload },
    //   });
    // }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getProductsByPrice = async (
  limit: number,
  offset: number,
  minPrice: number,
  maxPrice: number,
  dispatch?: React.Dispatch<ActionType>
) => {
  try {
    const { data } = await API({
      url: 'product/getAll',
      method: 'GET',
      params: {
        botId: BOT_ID,
        active: true,
        relations: ['merchants', 'categories', 'deliveryOptions'],
        limit,
        offset,
        minPrice,
        maxPrice,
      },
    });

    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_PRODUCTS,
        payload: { products: data.payload, count: data.count },
      });
    }
    if (dispatch) {
      dispatch({
        type: ActionTypes.SET_RESERV_PRODUCTS,
        payload: { products: data.payload },
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getProduct = async (id?: string | string[], dispatch?: any) => {
  try {
    const relations =
      process.env.NEXT_PUBLIC_PROJECT_NAME === 'raayonit'
        ? ['merchants', 'categories', 'asterisks']
        : [
            'merchants',
            'categories',
            'asterisks',
            'deliveryOptions',
            'attributes',
            'attributeSets',
            'attributeValues',
            'bonusRules',
            'tags',
          ];
    const { data } = await API({
      url: 'product',
      method: 'GET',
      params: {
        id,
        relations,
      },
    });
    return data.payload;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getBonusRuleByProduct = async (
  id: string,
  smbAccountId?: string,
  activityId?: string
) => {
  try {
    const { data } = await API({
      url: '/bonusRule/byProductId',
      method: 'GET',
      params: {
        productId: id,
        smbAccountId,
        activityId,
      },
    });
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getProductRestrictRule = async (id: string | string[], activityId: string) => {
  try {
    if (activityId && id) {
      const { data } = await API({
        url: '/restrictRule/getRestrictRules',
        method: 'GET',
        params: {
          productId: id,
          activityId,
        },
      });
      return data;
    }
    return false;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getProductByCategoryIds = async ({
  limit,
  offset,
  categoryIds,
  dispatch,
  minPrice = 0,
  maxPrice = 1400,
  search,
}: GetCategoriesProps) => {
  const local: any = typeof window !== 'undefined' ? localStorage.getItem('account') : '';
  const account = JSON.parse(local);
  try {
    const { data } = await API({
      url: 'product/getProductsByCategory',
      method: 'GET',
      params: {
        categoryIds,
        relations: ['categories', 'prices', 'merchants'],
        type: 'FIXED_PRICE',
        limit,
        offset,
        botId: BOT_ID,
        minPrice,
        maxPrice,
        search,
        smbAccountId: account?.id,
      },
    });

    // -- temporary
    // data.payload.length = 12;

    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_PRODUCTS,
        payload: { products: data.payload, count: data.count },
      });
    }
    if (dispatch) {
      dispatch({
        type: ActionTypes.SET_RESERV_PRODUCTS,
        payload: { products: data.payload },
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const getProductByCategory = async (
  smbAccountId: string,
  categoryIds: string[],
  dispatch?: React.Dispatch<ActionType>
) => {
  if (categoryIds.length) {
    try {
      const { data } = await API({
        // url: 'product/getProductsByCategory',
        url: `product/getAll?botId=${BOT_ID}`,
        method: 'GET',
        params: {
          categoryIds,
          limit: 10,
          offset: 0,
          minPrice: 0,
          maxPrice: 3000,
          // botId: BOT_ID,
          relations: ['categories', 'merchants', 'deliveryOptions'],
          smbAccountId,
        },
      });
      // console.log('data', data);
      // -- temporary
      data.payload.length = 10;

      if (dispatch) {
        dispatch({
          type: ActionTypes.GET_PRODUCTS_BY_CATEGORY,
          payload: { productsByCategory: data.payload },
        });
      }
    } catch (e) {
      console.error(e);
    }
  } else if (dispatch) {
    dispatch({
      type: ActionTypes.GET_PRODUCTS_BY_CATEGORY,
      payload: { productsByCategory: [] },
    });
  } else {
    console.log('');
  }
};

export const getCategories = async (
  smbAccountId: string,
  dispatch?: React.Dispatch<ActionType>
) => {
  try {
    const { data } = await API({
      url: 'product-category/getAll',
      method: 'GET',
      params: {
        limit: 100,
        offset: 0,
        smbAccountId,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_CATEGORIES,
        payload: data.payload,
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getProductsTags = async (
  smbAccountId: string,
  dispatch?: React.Dispatch<ActionType>
) => {
  try {
    const { data } = await API({
      url: 'product/getTags',
      method: 'GET',
      params: {
        limit: 100,
        offset: 0,
        smbAccountId,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_PRODUCTS_TAGS,
        payload: data.payload,
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export function useGetProductCategory(id?: string) {
  const URL = id
    ? [endpoints.product.categoryById, { params: { id } }]
    : [endpoints.product.categories, { params: { id } }];

  const { data, isLoading, error, isValidating } = useSWR(URL);

  const memoizedValue = useMemo(
    () => ({
      product: data?.payload as Ib2bProduct,
      productLoading: isLoading,
      productError: error,
      productValidating: isValidating,
    }),
    [data?.product, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetProduct(productId: string) {
  const relations =
    process.env.NEXT_PUBLIC_PROJECT_NAME === 'raayonit'
      ? ['merchants']
      : ['merchants', 'deliveryOptions'];
  const URL = productId ? [endpoints.product.byId, { params: { id: productId, relations } }] : '';
  const { data, isLoading, error, isValidating } = useSWR(URL);

  const memoizedValue = useMemo(
    () => ({
      product: data?.payload as IProductItem | any,
      productLoading: isLoading,
      productError: error,
      productValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetProducts(props: GetProductProps) {
  const array = (props?.merchantTagIds ?? [])
    .concat(props?.productTagIds ?? [])
    .concat(props?.attributeValueIds ?? []);
  const URL = [
    endpoints.product.getAll,
    {
      params: {
        // ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
        search: props.search,
        minPrice: props.minPrice,
        maxPrice: props.maxPrice,
        categoryIds: props.categoryIds,
        // tagsIds: props.tagsIds,
        merchantTagsIds: props.merchantTagsIds,
        priceType: props.priceType,
        smbAccount: props.smbAccount,
        attributeValueIds: array,
        relations: [
          'deliveryOptions',
          'tags',
          'merchants',
          'categories',
          'attributes',
          'attributeSets',
          'attributeValues',
        ],
      },
    },
  ];

  const merchantURL = [
    endpoints.product.byMerchantId,
    {
      params: {
        // ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
        search: props.search,
        minPrice: props.minPrice,
        maxPrice: props.maxPrice,
        categoryIds: props.categoryIds,
        // tagsIds: props.tagsIds,
        priceType: props.priceType,
        smbAccount: props.smbAccount,
        merchantId: props.merchantId,
        attributeValueIds: array,
        relations: [
          'asterisks',
          'merchants',
          'categories',
          'attributes',
          'attributeSets',
          'attributeValues',
        ],
      },
    },
  ];
  const currentUL = props?.merchantId?.length ? merchantURL : URL;
  const { data, isLoading, error, isValidating } = useSWR(currentUL, options);
  const { payload: products, count: productsCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      products,
      totalCount: productsCount,
      productsError: error,
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !data?.payload?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetProductsTags(props: GetProductProps) {
  const URL = [
    endpoints.product.getTags,
    {
      params: {
        ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
      },
    },
  ];

  const { data, isLoading, error, isValidating } = useSWR(URL, options);
  const { payload: productsTags, count: productsTagsCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      productsTags,
      totalCount: productsTagsCount,
      productsTagsError: error,
      productsTagsLoading: isLoading,
      productsTagsValidating: isValidating,
      productsTagsEmpty: !isLoading && !data?.payload?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetProductsCategories(props: GetProductProps) {
  const URL = [
    endpoints.product.categories,
    {
      params: {
        ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
      },
    },
  ];

  const { data, isLoading, error, isValidating } = useSWR(URL, options);
  const { payload: productsCategories, count: productsCategoriesCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      productsCategories,
      totalCount: productsCategoriesCount,
      productsCategoriesError: error,
      productsCategoriesLoading: isLoading,
      productsCategoriesValidating: isValidating,
      productsCategoriesEmpty: !isLoading && !data?.payload?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useSearchProducts(query: string) {
  const URL = query ? [endpoints.product.search, { params: { query } }] : '';

  const { data, isLoading, error, isValidating } = useSWR(URL, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: (data?.results as IProductItem[]) || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}
