import { useMemo, useState, useEffect, useCallback } from 'react';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Box, Stack, alpha, useTheme } from '@mui/material';

import { usePathname } from 'src/routes/hooks';

import { useResponsive } from 'src/hooks/use-responsive';

import { PROJECT_NAME } from 'src/config-global';

import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import Scrollbar from 'src/components/scrollbar';

import NavList from './nav-list';
import { NavProps } from '../types';

// ----------------------------------------------------------------------

export default function NavMobile({ data, buttonSize, logo, buttonColor, burgerLinksPopups }: NavProps) {
  const pathname = usePathname();
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const isCityPeople = useMemo(() => PROJECT_NAME.toLowerCase() === 'citypeople', []);

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  return (
    <>
      <IconButton onClick={handleOpenMenu} sx={isCityPeople ? {} : { ml: 1 }}>
        {isCityPeople ? (
          <Iconify
            width={buttonSize}
            icon="iconamoon:menu-burger-horizontal-thin"
            sx={{ cursor: 'pointer', color: buttonColor }}
          />
        ) : (
          <SvgColor
            src="/assets/icons/navbar/ic_menu_item_2.svg"
            color={buttonColor || 'primary.main'}
          />
        )}
      </IconButton>

      <Drawer
        open={openMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            pb: 5,
            bgcolor: `${theme.palette.common.white} !important`,
            xs: { width: '100%' },
            border: `${theme.spacing(0.5)} solid ${theme.palette.primary.lighter}`,
            borderTopRightRadius: theme.spacing(1.5),
            borderBottomRightRadius: theme.spacing(1.5),
            ...(mdUp && { width: 380 }),
          },
        }}
      >
        <Scrollbar>
          <Stack direction="row" alignItems="center" justifyContent="center" mb={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                width={32}
                height={32}
                bgcolor={theme.palette.primary.lighter}
                borderRadius={25}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                onClick={handleCloseMenu}
                sx={{
                  transition: theme.transitions.create('all', {
                    duration: theme.transitions.duration.short,
                  }),
                  '&:hover': {
                    cursor: 'pointer',
                    bgcolor: alpha(theme.palette.primary.lighter, 0.7),
                  },
                }}
              >
                <Iconify icon="akar-icons:cross" width={14} color={theme.palette.common.white} />
              </Box>
              <Box sx={{ m: 2, maxWidth: '180px' }}>
                <Logo src={logo} />
              </Box>
            </Stack>
          </Stack>
          <Box px={2}>
            {data.map((list) => (
              <NavList key={list.title} data={list} burgerLinksPopups={burgerLinksPopups} handleCloseMenu={handleCloseMenu}/>
            ))}
          </Box>
        </Scrollbar>
      </Drawer>
    </>
  );
}
