'use client';

import React, { FC } from 'react';
import { useRouter } from 'next/navigation';

import { Stack, Container, Typography } from '@mui/material';

import { ComponentTypeProps } from '../../../../types/page-generator';

const TitleAndButton: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  const text: any = block?.text;
  const link: any = block?.link;

  return (
    <Container maxWidth="xs" sx={{ py: 4, mb: '-35px' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ direction: 'rtl' }}
      >
        <Typography
          sx={{
            color: 'primary.main',
            cursor: 'pointer',
          }}
          onClick={() => router.push(`/${link}`)}
        >
          {'<<'} לכל השירותים
        </Typography>
        <Typography>{text}</Typography>
      </Stack>
    </Container>
  );
};

export default TitleAndButton;
