'use client';

// @mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// types

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// components
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';

import Iconify from '../../../../components/iconify';
//
import { ICheckoutItem } from '../../../../types/checkout';
import { fCurrency } from '../../../../utils/format-number';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';
import { removeProduct } from '../../../../api/basket';
import { useAppContext } from '../../../../contexts/AppContext';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'product', label: 'Product' },
  { id: 'price', label: 'Price' },
  { id: '' },
];

// ----------------------------------------------------------------------

type Props = {
  products: ICheckoutItem[] | any;
  onDelete: (id: string) => void;
  onDecreaseQuantity: (id: string) => void;
  onIncreaseQuantity: (id: string) => void;
};

export default function CheckoutCartProductListStraightAhead({
                                                               products,
                                                               onDelete,
                                                               onIncreaseQuantity,
                                                               onDecreaseQuantity,
                                                             }: Props) {
  const { dispatch, state } = useAppContext();
  return (
    <TableContainer sx={{ overflow: 'unset' }}>
      <Scrollbar>
          <Table>
            <TableHeadCustom headLabel={TABLE_HEAD} />

            <TableBody>
              {products && products.map((row: any, id: number) => (
                <TableRow key={id}>
                  <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      variant="rounded"
                      src={uploadProductsImage(row.product.mainImageURL)}
                      sx={{ width: 64, height: 64, mr: 2 }}
                    />

                    <Stack spacing={0.5}>
                      <Typography noWrap variant="subtitle2" sx={{ maxWidth: 240 }}>
                        {row.name}
                      </Typography>

                    </Stack>
                  </TableCell>

                  <TableCell>{fCurrency(row.price)}</TableCell>

                  <TableCell align="right" sx={{ px: 1 }}>
                    <IconButton onClick={() => removeProduct(row.id, state.smbAccount.id, dispatch)}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </TableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
      </Scrollbar>
    </TableContainer>
  );
}
