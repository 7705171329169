'use client';

import { useMemo, useState, useEffect } from 'react';

// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, InputLabel, FormControl, OutlinedInput, InputAdornment } from '@mui/material';

// constants
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';

// types
import { IVirtualCard, IVirtualCardProvider } from 'src/types/club-cards';

import { API } from '../../helpers/api';
import { useTranslate } from '../../locales';
import Iconify from '../../components/iconify';
import { UsedVirtualCard } from '../../types/checkout';
import { useResponsive } from '../../hooks/use-responsive';
import { ActionTypes, useAppContext } from '../../contexts/AppContext';

// ----------------------------------------------------------------------

type ClubCardProps = {
  card: IVirtualCard;
  handleToggleCard: () => void;
  // openModal: () => void;
  isChecked: boolean;
  isMix: boolean;
  isMixMustClub: boolean;
  isEnoughMoney: boolean;
  totalSum: number;
  showError: (text: string) => void;
  isDisabled?: boolean;
};

const ClubCard = ({
                    card,
                    handleToggleCard,
                    isEnoughMoney,
                    isChecked,
                    isMix,
                    isMixMustClub,
                    showError,
                    totalSum,
                    isDisabled
                  }: ClubCardProps) => {
    const isMobile = useResponsive('down', 'sm');
    const smUp = useResponsive('up', 'sm');
    const [cost, setCost] = useState<number | null>(null);
    const { dispatch, state } = useAppContext();
    const [balanceProvider, setBalanceProvider] = useState<number | string>('');
    const [isBalanceChecked, setIsBalanceChecked] = useState<boolean>(false);
    const isUsedCard = state.checkout.usedVirtualCards
      .map((usedCard: UsedVirtualCard) => usedCard.id)
      .includes(card.id);
    const cartCost = useMemo(
      () => state.checkout.usedVirtualCards.find((c: UsedVirtualCard) => c.id === card.id)?.cost || 0,
      [state.checkout.usedVirtualCards],
    );
    const isEqualCartCost = isUsedCard ? cartCost === cost : null;
    const isLimitCardsCount = useMemo(
      () => state.checkout.usedVirtualCards.length > 1,
      [state.checkout.usedVirtualCards],
    );

    const isLimitCardMoney = useMemo(() => {
      // @ts-ignore
      if (card?.cardType?.provider === 'MULTIPASS' || card?.cardType?.provider === 'PRAXELL') {
        return Number(cost) > Number(balanceProvider);
      }
      return Number(cost) > Number(card.balance);
    }, [cost, card]);

    const isDisableButton =
      cost === null ||
      isEqualCartCost ||
      (!isUsedCard && isLimitCardsCount) ||
      (!isUsedCard && cost === 0);

    const defaultImage =
      process.env.NEXT_PUBLIC_PROJECT_NAME === 'xtra'
        ? '/assets/images/xtra/default.png'
        : '/assets/images/happy-gift/banners/blue-card.png';

    const imageURL = card.cardType?.mainImageURL
      ? `${BASIC_URL_FOR_IMAGES}${card.cardType.mainImageURL}`
      : defaultImage;

    useEffect(() => setCost(cartCost || null), []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setCost(+event.target.value);
    const handleUseCard = useMemo(() => {
      if (typeof showError === 'function' && isLimitCardMoney)
        return () => showError('You entered an amount exceeding the card balance');
      if (isDisableButton) return () => {
      };

      return () => {
        let correctCost = cost <= totalSum ? cost : totalSum;

        if (isUsedCard) {
          correctCost = cost <= totalSum + cartCost ? cost : totalSum + cartCost;
        }

        dispatch({
          type: ActionTypes.USE_VIRTUAL_CARD,
          payload: { id: card.id, cost: correctCost, name: card.cardType?.name || 'card' },
        });

        setCost(correctCost || null);
      };
    }, [dispatch, isLimitCardMoney, isUsedCard, isDisableButton, cost, totalSum]);

    const handleCheckBalance = async () => {
      if (
        (card?.cardType?.provider === IVirtualCardProvider.MULTIPASS ||
          card?.cardType?.provider === IVirtualCardProvider.PRAXELL) &&
        card?.cardNumber
      ) {
        const { data } = await API({
          url: `/provider/balance`,
          method: 'GET',
          params: {
            code:
              card?.cardType?.provider === IVirtualCardProvider.MULTIPASS
                ? `${card?.cardNumber}-${card?.cardPin ? card?.cardPin : ''}`
                : card?.cardNumber,
          },
        });
        if (data) {
          // if (data?.balance) {
          setBalanceProvider(data?.balance);
          setIsBalanceChecked(true);
          // }
        }
      }
    };

  // eslint-disable-next-line no-nested-ternary
    const justifyContent = isMobile ? 'center' : isMix ? 'space-between' : 'space-between'

    if (!card.cardType) return null;
    return (
      <Card
        sx={{
          width: '100%',
          height: isMobile ? 'auto' : '48px' ,
          borderColor: isChecked ? 'primary.main' : 'transparent',
          ...(isMix && { border: 0 }),
          ...(!isUsedCard && isLimitCardsCount && { opacity: '0.4' }),
          ...(!isMix && !isEnoughMoney && { opacity: '0.4' }),
          display: 'flex',
          flexDirection: (isMix || isMixMustClub) && isMobile ? 'column' : 'row-reverse',
          alignItems: 'center',
          justifyContent,
          gap: '8px',
          borderRadius: 0.5,
          padding: !isMobile ? '36px 8px' : '8px 8px',
          // pointerEvents: isDisabled ? 'none' : 'auto',
          // opacity: isDisabled ? 0.5 : 1,
          // filter: isDisabled ? 'grayscale(100%)' : 'none',
        }}
        onClick={isEnoughMoney ? handleToggleCard : () => {
        }}
        variant="outlined"
      >
        {isMix && !isMobile && (
          <Box
            sx={{
              width: '104px',
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!isBalanceChecked && card?.cardType?.provider !== 'MULTIPASS' && card?.cardType?.provider !== 'PRAXELL' ? (
              <Button
                size="small"
                sx={{
                  height: '100%',
                  width: '58px',
                  backgroundColor: '#212B36',
                  color: 'white',
                  borderRadius: '8px',
                  ...(isUsedCard &&
                    isEqualCartCost && {
                      backgroundColor: '#22C55E',
                      width: '100%',
                    }),
                  ...(isLimitCardMoney && {
                    backgroundColor: '#FF5630',
                  }),
                  '&:hover': {
                    backgroundColor: '#0d0f11',
                    ...(isUsedCard &&
                      isEqualCartCost && {
                        backgroundColor: '#398556',
                      }),
                    ...(isLimitCardMoney && {
                      backgroundColor: '#b65946',
                    }),
                  },
                }}
                onClick={handleUseCard}
              >
                {isUsedCard && isEqualCartCost && !isLimitCardMoney ? (
                  <Stack display="flex" flexDirection="row" gap={2} alignItems="center">
                    <Typography variant="button">לתשלום</Typography>
                    <Iconify icon="el:ok" width={14} />
                  </Stack>
                ) : (
                  <Typography variant="button">שמור</Typography>
                )}
              </Button>
            ) : (
              <>
                {!isBalanceChecked ? (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      color: 'white',
                      bgcolor: 'black',
                      padding: 1,
                      fontSize: !smUp ? '8px' : '10px',
                      borderRadius: '5px',
                      fontWeight: 800,
                    }}
                    onClick={handleCheckBalance}
                  >
                    {/* {t('Check Balance')} */}
                    בדיקת יתרה
                  </Button>
                ) : (
                  <Button
                    size="small"
                    sx={{
                      height: '100%',
                      width: '58px',
                      backgroundColor: '#212B36',
                      color: 'white',
                      borderRadius: '8px',
                      ...(isUsedCard &&
                        isEqualCartCost && {
                          backgroundColor: '#22C55E',
                          width: '100%',
                        }),
                      ...(isLimitCardMoney && {
                        backgroundColor: '#FF5630',
                      }),
                      '&:hover': {
                        backgroundColor: '#0d0f11',
                        ...(isUsedCard &&
                          isEqualCartCost && {
                            backgroundColor: '#398556',
                          }),
                        ...(isLimitCardMoney && {
                          backgroundColor: '#b65946',
                        }),
                      },
                    }}
                    onClick={handleUseCard}
                  >
                    {isUsedCard && isEqualCartCost && !isLimitCardMoney ? (
                      <Stack display="flex" flexDirection="row" gap={2} alignItems="center">
                        <Typography variant="button">לתשלום</Typography>
                        <Iconify icon="el:ok" width={14} />
                      </Stack>
                    ) : (
                      <Typography variant="button">שמור</Typography>
                    )}
                  </Button>
                )}
              </>
            )}
          </Box>
        )}

        {(isMix || isMixMustClub) && !isMobile && (
          <FormControl sx={{ m: 0, width: '125px' }} variant="outlined">
            <InputLabel size="small" htmlFor={`cost_virtual_card_${card.id}`}>
              סכום להורדה
            </InputLabel>
            <OutlinedInput
              size="small"
              id={`cost_virtual_card_${card.id}`}
              type="text"
              value={cost || ''}
              endAdornment={
                <InputAdornment position="end">
                  <Iconify
                    icon="fa:shekel"
                    width={10}
                    color={isEqualCartCost ? '#EB157B' : 'inherit'}
                  />
                </InputAdornment>
              }
              label="סכום להורדה"
              onChange={handleChange}
            />
          </FormControl>
        )}
        {isMobile && (
          <Stack>
            <Stack alignItems="center">
              <Typography
                variant="body2"
                sx={{
                  minWidth: '23%',
                  fontSize: '14px',
                  textAlign: 'center',
                  fontWeight: 400,
                }}
              >
                {card.cardType?.name || ''}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  minWidth: '23%',
                  fontSize: '10px',
                  textAlign: 'center',
                  fontWeight: 400,
                  color: 'grey',
                }}
              >
                {card?.cardNumber || ''}
              </Typography>
            </Stack>

            {isMobile && (
              <CardMedia
                sx={{ height: 100, width: 180, borderRadius: 1, objectFit: 'contain', my: 1 }}
                image={imageURL}
              />
            )}
            <Stack direction="row-reverse">

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: '4px',
                  minWidth: '22%',
                  width: '25%',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'center'
                }}
              >
                {card?.cardType?.provider !== 'MULTIPASS' && card?.cardType?.provider !== 'PRAXELL' ? (
                  <Typography sx={{ color: '#EB157B', fontSize: '14px' }}>
                    <bdi>₪{card.balance || 0}</bdi>
                  </Typography>
                ) : (
                  <>
                    {!isMix && !isMixMustClub && balanceProvider === '' && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          color: 'white',
                          bgcolor: 'black',
                          padding: 1,
                          fontSize: !smUp ? '8px' : '10px',
                          borderRadius: '5px',
                          fontWeight: 800,
                        }}
                        onClick={handleCheckBalance}
                      >
                        {/* {t('Check Balance')} */}
                        בדיקת יתרה
                      </Button>
                    )}
                    {balanceProvider || balanceProvider === 0 ? (
                      <Typography sx={{ color: '#EB157B', fontSize: '14px' }}>
                        <bdi>₪{balanceProvider || 0}</bdi>
                      </Typography>
                    ) : (
                      <Typography sx={{ color: '#EB157B', fontSize: '14px' }}>₪</Typography>
                    )}
                  </>
                )}
                <Typography sx={{ fontSize: '12px' }}>
                  <bdi>יתרת הכרטיס:</bdi>
                </Typography>
              </Box>
              {!isMobile && (
                <Stack alignItems="center">
                  <Typography
                    variant="body2"
                    sx={{
                      minWidth: '23%',
                      fontSize: '14px',
                      textAlign: 'center',
                      fontWeight: 400,
                    }}
                  >
                    {card.cardType?.name || ''}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      minWidth: '23%',
                      fontSize: '10px',
                      textAlign: 'center',
                      fontWeight: 400,
                      color: 'grey',
                    }}
                  >
                    {card?.cardNumber || ''}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
        {!isMobile && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '4px',
                minWidth: '22%',
                width: '25%',
                alignItems: 'center',
                flex: 1,
              }}
            >
              {card?.cardType?.provider !== 'MULTIPASS' && card?.cardType?.provider !== 'PRAXELL' ? (
                <Typography sx={{ color: '#EB157B', fontSize: '14px' }}>
                  <bdi>₪{card.balance || 0}</bdi>
                </Typography>
              ) : (
                <>
                  {!isMix && !isMixMustClub && balanceProvider === '' && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        color: 'white',
                        bgcolor: 'black',
                        padding: 1,
                        fontSize: !smUp ? '8px' : '10px',
                        borderRadius: '5px',
                        fontWeight: 800,
                      }}
                      onClick={handleCheckBalance}
                    >
                      {/* {t('Check Balance')} */}
                      בדיקת יתרה
                    </Button>
                  )}
                  {balanceProvider || balanceProvider === 0 ? (
                    <Typography sx={{ color: '#EB157B', fontSize: '14px' }}>
                      <bdi>₪{balanceProvider || 0}</bdi>
                    </Typography>
                  ) : (
                    <Typography sx={{ color: '#EB157B', fontSize: '14px' }}>₪</Typography>
                  )}
                </>
              )}
              <Typography sx={{ fontSize: '12px' }}>
                <bdi>יתרת הכרטיס:</bdi>
              </Typography>
            </Box>
            <Stack alignItems="center">
              <Typography
                variant="body2"
                sx={{
                  minWidth: '23%',
                  fontSize: '14px',
                  textAlign: 'center',
                  fontWeight: 400,
                }}
              >
                {card.cardType?.name || ''}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  minWidth: '23%',
                  fontSize: '10px',
                  textAlign: 'center',
                  fontWeight: 400,
                  color: 'grey',
                }}
              >
                {card?.cardNumber || ''}
              </Typography>
            </Stack>
          </>
        )}
        {!isMobile && (
          <CardMedia
            sx={{ height: 70, width: 150, borderRadius: 1, objectFit: 'contain' }}
            image={imageURL}
          />
        )}
        {(isMix || isMixMustClub) && isMobile && (
          <FormControl sx={{ m: 0, width: '125px' }} variant="outlined">
            <InputLabel size="small" htmlFor={`cost_virtual_card_${card.id}`}>
              סכום להורדה
            </InputLabel>
            <OutlinedInput
              size="small"
              id={`cost_virtual_card_${card.id}`}
              type="text"
              value={cost || ''}
              endAdornment={
                <InputAdornment position="end">
                  <Iconify
                    icon="fa:shekel"
                    width={10}
                    color={isEqualCartCost ? '#EB157B' : 'inherit'}
                  />
                </InputAdornment>
              }
              label="סכום להורדה"
              onChange={handleChange}
            />
          </FormControl>
        )}
        {isMix && isMobile && (
          <Box
            sx={{
              width: '104px',
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!isBalanceChecked && card?.cardType?.provider !== 'MULTIPASS' && card?.cardType?.provider !== 'PRAXELL' ? (
              <Button
                size="small"
                sx={{
                  height: '100%',
                  width: '58px',
                  backgroundColor: '#212B36',
                  color: 'white',
                  borderRadius: '8px',
                  ...(isUsedCard &&
                    isEqualCartCost && {
                      backgroundColor: '#22C55E',
                      width: '100%',
                    }),
                  ...(isLimitCardMoney && {
                    backgroundColor: '#FF5630',
                  }),
                  '&:hover': {
                    backgroundColor: '#0d0f11',
                    ...(isUsedCard &&
                      isEqualCartCost && {
                        backgroundColor: '#398556',
                      }),
                    ...(isLimitCardMoney && {
                      backgroundColor: '#b65946',
                    }),
                  },
                }}
                onClick={handleUseCard}
              >
                {isUsedCard && isEqualCartCost && !isLimitCardMoney ? (
                  <Stack display="flex" flexDirection="row" gap={2} alignItems="center">
                    <Typography variant="button">לתשלום</Typography>
                    <Iconify icon="el:ok" width={14} />
                  </Stack>
                ) : (
                  <Typography variant="button">שמור</Typography>
                )}
              </Button>
            ) : (
              <>
                {!isBalanceChecked ? (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      color: 'white',
                      bgcolor: 'black',
                      padding: 1,
                      fontSize: !smUp ? '8px' : '10px',
                      borderRadius: '5px',
                      fontWeight: 800,
                    }}
                    onClick={handleCheckBalance}
                  >
                    {/* {t('Check Balance')} */}
                    בדיקת יתרה
                  </Button>
                ) : (
                  <Button
                    size="small"
                    sx={{
                      height: '100%',
                      width: '58px',
                      backgroundColor: '#212B36',
                      color: 'white',
                      borderRadius: '8px',
                      ...(isUsedCard &&
                        isEqualCartCost && {
                          backgroundColor: '#22C55E',
                          width: '100%',
                        }),
                      ...(isLimitCardMoney && {
                        backgroundColor: '#FF5630',
                      }),
                      '&:hover': {
                        backgroundColor: '#0d0f11',
                        ...(isUsedCard &&
                          isEqualCartCost && {
                            backgroundColor: '#398556',
                          }),
                        ...(isLimitCardMoney && {
                          backgroundColor: '#b65946',
                        }),
                      },
                    }}
                    onClick={handleUseCard}
                  >
                    {isUsedCard && isEqualCartCost && !isLimitCardMoney ? (
                      <Stack display="flex" flexDirection="row" gap={2} alignItems="center">
                        <Typography variant="button">לתשלום</Typography>
                        <Iconify icon="el:ok" width={14} />
                      </Stack>
                    ) : (
                      <Typography variant="button">שמור</Typography>
                    )}
                  </Button>
                )}
              </>
            )}
          </Box>
        )}
      </Card>
    );
  }
;

export default ClubCard;
