'use client';

import isEqual from 'lodash/isEqual';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';

// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  emptyRows,
  TableNoData,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import { API } from '../../../../../../helpers/api';
import Image from '../../../../../../components/image';
import { useTranslate } from '../../../../../../locales';
import HistoryTableRow from './history/history-table-row';
import { useRouter } from '../../../../../../routes/hooks';
import { useBoolean } from '../../../../../../hooks/use-boolean';
import HistoryTableToolbar from './history/history-table-toolbar';
import { getHistoryProvider } from '../../../../../../api/wallet';
import { useAppContext } from '../../../../../../contexts/AppContext';
import { useResponsive } from '../../../../../../hooks/use-responsive';
import { WALLET_STATUS_OPTIONS } from '../../../../../../_mock/_wallet';
import HistoryTableFiltersResult from './history/history-table-filters-result';
import { RHFSelect, RHFCheckbox } from '../../../../../../components/hook-form';
import { IProviderHistory, IWalletHistoryFilters, IWalletTableFilterValue } from '../../../../../../types/wallet';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  ...WALLET_STATUS_OPTIONS,
];

const defaultFilters = {
  budgetType: [],
  sendChanel: [],
  POSChannel: '',
  startDate: null,
  endDate: null,
  status: 'all',
};

type Props = {
  selectedMerchantId: number | null;
}

// ----------------------------------------------------------------------

export default function TabHistory({selectedMerchantId}: Props) {
  const table: any = useTable();
  const { state, dispatch } = useAppContext();
  const { t } = useTranslate();
  const settings = useSettingsContext();
  const router = useRouter();
  const confirm = useBoolean();
  const openModal = useBoolean();
  const [transactionsList, setTransactionsList] = useState<IProviderHistory[]>(
    [],
  );
  const [countTransactionsList, setCountTransactionsList] = useState<number>(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [localRow, setLocalRow] = useState<any>([]);
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [debited, setDebited] = useState('');
  const [done, setDone] = useState(false);
  const isMobile = useResponsive('down', 'sm');

  const TABLE_HEAD = [
    { id: 'provider', label: t('POS Channel') },
    { id: 'cardNumber', label: t('Coupon code') },
    { id: 'recipientsFirstName', label: t('Recipient’s First Name') },
    { id: 'recipientsLastName', label: t('Recipient’s Last Name') },
    { id: 'recipientsEmail', label: t('Recipient’s E-mail') },
    { id: 'recipientsMobile', label: t('Recipient’s Mobile') },
    { id: 'group', label: t('Group') },
    { id: 'role', label: t('Role') },
    { id: 'action', label: t('Action') },
    { id: 'loadType', label: t('Benefit Type') },
    { id: 'benefitId', label: t('Benefits') },
    { id: 'amount', label: t('Budget Value') },
    // { id: 'category', label: t('Category') },
    // { id: 'sendChannel', label: t('Send Channel') },
    // { id: 'sendDate', label: t('Send Date') },
    // { id: 'dateOfUse', label: t('Date of use') },
    { id: 'date', label: t('Date') },
    { id: 'businessRealized', label: t('Business Realized') },
    { id: 'budgetType', label: t('Budget Type') },
    // { id: 'website', label: t('Website / Distribution') },
    // { id: 'status', label: t('Status') },
    {
      id: 'settings',
      label: (
        <Box sx={{ textAlign: 'end' }}>
          <Image src="/assets/dashboard/settings.svg" />
        </Box>
      ),
      width: 100,
    },
  ];

  useEffect(() => {
    if (selectedMerchantId) {
      init();
    }
  }, [page, rowsPerPage, filters.POSChannel, selectedMerchantId]);

  const init = async () => {
    setLoading(true);

    try {
      const response = await getHistoryProvider({
        skip: page * rowsPerPage,
        limit: rowsPerPage,
        sortBy: 'date',
        supplierId : selectedMerchantId,
        ...(filters?.POSChannel && { provider: filters.POSChannel }),
        action: 'Transaction'
      });

      if (response?.data?.length) {
        setTransactionsList(response.data);
        setCountTransactionsList(response.count);
      } else {
        setTransactionsList([]); // Очистка списка, если данных нет
        setCountTransactionsList(0); // Сброс счётчика
      }

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };


  const dataFiltered = applyFilter({
    inputData: transactionsList,
    comparator: getComparator(table.order, table.orderBy),

    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage,
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = useCallback(
    (name: string, filterValue: IWalletTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: filterValue,
      }));
    },
    [table],
  );

  const handleDeleteRow = useCallback(
    (id: string) => {
      const deleteRow = transactionsList.filter(
        (row) => row.id.toString() !== id,
      );
      setTransactionsList(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, transactionsList],
  );
  const handleEditRow = useCallback(
    (id: string) => {
      // router.push(paths.dashboard.user.edit(id.toString()));
    },
    [router],
  );

  const handleFilterTypeOfUsed = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters],
  );

  const checkId = useCallback(
    (row: any) => {
      if (row) openModal.onTrue();
      setLocalRow(row);
    },
    [openModal],
  );

  const handleCodeChangeBalance = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDebited(event.target.value);
  };

  const handleCodeChange = async () => {
    if (cardNumber && amount) {
      try {
        const { data } = await API({
          data: {
            cardNumber,
            amount: +amount,
            supplierId: selectedMerchantId
          },
          url: `/provider/useCode`,
          method: 'POST',
        });
        init();
        setCardNumber('');
        setAmount('');
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{ height: '100%', mt: 2, padding: '0 !important' }}
    >
      <Card sx={{ py: '30px', px: '10px' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('Use code')}
        </Typography>
        <Stack direction="row" alignItems="center" gap={1} sx={{
          width: '100%',
          flexDirection: isMobile ? 'column' : 'row'
        }}>
          <TextField
            placeholder={t('Enter card number')}
            sx={{ width: isMobile ? '100%' : '40%' }}
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
          />
          <TextField
            placeholder={t('Amount')}
            sx={{ width: isMobile ? '100%' : '40%' }}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <LoadingButton
            variant="contained"
            loading={done}
            sx={{ borderRadius: '20px', height: '40px', ml: '10px' }}
            onClick={handleCodeChange}
          >
            {`${t('Use code')}`}
          </LoadingButton>
        </Stack>
      </Card>
      <Card sx={{ my: 5, padding: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('History')}
        </Typography>
        {/* <Tabs
          value={filters.status}
          onChange={handleFilterTypeOfUsed}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label
                  variant={((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'}
                  color={(tab.value === 'used' && 'success') || (tab.value === 'notUsed' && 'error') || 'default'}
                >
                  {tab.value === 'all' && transactionsList.length}
                  {tab.value === 'used' && transactionsList.filter((transaction) => transaction?.status === 'used').length}

                  {tab.value === 'notUsed' && transactionsList.filter((transaction) => transaction?.status === 'notUsed').length}
                </Label>
              }
            />
          ))}
        </Tabs> */}

        <HistoryTableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          // budgetOption={[]}
          // sendChannelOption={[]}
        />

        {canReset && (
          <HistoryTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={transactionsList.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                transactionsList.map((row) => row.id.toString()),
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table
              size={table.dense ? 'small' : 'medium'}
              sx={{ minWidth: 960 }}
            >
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={transactionsList.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    transactionsList.map((row) => row.id.toString()),
                  )
                }
              />

              {loading && (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 100,
                    backgroundColor: 'background.paper',
                    opacity: 0.5,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <TableBody>
                {dataFiltered.map((row) => (
                  <HistoryTableRow
                    key={row.id}
                    row={row}
                    selected={table.selected.includes(row.id.toString())}
                    onSelectRow={() => table.onSelectRow(row.id.toString())}
                    onDeleteRow={() => handleDeleteRow(row.id.toString())}
                    onEditRow={() => handleEditRow(row.id.toString())}
                    checkId={checkId}
                  />
                ))}
                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(
                    table.page,
                    table.rowsPerPage,
                    transactionsList.length,
                  )}
                />
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={countTransactionsList}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={openModal.value}
        onClose={openModal.onFalse}
      >
        <DialogTitle sx={{ pb: 2 }}>
          Message to {localRow.recipientsFirstName}{' '}
          {localRow.recipientsLastName}
        </DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          Send via
          <RHFCheckbox
            name="email"
            label={t('Email: davidnavahov@gmail.com')}
          />
          <RHFCheckbox name="sms" label={t('SMS: +974 54 1111 22 33')} />
          <RHFCheckbox
            name="whatsApp"
            label={t('WhatsApp: +974 54 1111 22 33')}
          />
          <RHFCheckbox name="send" label={t('Send to all contacts options')} />
          <Typography variant="h6" sx={{ my: 3 }}>
            {t('Message')}
          </Typography>
          <RHFSelect name="message" label={t('Select message')}>
            {['Message'].map((message) => (
              <MenuItem key={message} value={message}>
                {t(message)}
              </MenuItem>
            ))}
          </RHFSelect>
          <Button
            variant="text"
            color="inherit"
            onClick={() => {}}
            sx={{ color: 'red' }}
          >
            <Iconify icon="bi:plus" />
            {t('Create new')}
          </Button>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="inherit"
            onClick={openModal.onFalse}
            sx={{ borderRadius: '20px' }}
          >
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={openModal.onFalse}
            sx={{ borderRadius: '20px' }}
          >
            {t('Send')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

// ----------------------------------------------------------------------
function applyFilter({
                       inputData,
                       comparator,
                       filters,
                     }: {
  inputData: IProviderHistory[];
  comparator: (a: any, b: any) => number;
  filters: IWalletHistoryFilters;
}) {

  return inputData;
}
