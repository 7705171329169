'use client';

// locales
import { useLocales, useTranslate } from 'src/locales';

// @mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
import Iconify from 'src/components/iconify';
import { IWalletHistoryFilters, IWalletHistoryFilterValue } from '../../../../../../../types/wallet';
// types
// components

// ----------------------------------------------------------------------

type Props = StackProps & {
  filters: IWalletHistoryFilters;
  onFilters: (name: string, value: IWalletHistoryFilterValue) => void;
  //
  onResetFilters: VoidFunction;
  //
  results: number;
};

export default function HistoryTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) {
  const { t } = useTranslate();

  const handleRemoveTypeOfUsed = () => {
    onFilters('status', 'all');
  };

  const handleRemoveBudgetType = (inputValue: string) => {
    const newValue = filters.budgetType.filter((item) => item !== inputValue);
    onFilters('budgetType', newValue);
  };

  const handleRemoveSendChannel = (inputValue: string) => {
    const newValue = filters.sendChanel.filter((item) => item !== inputValue);
    onFilters('sendChannel', newValue);
  };

  const handleRemovePOSChannel = () => {
    onFilters('POSChannel', '');
  };

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          {t('results found')}
        </Box>
      </Box>

      <Stack
        flexGrow={1}
        spacing={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
      >
        {filters.status !== 'all' && (
          <Block label={t('Type of used:')}>
            <Chip
              size="small"
              label={filters.status === 'used' ? 'Used' : 'Not used'}
              onDelete={handleRemoveTypeOfUsed}
            />
          </Block>
        )}

        {!!filters.budgetType.length && (
          <Block label={t('Budget Type:')}>
            {filters.budgetType.map((item) => (
              <Chip
                key={item}
                label={t(item)}
                size="small"
                onDelete={() => handleRemoveBudgetType(item)}
              />
            ))}
          </Block>
        )}
        {!!filters.sendChanel.length && (
          <Block label={t('Send Channel:')}>
            {filters.sendChanel.map((item) => (
              <Chip
                key={item}
                label={t(item)}
                size="small"
                onDelete={() => handleRemoveSendChannel(item)}
              />
            ))}
          </Block>
        )}
        {!!filters.POSChannel && (
          <Block label={t('POS Channel:')}>
            <Chip
              key={filters.POSChannel}
              label={filters.POSChannel}
              size="small"
              onDelete={handleRemovePOSChannel}
            />
          </Block>
        )}
        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          {t('Clear')}
        </Button>
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
  label: string;
};

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}
