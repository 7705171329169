'use client';

import { FC, Key, useState } from 'react';
import { useRouter } from 'next/navigation';

import { Box, Card, Stack, Button, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { useLocales } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';

const FullWidthBannersCarousel: FC<ComponentTypeProps> = ({ block }) => {
  const { currentLang } = useLocales();
  const [active, setActive] = useState(0);
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();

  const { combined }: any = block || [];
  const position = (item: any) => {
    if (item.position === 'start') return 'flex-start';
    if (item.position === 'center') return 'center';
    if (item.position === 'end') return 'flex-end';
    return 'center';
  };
  return (
    <>
      {!isMobile ? (
        <Box width={1} sx={{ position: 'relative', mb: block?.settings?.enableOverlay ? '-20px' : '' }}>
          {combined?.map((item: any, index: Key) => (
            <Card
              key={index}
              component={Stack}
              justifyContent={position(item)}
              alignItems={item.contentAlign}
              sx={{
                display: index !== active ? 'none' : 'block',
                width: '100%',
                borderRadius: '0px',
                position: 'relative',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Image
                src={item.backgroundImage}
                sx={{ width: '100%', height: '100%' }}
              />
              <Stack
                className={item.contentClass}
                justifyContent="center"
                gap={2}
                width="auto"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: 1,
                  mx: '130px',
                }}
              >
                <Stack
                  sx={{ height: 1, justifyContent: 'center', position: 'relative' }}
                >
                  {item.enableText && (
                    <Typography
                      className={item.typographyClass}
                      sx={{
                        width: '100%',
                        '& h1': { my: 1 },
                        '& h2': { my: 1 },
                        '& h3': { my: 1 },
                        '& h4': { my: 1 },
                        '& h5': { my: 1 },
                        '& h6': { my: 1 },
                        '& p': { margin: 0 },
                        direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                        lineHeight: item.lineHeight,
                        mb: 2,
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item?.text }} />
                    </Typography>
                  )}
                  {item.enableButton && (
                    <Button
                      variant="contained"
                      href={item.buttonHref}
                      sx={
                        item.enableGradient
                          ? {
                            borderRadius: '5px',
                            position: 'absolute',
                            bottom: '40px',
                            width: '100%',
                            fontSize: '12px',
                            direction: 'rtl',
                            background: `linear-gradient(90deg, ${item?.firstGradientColor} ${item?.firstGradientColorStop}%, ${item?.secondGradientColor} ${item?.secondGradientColorStop}% )`,
                            color: item.buttonColor,
                            '&:hover': {
                              background: `linear-gradient(90deg, ${item?.firstGradientColor} ${item?.firstGradientColorStop}%, ${item?.secondGradientColor} ${item?.secondGradientColorStop}% )`,
                              color: item.buttonColor,
                            },
                          }
                          : {
                            borderRadius: '5px',
                            position: 'absolute',
                            bottom: '40px',
                            width: '100%',
                            fontSize: '12px',
                            direction: 'rtl',
                            color: item.buttonColor,
                            bgcolor: item.buttonBgColor,
                            '&:hover': {
                              color: item.buttonColor,
                              bgcolor: item.buttonBgColor,
                            },
                          }
                      }
                      endIcon={<Iconify icon={item.buttonIcon || ''} />}
                    >
                      {item.buttonLabel || 'No label'}
                    </Button>
                  )}
                </Stack>
              </Stack>
              {item?.enableDownloadBlock && (
                <Stack
                  gap={1}
                  direction="row-reverse"
                  alignItems="flex-end"
                  sx={{ position: 'absolute', bottom: 25, right: 10 }}
                >
                  <Image
                    src={uploadProductsImage(item?.qrCode)}
                    sx={{ '& img': { width: 0.7, height: 'auto' } }}
                  />
                  <Stack width={0.5}>
                    <Typography sx={{ textAlign: 'end', color: 'white' }}>
                      סרקו להורדת האפליקציה
                    </Typography>
                    <Stack direction="row" gap={1}>
                      <Image
                        sx={{ borderRadius: '5px', cursor: 'pointer' }}
                        src={uploadProductsImage(item?.appStore)}
                        onClick={() => router.push(`${item?.appStoreLink}`)}
                      />
                      <Image
                        sx={{ borderRadius: '5px', cursor: 'pointer' }}
                        src={uploadProductsImage(item?.playMarket)}
                        onClick={() => router.push(`${item?.palyMarketLink}`)}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Card>
          ))}
          <Stack
            direction="row"
            gap={1}
            sx={{
              position: 'absolute',
              bottom: '25px',
              mx: '130px',
            }}
          >
            {combined.map((item: any, id: number) => (
              <Box
                key={id}
                sx={{
                  background: active === id ? 'white' : 'lightgray',
                  width: '20px',
                  height: '3px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setActive(id)}
              />
            ))}
          </Stack>
        </Box>
      ) : (
        <Box width={1} sx={{ position: 'relative' }}>
          {combined?.map((item: any, index: Key) => (
            <Card
              key={index}
              component={Stack}
              justifyContent={position(item)}
              alignItems={item.contentAlign}
              sx={{
                display: index !== active ? 'none' : 'block',
                width: '100%',
                borderRadius: '0px',
                position: 'relative',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Image
                src={item.backgroundImageMobile}
                sx={{ width: '100%', height: '100%' }}
              />
              <Stack
                className={item.contentClass}
                justifyContent="center"
                gap={2}
                width="auto"
                sx={{
                  position: 'absolute',
                  top: 0,
                  height: 1, width: '100%',
                }}
              >
                <Stack
                  sx={{ height: 1, width: '100%', justifyContent: 'flex-start', position: 'relative' }}
                >
                  {item.enableText && (
                    <Typography
                      className={item.typographyClass}
                      sx={{
                        width: '100%',
                        '& h1': { my: 1, textAlign: 'center' },
                        '& h2': { my: 1, textAlign: 'center' },
                        '& h3': { my: 1, textAlign: 'center' },
                        '& h4': { my: 1, textAlign: 'center' },
                        '& h5': { my: 1, textAlign: 'center' },
                        '& h6': { my: 1, textAlign: 'center' },
                        '& p': { margin: 0, textAlign: 'center' },
                        direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                        lineHeight: item.lineHeight,
                        mb: 2,

                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item?.text }} />
                    </Typography>
                  )}
                  {item.enableButton && (
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2} // Устанавливаем промежуток между кнопками
                      sx={{ position: 'absolute', bottom: '40px', width: 1 }}
                    >
                      {item?.actionButtons?.map((button: any, id: number) => (
                        <Button
                          key={id}
                          variant="contained"
                          href={button.link}
                          sx={
                            button.isBackgroundGradient
                              ? {
                                borderRadius: '5px',
                                fontSize: '12px',
                                direction: 'rtl',
                                background: `linear-gradient(90deg, ${button?.firstGradientColor} ${button?.firstGradientStop}%, ${button?.secondGradientColor} ${button?.secondGradientStop}% )`,
                                color: 'white',
                                '&:hover': {
                                  background: `linear-gradient(90deg, ${button?.firstGradientColor} ${button?.firstGradientStop}%, ${button?.secondGradientColor} ${button?.secondGradientStop}% )`,
                                  color: 'white',
                                },
                              }
                              : {
                                borderRadius: '5px',
                                fontSize: '12px',
                                direction: 'rtl',
                                color: 'secondary.main',
                                border: '0.5px solid ',
                                borderColor: 'secondary.main',
                                bgcolor: button.background,
                                '&:hover': {
                                  color: 'secondary.main',
                                  bgcolor: button.background,
                                },
                              }
                          }
                          endIcon={<Iconify icon={button.icon || ''} />}
                        >
                          {button.label || 'No label'}
                        </Button>
                      ))}
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Card>
          ))}
          <Stack
            direction="row"
            gap={1}
            sx={{
              position: 'absolute',
              bottom: '25px',
              mx: '130px',
            }}
          >
            {combined.map((item: any, id: number) => (
              <Box
                key={id}
                sx={{
                  background: active === id ? 'white' : 'lightgray',
                  width: '20px',
                  height: '3px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => setActive(id)}
              />
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default FullWidthBannersCarousel;
