'use client';

import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { useForm } from 'react-hook-form';
import { Swiper as SwiperCore } from 'swiper/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState, useEffect, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';
import BasicModal from 'src/components/modals/basic-modal';
import MerchantCard from 'src/components/cards/merchant-card';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { IMerchant } from 'src/types/merchant';
import { IWalletProductTableFilter } from 'src/types/wallet';
import { getMerchantsByProductIds } from '../../../../api/merchant';
import WalletToolBar from '../../../wallet/components/wallet-tool-bar';
import { useAppContext } from '../../../../contexts/AppContext';
import { useGetMerchantCategories } from '../../../../api/shop';
import { Ib2bTableFilter, Ib2bTableFilterValue } from '../../../../types/b2b';


type Props = {
  card: any;
};

export const RenderCouponInfoPage = ({ card }: Props) => {

  const swiperRef = useRef<SwiperCore | null>(null);
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const LIMIT = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useResponsive('down', 'sm');
  const smUp = useResponsive('up', 'sm');
  const payHere = useBoolean();
  const success = useBoolean();
  const [merchants, setMerchants] = useState<IMerchant[]>([]);
  const { t } = useTranslate();
  const pagesCount = Math.round(merchants && merchants.length / 10) || 1; // TEMPORARY
  const { dispatch, state } = useAppContext();
  const { categories: merchantCategories } = useGetMerchantCategories({
    limit: 99,
    offset: 0,
  });

  const defaultFilters: Ib2bTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
    price: [0, 30000000],
    search: '',
  };

  const [filters, setFilters] = useState(defaultFilters);
  const canReset = !isEqual(defaultFilters, filters);

  const dataFiltered = applyFilter({
    inputData: merchants,
    filters,
  });

  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage);
    // dispatch(getProducts(activeCategory.id, newPage, itemsPerPage));
  };

  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState: any) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const handleResetArea = () => {
    handleFilters('area', []);
  };

  const handleResetCategory = () => {
    handleFilters('category', []);
  };

  const handleResetType = () => {
    handleFilters('type', []);
  };

  const handleResetName = () => {
    handleFilters('name', '');
  };

  useEffect(() => {
    if (isEqual(defaultFilters, filters)) setCurrentPage(1);
  }, [filters]);

  // const filterView = (
  //     <MerchantToolbar
  //       block={block}
  //       filters={filters}
  //       onFilters={handleFilters}
  //       //
  //       typeOptions={state.productsTags.map((mTag: ITag) => mTag.name)}
  //       categoryOptions={merchantCategories}
  //       areaOptions={state.merchantsTags.map((mTag: ITag) => mTag.name)}
  //       handleResetFilters={handleResetFilters}
  //       canReset={canReset}
  //       handleResetCategory={handleResetCategory}
  //       handleResetArea={handleResetArea}
  //       handleResetTags={handleResetType}
  //       handleResetName={handleResetName}
  //     />
  // );
  const filterView = (
    <WalletToolBar
      filters={filters}
      onFilters={handleFilters}
      //
      typeOptions={[]}
      categoryOptions={merchantCategories}
      areaOptions={[]}
      handleResetFilters={handleResetFilters}
      canReset={canReset}
      handleCloseAcceptingCardsModal={() => {}}
      сardsModal={false}
      handleResetCategory={handleResetCategory}
      handleResetArea={handleResetArea}
      handleResetTags={handleResetType}
      handleResetName={handleResetName}
    />
  );

  useEffect(() => {
    const { category } = filters;
    const categoryIds: string[] = [];
    category.forEach((c: string) =>
      categoryIds.push(String(merchantCategories.find((cat: any) => cat.title === c)?.id))
    );
    getMerchantsByProductIds({
      // limit: LIMIT,
      offset: (currentPage - 1) * LIMIT,
      dispatch,
      search: filters.name,
      productIds: card.product.id,
      categoryIds,
    }).then((response: any) => {
      console.log('response', response);
      setMerchants(response.payload);
    });
  }, [filters]);

  const FormSchema = Yup.object().shape({
    total: Yup.number().test({
      name: 'is-less-or-equal-than-balance',
      message: t("Amount should be less or equal than card's balance"),
      test: (value, context) => (value ? value <= Number(card.balance) : true),
    }),
  });

  const defaultValues = {
    total: 0,
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      reset();
      success.onTrue();
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const handleClosePayHere = useCallback(() => {
    payHere.onFalse();
    success.onFalse();
    reset();
  }, []);

  const extraButton = (
    <Button
      variant="outlined"
      color="secondary"
      sx={{
        mt: 3,
        borderRadius: '20px',
        color: 'primary.main',
        px: { xs: 1, md: 1 },
      }}
      onClick={payHere.onTrue}
    >
      {t('Pay here')}
    </Button>
  );

  return (
    <>
      <Stack direction="column" alignItems="center">
        {isMobile ? (
          <Stack width={1}>
            <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
            <Drawer open={open} onClose={toggleDrawer(false)}>
              {filterView}
            </Drawer>
          </Stack>
        ) : (
          <>{filterView}</>
        )}
        <Stack
          flexWrap="wrap"
          flexDirection="row-reverse"
          justifyContent={smUp ? '' : 'center'}
          gap={3}
        >
          {/* {isMobile ? ( */}
          {/*  <Box sx={{ '& .swiper-slide': { width: 'auto', margin: 1 } }}> */}
          {/*    <Box */}
          {/*      sx={{ */}
          {/*        width: '90vw', */}
          {/*      }} */}
          {/*    > */}
          {/*      <Swiper */}
          {/*        speed={500} */}
          {/*        slidesPerView="auto" */}
          {/*        mousewheel={{ */}
          {/*          forceToAxis: true, */}
          {/*          sensitivity: 1, */}
          {/*          releaseOnEdges: true, */}
          {/*        }} */}
          {/*        style={{ */}
          {/*          display: 'flex', */}
          {/*          flexDirection: 'row', */}
          {/*          justifyContent: 'space-between', */}
          {/*        }} */}
          {/*        onSwiper={(swiper) => { */}
          {/*          swiperRef.current = swiper; */}
          {/*        }} */}
          {/*      > */}
          {/*        {dataFiltered?.map((merchant) => ( */}
          {/*          <SwiperSlide key={merchant.id}> */}
          {/*            <MerchantCard */}
          {/*              key={merchant.id} */}
          {/*              merchant={merchant} */}
          {/*              extraButton={extraButton} */}
          {/*            /> */}
          {/*          </SwiperSlide> */}
          {/*        ))} */}
          {/*      </Swiper> */}
          {/*    </Box> */}
          {/*  </Box> */}
          {/* ) : ( */}
          {/*  <> */}
              {dataFiltered?.map((merchant) => (
                <MerchantCard key={merchant.id} merchant={merchant} extraButton={extraButton} />
              ))}
            {/* </> */}
          {/* )} */}

          {/* TO-DO: add placeholder if no merchants  */}
        </Stack>
        {/* <Pagination */}
        {/*  onChange={handlePageChange} */}
        {/*  page={currentPage} */}
        {/*  count={Math.round(state.merchantCount / LIMIT) || 1} */}
        {/*  color="primary" */}
        {/*  sx={{ margin: '0 auto' }} */}
        {/* /> */}
      </Stack>
      <BasicModal open={payHere.value} onClose={handleClosePayHere}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack alignItems="flex-start" gap={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
              {t('Pay here')}
            </Typography>
            {!success.value && (
              <>
                <RHFTextField
                  sx={{ mt: 1, width: '100%' }}
                  name="total"
                  label={t('Total')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Iconify icon="icons8:shekel" />
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  fullWidth={false}
                  helperText=" "
                />
                <LoadingButton
                  color="secondary"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t('Pay')}
                </LoadingButton>
              </>
            )}
            {success.value && (
              <>
                <Typography variant="h4">{t('Success!')}</Typography>
                <Typography>Your card number is 0533-6325</Typography>
              </>
            )}
          </Stack>
        </FormProvider>
      </BasicModal>
    </>
  );
};

function applyFilter({
  inputData,
  filters,
}: {
  inputData: IMerchant[];
  filters: IWalletProductTableFilter;
}) {
  const {
    name,
    // area,
    // type,
    // category,
  } = filters;

  if (name) {
    inputData = inputData.filter(
      (product) => product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  // if (area.length) {
  //   inputData = inputData.filter((product) => area.includes(product.area));
  // }

  // if (type.length) {
  //   inputData = inputData.filter((product) => type.includes(product.type));
  // }

  // if (category.length) {
  //   inputData = inputData.filter((product) => category.includes(product.category));
  // }

  return inputData;
}
