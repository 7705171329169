'use client';

import { FC, useEffect, useState } from 'react';
import Slider from 'react-slick';
import Stack from '@mui/material/Stack';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../components/iconify';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { Ib2bProduct } from '../../../../types/b2b';
import { AlternativeProductCarousel } from '../../../products/alternativeProductcarousel';
import { useGetProducts } from '../../../../api/product';
import { useResponsive } from '../../../../hooks/use-responsive';
import { useRouter } from '../../../../routes/hooks';

export const ProductsCarousel: FC<ComponentTypeProps> = ({ block }) => {
  const actionButtons: any = block?.actionButtons || [];
  const { products, totalCount } = useGetProducts({ });
  let sliderRef: Slider | any;
  const text: string = block?.text as string;
  const size = block?.settings?.enableDouble ? 5 : 8;
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();

  const [localCategories, setLocalCategories] = useState([]);
  const { products: localProducts } = useGetProducts({categoryIds: localCategories });

  const enableCategoryFilter: boolean = block?.settings?.enableCategoryFilter;

  const mapData = enableCategoryFilter ? localProducts : products || [];

  useEffect(() => {
    const array: any = [];
    block?.settings?.categories?.map((item: { value: string }) => {
      array.push(item.value);
      return null;
    });
    setLocalCategories(block?.settings?.enableCategoryFilter ? array : []);
  }, [block?.settings?.categories]);

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon="ep:arrow-left-bold" color="rgba(14, 23, 50, 1)" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon="ep:arrow-right-bold" color="rgba(14, 23, 50, 1)" />
    </Box>
  );

  const handlePrevClick = () => {
    sliderRef.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.slickNext();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: !block?.settings?.enablePriceOnTop ? size : 6,
    slidesToScroll: 1,
    arrows: !isMobile,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    nextArrow: <CustomPrevArrow onClick={handlePrevClick} />,
    prevArrow: <CustomNextArrow onClick={handleNextClick} />,
  };

  return (
    <Box
      sx={{
        '& .slick-track': { mt: '10px' },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography sx={{ fontSize: isMobile ? '18px' : '22px', fontWeight: 800 }}>
          {text}
        </Typography>
        {actionButtons.length > 0 && (
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1, cursor: 'pointer' }} onClick={() => router.push(`${actionButtons[0].link}`)}>{actionButtons[0]?.label}</Typography>
          </Stack>
        )}
      </Stack>
      <Divider sx={{ my: 2 }} />

      <Slider {...settings}>
        {mapData?.map((product: Ib2bProduct, id: number) => (
          <Box key={id} sx={{ px: 0.5 }}>
            {' '}
            {/* Уменьшено значение px */}
            <AlternativeProductCarousel block={block} product={product} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
