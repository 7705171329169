'use client';

import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslate } from 'src/locales';
import { cardStatusOrder, getCardStatusColor } from 'src/helpers/cardStatusMap';

import WalletTableRow from './table-row';
import Image from '../../../components/image';
import { IVirtualCard } from '../../../types/club-cards';
import { BlockType } from '../../../types/page-generator';
import TransactionsTableRow from './transactions-table-row';
import { useAppContext } from '../../../contexts/AppContext';
import { useTable, TableHeadCustom } from '../../../components/table';
import { uploadProductsImage } from '../../../helpers/uploadProductsImage';
import { API } from '../../../helpers/api';
import { BOT_ID } from '../../../config-global';
import { useAuthContext } from '../../../auth/hooks';
import { ICoupon } from '../../../types/coupons';
import { TabTransactionHistoryChildren } from './tab-transaction-history-children';

type Props = {
  block: BlockType | undefined;
};

export const WalletTables = ({ block }: Props) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [coupons, setCoupons] = useState<ICoupon[]>([]);

  const tableCellStyle = {
    whiteSpace: isMobile ? 'pre-line' : 'normal',
    overflow: isMobile ? 'hidden' : 'visible',
    textOverflow: isMobile ? 'ellipsis' : 'clip',
  };

  const [transactionsTabs, setTransactionsTabs] = useState(['']);

  const { state } = useAppContext();
  const { virtualCards, cards } = state;
  const activityId = state.smbActivityId;
  const { user } = useAuthContext();

  useEffect(() => {
    const array: string[] = [];
    if (virtualCards.length) {
      virtualCards.map((card: IVirtualCard) => array.push(card?.cardType?.name as string));
    }
    setTransactionsTabs(array);
  }, []);

  const [currentTab, setCurrentTab] = useState(-1);
  const [currentSubTab, setCurrentSubTab] = useState(0);
  const localTabs = [
    { id: 0, label: 'מתנה לבחירה', show: !!block?.settings?.enableCoupons },
    { id: 1, label: 'הקופונים שלי', show: !!block?.settings?.enableGift },
    {
      id: 2,
      label: 'מוצרים מסובסדים',
      show: !!block?.settings?.enableSubsidizedProducts,
    },
    { id: 3, label: 'אתרים', show: !!block?.settings?.enableSites },
    { id: 4, label: 'עסקאות', show: !!block?.settings?.enableTransactions },
  ];

  useEffect(() => {
    const idx: any = localTabs.find((tab) => tab.show)?.id;
    setCurrentTab(idx);
  }, []);
  const [currentTransactionsTab, setCurrentTransactionsTab] = useState(0);
  const [tableData, setTableData] = useState([
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 1111111111,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 1111111111,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 1111111111,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 1111111111,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 1111111111,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
  ]);

  const secondTableData = [
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 2222222222,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 2222222222,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 2222222222,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 2222222222,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 2222222222,
      dateOfUse: '10 Mar 2022',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
  ];

  const thirdTableData = [
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 3333333333,
      dateOfUse: '10 Mar 2023',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 3333333333,
      dateOfUse: '10 Mar 2023',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 3333333333,
      dateOfUse: '10 Mar 2023',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 3333333333,
      dateOfUse: '10 Mar 2023',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
    {
      id: 1,
      coupon: 'כרטיס כניסה בוגרים ברשת Funky Monkey',
      code: 3333333333,
      dateOfUse: '10 Mar 2023',
      placeOfUse: 'פאנקי מאנקי באר שבע',
      eventType: 'מענק',
    },
  ];
  const TABLE_HEAD = [
    { id: 'coupon', label: 'שם קופון', with: 216, fontSize: '20px' },
    { id: 'code', label: 'קוד', width: 250, fontSize: '20px' },
    { id: 'dateOfUse', label: 'תאריך שימוש', width: 250, fontSize: '20px' },
    { id: 'placeOfUse', label: 'מקום שימוש', width: 200, fontSize: '20px' },
    { id: 'eventType', label: 'סוג אירוע', width: 200, fontSize: '20px' },
  ];
  const TABLE_HEAD_TRANSACTIONS = [
    { id: 'type', label: 'סוג', fontSize: '20px' },
    { id: 'date', label: ' תאריך ו זמן', fontSize: '20px' },
    { id: 'amount', label: 'כמות', fontSize: '20px' },
  ];

  const handleChangeTab = (event: any, newValue: number) => {
    setCurrentTab(newValue);
  };
  const handleChangeTransactionsTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTransactionsTab(newValue);
  };
  const table = useTable();


  const init = async () => {
    if (activityId && user) {
      try {
        const { data } = await API({
          params: {
            botId: BOT_ID,
            smbActivityId: activityId,
          },
          url: `card`,
          method: 'GET',
        });
        if (data?.cards?.length) {
          setCoupons(data.cards);
        } else {
          setCoupons([]);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <Tabs
        sx={{
          mb: 3,
          '& .MuiButtonBase-root': { fontSize: '20px' },
          '& .MuiTabScrollButton-root': {
            display: !isMobile && !localTabs.find((item: any) => item.show) ? 'none' : '',
          },
        }}
        value={currentTab}
        onChange={handleChangeTab}
        scrollButtons={localTabs.filter((item: any) => item.show).length > 1 ? 'auto' : false} // Отключаем кнопки, если одна вкладка
      >
        {localTabs.map((tab, id) => (
          <Tab key={id} label={tab.label} sx={{ display: tab.show ? '' : 'none' }} />
        ))}
      </Tabs>
      {currentTab === 0 && (
        <div style={{ overflowX: 'auto' }}>
          <TableHeadCustom
            order={table.order}
            orderBy={table.orderBy}
            headLabel={TABLE_HEAD}
            rowCount={tableData.length}
            numSelected={table.selected.length}
            onSort={table.onSort}
            sx={{
              '& .MuiTableCell-root': {
                '& .MuiButtonBase-root': {
                  fontSize: '20px',
                },
              },
            }}
          />
          <TableBody>
            {tableData.map((row, id) => (
              <WalletTableRow key={`${row.id}_${id}`} row={row} cellStyle={{ fontSize: '20px' }} />
            ))}
          </TableBody>
        </div>
      )}
      {currentTab === 1 && (
        <div style={{ overflowX: 'auto' }}>
          <TableHeadCustom
            order={table.order}
            orderBy={table.orderBy}
            headLabel={TABLE_HEAD}
            rowCount={secondTableData.length}
            numSelected={table.selected.length}
            onSort={table.onSort}
            sx={{
              '& .MuiTableCell-root': {
                '& .MuiButtonBase-root': {
                  fontSize: '20px',
                },
              },
            }}
          />
          <TableBody>
            {secondTableData.map((row, id) => (
              <WalletTableRow key={`${row.id}_${id}`} row={row} cellStyle={{ fontSize: '20px' }} />
            ))}
          </TableBody>
        </div>
      )}
      {currentTab === 2 && (
        <div style={{ overflowX: 'auto' }}>
          <TableHeadCustom
            order={table.order}
            orderBy={table.orderBy}
            headLabel={TABLE_HEAD}
            rowCount={thirdTableData.length}
            numSelected={table.selected.length}
            onSort={table.onSort}
            sx={{
              '& .MuiTableCell-root': {
                '& .MuiButtonBase-root': {
                  fontSize: '20px',
                },
              },
            }}
          />
          <TableBody>
            {thirdTableData.map((row, id) => (
              <WalletTableRow key={`${row.id}_${id}`} row={row} cellStyle={{ fontSize: '20px' }} />
            ))}
          </TableBody>
        </div>
      )}
      {currentTab === 3 && (
        <div style={{ overflowX: 'auto' }}>
          <TableHeadCustom
            order={table.order}
            orderBy={table.orderBy}
            headLabel={TABLE_HEAD}
            rowCount={tableData.length}
            numSelected={table.selected.length}
            onSort={table.onSort}
            sx={{
              '& .MuiTableCell-root': {
                '& .MuiButtonBase-root': {
                  fontSize: '20px',
                },
              },
            }}
          />
          <TableBody>
            {tableData.map((row, id) => (
              <WalletTableRow key={`${row.id}_${id}`} row={row} cellStyle={{ fontSize: '20px' }} />
            ))}
          </TableBody>
        </div>
      )}
      {/* TODO: Organize with components */}
      {currentTab === 4 && (
        <Box>
          <Tabs
            sx={{ mb: 2 }}
            value={currentSubTab}
            onChange={(e, newValue) => setCurrentSubTab(newValue)}
          >
            <Tab label={t('Coupons')} />
            <Tab label={t('History')} />
          </Tabs>
          {currentSubTab === 0 && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
                gap: 2,
                mb: 3,
              }}
            >
              {cards?.cards
                .sort((a, b) => cardStatusOrder.indexOf(a.status) - cardStatusOrder.indexOf(b.status))
                .map((card) => (
                  <Card sx={{ p: 2, maxWidth: 350 }} key={card.id}>
                    {!!card.product?.mainImageURL && (
                      <Image
                        src={uploadProductsImage(card.product?.mainImageURL)}
                        sx={{ width: '100px', borderRadius: '5px' }}
                      />
                    )}
                    <Chip
                      label={t(card.status)}
                      color={getCardStatusColor(card.status)}
                      sx={{ zIndex: 2, position: 'absolute', top: 10, right: 10 }}
                    />
                    <Typography>
                      <strong>{t('Product')}:</strong> {card.product?.title || ''}
                    </Typography>
                    <Typography>
                      <strong>{t('Card number')}:</strong> {card.cardNumber}
                    </Typography>
                    {card.type === 'LOAD' && (
                      <Typography>
                        <strong>{t('Balance')}:</strong> {card.balance || ''}
                      </Typography>
                    )}
                  </Card>
                ))}
            </Box>
          )}
          {currentSubTab === 1 && (
            <div style={{ overflowX: 'auto' }}>
              <Tabs
                sx={{
                  mb: 3,
                  '& .MuiButtonBase-root': { fontSize: '20px' },
                }}
                value={currentTransactionsTab}
                onChange={handleChangeTransactionsTab}
              >
                {transactionsTabs.map((tab, id) => (
                  <Tab
                    key={id}
                    label={
                      <Stack direction="row" gap={1}>
                        <Image
                          src={uploadProductsImage(virtualCards[id]?.cardType?.mainImageURL || '')}
                          sx={{ width: '30px', borderRadius: '5px' }}
                        />
                        <Typography>{tab}</Typography>
                      </Stack>
                    }
                  />
                ))}
              </Tabs>
              {virtualCards[currentTransactionsTab] &&
                virtualCards[currentTransactionsTab]?.transactions && (
                  // <TabTransactionHistoryChildren localCard={virtualCards[currentTransactionsTab]} />
                  <>
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD_TRANSACTIONS}
                      rowCount={tableData.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      sx={{
                        '& .MuiTableCell-root': {
                          '& .MuiButtonBase-root': {
                            fontSize: '20px',
                          },
                        },
                      }}
                    />
                    <TableBody>
                      {virtualCards[currentTransactionsTab].transactions.map((row, id: number) => (
                        <TransactionsTableRow
                          key={`${row.id}_${id}`}
                          row={row}
                          cellStyle={{ fontSize: '20px' }}
                        />
                      ))}
                    </TableBody>
                  </>
                )}
            </div>

          )}
        </Box>

      )}
    </Box>
  );
};
