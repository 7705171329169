import { API } from 'src/helpers/api';
// import { SERVICES } from 'src/_mock/_services';
  // eslint-disable-next-line import/no-cycle
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

import { BOT_ID } from '../config-global';

export type ITestimonial = {
  approved: boolean;
  createdAt: string;
  customerId: string;
  description: string;
  extra: {
    pros: string;
    cons: string;
  },
  id: string;
  merchantId: number;
  productId: string;
  rating: number
};

export const getTestimonialsByProductId = async (productId: string | string[], dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({
      url: `testimonial/approved`,
      method: 'GET',
      params: {
        limit: 1000,
        offset: 0,
        productId,
        withCustomer: '1'
      },
    });
    dispatch({ type: ActionTypes.PRODUCT_TESTIMONIALS, payload: data.payload.testimonials });

  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const getTestimonialsByMerchantId = async (merchantId: string | string[], dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({
      url: `testimonial/approved`,
      method: 'GET',
      params: {
        limit: 1000,
        offset: 0,
        merchantId,
        withCustomer: '1'
      },
    });
    dispatch({ type: ActionTypes.MERCHANT_TESTIMONIALS, payload: data.payload.testimonials });

  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const getTestimonialsByPageId = async (pageId: string | string[], dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({
      url: `testimonial/approved`,
      method: 'GET',
      params: {
        limit: 1000,
        offset: 0,
        pageId,
        withCustomer: '1'
      },
    });
    dispatch({ type: ActionTypes.PAGE_TESTIMONIALS, payload: data.payload.testimonials });

  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};
