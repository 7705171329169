import type { ActionType } from 'src/contexts/AppContext';

export interface PaginationFilters {
  offset?: number;
  limit?: number;
  search?: string;
  dispatch?: React.Dispatch<ActionType>;
}

export const defaultPaginationFilters: PaginationFilters = {
  offset: 0,
  limit: 8,
};
