'use client';

import Link from 'next/link';
import { useRef, useState, useEffect } from 'react';
// eslint-disable-next-line
import ReactCodeInput from 'react-verification-code-input';

import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Stack, Button, TextField, Typography } from '@mui/material';

import Image from '../../../../../../components/image';
import { IMerchant } from '../../../../../../types/merchant';
import { useAuthContext } from '../../../../../../auth/hooks';
import { BlockType } from '../../../../../../types/generator';
import { useBoolean } from '../../../../../../hooks/use-boolean';
import { BasicPageParams } from '../../../../../../types/common';
import { useLocales, useTranslate } from '../../../../../../locales';
import { useResponsive } from '../../../../../../hooks/use-responsive';
import { useContentContext } from '../../../../../../components/content';
import { useGetActivityByDomain } from '../../../../../../api/activities';
import { useGetCustomers } from '../../../../../../redux/slices/customers';
import { getCustomerByPhoneOrMail } from '../../../../../../api/customers';
import { ICustomerBase } from '../../../../../../types/customer-citypeople';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';
import { uploadProductsImage } from '../../../../../../helpers/uploadProductsImage';

interface Props {
  onClose: () => void;
  closingForbidden?: boolean;
}

type Propses = {
  block: BlockType | undefined;
  noImage?: boolean;
  params?: BasicPageParams;
};

const WARNING_TEXT = 'יש לאשר את תנאי השימוש במערכת';

export const NewLogin = ({ block, noImage, params }: Propses) => {
  const {
    loginByEmail,
  } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const { login, checkCode, user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  const { currentLang } = useLocales();
  const [expiration, setExpiration] = useState('30d');
  const isMobile = useResponsive('down', 'sm');

  const {
    authVariantInActivity,
    authVariantInMerchant,
    authVariantAll,
    merchantLogo,
    globalSite,
    useCustomExpiration,
    numberExpiration,
    unitOfTimeExpiration,
  } =
    useContentContext();

  useEffect(() => {
      if (useCustomExpiration) {
        const exp = `${numberExpiration}${unitOfTimeExpiration}`;
        setExpiration(exp);
      }
    },
    [useCustomExpiration,
      numberExpiration,
      unitOfTimeExpiration]);

  const [currentTab, setCurrentTab] = useState(0);
  const [countryCode, setCountryCode] = useState("+972"); // Код страны по умолчанию
  const [localNumber, setLocalNumber] = useState(""); // Локальный номер без кода страны
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [authUser, setUser] = useState([]);
  const [merchants, setMerchants] = useState<any | IMerchant[]>([]);
  const [isCodeView, setIsCodeView] = useState(false);
  const [checked, setChecked] = useState(false);
  const isWarning = useBoolean(false);
  const merchantId = state?.smbAccount?.merchant?.id;
  const openDialog = useBoolean();
  const { customers } = useGetCustomers(+merchantId || 0);

  const { activity } = useGetActivityByDomain();
  const mainImage: string = block?.mainImage as string;
  const rulesLink: any = block?.rulesLink || '';
  const ref = useRef<any>();
  const { t } = useTranslate();

  const localTabs = [
    {
      id: 1,
      label: 'Phone',
    },
    {
      id: 0,
      label: 'Email',
    },
  ];
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const findCustomerByPhone = (users: ICustomerBase[], phones: string) =>
    users?.find(
      (customer: ICustomerBase) =>
        customer &&
        customer?.phones &&
        customer?.phones.some(
          (phoneObj) => phoneObj.type === 'mobile' && phoneObj.number === phones,
        ),
    );
  const checkCustomerActivity = (users: ICustomerBase[], activityId: string, phones: string) =>
    users?.find(
      (customer: ICustomerBase) =>
        customer?.smbActivities?.some((a: { id: string }) => a.id === activityId) &&
        customer?.phones?.some(
          (phoneObj) => phoneObj.type === 'mobile' && phoneObj.number === phones,
        ),
    );

  useEffect(() => {
    if (customers && customers.length) {
      if (findCustomerByPhone(customers, phone)) {
        getCustomerByPhoneOrMail(phone, '', dispatch).then((response) => {
          const allMerchants = response.flatMap(
            (customer: { merchants: IMerchant }) => customer.merchants,
          );
          const uniqueMerchants = Array.from(
            new Map(allMerchants.map((m: IMerchant) => [m.id, m])).values(),
          );
          setMerchants(uniqueMerchants);
          openDialog.onTrue();
        });
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (user && authUser && merchants && merchants?.length < 1) {
      checkMerchant(merchants[0]);
    }
  }, [authUser, user]);

  const executeLogin = async () => {
    if (block?.settings?.enableCheckbox) {
      if (checked) {
        try {
          if (authVariantInActivity || authVariantInMerchant) {
            // await login(phone, activity.id);
            await login(phone);
          } else {
            await login(phone);
          }
          setIsCodeView(true);
        } catch (e) {
          console.error(e);
        }
      } else {
        isWarning.onTrue();
        setTimeout(isWarning.onFalse, 3000);
      }
    } else {
      try {
        if (authVariantInActivity || authVariantInMerchant) {
          // await login(phone, activity.id);
          await login(phone);
        } else {
          await login(phone);
        }
        setIsCodeView(true);
      } catch (e) {
        console.error(e);
      }
    }
  };
  const getCode = async () => {
    if (customers && customers.length) {
      const matchedCustomer = findCustomerByPhone(customers, phone);
      const isActiveCustomer = checkCustomerActivity(customers, activity.id, phone);
      if (authVariantAll) {
        executeLogin();
      } else if (authVariantInMerchant) {
        if (!matchedCustomer) {
          setError('You are not a member of this merchant');
        }
        if (authVariantInActivity) {
          if (!isActiveCustomer) {
            setError('You are not a member of this merchant and activity');
          } else {
            executeLogin();
          }
        } else {
          executeLogin();
        }
      } else if (authVariantInActivity) {
        if (!isActiveCustomer) {
          setError('You are not a member in this activity');
        } else {
          executeLogin();
        }
      } else {
        executeLogin();
      }
    } else {
      executeLogin();
    }
  };

  const sendCode = async (code: string) => {
    try {
      if (!globalSite) {
        await checkCode(phone, code, expiration);
        setActiveDialog(null);
        // window.location.reload();
      } else {
        await checkCode(phone, code, expiration).then((response: any) => {
          if (response.user) {
            setUser(response.user);
            openDialog.onTrue();
          }
        });
        // setActiveDialog(null);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkMerchant = (merchant: IMerchant) => {
    if (merchantLogo) {
      localStorage.setItem('logoImage', merchant.mainImageURL);
    }
    localStorage.setItem('merchantId', merchantId);
    setActiveDialog(null);
  };

  const handleCountryChange = (event: { target: { value: any } }) => {
    const newCode = event.target.value;
    setCountryCode(newCode);
    setPhone(`${newCode}${localNumber}`); // Обновляем полный номер
  };

  const handleLocalNumberChange = (event: { target: { value: any } }) => {
    let inputValue = event.target.value;

    // Удаляем ведущий "0"
    if (inputValue.startsWith('0')) {
      inputValue = inputValue.slice(1);
    }

    setLocalNumber(inputValue);
    setPhone(`${countryCode}${inputValue}`); // Обновляем полный номер
  };

  if (user && globalSite) {
    return (
      <Box>
        <Typography mb={5} sx={{ width: 1, textAlign: 'center' }}>
          You are logged in by phone <b>{user.phone}</b>
        </Typography>
        <Stack>
          {user && (
            <Typography sx={{ width: 1, textAlign: 'center', fontSize: '22px', fontWeight: 800 }}>
              Select a merchant
            </Typography>
          )}
          <Box
            sx={{
              maxHeight: '300px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
            }}
          >
            <Grid container spacing={2}>
              {merchants &&
                // merchants.length > 1 &&
                merchants.map((merchant: IMerchant) => (
                  <Grid
                    item
                    xs={6}
                    md={6}
                    key={merchant.id}
                    sx={{
                      cursor: 'pointer',
                      justifyContent: 'center',
                      padding: '10px',
                    }}
                    onClick={() => checkMerchant(merchant)}
                  >
                    <Image height={150} src={`${uploadProductsImage(merchant.mainImageURL)}`} />
                    <Typography sx={{ width: '100%', textAlign: 'center' }}>
                      {merchant.title}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Stack>
      </Box>
    );
  }

  const isMaybeNext = !(phone.length > 9);

  let align;
  if (block?.settings?.imageAlign === 'start') align = 'flex-start';
  if (block?.settings?.imageAlign === 'end') align = 'flex-end';
  if (block?.settings?.imageAlign === 'center') align = 'center';

  const handleLoginByEmail = () => {
    loginByEmail(email, password)
      .then(() => dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null }));
  };

  const handleChange = {
    password: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (loginError) setLoginError('');
      setPassword(event.target.value);
    },
    email: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (loginError) setLoginError('');
      setEmail(event.target.value);
    },
  };

  return (
    <Box>
      {!noImage && (
        <Stack alignItems={align}>
          <Image src={mainImage || ''} sx={{ width: '50%' }} />
        </Stack>
      )}
      <Tabs
        sx={{
          mb: 3,
          '& .MuiButtonBase-root': { fontSize: '20px' },
          '& .MuiTabScrollButton-root': {
            display: !isMobile || !localTabs.find((item: any) => item.show) ? 'none' : '',
          },
          '& .MuiTabs-flexContainer': { justifyContent: 'center' },
        }}
        value={currentTab}
        onChange={handleChangeTab}
      >
        {localTabs.map((tab, id) => (
          <Tab key={id} label={tab.label} />
        ))}
      </Tabs>
      {currentTab === 0 && (
        <>
          {!isCodeView && (
            <Stack p={3} gap={2}>
              <Typography
                color="primary"
                sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'start' }}
              >
                {t('Account verification')}
              </Typography>
              {/* <Box display="flex" gap={1} alignItems="center"> */}
              {/*  /!* Выбор кода страны *!/ */}
              {/*  <Select */}
              {/*    value={countryCode} */}
              {/*    onChange={handleCountryChange} */}
              {/*    sx={{ minWidth: 150 }} */}
              {/*  > */}
              {/*    {00countryOptions.map((option) => ( */}
              {/*      <MenuItem key={option.code} value={option.code}> */}
              {/*        {`${option.label} (${option.code})`} */}
              {/*      </MenuItem> */}
              {/*    ))} */}
              {/*  </Select> */}

                {/* Поле для ввода локального номера */}
                <TextField
                  value={localNumber}
                  onChange={handleLocalNumberChange}
                  label="Phone Number"
                  variant="outlined"
                  helperText={isWarning.value ? WARNING_TEXT : `${t('Enter 10 digits')}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{countryCode}</InputAdornment>
                    ),
                  }}
                />
              {/* </Box> */}

              {!!error.length && (
                <Typography sx={{ width: '100%', fontSize: '11px', color: 'red', mx: 1.5 }}>
                  {error}
                </Typography>
              )}
              {block?.settings?.enableCheckbox && (
                <Stack>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                    <Typography sx={{ fontSize: '16px' }}>{t('I approve the terms of use of the system')}</Typography>
                  </Stack>
                  <Link href={rulesLink} passHref>
                    <Typography
                      component="a"
                      sx={{ textDecoration: 'underline', mx: 6, color: 'black' }}
                      onClick={() => setActiveDialog(null)}
                    >
                      {t('Read the terms of use')}
                    </Typography>
                  </Link>
                </Stack>
              )}
              <Stack direction="row" gap={2}>
                <Button
                  sx={{ height: '40px', padding: 0 }}
                  onClick={getCode}
                  variant="contained"
                  color="primary"
                  disabled={isMaybeNext}
                >
                  {t('Entry')}
                </Button>
                {/* <Stack direction="row" gap={2}> */}
                {/*  <Button */}
                {/*    sx={{ height: '40px', padding: 0 }} */}
                {/*    onClick={getCode} */}
                {/*    variant="contained" */}
                {/*    color="primary" */}
                {/*    disabled={isMaybeNext} */}
                {/*  > */}
                {/*    כניסה */}
                {/*  </Button> */}
                {/*  {block?.settings?.enableCheckbox && ( */}
                {/*    <Stack> */}
                {/*      <Stack direction="row" gap={1} alignItems="center"> */}
                {/*        <Checkbox checked={checked} onChange={() => setChecked(!checked)} /> */}
                {/*        <Typography sx={{ fontSize: '16px' }}> */}
                {/*          אני מאשר/מאשרת תנאי שימוש במערכת */}
                {/*        </Typography> */}
                {/*      </Stack> */}
                {/*      <Link href={rulesLink} passHref> */}
                {/*        <Typography */}
                {/*          component="a" */}
                {/*          sx={{ textDecoration: 'underline', mx: 6, color: 'black' }} */}
                {/*          onClick={() => setActiveDialog(null)} */}
                {/*        > */}
                {/*          לקריאה תקנון השימוש */}
                {/*        </Typography> */}
                {/*      </Link> */}
                {/*    </Stack> */}
                {/*  )} */}
                {/* </Stack> */}
              </Stack>
            </Stack>
          )}

          {isCodeView && (
            <Stack alignItems="center">
              <Typography mb={5}>הזן קוד</Typography>
              <Box mb={5} sx={{ direction: currentLang?.value === 'he' ? 'rtl' : 'ltr' }}>
                <ReactCodeInput
                  ref={ref}
                  autoFocus
                  fields={6}
                  onComplete={(code) => sendCode(code)}
                  // onChange={() => setError(null)}
                />
              </Box>
            </Stack>
          )}
        </>
      )}
      {currentTab === 1 && (
        <Stack p={3} pt={5} gap={2} sx={{ position: 'relative' }}>
          <Typography
            color="primary"
            sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'start' }}
          >
            {t('account verification')}
          </Typography>
          <TextField fullWidth value={email} onChange={handleChange.email} label={t('Email')} />
          <TextField
            fullWidth
            value={password}
            onChange={handleChange.password}
            label={t('Password')}
          />
          <Typography sx={{ fontSize: 10, color: 'red' }}>{loginError}</Typography>
          <Button
            sx={{ height: '40px', padding: 0, mt: '20px' }}
            variant="contained"
            color="primary"
            disabled={password.length < 7 || email.length < 8 || !email.includes('@')}
            onClick={handleLoginByEmail}
          >
            {t('login')}
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default NewLogin;
