'use client';

import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

// locales
import { useTranslate } from 'src/locales';

import Label from '../label';
import Iconify from '../iconify';
import { useRouter } from '../../routes/hooks';
import { useGetProducts } from '../../api/shop';
import { BlockType } from '../../types/page-generator';
import { IAttributeValue } from '../../types/attribute';
import { useResponsive } from '../../hooks/use-responsive';
import { extractPrices } from '../../helpers/extractPrices';
import { ActionTypes, useAppContext } from '../../contexts/AppContext';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';

type Props = {
  block: BlockType | undefined;
  attributes?: IAttributeValue[];
};

export const MyApartments = ({ block, attributes }: Props) => {
  const [likedProducts, setLikedProducts] = useState<string[]>([]);
  const [likedProductsList, setLikedProductsList] = useState<any[]>([]);
  const router = useRouter();

  const isMobile = useResponsive('down', 'sm');
  const { dispatch } = useAppContext();
  const { t } = useTranslate();

  const { products } = useGetProducts({});

  useEffect(() => {
    const storedLikes =
      typeof window !== 'undefined' ? localStorage.getItem('likedProducts') || '' : '';
    if (storedLikes) {
      setLikedProducts(JSON.parse(storedLikes));
    }
  }, []);

  useEffect(() => {
    if (products?.length && likedProducts.length) {
      const filteredProducts = products.filter((product: any) =>
        likedProducts.includes(product.id)
      );
      setLikedProductsList(filteredProducts);
    } else {
      setLikedProductsList([]);
    }
  }, [products, likedProducts]);

  const addToLikeList = (id: string) => {
    let updatedLikes;

    if (likedProducts.includes(id)) {
      updatedLikes = likedProducts.filter((productId) => productId !== id);
    } else {
      updatedLikes = [...likedProducts, id];
    }

    setLikedProducts(updatedLikes);
    localStorage.setItem('likedProducts', JSON.stringify(updatedLikes));
  };

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const getCurrentAttribute = (id: string) => attributes?.find((item) => item.id === id)?.name;
  console.log('likedProductsList', likedProductsList);
  return (
    <Grid container spacing={2}>
      {likedProductsList.map((product) => {
        const { businessPrice, basePrice, price } = extractPrices(product?.prices);
        return (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <Card
              sx={{ position: 'relative', cursor: 'pointer' }}
              onClick={() => {
                router.push(`/product/${product.id}`);
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ p: 1, position: 'absolute', width: '100%' }}
              >
                {/* Теги */}
                <Stack direction="row" alignItems="center" spacing={1}>
                  {product?.tags?.map((tag: any) => (
                    <React.Fragment key={tag.id}>
                      {tag.name === 'Sold Out' && <Label color="error">{tag.name}</Label>}
                      {tag.name === 'Ready for sale' && <Label color="success">{tag.name}</Label>}
                    </React.Fragment>
                  ))}
                </Stack>
                {/* Лайк */}
                <Box
                  sx={{
                    background: 'grey',
                    width: '20px',
                    height: '20px',
                    opacity: 0.5,
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    addToLikeList(product.id);
                  }}
                >
                  {likedProducts.includes(product.id) ? (
                    <Iconify icon="weui:like-filled" sx={{ color: 'red' }} />
                  ) : (
                    <Iconify icon="weui:like-outlined" />
                  )}
                </Box>
              </Stack>

              <CardMedia
                component="img"
                height="200"
                image={uploadProductsImage(product.mainImageURL)}
                alt={product.title}
              />

              <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Stack direction="row">
                  {product?.type === '' ? (
                    <Typography
                      sx={{
                        fontSize: '16px',
                      }}
                    >
                      {product?.title}
                    </Typography>
                  ) : (
                    <Stack direction="row">
                      <Typography
                        sx={{
                          fontSize: '16px',
                        }}
                      >
                        {product?.type}
                      </Typography>
                      <Typography sx={{mx: 0.5}}>•</Typography>
                      <Typography
                        sx={{
                          fontSize: '16px',
                        }}
                      >
                        {product?.title}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                <Stack direction="row" sx={{ mx: 1 }} gap={1} justifyContent="center">
                  {product?.attributeValues?.map((item: IAttributeValue) => (
                    <Stack key={item.value} direction="row" gap={0.5} alignItems="flex-start">
                      <Typography sx={{ fontSize: '10px' }}>{item.value}</Typography>
                      <Iconify
                        icon={`mdi:${getCurrentAttribute(item.attributeId)}`}
                        width={14}
                        height={14}
                        sx={{ color: 'grey' }}
                      />
                    </Stack>
                  ))}
                </Stack>
                <Typography align="center" sx={{ fontSize: 16 }}>
                  ₪{price}
                </Typography>
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <Iconify icon="mynaui:location" />
                  <Typography>{product.description}</Typography>
                </Stack>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setActiveDialog(block?.settings?.contactUs);
                  }}
                >
                  Contact us
                </Button>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
