'use client';

import { useState, useCallback } from 'react';

// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks

// utils

// assets
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// components
import Iconify from 'src/components/iconify';

import { useTranslate } from '../../../../locales';
import { HistoryTab } from './components/inquiries/history-tab';
import { ChannelsTab } from './components/inquiries/channels-tab';
import { useSettingsContext } from '../../../../components/settings';
import { ComponentTypeProps } from '../../../../types/page-generator';
// ----------------------------------------------------------------------

type AccountTab = {
  value: string;
  label: string;
};

export default function Inquiries({ block }: ComponentTypeProps) {
  const { t } = useTranslate();
  const settings = useSettingsContext();
  const [activeAction, setActiveAction] = useState('');
  const [currentTab, setCurrentTab] = useState('history');
  const quickActions = ['זימון תור', 'עדכון סיסמה', 'אתר רשות עירוני', 'הרשמה לניוזלטר'];
  const TABS: AccountTab[] = [
    {
      value: 'history',
      label: 'Inquiry history',
    },
    {
      value: 'channels',
      label: 'Media channels',
    },
  ];

  const historyTabItem = Object.prototype.hasOwnProperty.call(block, 'inquiriesHistoryTabItem')
    ? block?.inquiriesHistoryTabItem
    : true;
  const channelsTabItem = Object.prototype.hasOwnProperty.call(block, 'inquiriesChannelsTabItem')
    ? block?.inquiriesChannelsTabItem
    : true;

  const activeTabNames: string[] = [];

  if (historyTabItem) activeTabNames.push('history');
  if (channelsTabItem) activeTabNames.push('channels');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Card sx={{ borderRadius: 1 }}>
      <Stack width={1} gap={2}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              background: 'rgb(0, 89, 210)',
              borderRadius: 1,
              width: '48px',
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Iconify icon="grommet-icons:contact" width={30} color="white" />
          </Box>
          <Typography sx={{ fontSize: '24px', fontWeight: 1000 }}>{`${t('Inquiries')}`}</Typography>
        </Stack>
        <Stack
          sx={{
            borderRadius: '5px',
            border: '1px solid',
            borderColor: '#d9d9d9',
            padding: '16px',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: 1000 }}>פעולות מהירות</Typography>
          <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
            {quickActions.map((item: string, id) => (
              <Stack
                key={id}
                direction="row"
                gap={1}
                alignItems="center"
                sx={{
                  borderLeft: activeAction === item && id !== 0 ? '1px solid blue' : 'none',
                  borderRight:
                    activeAction === item && id !== quickActions.length - 1
                      ? '1px solid blue'
                      : 'none',
                }}
              >
                <Iconify icon="iconoir:page" width={10} color="#282894" />
                <Typography
                  onClick={() => setActiveAction(item)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { color: 'blue' },
                  }}
                >
                  {item}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Tabs value={currentTab} onChange={handleChangeTab}>
          {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
            (tab: AccountTab) => (
              <Tab key={tab.value} label={`${t(`${tab.label}`)}`} value={tab.value} />
            )
          )}
        </Tabs>

        {currentTab === 'history' && historyTabItem && <HistoryTab block={block} />}

        {currentTab === 'channels' && channelsTabItem && <ChannelsTab block={block} />}
      </Stack>
    </Card>
  );
}
