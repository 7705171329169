'use client';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { getOrderById } from 'src/api/order';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import {
  checkout,
  getBasket,
  splitCheckout,
  checkoutPelecard,
  completeCheckout,
  completeCheckoutWithError,
} from 'src/api/basket';

// components
import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form';

//
import CheckoutSummary from './checkout-summary';
import CheckoutDelivery from './checkout-delivery';
// types
import { BlockType } from '../../types/page-generator';
import { useLocales, useTranslate } from '../../locales';
import CheckoutBillingInfo from './checkout-billing-info';
import CheckoutPaymentMethods from './checkout-payment-methods';
import {
  ICheckoutCardOption,
  ICheckoutPaymentOption,
  ICheckoutDeliveryOption,
} from '../../types/checkout';

// ----------------------------------------------------------------------

const DELIVERY_OPTIONS: ICheckoutDeliveryOption[] = [
  {
    id: '1',
    value: 0,
    label: 'Free',
    description: '5-7 Days delivery',
    imageUrl: '',
  },
  {
    id: '2',
    value: 10,
    label: 'Standard',
    description: '3-5 Days delivery',
    imageUrl: '',
  },
  {
    id: '3',
    value: 20,
    label: 'Express',
    description: '2-3 Days delivery',
    imageUrl: '',
  },
];

const PAYMENT_OPTIONS: ICheckoutPaymentOption[] = [
  // {
  //   value: 'paypal',
  //   label: 'Pay with Paypal',
  //   description: 'You will be redirected to PayPal website to complete your purchase securely.',
  // },
  {
    value: 'credit',
    label: 'Credit / Debit Card',
    description: 'We support Mastercard, Visa, Discover and Stripe.',
  },
  {
    value: 'cash',
    label: 'Cash',
    description: 'Pay with cash when your order is delivered.',
  },
  {
    value: 'point',
    label: 'Club card / points',
    description: 'Pay with club card',
  },
  {
    value: 'mix',
    label: 'Club card mix Credit / Debit Card',
    description: 'Pay club card with credit card',
  },
  {
    value: 'mixMustClub',
    label: 'Must Club card mix Credit / Debit Card',
    description: 'Pay club card with credit card',
  },
];

const CARDS_OPTIONS: ICheckoutCardOption[] = [
  { value: 'ViSa1', label: '**** **** **** 1212 - Jimmy Holland' },
  { value: 'ViSa2', label: '**** **** **** 2424 - Shawn Stokes' },
  { value: 'MasterCard', label: '**** **** **** 4545 - Cole Armstrong' },
];

interface Props {
  checkoutPrevStep: VoidFunction;
  total: number;
  totalSum: number;
  block: BlockType;
}

const LIMIT_USED_CARD = 2;

export default function CheckoutPayment({ checkoutPrevStep, total, totalSum, block }: Props) {
  const [paymentURL, setPaymentURL] = useState('');
  const [paymentMethods, setPaymentMethods] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [deliveryCost, setDeliveryCost] = useState(0);
  const { t } = useTranslate();
  const { currentLang } = useLocales();
  const { dispatch, state } = useAppContext();
  const PaymentSchema = Yup.object().shape({
    payment: Yup.string().required(`${t('Payment is required')}`),
  });

  const defaultValues = {
    // delivery: checkout.shipping,
    payment: '',
  };

  const methods = useForm({
    resolver: yupResolver(PaymentSchema),
    defaultValues,
  });

  const checkoutFirstStep = useCallback(
    () => dispatch({ type: ActionTypes.CHECKOUT_FIRST_STEP, payload: {} }),
    []
  );
  const clearUsedClubCards = useCallback(
    () => dispatch({ type: ActionTypes.CLEAR_USED_VIRTUAL_CARDS, payload: {} }),
    []
  );
  const showError = (text: string) => {
    setErrorMessage(text);
    setTimeout(() => setErrorMessage(''), 6000);
  };
  const getBlockClubCards = () => {
    if (Array.isArray(block.clubCards) && block.clubCards.length) return block.clubCards;
    if (Array.isArray(block.settings.clubCards) && block.settings.clubCards.length)
      return block.settings.clubCards;
    return [];
  };

  useEffect(() => {
    let array: any = [];
    if (block.credit) {
      array = [...array, ...PAYMENT_OPTIONS.filter((option) => option.value === 'credit')];
    }
    if (block.cash) {
      array = [...array, ...PAYMENT_OPTIONS.filter((option) => option.value === 'cash')];
    }
    if (block.point) {
      array = [...array, ...PAYMENT_OPTIONS.filter((option) => option.value === 'point')];
    }
    if (block.mix) {
      array = [...array, ...PAYMENT_OPTIONS.filter((option) => option.value === 'mix')];
    }
    if (block.mixMustClub) {
      array = [...array, ...PAYMENT_OPTIONS.filter((option) => option.value === 'mixMustClub')];
    }
    setPaymentMethods(array);
  }, []);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  // console.log('state', state);
  const onSubmit = handleSubmit(async (data) => {
    if (data.payment === 'point') {
      await checkout(state.checkout.clubCardId, state.smbAccount.id, state.smbActivityId, dispatch);
    } else if (data.payment === 'credit') {
      const response = await checkoutPelecard(state.smbActivityId,state.smbAccount.id, currentLang.value);
      setPaymentURL(response);
    } else if (data.payment === 'mix') {
      const response = await splitCheckout(
        state.checkout.usedVirtualCards,
        state.smbAccount.id,
        currentLang.value,
        dispatch
      );
      setPaymentURL(response);
    } else if (data.payment === 'mixMustClub') {
      const ERROR_TEXT =
        'You have to first spend all points on virtual cards and then proceed to pay with a credit card';

      const isNotUseClubCard = !state.checkout.usedVirtualCards.length;
      const isExistClubCardWithBalance = state.virtualCards.some((vCard) => +vCard.balance > 0);
      const isExistUsedClubCardWithRest = state.checkout.usedVirtualCards.some(
        (uCard) => uCard.cost !== +(state.virtualCards.find((c) => c.id === uCard.id)?.balance || 0)
      );
      const isCardLimitNotUsed = state.checkout.usedVirtualCards.length < LIMIT_USED_CARD;
      const isExistNotUsedClubCardWithBalance = state.virtualCards.filter(
        (notUsedCard) =>
          +notUsedCard.balance > 0 &&
          !state.checkout.usedVirtualCards.map((usedCard) => usedCard.id).includes(notUsedCard.id)
      );

      if (
        (isNotUseClubCard && isExistClubCardWithBalance) ||
        (totalSum && isExistUsedClubCardWithRest) ||
        (totalSum && isCardLimitNotUsed && isExistNotUsedClubCardWithBalance)
      ) {
        showError(ERROR_TEXT);
      } else {
        const response = await splitCheckout(
          state.checkout.usedVirtualCards,
          state.smbAccount.id,
          currentLang.value,
          dispatch
        );
        setPaymentURL(response);
      }
    }
  });
  const paymentOptions = paymentMethods;
  // useMemo(() => {
  //   if (!Array.isArray(block.paymentMethods)) return PAYMENT_OPTIONS;
  //   const paymentMethodNames = [] as string[];
  //   block.paymentMethods.forEach((method: BlockPaymentMethod) => {
  //     if (method.isEnable) paymentMethodNames.push(method.name);
  //   });
  //   return PAYMENT_OPTIONS.filter((opt: ICheckoutPaymentOption) =>
  //     paymentMethodNames.includes(opt.value)
  //   );
  // }, [block]);
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === 'ORDER_ID') {
        const { id, status } = event.data;
        getOrderById(id, dispatch).then(() => {
          if (status === 'success') {
            completeCheckout(dispatch);
          } else {
            completeCheckoutWithError(dispatch);
          }
        });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    let result = 0;
    state.cart.map((item) => {
      if (item.deliveryOptionId) {
        const price = item.deliveryOptionId ? Number(item.deliveryOption?.price) : 0;
        result += price;
      }
      setDeliveryCost(result);
      return result;
    });
  }, [state.cart]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {paymentURL ? (
        <Stack>
          <iframe
            src={paymentURL}
            title="Payment"
            width="100%"
            height="700px"
            allow="payment"
            style={{ border: 0 }}
          />
          <Button
            size="small"
            color="inherit"
            onClick={() => {
              setPaymentURL('');
              getBasket(state.smbAccount.id, dispatch).then((data) => {
                if (Array.isArray(data) && !data.length) {
                  checkoutFirstStep();
                  clearUsedClubCards();
                }
              });
            }}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          >
            {t('Back')}
          </Button>
        </Stack>
      ) : (
        <Grid container spacing={3}>
          <Grid xs={12} md={8}>
            {(block.isDeliveryEnable || typeof block.isDeliveryEnable !== 'boolean') && (
              <CheckoutDelivery options={block.deliveryMethods || DELIVERY_OPTIONS} />
            )}

            <CheckoutPaymentMethods
              block={block}
              blockClubCards={getBlockClubCards()}
              cardOptions={CARDS_OPTIONS}
              options={paymentOptions}
              sx={{ my: 3 }}
              total={total}
              totalSum={totalSum}
              showError={showError}
            />

            <Button
              size="small"
              color="inherit"
              onClick={checkoutPrevStep}
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            >
              {`${t('Back')}`}
            </Button>
          </Grid>

          <Grid xs={12} md={4}>
            <CheckoutBillingInfo />

            <CheckoutSummary
              totalSum={totalSum}
              subTotal={total}
              discount={0}
              // shipping={state.checkout.deliveryCost}
              shipping={deliveryCost}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {`${t('Complete Order')}`}
            </LoadingButton>

            {errorMessage && (
              <Box
                component="div"
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
              >
                <Typography sx={{ textAlign: 'center' }} color="error.main">
                  {errorMessage}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
}
