'use client';

import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
// @mui
import { Stack, Alert, IconButton, InputAdornment } from '@mui/material';

// locales
import { useLocales } from 'src/locales';
// routes
// import { PATH_AUTH } from '../../routes/paths';
// auth
// redux
// import { useSelector } from '../../redux/store';
import { useTranslation } from 'react-i18next';

import { LocalizationProvider } from '@mui/x-date-pickers';
// components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Iconify from '../../../../../../components/iconify';
import { useAuthContext } from '../../../../../../auth/hooks';
import { BlockType } from '../../../../../../types/page-generator';
import FormProvider, { RHFTextField } from '../../../../../../components/hook-form';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  firstName: string;
  lastName?: string;
  passwordAuthentication: string;
  afterSubmit?: string;
  birthday?: string;
  phone?: string;
};

type Props = {
  block: BlockType | undefined;
};

export default function ModalAuthRegisterForm({ block }: Props) {
  // const { BotId } = useSelector((state) => state.widget);
  const { register } = useAuthContext();
  const { currentLang } = useLocales();
  const local = currentLang.value === 'he' ? 'he' : 'en';
  const { t } = useTranslation();
  const { state, dispatch } = useAppContext();
  const activityId = state.smbActivityId;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().required(`${t('Email is required')}`),
    passwordAuthentication: Yup.string().required(`${t('Password verification is required')}`),
    password: Yup.string().required(`${t('Password required')}`),
    firstName: Yup.string().required(`${t('First name is required')}`),
    lastName: Yup.string(),
    birthday: Yup.string(),
    phone: Yup.string().required(`${t('Phone is required')}`),
  });

  const defaultValues = {
    email: '',
    passwordAuthentication: '',
    password: '',
    firstName: '',
    lastName: '',
    birthday: '',
    phone: '',
  };

  const methods = useForm<FormValuesProps>({
    // @ts-ignore
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const requirements = [
      data.password?.length >= 8, // Длина минимум 8 символов
      /[A-Z]/.test(data.password), // Минимум 1 заглавная буква
      /[a-z]/.test(data.password), // Минимум 1 строчная буква
      /\d/.test(data.password), // Минимум 1 цифра
      /[$&+,:;=?@#|'<>.^*()%!-]/.test(data.password), // Минимум 1 спецсимвол
    ];

    const isValid = requirements.every(Boolean);

    try {
      if (data.password !== data.passwordAuthentication) {
        setError('passwordAuthentication', {
          type: 'manual',
          message: `${t('Passwords do not match')}`,
        });
        return;
      }

      if (!isValid) {
        setError('password', {
          type: 'manual',
          message: `${t(
            'Password should contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character'
          )}`,
        });
        return;
      }

      if (register) {
        await register(
          process.env.NEXT_PUBLIC_BOT_ID || '',
          data.email,
          data.password,
          data.firstName,
          data.lastName || '',
          data.phone || '',
          activityId || ''
        ).then((response) => {
          dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
        });
      }
    } catch (error) {
      console.error(error);
      setError('afterSubmit', {
        type: 'manual',
        message: error.message || error,
      });
    }
  };


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        {block?.settings?.enableEmail && <RHFTextField name="email" label={`${t('Email')}`} />}

        <RHFTextField name="firstName" label={`${t('First name')}`} />

        {block?.settings?.enableLastName && (
          <RHFTextField name="lastName" label={`${t('Last name')}`} />
        )}

        <RHFTextField name="phone" label={`${t('Phone number')}`} />

        {block?.settings?.enableBirthday && (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={local}>
            <DatePicker sx={{ width: '100%', marginRight: '10%' }} label={`${t('Birthday')}`} />
          </LocalizationProvider>
        )}

        <RHFTextField
          name="password"
          label={`${t('Password')}`}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="passwordAuthentication"
          type={showConfirmPassword ? 'text' : 'password'}
          label={`${t('Password verification')}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                  <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting || isSubmitSuccessful}
          sx={{
            mb: 1,
            borderRadius: 5,
            bgcolor: 'primary.main',
            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            '&:hover': {
              bgcolor: 'primary.main',
              color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            },
          }}
        >
          {`${t('Sign up')}`}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
