'use client';

import React, { FC, useMemo } from 'react';
import { useRouter } from 'next/navigation';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import Image from 'src/components/image';

import Iconify from '../../../../components/iconify';
import Carousel from '../../../../components/carousel';
import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

type CategoryType = {
  value: string;
  title: string;
};
const PopularCategories: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const router = useRouter();

  const categoriesImages = useMemo(
    () => getFieldValue('categoriesImages'),
    [block?.categoriesImages]
  );

  const categoriesAndImages = useMemo(() => {
    const categoriesValue = getFieldValue('categories');
    if (Array.isArray(categoriesValue)) {
      return categoriesValue.map((category: CategoryType) => ({
        ...category,
        image: categoriesImages[category.value] ?? '/assets/img-placeholder.svg',
      }));
    }
    return [];
  }, [block?.categories, categoriesImages]);

  const categories: any = block?.categories || [];

  const mainTitle = useMemo(() => getFieldValue('mainTitle'), [block?.mainTitle]);

  const headingTitleTextColor = useMemo(
    () => getFieldValue('settings.headingTitleTextColor'),
    [block?.settings.headingTitleTextColor]
  );
  const headingTitleFontSize = useMemo(
    () => getFieldValue('settings.headingTitleFontSize'),
    [block?.settings.headingTitleFontSize]
  );
  const headingTitleFontWeight = useMemo(
    () => getFieldValue('settings.headingTitleFontWeight'),
    [block?.settings.headingTitleFontWeight]
  );
  const headingTitleFontStyle = useMemo(
    () => getFieldValue('settings.headingTitleFontStyle'),
    [block?.settings.headingTitleFontStyle]
  );
  const headingTitleTextDecoration = useMemo(
    () => getFieldValue('settings.headingTitleTextDecoration'),
    [block?.settings.headingTitleTextDecoration]
  );
  const headingTextAlignItems = useMemo(
    () => getFieldValue('settings.headingTextAlignItems'),
    [block?.settings.headingTextAlignItems]
  );

  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [block?.settings.contentTitleTextColor]
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [block?.settings.contentTitleFontSize]
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [block?.settings.contentTitleFontWeight]
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [block?.settings.contentTitleFontStyle]
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [block?.settings.contentTitleTextDecoration]
  );
  const contentTextAlignItems = useMemo(
    () => getFieldValue('settings.contentTextAlignItems'),
    [block?.settings.contentTextAlignItems]
  );
  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [block?.settings.forceRtl]);
  const title = 'קטגוריות פופולריות';
  const smDown = useResponsive('down', 'sm');
  const isMobile = useResponsive('down', 'sm');
  const responsive = [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 650, settings: { slidesToShow: 2 } },
    { breakpoint: 426, settings: { slidesToShow: 1 } },
  ];

  const settings = {
    arrows: true,
    nextArrow: <Iconify icon="ep:arrow-left-bold" color="primary.main" />,
    prevArrow: <Iconify icon="ep:arrow-right-bold" color="primary.main" />,
  };

  const categoriesView =
    block?.settings?.variant === '1' ? categoriesAndImages : categories;

  return (
    <Box>
      <Typography
        sx={{
          direction: forceRtl ? 'rtl' : undefined,
          color: headingTitleTextColor,
          fontSize: headingTitleFontSize,
          fontStyle: headingTitleFontStyle,
          fontWeight: headingTitleFontWeight,
          textDecoration: headingTitleTextDecoration,
          textAlign: headingTextAlignItems,
        }}
      >
        {mainTitle}
      </Typography>
      <Stack
        alignItems="center"
        width={1}
        sx={{ '& .slick-slider ': { '& svg': { color: 'primary.main' } } }}
      >
        <Container maxWidth="xl" sx={{ mt: 8 }}>
          <Carousel
            autoplay={block?.settings?.autoplay || false}
            {...settings}
            slidesToShow={smDown ? 2 : categories.length - 1}
          >
            {categoriesView &&
              categoriesView?.map((item: CategoryType & { image?: string }, idx: number) => (
                <Box
                  key={idx}
                  sx={{
                    position: 'relative',
                    borderRadius: 1.25,
                    px: !isMobile ? '10px' : '2px',
                    cursor: 'pointer',
                    height: '100px',
                  }}
                  onClick={() => {
                    if (block?.settings?.enableLink) router.push(`/category/${item.value}`);
                  }}
                >
                  <Image
                    src={item?.image}
                    sx={{
                      borderRadius: 1.25,
                      height: '100%',
                      width: '100%',
                      '& img': { objectFit: 'cover', height: '100%' },
                    }}
                  />
                  <Typography
                    sx={{
                      direction: forceRtl ? 'rtl' : undefined,
                      color: contentTitleTextColor,
                      fontSize: contentTitleFontSize,
                      fontStyle: contentTitleFontStyle,
                      fontWeight: contentTitleFontWeight,
                      textDecoration: contentTitleTextDecoration,
                      textAlign: contentTextAlignItems,
                      bgcolor: 'rgba(255, 255, 255, 0.7)',
                      position: 'absolute',
                      bottom: '0px',
                      width: { md: '90%', xs: '100%' },
                      height: '35%',
                      pt: '10px',
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              ))}
          </Carousel>
        </Container>
      </Stack>
    </Box>
  );
};

export default PopularCategories;
