'use client';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect, useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { getOrderById } from 'src/api/order';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import {
  checkout,
  getBasket,
  splitCheckout,
  checkoutPelecard,
  completeCheckout,
  completeCheckoutWithError,
} from 'src/api/basket';

// components
import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form';

//
import CheckoutSummary from './checkout-summary';
import CheckoutDelivery from './checkout-delivery';
// types
import { BlockType } from '../../types/page-generator';
import { useLocales, useTranslate } from '../../locales';
import CheckoutBillingInfo from './checkout-billing-info';
import CheckoutPaymentMethods from './checkout-payment-methods';
import {
  ICheckoutCardOption,
  ICheckoutPaymentOption,
  ICheckoutDeliveryOption,
} from '../../types/checkout';
import CheckoutSummaryStraightAhead from './checkout-summary-straight-ahead';
import CheckoutCartProductList from '../generate-page/components/cart/checkout-cart-product-list';
import { useCheckoutContext } from '../generate-page/components/cart/context';
import CheckoutCartProductListStraightAhead
  from '../generate-page/components/cart/checkout-cart-product-list-straifgt-ahead';
import CheckoutOrderError from './checkout-order-error';
import CheckoutOrderComplete from './checkout-order-complete';
import { getVirtualCards } from '../../api/clubCards';
import { getCards } from '../../api/card';

// ----------------------------------------------------------------------

const CARDS_OPTIONS: ICheckoutCardOption[] = [
  { value: 'ViSa1', label: '**** **** **** 1212 - Jimmy Holland' },
  { value: 'ViSa2', label: '**** **** **** 2424 - Shawn Stokes' },
  { value: 'MasterCard', label: '**** **** **** 4545 - Cole Armstrong' },
];

interface Props {
  block: BlockType;
}

const LIMIT_USED_CARD = 2;

export default function CheckoutPaymentStraightAhead({ block }: Props) {
  const checkoutContext = useCheckoutContext();
  const [paymentURL, setPaymentURL] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([
    {
      'value': 'credit',
      'label': 'Credit / Debit Card',
      'description': 'We support Mastercard, Visa, Discover and Stripe.',
    },
    {
      'value': 'point',
      'label': 'Club card / points',
      'description': 'Pay with club card',
    },
  ]);
  const [errorMessage, setErrorMessage] = useState('');
  const [deliveryCost, setDeliveryCost] = useState(0);
  const { t } = useTranslate();
  const { currentLang } = useLocales();
  const { dispatch, state } = useAppContext();
  const trigger = state.trigger;
  const PaymentSchema = Yup.object().shape({
    payment: Yup.string().required(`${t('Payment is required')}`),
  });

  const total = useMemo(() => {
    let result = 0;
    // eslint-disable-next-line
    for (const item of state.cart) {
      result += item.price * item.quantity + Number(item.deliveryOption?.price || 0);
    }

    return result;
  }, [state.cart]);

  const totalSum = useMemo(() => {
    let result = total + (state.checkout.deliveryCost || 0);
    // eslint-disable-next-line no-restricted-syntax
    for (const checkoutCard of state.checkout.usedVirtualCards) {
      result -= checkoutCard.cost;
    }
    // delivery = item.deliveryOptionId && item?.deliveryOption ? +item.deliveryOption.price : 0;
    return result;
  }, [total, state.checkout]);

  const defaultValues = {
    // delivery: checkout.shipping,
    payment: '',
  };

  const methods = useForm({
    resolver: yupResolver(PaymentSchema),
    defaultValues,
  });

  const checkoutFirstStep = useCallback(
    () => dispatch({ type: ActionTypes.CHECKOUT_FIRST_STEP, payload: {} }),
    [],
  );
  const clearUsedClubCards = useCallback(
    () => dispatch({ type: ActionTypes.CLEAR_USED_VIRTUAL_CARDS, payload: {} }),
    [],
  );
  const showError = (text: string) => {
    setErrorMessage(text);
    setTimeout(() => setErrorMessage(''), 6000);
  };
  const getBlockClubCards = () => {
    if (Array.isArray(block.clubCards) && block.clubCards.length) return block.clubCards;
    if (Array.isArray(block.settings.clubCards) && block.settings.clubCards.length)
      return block.settings.clubCards;
    return [];
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (data.payment === 'point') {
      await checkout(state.checkout.clubCardId, state.smbAccount.id, state.smbActivityId, dispatch);
    } else if (data.payment === 'credit') {
      const response = await checkoutPelecard(state.smbActivityId, state.smbAccount.id, currentLang.value);
      setPaymentURL(response);
    }
  });
  const paymentOptions = paymentMethods;

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === 'ORDER_ID') {
        const { id, status } = event.data;
        getOrderById(id, dispatch).then(() => {
          if (status === 'success') {
            completeCheckout(dispatch);
          } else {
            completeCheckoutWithError(dispatch);
          }
        });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    let result = 0;
    state.cart.map((item) => {
      if (item.deliveryOptionId) {
        const price = item.deliveryOptionId ? Number(item.deliveryOption?.price) : 0;
        result += price;
      }
      setDeliveryCost(result);
      return result;
    });
  }, [state.cart]);

  const resetBasket = () => {
    dispatch({ type: ActionTypes.CLEAR_CART, payload: {} });
    dispatch({ type: ActionTypes.SET_TRIGGER, payload: !trigger });
    const activityId = state.smbActivityId || '';
    getVirtualCards(activityId, dispatch);
    getCards(activityId, dispatch);
  };

  console.log('state', state);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {paymentURL.length ? (
        <Stack>
          <iframe
            src={paymentURL}
            title="Payment"
            width="100%"
            height="700px"
            allow="payment"
            style={{ border: 0 }}
          />
          {state.checkout.isPaymentFailed && (
            <CheckoutOrderError open={state.checkout.isPaymentFailed} onReset={resetBasket} />
          )}

          {state.checkout.completed && !state.checkout.isPaymentFailed && (
            <CheckoutOrderComplete
              open={state.checkout.completed}
              onReset={resetBasket}
              onDownloadPDF={() => {
              }}
              block={block}
              basketData={state.cart}
            />
          )}
          <Button
            size="small"
            color="inherit"
            onClick={() => {
              setPaymentURL('');
              getBasket(state.smbAccount.id, dispatch).then((data) => {
                if (Array.isArray(data) && !data.length) {
                  checkoutFirstStep();
                  clearUsedClubCards();
                }
              });
            }}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          >
            {t('Back')}
          </Button>
        </Stack>
      ) : (
        <Grid container spacing={3}>
          <Grid
            xs={12}
            md={8}
            sx={{
              maxHeight: '400px', // Установите максимальную высоту
              overflowY: 'auto',  // Включает вертикальный скроллбар
              overflowX: 'hidden', // Убирает горизонтальный скроллбар
            }}
          >
            <CheckoutCartProductListStraightAhead
              products={state.cart}
              onDelete={checkoutContext.onDeleteCart}
              onIncreaseQuantity={checkoutContext.onIncreaseQuantity}
              onDecreaseQuantity={checkoutContext.onDecreaseQuantity}
            />

            <CheckoutPaymentMethods
              block={block}
              blockClubCards={getBlockClubCards()}
              cardOptions={CARDS_OPTIONS}
              options={paymentOptions}
              sx={{ my: 3 }}
              total={total}
              totalSum={totalSum}
              showError={showError}
            />
          </Grid>

          <Grid xs={12} md={4}>
            <CheckoutSummaryStraightAhead
              totalSum={totalSum}
              subTotal={total}
              discount={0}
              shipping={deliveryCost}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {`${t('Complete Order')}`}
            </LoadingButton>

            {errorMessage && (
              <Box
                component="div"
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
              >
                <Typography sx={{ textAlign: 'center' }} color="error.main">
                  {errorMessage}
                </Typography>
              </Box>
            )}
          </Grid>

        </Grid>
      )}
    </FormProvider>
  );
}
