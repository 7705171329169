'use client';

import { useState, useEffect } from 'react';

import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import { Collapse, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';

// components
import Iconify from 'src/components/iconify';

import { API } from '../../../../helpers/api';
import Image from '../../../../components/image';
import { useTranslate } from '../../../../locales';
import { BOT_ID } from '../../../../config-global';
import { useAuthContext } from '../../../../auth/hooks';
import Scrollbar from '../../../../components/scrollbar';
import { fDateTime } from '../../../../utils/format-time';
import { useBoolean } from '../../../../hooks/use-boolean';
import { fCurrency } from '../../../../utils/format-number';
import { IUserAccountBillingHistory } from '../../../../types/user';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';

type Props = {
  invoices: IUserAccountBillingHistory[];
};

export default function AccountBillingHistory({ invoices }: Props) {
  const showMore = useBoolean();
  const { t } = useTranslate();
  const { user } = useAuthContext();
  const [orders, setOrders] = useState<any>([]);
  const [expandedOrderId, setExpandedOrderId] = useState<number | null>(null); // Состояние для раскрытого заказа

  const init = async () => {
    if (user) {
      try {
        const { data } = await API({
          params: {
            botId: BOT_ID,
            offset: 0,
            limit: 1000,
          },
          url: `order/getOrdersByUser`,
          method: 'GET',
        });
        setOrders(data.payload);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (user) {
      init();
    }
  }, [user]);

  const toggleOrderDetails = (orderId: number) => {
    setExpandedOrderId((prev) => (prev === orderId ? null : orderId));
  };

  return (
    <Card>
      <CardHeader title={`${t('Invoice History')}`} />

      <Stack spacing={1.5} sx={{ px: 3, pt: 3 }}>
        <Scrollbar sx={{ maxHeight: 400, overflowY: 'auto' }}>
          {(showMore.value ? orders : orders.slice(0, 8)).map((order: any) => (
            <Stack key={order.id} direction="column" spacing={1}>
              {/* Основная информация о заказе */}
              <Stack
                direction="row"
                alignItems="center"
                my={1}
                sx={{ cursor: 'pointer' }}
                onClick={() => toggleOrderDetails(order.id)}
              >
                <ListItemText
                  primary={order.orderNumber}
                  secondary={fDateTime(order.createdAt)}
                  primaryTypographyProps={{
                    typography: 'body2',
                  }}
                  secondaryTypographyProps={{
                    mt: 0.5,
                    component: 'span',
                    typography: 'caption',
                    color: 'text.disabled',
                  }}
                />

                <Typography variant="body2" sx={{ textAlign: 'right', mr: 5 }}>
                  {fCurrency(order?.orderItems[0]?.priceAtTimeOfPurchase)}
                </Typography>

                <Link color="inherit" underline="always" variant="body2" href="#">
                  {`${t('PDF')}`}
                </Link>
              </Stack>

              <Collapse in={expandedOrderId === order.id} timeout="auto" unmountOnExit>
                <Grid container spacing={2}>
                  {order?.orderItems[0]?.customer && (
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={2}>
                          <Typography color="text.secondary">{`${t('Customer')}:`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <ListItemText
                            primary={`${order?.customer?.firstName} ${order?.customer?.lastName ? order?.customer?.lastName : ''}`}
                            secondary={`${order?.customer?.email ? order?.customer?.email : ''}`}
                            primaryTypographyProps={{ typography: 'body2' }}
                            secondaryTypographyProps={{
                              fontSize: 12,
                              component: 'p',
                              color: 'text.disabled',
                            }}
                          />
                        </Grid>
                        {order?.customer?.avatar && (
                          <Grid item xs={4}>
                            <Image
                              src={uploadProductsImage(order?.customer?.avatar) || ''}
                              sx={{
                                width: '60px',
                                height: '30px',
                                borderRadius: '5px',
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {order?.smbAccount?.merchant && (
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={2}>
                          <Typography color="text.secondary">{`${t('Customer')}:`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ fontSize: '12px' }}>
                            {order?.smbAccount?.merchant?.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Image
                            src={
                              uploadProductsImage(order.smbAccount?.merchant?.mainImageURL) || ''
                            }
                            sx={{
                              width: '60px',
                              height: '30px',
                              borderRadius: '5px',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {order?.orderItems[0]?.product?.id && (
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={2}>
                          <Typography color="text.secondary">{`${t('Product')}:`}</Typography>
                        </Grid>
                        {order.orderItems && order.orderItems[0] && order.orderItems[0].product && (
                          <Grid item xs={6}>
                            <Typography sx={{ fontSize: '12px' }}>
                              {order.orderItems[0].product.title}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={4}>
                          <Image
                            src={
                              uploadProductsImage(order.orderItems[0].product.mainImageURL) || ''
                            }
                            sx={{
                              width: '60px',
                              height: '30px',
                              borderRadius: '5px',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Collapse>
              <Divider sx={{ width: 1, my: 1 }} />
            </Stack>
          ))}

          <Divider sx={{ borderStyle: 'dashed' }} />
        </Scrollbar>
      </Stack>

      <Stack alignItems="flex-start" sx={{ p: 2 }}>
        <Button
          size="small"
          color="inherit"
          startIcon={
            <Iconify
              icon={showMore.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            />
          }
          onClick={showMore.onToggle}
        >
          {showMore.value ? `${t(`Show Less`)}` : `${t(`Show More`)}`}
        </Button>
      </Stack>
    </Card>
  );
}
