'use client';

import { FC, useMemo } from 'react';
import { useParams } from 'next/navigation';

import { Box, Stack, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { getContentValueFromProps } from '../../utils';
import { useAppContext } from '../../../../contexts/AppContext';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';
import { useRouter } from '../../../../routes/hooks';

const LogoComponent: FC<ComponentTypeProps> = ({ block }) => {
  const { title } = useParams();
  const { id } = useParams();
  const { dispatch, state } = useAppContext();
  const category = state.categories.find((item) => item.id === id);
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();

  const getFieldValue = getContentValueFromProps(block);
  const enableText = useMemo(() => getFieldValue('settings.enableText'), [block]);
  const text = useMemo(() => getFieldValue('text'), [block]);
  const contentTextColor = useMemo(
    () => getFieldValue('settings.contentColor'),
    [block?.settings.contentColor],
  );
  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [block?.settings.forceRtl]);
  const contentFontSize = useMemo(
    () => getFieldValue('settings.contentFontSize'),
    [block?.settings.contentFontSize],
  );
  const contentFontWeight = useMemo(
    () => getFieldValue('settings.contentFontWeight'),
    [block?.settings.contentFontWeight],
  );
  const contentFontStyle = useMemo(
    () => getFieldValue('settings.contentFontStyle'),
    [block?.settings.contentFontStyle],
  );
  const contentTextDecoration = useMemo(
    () => getFieldValue('settings.contentTextDecoration'),
    [block?.settings.contentTextDecoration],
  );
  const contentAlign = useMemo(
    () => getFieldValue('settings.contentAlign'),
    [block?.settings.contentAlign],
  );

  const contentClasses = useMemo(
    () => getFieldValue('settings.contentClasses'),
    [block?.settings.contentClasses],
  );

  const bannerClasses = useMemo(
    () => getFieldValue('settings.contentClasses'),
    [block?.settings.contentClasses],
  );

  const enableInfoBlock = useMemo(
    () => getFieldValue('settings.enableInfoBlock'),
    [block?.settings.enableInfoBlock],
  );

  const enableQrCodeBlock = useMemo(
    () => getFieldValue('settings.enableQrCodeBlock'),
    [block?.settings.enableQrCodeBlock],
  );

  const qrCode = useMemo(() => getFieldValue('qrCode'), [block?.qrCode]);
  const appStore = useMemo(() => getFieldValue('appStore'), [block?.appStore]);
  const playMarket = useMemo(
    () => getFieldValue('playMarket'),
    [block?.playMarket],
  );
  const appStoreLink = useMemo(() => getFieldValue('appStoreLink'), [block?.appStoreLink]);
  const playMarketLink = useMemo(
    () => getFieldValue('playMarketLink'),
    [block?.playMarketLink],
  );

  const contentText = useMemo(
    () => getFieldValue('contentText'),
    [block?.contentText],
  );
  const contentTextQr = useMemo(
    () => getFieldValue('contentTextQr'),
    [block?.contentTextQr],
  );

  const qrCodeSwitchPosition = useMemo(
    () => getFieldValue('settings.qrCodeSwitchPosition'),
    [block?.settings.qrCodeSwitchPosition],
  );
  const logo = useMemo(() => getFieldValue('logo'), [block?.logo]);
  return (
    <>{!isMobile ? (
      <Box sx={{ mb: block?.settings?.enableOverlay ? '-40px' : '' }}>
        {title !== 'category' ? (
          <Stack alignItems="center" className={bannerClasses} sx={{ position: 'relative' }}>
            <Image
              src={getFieldValue('logo')}
              alt="logo"
              sx={{
                objectFit: 'contain',
                width: getFieldValue('settings.width'),
                height: enableInfoBlock ? '100%' : '50%',
              }}
            />
            {enableInfoBlock && (
              <Stack
                direction={qrCodeSwitchPosition ? 'row' : 'column'}
                alignItems="center" // Выравниваем по вертикали
                gap={1} // Отступ между QR-кодом и текстом
                justifyContent={qrCodeSwitchPosition ? 'space-between' : ''}
                width={qrCodeSwitchPosition ? 0.8 : '100%'}
                sx={{
                  position: 'absolute',
                  bottom: 5,
                  left: 100,
                  gap: 1, // Разделяем блоки небольшим отступом
                  alignItems: 'flex-start', // Выравниваем по левому краю
                  height: !enableQrCodeBlock ? '85%' : 'auto',
                }}
              >
                <Typography
                  sx={{
                    width: `${block?.settings?.textWidth}` || '100%',
                    lineHeight: block?.settings?.lineHeight || 1,
                    fontSize: contentFontSize,
                    fontWeight: contentFontWeight,
                    height: qrCodeSwitchPosition ? '140px' : '',
                  }}
                  className={contentClasses}
                >
                  <span dangerouslySetInnerHTML={{ __html: contentText }} />
                </Typography>
                {qrCodeSwitchPosition && (
                  <Stack
                    direction={qrCodeSwitchPosition ? 'row-reverse' : 'row'}
                    alignItems="flex-end"
                    gap={1}
                  >
                    <Image
                      src={uploadProductsImage(qrCode)}
                      sx={{
                        width: '55px', // Ограничиваем ширину QR-кода
                        height: '55px', // Ограничиваем высоту для квадрата
                        objectFit: 'contain', // Сохраняем пропорции
                      }}
                    />
                    <Stack direction="column" gap={1}>
                      <Typography
                        sx={{
                          width: '100%',
                          lineHeight: 0.5,
                          fontSize: '10px',
                          ...(qrCodeSwitchPosition && {
                            '& p': {
                              textAlign: 'end!important',
                            },
                          }),
                        }}
                        className={contentClasses}
                      >
                        <span dangerouslySetInnerHTML={{ __html: contentTextQr }} />
                      </Typography>
                      <Stack direction="row" gap={1}>
                        <Image
                          sx={{ borderRadius: '5px', width: '50px', height: 'auto' }} // Размеры для App Store
                          src={uploadProductsImage(appStore)}
                          onClick={() => router.push(`${appStoreLink}`)}
                        />
                        <Image
                          sx={{ borderRadius: '5px', width: '50px', height: 'auto' }} // Размеры для Play Market
                          src={uploadProductsImage(playMarket)}
                          onClick={() => router.push(`${playMarketLink}`)}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
                {enableQrCodeBlock && !qrCodeSwitchPosition && (
                  <Stack direction="row" alignItems="flex-end" gap={1}>
                    <Image
                      src={uploadProductsImage(qrCode)}
                      sx={{
                        width: '85px', // Ограничиваем ширину QR-кода
                        height: '85px', // Ограничиваем высоту для квадрата
                        objectFit: 'contain', // Сохраняем пропорции
                      }}
                    />
                    <Stack direction="column" gap={1} height={0.9}>
                      <Typography
                        sx={{
                          width: '100%',
                          lineHeight: 0.5,
                          '& p': {
                            textAlign: qrCodeSwitchPosition ? 'end!important' : '',
                          },
                        }}
                        className={contentClasses}
                      >
                        <span dangerouslySetInnerHTML={{ __html: contentTextQr }} />
                      </Typography>
                      <Stack direction="row" gap={1}>
                        <Image
                          sx={{
                            cursor: 'pointer',
                            borderRadius: '5px',
                            width: '75px',
                            height: 'auto',
                          }} // Размеры для App Store
                          src={uploadProductsImage(appStore)}
                          onClick={() => router.push(`${appStoreLink}`)}
                        />
                        <Image
                          sx={{
                            cursor: 'pointer',
                            borderRadius: '5px',
                            width: '75px',
                            height: 'auto',
                          }} // Размеры для Play Market
                          src={uploadProductsImage(playMarket)}
                          onClick={() => router.push(`${playMarketLink}`)}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}
            {enableText && (
              <Box
                sx={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 0,
                  py: 1,
                  background: 'rgba(255, 255, 255, 0.7)',
                }}
              >
                <Typography
                  className={contentClasses}
                  sx={{
                    width: '70%',
                    margin: 'auto',
                    direction: forceRtl ? 'rtl' : undefined,
                    color: contentTextColor,
                    fontSize: contentFontSize,
                    fontWeight: contentFontWeight,
                    fontStyle: contentFontStyle,
                    textDecoration: contentTextDecoration,
                    textAlign: contentAlign,
                  }}
                >
                  {text}
                </Typography>
              </Box>
            )}
          </Stack>
        ) : (
          <Stack alignItems="center" className={bannerClasses} sx={{ position: 'relative' }}>
            <Box
              width="100%"
              height="20vh"
              overflow="hidden"
              style={{
                backgroundImage: `url("https://api-ksharim.b24online.com/upload/272c02dd-e436-47aa-b6bb-ca823b5c067d.png")`,
                backgroundRepeat: 'repeat',
                backgroundSize: 'contain',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '0px',
                  width: '99vw',
                  height: '3vw',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    py: 1,
                    background: 'rgba(255, 255, 255, 0.9)',
                  }}
                >
                  <Typography
                    className={contentClasses}
                    sx={{
                      width: '70%',
                      margin: 'auto',
                      direction: forceRtl ? 'rtl' : undefined,
                      color: contentTextColor,
                      fontSize: contentFontSize,
                      fontWeight: contentFontWeight,
                      fontStyle: contentFontStyle,
                      textDecoration: contentTextDecoration,
                      textAlign: contentAlign,
                    }}
                  >
                    {`${category?.title}`} תיאור קטגוריית
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        )}
      </Box>

    ) : (
      <Box sx={{ mb: block?.settings?.enableOverlay ? '-40px' : '' }}>
        {title !== 'category' ? (
          <Stack alignItems="center" className={bannerClasses} sx={{ position: 'relative' }}>
            <Image
              src={getFieldValue('mobileLogo') || getFieldValue('logo')}
              alt="logo"
              sx={{
                objectFit: 'contain',
                width: getFieldValue('settings.width'),
                height: enableInfoBlock ? '100%' : '50%',
              }}
            />
            {enableInfoBlock && (
              <Stack
                direction={qrCodeSwitchPosition ? 'row' : 'column'}
                alignItems="center" // Выравниваем по вертикали
                gap={1} // Отступ между QR-кодом и текстом
                justifyContent={qrCodeSwitchPosition ? 'space-between' : ''}
                width={qrCodeSwitchPosition ? 0.8 : '100%'}
                sx={{
                  position: 'absolute',
                  bottom: 5,
                  gap: 1, // Разделяем блоки небольшим отступом
                  alignItems: 'flex-start', // Выравниваем по левому краю
                  height: !enableQrCodeBlock || isMobile ? '85%' : 'auto',
                }}
              >
                <Typography
                  sx={{
                    width: `${block?.settings?.textWidth}` || '100%',
                    lineHeight: block?.settings?.lineHeight || 1,
                    fontSize: !isMobile ? contentFontSize : '12px',
                    fontWeight: contentFontWeight,
                    height: qrCodeSwitchPosition ? '140px' : '',
                  }}
                  className={contentClasses}
                >
                  <span dangerouslySetInnerHTML={{ __html: contentText }} />
                </Typography>
              </Stack>
            )}
            {enableText && (
              <Box
                sx={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 0,
                  py: 1,
                  background: 'rgba(255, 255, 255, 0.7)',
                }}
              >
                <Typography
                  className={contentClasses}
                  sx={{
                    width: '70%',
                    margin: 'auto',
                    direction: forceRtl ? 'rtl' : undefined,
                    color: contentTextColor,
                    fontSize: contentFontSize,
                    fontWeight: contentFontWeight,
                    fontStyle: contentFontStyle,
                    textDecoration: contentTextDecoration,
                    textAlign: contentAlign,
                  }}
                >
                  {text}
                </Typography>
              </Box>
            )}
          </Stack>
        ) : (
          <Stack alignItems="center" className={bannerClasses} sx={{ position: 'relative' }}>
            <Box
              width="100%"
              height="20vh"
              overflow="hidden"
              style={{
                backgroundImage: `url("https://api-ksharim.b24online.com/upload/272c02dd-e436-47aa-b6bb-ca823b5c067d.png")`,
                backgroundRepeat: 'repeat',
                backgroundSize: 'contain',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '0px',
                  width: '99vw',
                  height: '3vw',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    py: 1,
                    background: 'rgba(255, 255, 255, 0.9)',
                  }}
                >
                  <Typography
                    className={contentClasses}
                    sx={{
                      width: '70%',
                      margin: 'auto',
                      direction: forceRtl ? 'rtl' : undefined,
                      color: contentTextColor,
                      fontSize: contentFontSize,
                      fontWeight: contentFontWeight,
                      fontStyle: contentFontStyle,
                      textDecoration: contentTextDecoration,
                      textAlign: contentAlign,
                    }}
                  >
                    {`${category?.title}`} תיאור קטגוריית
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        )}
      </Box>

    )}</>
  );
};

export default LogoComponent;
