'use client';

import { FC, useMemo, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

import Image from '../../../../components/image';
import { Ib2bProduct } from '../../../../types/b2b';
import { useRouter } from '../../../../routes/hooks';
import { getContentValueFromProps } from '../../utils';
import { randomInArray } from '../../../../utils/random';
import { useGetProducts } from '../../../../api/product';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';

export const SuperProductsBanner: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  const getFieldValue = getContentValueFromProps(block);
  const image = useMemo(() => getFieldValue('image'), [block]);
  const logo = useMemo(() => getFieldValue('logo'), [block]);
  const mainTitle = useMemo(() => getFieldValue('mainTitle'), [block]);
  const enableLogo = useMemo(() => getFieldValue('settings.enableLogo'), [block]);
  const enableTitle = useMemo(() => getFieldValue('settings.enableTitle'), [block]);
  const { products, totalCount } = useGetProducts({});
  const [filteredProducts, setFilteredProducts] = useState<any>(
    [],
  );
  const colors = [
    '#E5DCBB',
    '#FDDE4F',
    '#F8825A',
    '#F8733A',
    '#F4493F',
    '#BA292C',
    '#C0817C',
    '#89577C',
    '#6E86A8',
    '#75A686',
    '#C6D4A3',
    '#8EA571',
    '#7A874F',
    '#A7C7E7',
  ];

  useEffect(() => {
    if (block?.products) {
      setFilteredProducts(block.products);
    }
  }, [block?.products]);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item md={7} xs={12}>
        <Stack>
          {enableLogo && (
            <Image
              src={uploadProductsImage(logo)}
              sx={{ width: '150px', mx: 2 }}
            />
          )}
          {enableTitle && (
            <Typography
              sx={{ fontSize: '30px', fontWeight: 600, textAlign: 'center' }}
            >
              {mainTitle}
            </Typography>
          )}
          <Image src={uploadProductsImage(image)} />
        </Stack>
      </Grid>
      <Grid item md={5} xs={12}>
        <Stack gap={1}>
          {filteredProducts.map((product: Ib2bProduct, id: number) => (
            <Stack
              direction="row"
              key={id}
              sx={{
                bgcolor: product?.bgcolor,
                py: 0.5,
                px: 2,
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
                cursor: 'pointer',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              onClick={() => router.push(`/product/${product?.id}`)}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: 1000 }}>
                {product.title}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};
