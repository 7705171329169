'use client';

import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { IconButton, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { extractPrices } from 'src/helpers/extractPrices';
import { generateOptions } from 'src/helpers/generateOptions';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';
import { ActionType, ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { BlockType } from 'src/types/page-generator';
import { Ib2bProduct, PriceInterface, ProvidersEnum } from 'src/types/b2b';

import { CartInterface } from '../../types/cart';
import { useBoolean } from '../../hooks/use-boolean';

import { useResponsive } from '../../hooks/use-responsive';
import { useContentContext } from '../../components/content';
import { getProduct, getProductRestrictRule, useGetProduct } from '../../api/product';
import { ICheckoutDeliveryOption } from '../../types/checkout';
import Delivery from '../b2c-shop/view/delivery';
import { addToBasket, getBasket } from '../../api/basket';
import { IProduct, PriceTypeEnum } from '../../types/product';
import { IMerchant } from '../../types/merchant';
import { useSnackbar } from '../../components/snackbar';
import BasicModal from '../../components/modals/basic-modal';
import CheckoutPaymentStraightAhead from '../checkout/checkout-payment-straight-ahead';

type openPrice = {
  price: string;
  title: string;
  value: string;
};

interface ProductOpenPrice {
  price: number;
  title: string;
  value: string;
}

type Props = {
  block: BlockType;
  localSettings: BlockType;
  product: Ib2bProduct;
  mode: string;
  openPriceProducts?: openPrice[] | any;
  handleOpenPriceChange: Function;
  productOpenPrices?: ProductOpenPrice[];
  merchantId?: string | string[];
};

export const ProductOneCard = ({
                              block,
                              localSettings,
                              product,
                              mode,
                              openPriceProducts,
                              handleOpenPriceChange,
                              productOpenPrices,
                              merchantId,
                            }: Props) => {
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { openSite } = useContentContext();
  const router = useRouter();
  const { t } = useTranslate();
  const payModal = useBoolean();
  const defaultLimit = 0;
  // const isStock = product?.provider === ProvidersEnum.STOCK;
  const isStock = product?.useStock;
  const isMobile = useResponsive('down', 'sm');
  const limit = isStock && product?.stock ? +product.stock : defaultLimit;
  const OPTIONS = generateOptions(1, limit);
  const openDeliveryModal = useBoolean();
  const asterisksInfo = useBoolean();
  const { dispatch, state } = useAppContext();
  const { cart } = state;
  const activityId = state.smbActivityId || '';
  const smbAccountId = state.smbAccount.id || '';
  const trigger = state.trigger;

  const [count, setCount] = useState<string>('');
  const [deliveryOptions, setProductDeliveryOptions] = useState<ICheckoutDeliveryOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ICheckoutDeliveryOption>({
    id: '1',
    value: 0,
    label: 'Free',
    description: '5-7 Days delivery',
    imageUrl: '',
  });
  const localDataString: string | null = typeof window !== 'undefined' ? localStorage.getItem('openPrice') : '';
  const localData: any = localDataString ? JSON.parse(localDataString) : [];
  const isInCart = checkIfProductInCart(cart, product?.id);
  // const { product: localProduct } = useGetProduct(product?.id);
  const settings = () => {
    if (mode !== 'notCustom' && !localSettings?.settings?.generalSettings) return block;
    if (mode !== 'notCustom' && localSettings?.settings?.generalSettings)
      return localSettings.settings;
    return block?.settings;
  };
  const info = (value: Ib2bProduct, localBlock: BlockType | undefined) => {
    localStorage.setItem('product', JSON.stringify(value));
    if (localBlock !== undefined) {
      localStorage.setItem('settings', JSON.stringify(localBlock));
    }
    router.push(`/product/${value.id}`);
  };

  const value: number | undefined = localData.find(
    (item: ProductOpenPrice) => item.value === product?.id,
  )?.price;

  const coincidence = openPriceProducts?.find((item: openPrice) => item.value === product?.id);

  useEffect(() => {
    const updatedCard = state?.openPricesProducts?.map((item: ProductOpenPrice) => {
      if (item.value === product?.id) {
        return {
          ...item,
          price: count,
        };
      }
      return item;
    });
    dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload: updatedCard });
  }, [count]);
  useEffect(() => {
    const current = localData?.find((item: openPrice) => item.value === product?.id);
    setCount(current?.price);
  }, [state]);

  const {
    contentTextColor,
    contentFontSize,
    contentFontWeight,
    contentFontStyle,
    contentTextDecoration,
    contentAlign,
    enableButtonAdd,
    buttonBorderRadius,
    buttonBgColor,
    buttonColor,
    enableButton,
    buttonIcon,
    enableCount,
    enablePrice,
    enableSwitch,
    enableDiscount,
    topPriceColor,
    topPriceFontSize,
    bottomPriceColor,
    bottomPriceFontSize,
    enableBalance,
    enableShare,
    enableAvailable,
    enableAvailableLimit,
    buttonLabel,
    buttonLabelNoPrice,
  } = settings();

  const { businessPrice, basePrice, price, maxPrice, minPrice } = extractPrices(product?.prices);

  // const isOpenPrice = !businessPrice && !price && !!maxPrice && !!minPrice;
  const isOpenPrice = !businessPrice && !price;

  const allPrice = product?.prices ? product?.prices[0]?.value : '';

  let currentPriceView = coincidence?.price;
  if (coincidence && maxPrice && coincidence?.price > maxPrice) currentPriceView = maxPrice;
  if (coincidence && minPrice && coincidence?.price < minPrice) currentPriceView = minPrice;

  const imageLink =
    (product?.mainImageURL
      ? `${uploadProductsImage(`${product?.mainImageURL}`)}`
      : product?.merchants &&
      product?.merchants.length &&
      `${uploadProductsImage(`${product?.merchants[0].mainImageURL}`)}`) || '';

  const handleClick = () => {
    const currentPageUrl = window.location.href;
    let params = '?';
    if (enableDiscount) params += params === '?' ? 'ds' : ',ds';
    if (enableBalance) params += params === '?' ? 'ba' : ',ba';
    if (enableCount) params += params === '?' ? 'co' : ',co';
    if (enableAvailable) params += params === '?' ? 'av' : ',av';
    if (enableAvailableLimit) params += params === '?' ? 'av' : ',avl';
    const url = `${currentPageUrl}product/${product?.id}/${params}`;
    if (navigator?.share) {
      navigator
        ?.share({
          url,
        })
        .then(() => console.log(''))
        .catch((error) => console.error('Error', error));
    } else {
      navigator?.clipboard
        ?.writeText(url)
        ?.then(() => {
        })
        .catch((error) => {
          console.error('Failed to copy: ', error);
        });
    }
  };

  const addToCart = async (locProduct: Ib2bProduct) => {
    console.log('addToCart');
    openDeliveryModal.onTrue();
    setProductDeliveryOptions(product?.deliveryOptions);
  };

  const handleDeliveryChange = (option: any) => {
    setSelectedOption(option);
  };

  let productMerchantAsterisks: string = '';
  if (product && product.asterisks && merchantId) {
    product.asterisks.forEach((asterisk) => {
      if (asterisk.merchants) {
        const current: IMerchant | undefined = asterisk.merchants.find(
          (item) => +item.id === +merchantId,
        );

        if (current) {
          productMerchantAsterisks = asterisk.description || '';
        }
      }
    });
  }

  const openDonateModal = async () => {
    await getBasket(smbAccountId, dispatch).then(() => {
      payModal.onTrue();
    });
  }

  useEffect(() => {
    payModal.onFalse();
  }, [trigger])


  return (
    <Grid
      item
      md={12}
      xs={12}
      sx={{
        height: 'auto',
        display: 'flex',
        justifyContent: 'center'
        //   my: block?.settings?.enableRotation && '50px',
        //   mx: block?.settings?.enableRotation && 2,
      }}
    >
      <Card
        sx={{
          borderRadius: '5px',
          pb: 1,
          maxWidth: '360px',
          cursor: 'pointer',
          boxShadow: 5,
          maxHeight: '385px',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          // transform: block?.settings?.enableRotation && 'rotate(-30deg)',
        }}
      >
        <Box
          onClick={() => {
            info(product && product, block);
          }}
          sx={{
            backgroundSize: 'cover',
            backgroundImage: `url(${imageLink})`,
            backgroundRepeat: 'no-repeat',
            height: process.env.NEXT_PUBLIC_BOT_ID === '504' ? '190px' : '170px',
            borderRadius: '10px',
            transform: block?.settings?.enableRotation && 'rotate(-20deg)',
            mt: block?.settings?.enableRotation && '50px',
            width: block?.settings?.enableRotation ? '85%' : '100%',
            mx: block?.settings?.enableRotation && 'auto',
          }}
        >
          {productMerchantAsterisks && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                asterisksInfo.onTrue();
              }}
            >
              <Iconify width={30} icon="clarity:info-line" />
            </IconButton>
          )}
        </Box>
        {product?.brand && !product?.gift && (
          <Box
            sx={{
              borderRadius: '15px',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'secondary.contrastText',
              m: 1,
            }}
          >
            <Image src={product?.brand} sx={{ borderRadius: '15px' }} />
          </Box>
        )}
        {!block?.settings?.disableName && (
          <Box height="70px">
            <Typography
              sx={{
                my: 1,
                mx: 2,
                color: contentTextColor,
                fontSize: contentFontSize,
                fontWeight: contentFontWeight,
                fontStyle: contentFontStyle,
                textDecoration: contentTextDecoration,
                flex: 1,
                textAlign: contentAlign,
                // transform: block?.settings?.enableRotation && 'rotate(-20deg)',
                mt: block?.settings?.enableRotation && '60px',
              }}
            >
              {product?.title}
            </Typography>
          </Box>
        )}
        {!price && !allPrice ? (
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="center"
            px={1}
            mb={1}
          >
            {enableButtonAdd && (
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  height: '34px',
                  borderRadius: buttonBorderRadius,
                  fontWeight: 400,
                  bgcolor: buttonBgColor,
                  color: buttonColor,
                  '&:hover': {
                    bgcolor: buttonBgColor,
                    color: buttonColor,
                  },
                }}
              >
                {buttonLabelNoPrice || 'כניסה לאתר'}
              </Button>
            )}
          </Stack>
        ) : (
          <Stack direction="row-reverse" justifyContent="space-between" alignItems="center" px={1}>
            {enableButton && (
              <>
                {isInCart ? (
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      height: '34px',
                      borderRadius: '25px',
                      fontWeight: 400,
                    }}
                    endIcon={<Iconify icon="ep:success-filled" />}
                  >
                    בעגלה
                  </Button>
                ) : (
                  <Button
                    disabled={isStock && limit === 0}
                    onClick={() => {
                      if (!user) {
                        dispatch({
                          type: ActionTypes.SET_ACTIVE_DIALOG,
                          payload: 'login',
                        });
                      } else if (!block?.settings?.linkOnProductInfo) {
                        if (product?.deliveryOptions?.length) {
                          addToCart(product);
                        } else {
                          getProductRestrictRule(product?.id, activityId).then((it) => {
                            if (!it?.data.length) {
                              let currentPrice;
                              if (value && !coincidence) {
                                if (!isOpenPrice) {
                                  currentPrice = value;
                                  if (minPrice && value < minPrice) currentPrice = minPrice;
                                  if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                                } else {
                                  currentPrice = count;
                                }
                              } else if (isOpenPrice && coincidence) {
                                currentPrice = coincidence.price;
                                // @ts-ignore
                                if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                                // @ts-ignore
                                if (+coincidence.price < minPrice) currentPrice = minPrice;
                              } else {
                                currentPrice = (enableDiscount && basePrice) || price;
                              }
                              addToBasket(
                                state.smbAccount.id,
                                product?.id,
                                currentPrice,
                                imageLink,
                                dispatch,
                                cart,
                                [],
                              );
                              if (block?.settings?.enablePayModal) {
                               openDonateModal();
                              }
                            } else if (it?.data?.length && it?.message) {
                              enqueueSnackbar(`${it?.message}`, { variant: 'error' });
                            }
                          });
                        }
                      } else info(product, block);
                    }}
                    variant={block?.settings?.buttonVariant || 'contained'}
                    sx={{
                      height: '34px',
                      borderRadius: buttonBorderRadius,
                      fontWeight: 400,
                      bgcolor: block?.settings?.buttonVariant === 'outlined' ? '' : buttonBgColor,
                      color:
                        block?.settings?.buttonVariant === 'outlined' ? buttonBgColor : buttonColor,
                      borderColor:
                        block?.settings?.buttonVariant === 'outlined' &&
                        block?.settings?.buttonBgColor,
                      '&:hover': {
                        background:
                          block?.settings?.buttonVariant === 'contained' ? buttonBgColor : '',
                        color:
                          block?.settings?.buttonVariant === 'outlined'
                            ? buttonBgColor
                            : buttonColor,
                        borderColor: block?.settings?.buttonVariant === 'outlined' && buttonBgColor,
                      },
                    }}
                    endIcon={<Iconify icon={buttonIcon} />}
                  >
                    {buttonLabel || 'לרכישה'}
                  </Button>
                )}
              </>
            )}
            {enableCount && (
              <TextField
                select
                SelectProps={{ native: true }}
                sx={{
                  '& .MuiNativeSelect-select': {
                    py: 1,
                    px: 2,
                  },
                }}
              >
                {OPTIONS.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            )}
            {enablePrice && (
              <>
                {!isOpenPrice && (
                  <Stack>
                    {!enableSwitch && enableDiscount && (
                      <Typography
                        sx={{
                          color: topPriceColor,
                          textDecoration: 'line-through',
                          fontSize: topPriceFontSize,
                        }}
                      >
                        ₪{price}
                      </Typography>
                    )}
                    {!enableSwitch && enableDiscount ? (
                      <Typography
                        sx={{
                          color: bottomPriceColor,
                          fontSize: bottomPriceFontSize,
                        }}
                      >
                        ₪{basePrice}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: bottomPriceColor,
                          fontSize: bottomPriceFontSize,
                        }}
                      >
                        ₪{enableSwitch ? allPrice : price}
                      </Typography>
                    )}
                  </Stack>
                )}
                {isOpenPrice && (
                  <>
                    {coincidence && coincidence?.price ? (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                          sx={{
                            color: bottomPriceColor,
                            fontSize: bottomPriceFontSize,
                          }}
                        >
                          ₪{coincidence && coincidence?.price && currentPriceView}
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <TextField
                          name="openPrice"
                          // placeholder={`${t('Sum')}`}
                          placeholder="הזן סכום"
                          sx={{ width: !enableCount ? '80%' : 60, '& input': { p: 1 } }}
                          value={count}
                          onChange={(e) => {
                            handleOpenPriceChange(e, product, maxPrice, minPrice);
                            setCount(e.target.value);
                          }}
                        />
                        <Typography
                          sx={{
                            color: 'text.secondary',
                            fontSize: 16,
                          }}
                        >
                          ₪
                        </Typography>
                      </Stack>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        )}
        <Grid
          container
          justifyContent="space-between"
          sx={{
            p: 1,
            // height: '50px',
            mb: 0,
            mt: 1,
            py: 0,
            display: !enableBalance && !enableAvailable && !enableShare ? 'none' : '',
          }}
        >
          <Grid item md={6} xs={6}>
            {enableBalance && isStock && limit !== 0 && (
              <Stack direction="row">
                <Typography>נשאר: {limit}</Typography>
              </Stack>
            )}
            {enableBalance && isStock && limit === 0 && (
              <Stack direction="row">
                <Typography>אין במלאי</Typography>
              </Stack>
            )}
            {enableShare && (
              <Iconify icon="solar:share-bold" width={16} sx={{ mr: 0.5 }} onClick={handleClick} />
            )}
          </Grid>
          <Grid item md={6} xs={6}>
            <Stack>
              {enableAvailable && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ mx: 1 }}>כמות</Typography>
                  <TextField
                    sx={{
                      '& .MuiNativeSelect-select': { py: 0 },
                    }}
                    select
                    fullWidth={!isMobile}
                    SelectProps={{
                      native: true,
                      sx: { textTransform: 'capitalize' },
                    }}
                    onChange={() => {
                    }}
                  >
                    {OPTIONS.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </TextField>
                </Stack>
              )}
              {enableAvailableLimit && isStock && limit !== 0 && (
                <Typography sx={{ fontSize: enableAvailable ? '10px' : '16px', textAlign: 'end' }}>
                  ניתן לקנייה: {limit}
                </Typography>
              )}
              {enableAvailableLimit && isStock && limit === 0 && (
                <Typography sx={{ fontSize: enableAvailable ? '10px' : '16px', textAlign: 'end' }}>
                  אין במלאי
                </Typography>
              )}

            </Stack>
          </Grid>
        </Grid>
      </Card>
      <Modal open={openDeliveryModal.value} onClose={() => openDeliveryModal.onFalse()}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Delivery
            options={deliveryOptions || []}
            isDeliveryEnable
            onChange={(option) => handleDeliveryChange(option)}
            selectedOption={selectedOption}
          />
          <Button
            disabled={selectedOption.id === '1'}
            onClick={() => {
              if (!user) {
                dispatch({
                  type: ActionTypes.SET_ACTIVE_DIALOG,
                  payload: 'login',
                });
              } else if (!block?.settings?.linkOnProductInfo) {
                if (product?.deliveryOptions?.length) {
                  addToCart(product);
                } else {
                  getProductRestrictRule(product?.id, activityId).then((it) => {
                    if (!it?.data.length) {
                      let currentPrice;
                      if (value && !coincidence) {
                        if (!isOpenPrice) {
                          currentPrice = value;
                          if (minPrice && value < minPrice) currentPrice = minPrice;
                          if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                        } else {
                          currentPrice = count;
                        }
                      } else if (isOpenPrice && coincidence) {
                        currentPrice = coincidence.price;
                        // @ts-ignore
                        if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                        // @ts-ignore
                        if (+coincidence.price < minPrice) currentPrice = minPrice;
                      } else {
                        currentPrice = (enableDiscount && basePrice) || price;
                      }
                      addToBasket(
                        state.smbAccount.id,
                        product?.id,
                        currentPrice,
                        imageLink,
                        dispatch,
                        cart,
                        [],
                      );
                    } else if (it?.data?.length && it?.message) {
                      enqueueSnackbar(`${it?.message}`, { variant: 'error' });
                    }
                  });
                }
              } else info(product, block);
            }}
            variant={block?.settings?.buttonVariant || 'contained'}
            sx={{
              mt: 1,
              height: '34px',
              borderRadius: buttonBorderRadius,
              fontWeight: 400,
              bgcolor: block?.settings?.buttonVariant === 'outlined' ? '' : buttonBgColor,
              color: block?.settings?.buttonVariant === 'outlined' ? buttonBgColor : buttonColor,
              borderColor:
                block?.settings?.buttonVariant === 'outlined' && block?.settings?.buttonBgColor,
              '&:hover': {
                background: block?.settings?.buttonVariant === 'contained' ? buttonBgColor : '',
                color: block?.settings?.buttonVariant === 'outlined' ? buttonBgColor : buttonColor,
                borderColor: block?.settings?.buttonVariant === 'outlined' && buttonBgColor,
              },
            }}
            endIcon={<Iconify icon={buttonIcon} />}
          >
            {buttonLabel || 'לרכישה'}
          </Button>
        </Box>
      </Modal>
      <Modal open={asterisksInfo.value} onClose={() => asterisksInfo.onFalse()}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography color="red">{`${productMerchantAsterisks}*`}</Typography>
        </Box>
      </Modal>
      <BasicModal open={payModal.value} onClose={() => {
        payModal.onFalse();
      }}>
        <Stack spacing={2} sx={{ padding: 2 }}>
          <CheckoutPaymentStraightAhead block={block} />
        </Stack>
      </BasicModal>
    </Grid>
  );
};
