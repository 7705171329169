// locales
import { useTranslate } from 'src/locales';
// @mui

import { Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material';

//
import { ConfirmDialogProps } from './types';

// ----------------------------------------------------------------------

export default function ConfirmResendingDialog({ title, content, action, open, onClose, ...other }: ConfirmDialogProps) {
  const { t: translate } = useTranslate();
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions>
        {action}

        <Button variant="outlined" color="inherit" onClick={onClose}>
          {`${translate('Cancel')}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
