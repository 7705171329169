'use client';

import NextLink from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import { FC, useRef, useState, useEffect, useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { Box, Grid, Stack, Button, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { useLocales, useTranslate } from '../../../../locales';
import { ButtonField } from '../../../../types/generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import SanitizeHTML from '../../../../utils/sanitize';
import { ProductOneCard } from '../../../products/product-one-card';
import { getContentValueFromProps } from '../../utils';
import { Ib2bProduct } from '../../../../types/b2b';

interface ProductOpenPrice {
  price: number;
  title: string;
  value: string;
}

export const HeadActiveBanner: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const { t } = useTranslate();
  const { currentLang } = useLocales();
  const swiperRef = useRef<SwiperCore | null>(null);
  const { state, dispatch } = useAppContext();
  const mdUp = useResponsive('up', 'md');
  const smUp = useResponsive('up', 'sm');
  const isMobile = useResponsive('down', 'sm');
  const title: any = block?.mainTitle || '';
  const subTitle: any = block?.subTitle || '';
  const actionButtons = block?.actionButtons || [];
  const combinedSlider: any = block?.combinedSlider || [];
  const [isLoaded, setIsLoaded] = useState(false);
  const [productOpenPrices, setProductOpenPrices] = useState<ProductOpenPrice[]>([]);


  const img: any = block?.image || '';
  const lineStyle = block?.settings?.enableLine
    ? {
      borderBottom: `${block?.settings?.lineSize} solid`,
      borderColor: block?.settings?.lineColor,
    }
    : {};

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const firstStop = block?.settings?.firstGradientColorStop
    ? `${block?.settings?.firstGradientColorStop}%`
    : '40%';
  const secondStop = block?.settings?.secondGradientColorStop
    ? `${block?.settings?.secondGradientColorStop}%`
    : '100%';

  const colorButton = block?.settings?.enableGradient
    ? {
      background: `linear-gradient(90deg, ${block?.settings?.firstGradientColor} ${firstStop}, ${block?.settings?.secondGradientColor} ${secondStop} )`,
    }
    : {};

  const localProduct = useMemo(() => getFieldValue('product'), [block]);

  const handleOpenPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    product: Ib2bProduct,
    maxPrice: number,
    minPrice: number,
  ) => {
    const { value } = event.target;
    const newPrice: ProductOpenPrice = {
      value: product.id,
      title: product.title,
      price: parseInt(value, 10),
    };

    if (newPrice.price > maxPrice) {
      newPrice.price = maxPrice;
    }

    setProductOpenPrices((prevState: ProductOpenPrice[]) => {
      const updatedPrices = [...prevState.filter((price) => price.value !== product.id), newPrice];
      dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload: updatedPrices });
      return updatedPrices;
    });

    const localDataString: string | null = typeof window !== 'undefined' ? localStorage.getItem('openPrice') : '';

    if (localDataString) {
      const localData: ProductOpenPrice[] = JSON.parse(localDataString);
      const updated: ProductOpenPrice[] = [
        ...localData.filter((price: ProductOpenPrice) => price.value !== product.id),
        newPrice,
      ];
      localStorage.setItem('openPrice', JSON.stringify(updated));
    } else {
      localStorage.setItem('openPrice', JSON.stringify([newPrice]));
    }
  };

  return (
    <>
      {isLoaded ? (
        <Box
          sx={{
            height: 1,
            ...lineStyle,
            display: isMobile && block?.settings?.disableOnMobile ? 'none' : 'block',
          }}
        >
          {block?.settings?.enableSlider ? (
            <Swiper
              speed={500}
              slidesPerView="auto"
              autoplay
              loop
              mousewheel={{
                forceToAxis: true,
                sensitivity: 1,
                releaseOnEdges: true,
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {combinedSlider.map((item: any, ind: number) => (
                <SwiperSlide key={ind}>
                  <Grid
                    container
                    sx={{
                      height: 1,
                    }}
                  >
                    <Grid
                      item
                      md={block?.settings?.fullWidth ? 12 : 6}
                      xs={12}
                      height={1}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Image
                        src={item?.image}
                        sx={{
                          transform: item.disableMirrorReflection ? '' : 'scaleX(-1)',
                          '&:first-of-type': {
                            display: 'flex',
                            justifyContent: 'center',
                          },
                          '& img': {
                            objectFit: 'contain',
                          },
                        }}
                        width={isMobile ? '70%' : block?.settings?.imageWidth || '100%'}
                        height={isMobile ? '30vh' : '20vw'}
                      />
                    </Grid>
                    <Grid item md={block?.settings?.fullWidth ? 12 : 6} xs={12}>
                      <Stack
                        alignItems="center"
                        gap={2}
                        height={1}
                        justifyContent={item?.verticalPosition}
                      >
                        <Typography
                          sx={{
                            mt: 1,
                            textAlign: item.titleAlign,
                            width: item.titleWidth,
                            color: item.titleTextColor,
                            fontSize: { md: item.titleFontSize, xs: '25px' },
                            fontWeight: item.titleFontWeight,
                            fontStyle: item.titleFontStyle,
                            textDecoration: item.titleTextDecoration,
                            lineHeight: 1,
                          }}
                        >
                          {item.mainTitle}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: item.subtitleAlign,
                            width: item.subTitleWidth,
                            color: item.subTitleTextColor,
                            fontSize: { md: item.subTitleFontSize, xs: '15px' },
                            fontWeight: item.subTitleFontWeight,
                            fontStyle: item.subTitleFontStyle,
                            textDecoration: item.subTitleTextDecoration,
                          }}
                        >
                          {item.subTitle}
                        </Typography>
                        <Stack
                          alignItems="center"
                          justifyContent={item?.buttonsAlign}
                          spacing={1}
                          direction="row"
                          width={1}
                          sx={{ mb: { md: block?.settings?.fullWidth ? 1 : 0, xs: 1 } }}
                        >
                          {item.actionButtons.map((menuButton: ButtonField, idx: number) => {
                            const firstStopItem = item.firstGradientColorStop
                              ? `${item.firstGradientColorStop}%`
                              : '40%';
                            const secondStopItem = item.secondGradientColorStop
                              ? `${item.secondGradientColorStop}%`
                              : '100%';

                            const colorButtonItem = block?.settings?.enableGradient
                              ? {
                                background: `linear-gradient(90deg, ${block?.settings?.firstGradientColor} ${firstStopItem}, ${block?.settings?.secondGradientColor} ${secondStopItem} )`,
                              }
                              : {};
                            return (
                              // @ts-ignore - проблемы с совместимостью типов, не кртичино
                              <Button
                                component={
                                  menuButton?.link.includes('modal') ||
                                  menuButton.link.includes('#')
                                    ? Button
                                    : NextLink
                                }
                                variant={menuButton?.variant || 'contained'}
                                color={menuButton?.color || 'primary'}
                                size={menuButton?.size || 'medium'}
                                key={`menuButton_${idx}`}
                                sx={{
                                  borderRadius: block?.settings?.isAlternativeDesign ? '5px' : 10,
                                  height: !smUp ? '40px' : 'auto',
                                  fontSize: !smUp ? '10px' : '',
                                  px: !smUp ? '6px' : '',
                                  textWrap: 'nowrap',
                                }}
                                href={
                                  (!menuButton?.link.includes('modal') &&
                                    !menuButton.isDialogToggler &&
                                    !menuButton.link.includes('#') &&
                                    menuButton?.link) ||
                                  ''
                                }
                                onClick={() => {
                                  if (menuButton.isDialogToggler && menuButton.modalId) {
                                    setActiveDialog(menuButton.modalId);
                                  }
                                  if (menuButton.link.includes('#')) {
                                    const newLink = menuButton?.link.replace('#', '');
                                    window.location.href = `#${newLink}`;
                                  }
                                }}
                              >
                                {menuButton.label || `[${t('no label')}]`}
                              </Button>
                            );
                          })}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Grid
              container
              sx={{
                height: 1,
                flexDirection: block?.settings?.enableReverse
                  ? 'row-reverse'
                  : 'row',
              }}
            >
              <Grid item md={block?.settings?.fullWidth ? 12 : 6} xs={12} height={1}
                    sx={{ display: 'flex', alignItems: 'center' }}>
                {block?.settings?.variant === 'image' && (
                  <Image
                    src={img}
                    sx={{
                      transform: block?.settings?.disableMirrorReflection ? '' : 'scaleX(-1)',
                      '& span.component-image-wrapper': {
                        display: 'flex!important',
                        justifyContent: 'center!important',
                      },
                      '& img': { width: block?.settings?.imageWidth || '100%' },
                    }}
                    width={1}
                  />
                )}
                {block?.settings?.variant === 'product' && (
                  <ProductOneCard
                    block={block}
                    localSettings={block}
                    product={localProduct[0]}
                    mode="notCustom"
                    handleOpenPriceChange={handleOpenPriceChange}
                    productOpenPrices={productOpenPrices}
                  />
                )}

              </Grid>
              <Grid item md={block?.settings?.fullWidth ? 12 : 6} xs={12} className={block?.settings?.contentClasses}>
                <Stack
                  alignItems="center"
                  gap={2}
                  height={1}
                  justifyContent={block?.settings?.verticalPosition}
                >
                  <Typography
                    sx={{
                      textAlign: 'right',
                      mt: 1,
                      '& h1': { my: 1 },
                      '& h2': { my: 1 },
                      '& h3': { my: 1 },
                      '& h4': { my: 1 },
                      '& h5': { my: 1 },
                      '& h6': { my: 1 },
                      '& p': { margin: 0 },
                      fontWeight: block?.settings?.titleFontWeight,
                      direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                      width: block?.settings?.titleWidth,
                      lineHeight: block?.settings?.lineHeight || 1,
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                    {/* <SanitizeHTML html={title} /> */}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: 'right',
                      '& h1': { my: 1 },
                      '& h2': { my: 1 },
                      '& h3': { my: 1 },
                      '& h4': { my: 1 },
                      '& h5': { my: 1 },
                      '& h6': { my: 1 },
                      '& p': { margin: 0 },
                      fontWeight: block?.settings?.subtitleFontWeight,
                      direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                      width: block?.settings?.subTitleWidth,
                      lineHeight: block?.settings?.lineSubtitleHeight || 1,
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: subTitle }} />
                    {/* <SanitizeHTML html={subTitle} /> */}
                  </Typography>
                  <Stack
                    alignItems="center"
                    justifyContent={block?.settings?.buttonsAlign}
                    spacing={1}
                    direction="row"
                    width={1}
                  >
                    {actionButtons.map((menuButton: ButtonField, idx: number) => (
                      // @ts-ignore - проблемы с совместимостью типов, не кртичино
                      <Button
                        key={idx}
                        variant={menuButton?.buttonVariant || 'contained'}
                        size={menuButton?.buttonSize}
                        sx={{
                          borderRadius: menuButton.buttonBorderRadius,
                          width: '100%',
                          color: `${menuButton?.color}.main`,
                          background: menuButton.isBackgroundGradient
                            ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                            : menuButton?.background,
                          fontSize: '16px',
                          px: 1.1,
                          '&:hover': {
                            color: `${menuButton?.color}.main`,
                            background: menuButton.isBackgroundGradient
                              ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                              : menuButton?.background,
                          },
                        }}
                        href={
                          (!menuButton?.link.includes('modal') &&
                            !menuButton.isDialogToggler &&
                            !menuButton.link.includes('#') &&
                            menuButton?.link) ||
                          ''
                        }
                        onClick={() => {
                          if (menuButton.isDialogToggler && menuButton.modalId) {
                            setActiveDialog(menuButton.modalId);
                            dispatch({ type: ActionTypes.SET_POPUP_NAME, payload: menuButton.label });
                          }
                          if (menuButton.link.includes('#')) {
                            const newLink = menuButton?.link.replace('#', '');
                            window.location.href = `#${newLink}`;
                          }
                        }}
                      >
                        <Typography sx={{ textTransform: 'none', fontSize: isMobile ? '13px' : '' }}>
                          {menuButton?.label || `[${t('no label')}]`}
                        </Typography>
                      </Button>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Box>
      ) : (
        <Skeleton variant="rectangular" width="100%" height={160} />
      )}
    </>
  );
};
