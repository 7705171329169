import { useState } from 'react';

import { Box, Paper, Alert, Button, Slider, TextField, Typography } from '@mui/material';
import { useTranslate } from '../../locales';

type Props = {
  price: number;
  currencyToDisplay?: string;
};

const CreditPaymentCalculator = ({ price, currencyToDisplay }: Props) => {
  const { t } = useTranslate();
  const [propertyPrice, setPropertyPrice] = useState(price);
  const [downPaymentPercent, setDownPaymentPercent] = useState(20);
  const [loanTerm, setLoanTerm] = useState('25');
  const [interestRate, setInterestRate] = useState('3.5');
  const [monthlyPayment, setMonthlyPayment] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const calculatePayment = () => {
    // Проверяем, заполнены ли поля
    if (!loanTerm || !interestRate) {
      setError(`${t('Please fill in both Loan Term and Interest Rate.')}`);
      return;
    }

    setError(null); // Сбрасываем ошибку при успешной валидации

    const downPaymentAmount = (propertyPrice * downPaymentPercent) / 100;
    const loanAmount = propertyPrice - downPaymentAmount;
    const monthlyInterestRate = +interestRate / 100 / 12;
    const numberOfPayments = +loanTerm * 12;

    const payment =
      (loanAmount * monthlyInterestRate) / (1 - (1 + monthlyInterestRate) ** -numberOfPayments);

    setMonthlyPayment(+payment.toFixed(2));
  };

  const handleDownPaymentChange = (value: number) => {
    setDownPaymentPercent(value);
  };

  const downPaymentAmount = ((propertyPrice * downPaymentPercent) / 100).toFixed(2);

  return (
    <Paper
      elevation={3}
      sx={{
        p: 1,
        borderRadius: 2,
      }}
    >
      <Typography variant="h5" gutterBottom sx={{textAlign: 'end'}}>
        {t('Loan Payment Calculator')}
      </Typography>

      {/* <Box mb={3}> */}
      {/*  <TextField */}
      {/*    fullWidth */}
      {/*    label="Property Price (₪)" */}
      {/*    type="number" */}
      {/*    disabled */}
      {/*    value={propertyPrice} */}
      {/*    onChange={(e) => setPropertyPrice(Number(e.target.value))} */}
      {/*    required */}
      {/*  /> */}
      {/* </Box> */}

      <Box mb={3}>
        <Typography gutterBottom sx={{direction: 'ltr'}}>
          {t('Down Payment:')} {downPaymentPercent}% ({downPaymentAmount} {currencyToDisplay})
        </Typography>
        <Slider
          value={downPaymentPercent}
          onChange={(e, value) => handleDownPaymentChange(value as number)}
          aria-labelledby="down-payment-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={0}
          max={100}
        />
      </Box>

      <Box mb={3}>
        <TextField
          fullWidth
          label={`${t('Loan Term (years)')}`}
          type="number"
          value={loanTerm}
          onChange={(e) => setLoanTerm(e.target.value)}
          required
        />
      </Box>

      <Box mb={3}>
        <TextField
          fullWidth
          label={`${t('Interest Rate (%)')}`}
          type="number"
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
          required
        />
      </Box>

      <Button
        type="button"
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={calculatePayment}
      >
        {t('Calculate Rent')}
      </Button>

      {error && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {t(`${error}`)}
        </Alert>
      )}

      {monthlyPayment !== null && !error && (
        <Box mt={4} p={2} textAlign="center" bgcolor="#f1f1f1" borderRadius={2}>
          <Typography variant="h6">{t('Estimated Monthly Payment:')}</Typography>
          <Typography variant="h4" color="primary" sx={{direction: 'rtl'}}>
            {monthlyPayment} {currencyToDisplay}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default CreditPaymentCalculator;
