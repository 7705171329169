'use client';

import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { Stack, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Image from '../../../components/image';
import FormProvider from '../../../components/hook-form';
import { IVirtualCard } from '../../../types/club-cards';
import { BlockType } from '../../../types/page-generator';
import { useAppContext } from '../../../contexts/AppContext';
import { useResponsive } from '../../../hooks/use-responsive';
import { ICustomerItem } from '../../../types/customer-citypeople';
import { virtualCardTransfer } from '../../../api/virtualCardType';
import { useLocales } from '../../../locales';

type Props = {
  onClose: Function;
  block: BlockType | undefined;
  localMember?: ICustomerItem;
  localCard?: IVirtualCard;
  refreshData: Function;
  sectionType: string;
};

export const DialogCustomFamily = ({ onClose, block, localMember, localCard, refreshData, sectionType }: Props) => {
  const { currentLang } = useLocales();
  const [currentModal, setCurrentModal] = useState(1);
  const [currentTab, setCurrentTab] = useState(0);
  const { state } = useAppContext();
  const activityId = state.smbActivityId;
  const [transferAmount, setTransferAmount] = useState('');
  const [selectedCard, setSelectedCard] = useState({ id: '', name: '' });

  const virtualCards = state.virtualCards.filter((item) => {
    if (!item.cardType || !localCard?.cardType) {
      return false;
    }
    return item.cardType.id === localCard.cardType.id;
  });

  const enableNewTransfer = block?.settings?.enableNewTransfer || true;
  const enableContact = block?.settings?.enableContact || true;
  const enableTransferType = block?.settings?.enableTransferType || true;
  const enablePhoneNumber = block?.settings?.enablePhoneNumber || true;
  const enableTransferAmount = block?.settings?.enableTransferAmount || true;
  const enableCardName = block?.settings?.enableCardName || true;

  const isMobile = useResponsive('down', 'sm');

  const localTabs = [
    {
      id: 0,
      label: 'Phone',
    },
    {
      id: 1,
      label: 'Email',
    },
  ];

  const CommentSchema = Yup.object().shape({
    comment: Yup.string().required('Comment is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    comment: '',
    name: '',
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const phone = localMember?.phones?.find((item) => item.type === 'mobile');

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleAmountChange = (event: { target: { value: any; }; }) => {
    setTransferAmount(event.target.value); // Обновляем состояние
  };

  const handleCardChange = (event: { target: { value: string } }) => {
    const selectedId = event.target.value;
    const selected = virtualCards.find((card) => card.id === selectedId);

    setSelectedCard({
      id: selected?.id || '', // Используем пустую строку, если id отсутствует
      name: selected?.cardType?.name || selected?.cardNumber || '', // Предоставляем значения по умолчанию
    });
  };


  const transfer = () => {
    try {
      const currentURL = window.location.href; // Получаем полный URL
      const url = new URL(currentURL);

      const secureBaseURL = `${url.protocol === 'http:' ? 'https:' : url.protocol}//${url.hostname}/${currentLang?.value}`;
      const link = secureBaseURL;
      const newData: any = {};
      newData.hoursToExpire = 72;
      newData.phoneNumber = currentTab === 0 ? phone && phone.number : '';
      newData.email = currentTab === 1 ? localMember && localMember.email : '';
      newData.amount = +transferAmount;
      newData.virtualCardId = selectedCard?.id;
      newData.activityId = activityId;
      newData.link = link;
      newData.sectionType = sectionType;
      virtualCardTransfer(newData).then((response) => {
        if (response.success === true) {
          setCurrentModal(3);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {currentModal === 1 && (
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle maxWidth="100%">
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ fontWeight: 800 }}>העברה חדשה</Typography>
              {/* <Typography sx={{ fontWeight: 800 }}>account`s name </Typography> */}
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack gap={1}>
              {enableNewTransfer && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ fontWeight: 800 }}>למי?</Typography>
                  {/* <Stack direction="row"> */}
                  {/*  <Typography sx={{ fontWeight: 800 }}> העברה חדשה</Typography> */}
                  {/*  <Iconify icon="charm:plus" /> */}
                  {/* </Stack> */}
                </Stack>
              )}
              {/* {enableContact && ( */}
              {/*  <RHFAutocomplete */}
              {/*    name="contact" */}
              {/*    label="בחר איש קשר" */}
              {/*    options={['איגור גוברמן', '2איגור גוברמן']} */}
              {/*    getOptionLabel={(option) => option} */}
              {/*    isOptionEqualToValue={(option, value) => option === value} */}
              {/*    renderOption={(props, option) => ( */}
              {/*      <li {...props} key={option}> */}
              {/*        {option} */}
              {/*      </li> */}
              {/*    )} */}
              {/*    multiple */}
              {/*    renderTags={(value, getTagProps) => */}
              {/*      value.map((option, index) => ( */}
              {/*        <Chip */}
              {/*          sx={{ borderRadius: '20px' }} */}
              {/*          label={option} */}
              {/*          {...getTagProps({ index })} */}
              {/*        /> */}
              {/*      )) */}
              {/*    } */}
              {/*  /> */}
              {/* )} */}
              {/* {enableTransferType && ( */}
              {/*  <RHFSelect name="status" label="בחר סוג העברה"> */}
              {/*    {['העברת כספים', 'העברת נקודות', 'העברת קופונים והטבות'].map((status) => ( */}
              {/*      <MenuItem key={status} value={status}> */}
              {/*        {status} */}
              {/*      </MenuItem> */}
              {/*    ))} */}
              {/*  </RHFSelect> */}
              {/* )} */}
              {/* <Tabs */}
              {/*  sx={{ */}
              {/*    mb: 3, */}
              {/*    '& .MuiButtonBase-root': { fontSize: '20px' }, */}
              {/*    '& .MuiTabScrollButton-root': { */}
              {/*      display: !isMobile || !localTabs.find((item: any) => item.show) ? 'none' : '', */}
              {/*    }, */}
              {/*    '& .MuiTabs-flexContainer': { justifyContent: 'center' }, */}
              {/*  }} */}
              {/*  value={currentTab} */}
              {/*  onChange={handleChangeTab} */}
              {/* > */}
              {/*  {localTabs.map((tab, id) => ( */}
              {/*    <Tab key={id} label={tab.label} /> */}
              {/*  ))} */}
              {/* </Tabs> */}
              {currentTab === 0 && (
                <>
                  {enablePhoneNumber && (
                    <TextField disabled fullWidth value={phone && phone?.number} onChange={() => {
                    }} placeholder={!phone?.number ? 'טלפון לא נמצא' : 'הקלד מספר הטלפון '} />
                  )}
                  {enableTransferAmount && (
                    <TextField
                      fullWidth
                      value={transferAmount}
                      onChange={handleAmountChange}
                      placeholder="₪ סכום להעברה"
                    />
                  )}
                </>
              )}
              {currentTab === 1 && (
                <>
                  {enablePhoneNumber && (
                    <TextField
                      disabled
                      fullWidth
                      value={localMember && localMember?.email}
                      onChange={() => {
                      }}
                      placeholder={!localMember?.email ? 'דוא"ל לא נמצא' : 'הקלד כתובת דוא"ל'}
                    />
                  )}
                  {enableTransferAmount && (
                    <TextField
                      fullWidth
                      value={transferAmount}
                      onChange={handleAmountChange}
                      placeholder="₪ סכום להעברה"
                    />
                  )}
                </>
              )}
              <TextField
                select
                label="Card type"
                fullWidth
                placeholder="Select a card"
                value={selectedCard.id} // Привязка значения к id
                onChange={handleCardChange} // Обработчик изменения
              >
                {virtualCards &&
                  virtualCards.map((card) => (
                    <MenuItem key={card.id} value={card.id}>
                      {card.cardType?.name || card.cardNumber}
                    </MenuItem>
                  ))}
              </TextField>
              {/* {enableCardName && ( */}
              {/*  <RHFSelect name="status" label="בחר שם הכרטיס"> */}
              {/*    {[' B24online'].map((status) => ( */}
              {/*      <MenuItem key={status} value={status}> */}
              {/*        {status} */}
              {/*      </MenuItem> */}
              {/*    ))} */}
              {/*  </RHFSelect> */}
              {/* )} */}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="flex-end" gap={1} width={1}>
              <Button
                variant="contained"
                onClick={() => setCurrentModal(2)}
                sx={{ color: 'white', bgcolor: 'black' }}
              >
                להעביר
              </Button>
              <Button
                variant="outlined"
                onClick={() => onClose()}
                sx={{
                  color: 'black',
                  borderColor: 'grey',
                }}
              >
                ביטול
              </Button>
            </Stack>
          </DialogActions>
        </FormProvider>
      )}
      {currentModal === 2 && (
        <Stack>
          <DialogTitle maxWidth="100%">
            <Typography color="red" sx={{ textAlign: 'center' }}>
              אנא לוודא שהמידע הבא נכון
            </Typography>
          </DialogTitle>
          <DialogContent>
            {/* <Stack */}
            {/*  direction="row" */}
            {/*  justifyContent="space-between" */}
            {/*  sx={{ mb: 2 }} */}
            {/*  alignItems="center" */}
            {/* > */}
            {/*  <Typography sx={{ fontWeight: 800 }}>העברה חדשה ל-</Typography> */}
            {/*  <Typography sx={{ fontWeight: 800 }}>account`s name </Typography> */}
            {/* </Stack> */}
            <Grid container spacing={1}>
              {/* {enableCardName && ( */}
              {/*  <> */}
              {/*    <Grid item xs={6}> */}
              {/*      {' '} */}
              {/*      <Typography>איש קשר</Typography> */}
              {/*    </Grid> */}
              {/*    <Grid item xs={6}> */}
              {/*      <Typography sx={{ fontWeight: 800 }}>איגור גוברמן</Typography> */}
              {/*    </Grid> */}
              {/*  </> */}
              {/* )} */}
              {/* {enableCardName && ( */}
              {/*  <> */}
              {/*    <Grid item xs={6}> */}
              {/*      {' '} */}
              {/*      <Typography>סוג העברה</Typography> */}
              {/*    </Grid> */}
              {/*    <Grid item xs={6}> */}
              {/*      <Typography sx={{ fontWeight: 800 }}>העברת כספים</Typography> */}
              {/*    </Grid> */}
              {/*  </> */}
              {/* )} */}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>למספר הטלפון</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {currentTab === 0 && (
                      <Typography sx={{ fontWeight: 800 }}>{phone?.number}</Typography>
                    )}
                    {currentTab === 1 && (
                      <Typography sx={{ fontWeight: 800 }}>{localMember?.email}</Typography>
                    )}
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>סכום להעברה</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>₪{transferAmount}</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>העברה מהכרטיס</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>{selectedCard?.name}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="space-between" gap={1} width={1}>
              <Button
                variant="contained"
                onClick={() => transfer()}
                sx={{ color: 'white', bgcolor: 'black', fontSize: '12px' }}
              >
                אישור ההעברה
              </Button>
              <Button
                variant="outlined"
                onClick={() => setCurrentModal(1)}
                sx={{
                  color: 'black',
                  borderColor: 'grey',
                  fontSize: '12px',
                }}
              >
                חזרה להזנת נתונים
              </Button>
            </Stack>
          </DialogActions>
        </Stack>
      )}
      {currentModal === 3 && (
        <Stack>
          <DialogTitle maxWidth="100%">
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              ההעברה בוצעה בהצלחה!
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack direction="row" justifyContent="center">
              <Image src="/assets/illustrations/illustration_order_complete.png" />
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography>תודה על ביצוע ההעברה:</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ my: 3 }}>
              {/* <Grid container spacing={1}> */}
              {/*  <Grid item xs={6}> */}
              {/*    {' '} */}
              {/*    <Typography>מספר ההעברה</Typography> */}
              {/*  </Grid> */}
              {/*  <Grid item xs={6}> */}
              {/*    <Typography sx={{ fontWeight: 800 }}>2222-333-555</Typography> */}
              {/*  </Grid> */}
              {/* </Grid> */}
            </Stack>
            <Grid container spacing={1}>
              {/* {enableCardName && ( */}
              {/*  <> */}
              {/*    <Grid item xs={6}> */}
              {/*      {' '} */}
              {/*      <Typography>איש קשר</Typography> */}
              {/*    </Grid> */}
              {/*    <Grid item xs={6}> */}
              {/*      <Typography sx={{ fontWeight: 800 }}>איגור גוברמן</Typography> */}
              {/*    </Grid> */}
              {/*  </> */}
              {/* )} */}
              {/* {enableCardName && ( */}
              {/*  <> */}
              {/*    <Grid item xs={6}> */}
              {/*      {' '} */}
              {/*      <Typography>סוג העברה</Typography> */}
              {/*    </Grid> */}
              {/*    <Grid item xs={6}> */}
              {/*      <Typography sx={{ fontWeight: 800 }}>העברת כספים</Typography> */}
              {/*    </Grid> */}
              {/*  </> */}
              {/* )} */}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>למספר הטלפון</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>{phone && phone?.number}</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>סכום להעברה</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>₪{transferAmount}</Typography>
                  </Grid>
                </>
              )}
              {enableCardName && (
                <>
                  <Grid item xs={6}>
                    <Typography>העברה מהכרטיס</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 800 }}>{selectedCard?.name}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="space-between" gap={1} width={1}>
              <Button
                variant="contained"
                onClick={() => {
                  onClose();
                  refreshData();
                }}
                sx={{ color: 'white', bgcolor: 'black', fontSize: '12px' }}
              >
                תודה, אפשר לסגור
              </Button>
              <Button
                variant="outlined"
                onClick={() => setCurrentModal(1)}
                sx={{
                  color: 'black',
                  borderColor: 'grey',
                  fontSize: '12px',
                }}
              >
                להעביר עוד
              </Button>
            </Stack>
          </DialogActions>
        </Stack>
      )}
    </>
  );
};
