'use client';

import React, { FC, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import MenuItem from '@mui/material/MenuItem';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Card,
  Stack,
  Button,
  Collapse,
  Checkbox,
  TextField,
  Accordion,
  CardMedia,
  IconButton,
  Typography,
  CardContent,
  CardActions,
  InputAdornment,
  FormControlLabel,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { useRouter } from '../../../../../routes/hooks';
import { RHFRadioGroup } from '../../../../../components/hook-form';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import { useGetAllCustomers } from '../../../../../redux/slices/customers';
import Iconify from '../../../../../components/iconify';

const doctorsArr = [
  {
    img: '/assets/images/specialists/specialists1.jpg',
    firstName: 'דניאל כהן',
    position: 'מנקה',
    link: '/appointment',
    aboutLink: '/aboutspesialist',
  },
  {
    img: '/assets/images/specialists/specialists2.jpg',
    firstName: 'יוני לוי',
    position: 'שיפוצניקית',
    link: '/appointment',
    aboutLink: '/aboutspesialist',
  },
  {
    img: '/assets/images/specialists/specialists3.jpg',
    firstName: 'איתי ערד',
    position: 'שרברב',
    link: '/appointment',
    aboutLink: '/aboutspesialist',
  },
  {
    img: '/assets/images/specialists/specialists4.jpg',
    firstName: 'חיה אזולאי',
    position: 'מנקה',
    link: '/appointment',
    aboutLink: '/aboutspesialist',
  },
  {
    img: '/assets/images/specialists/specialists5.jpg',
    firstName: 'יעל כהן',
    position: 'מנקה',
    link: '/appointment',
    aboutLink: '/aboutspesialist',
  },
  {
    img: '/assets/images/specialists/specialists6.jpg',
    firstName: 'נעם מאיר',
    position: 'מנקה',
    link: '/appointment',
    aboutLink: '/aboutspesialist',
  },
  {
    img: '/assets/images/specialists/specialists7.jpg',
    firstName: 'מאיר ברנר',
    position: 'מאבטח',
    link: '/appointment',
    aboutLink: '/aboutspesialist',
  },
  {
    img: '/assets/images/specialists/specialists8.jpg',
    firstName: 'אבי דוידוף',
    position: 'חשמלאית',
    link: '/appointment',
    aboutLink: '/aboutspesialist',
  },
];

const OurSpecialists: FC<ComponentTypeProps> = ({ block }) => {
  const [showFilter, setShowFilter] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesEndRef1 = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const tags: any = block ? block.tags : [];
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [selectedValue, setSelectedValue] = useState<number>(10);
  const [searchText, setSearchText] = useState('');

  const { customers, count } =
    useGetAllCustomers({ limit: selectedValue, offset: 0, tagsIds: selectedTags, fullName: searchText }) || [];

  const toggleFilter = () => {
    setShowFilter(!showFilter);
    if (showFilter) {
      scrollToTop();
    } else {
      scrollToBottom();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const methods = useForm({
    defaultValues: {
      addressType: '',
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
  };

  const handleToggle = (id: number) => {
    setSelectedTags((prevSelected) => {
      if (prevSelected.includes(id)) {
        // Убираем тег, если он уже выбран
        return prevSelected.filter((tagId) => tagId !== id);
      }
      // Добавляем тег, если он не выбран
      return [...prevSelected, id];
    });
  };

  const numbers = Array.from({ length: Math.ceil(count / 10) }, (_, i) => (i + 1) * 10);

  const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearchText(event.target.value); // Сохраняем текст в состояние
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack>
        <Box className="SearchDiv" ref={messagesEndRef}>
          <Stack direction="row" gap={1} className="Search" display="flex" alignItems="center">
            <TextField
              placeholder="חפשי לפי שם"
              type="search"
              fullWidth
              variant="outlined"
              className="SearchInput"
              value={searchText} // Привязываем значение из состояния
              onChange={handleChange} // Обработчик изменения
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon sx={{ color: 'primary.main' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={() => {
                setShowFilter((prev) => !prev);
              }}
              sx={{
                bgcolor: 'primary.main',
                height: '50px',
                '&:hover': { bgcolor: 'primary.main' },
                borderRadius: '5px',
              }}
            >
              <img src="/assets/images/filter/filterBtn.png" alt="Filter" />
            </Button>
          </Stack>
        </Box>
        <Stack spacing={0.5}>
          {customers &&
            customers?.map((el: any, i: number) => (
              <Card
                key={i}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0.5,
                  mb: 0.5,
                }}
              >
                <CardMedia
                  component="img"
                  height="60"
                  image={el.avatar || '/assets/images/user-default.png'}
                  alt={el.firstName}
                  sx={{ width: 60, mr: 1 }}
                />
                <CardContent sx={{ padding: '4px 0', flex: 1 }}>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{ fontSize: '0.9rem', fontWeight: 500 }}
                  >
                    {el.firstName} {el.lastName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                    {el.position}
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={0.3}>
                    <StarIcon sx={{ fontSize: '1rem' }} />
                    <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
                      4.6 (1435 ביקורות)
                    </Typography>
                  </Stack>
                </CardContent>
                <CardActions sx={{ padding: '0 4px' }}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      borderRadius: '5px',
                      minWidth: 'auto',
                      padding: '2px 4px',
                      fontSize: '0.7rem',
                      color: 'primary.main',
                      bgcolor: 'lightgray',
                      '&:hover': {
                        bgcolor: 'lightgray',
                      },
                    }}
                    onClick={() => router.push('/specialistdetails')}
                  >
                    אודות
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      borderRadius: '5px',
                      minWidth: 'auto',
                      padding: '2px 4px',
                      fontSize: '0.7rem',
                      bgcolor: 'primary.main',
                      '&:hover': {
                        bgcolor: 'primary.main',
                      },
                    }}
                    onClick={() => router.push('/appointment')}
                  >
                    פגישה
                  </Button>
                </CardActions>
              </Card>
            ))}
        </Stack>
      </Stack>

      <Collapse
        in={showFilter}
        sx={{ position: 'fixed', bottom: '70px', background: 'white', width: '90%' }}
      >
        <Box className="filter-wrapper" id="filter_section" sx={{ direction: 'rtl' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            className="filter-title d-flex justify-content-between align-items-center"
          >
            <Typography variant="h4">סינון</Typography>
            <Button onClick={() => setShowFilter(false)} sx={{ color: 'grey' }}>
              איפוס
            </Button>
          </Stack>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">סדר לפי</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack sx={{ direction: 'ltr' }}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <RHFRadioGroup
                      name="addressType"
                      options={[
                        { label: 'ציון', value: '1' },
                        { label: 'אקראי', value: '2' },
                      ]}
                    />
                  </form>
                </FormProvider>
              </Stack>
              <TextField
                select
                label="בחרו את מספר המומחים להצגה"
                value={selectedValue}
                onChange={(e) => setSelectedValue(Number(e.target.value))}
                fullWidth
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Ограничиваем высоту выпадающего списка
                        overflowY: 'auto', // Добавляем прокрутку
                      },
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selectedValue !== 10 && (
                        <Iconify icon="mingcute:close-fill" onClick={() => setSelectedValue(10)} />
                      )}
                    </InputAdornment>
                  ),
                }}
                sx={{ mt: 3 }}
              >
                {numbers.map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </TextField>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">מומחיות</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                sx={{
                  direction: 'ltr',
                  '& .MuiFormControlLabel-root': {
                    justifyContent: 'space-between',
                  },
                }}
              >
                <FormControlLabel control={<Checkbox />} label="תרפיה" />
                <FormControlLabel control={<Checkbox />} label="קרדולוגיה" />
                <FormControlLabel control={<Checkbox />} label="אנדוקרינולוגיה" />
                <FormControlLabel control={<Checkbox />} label="מנתח" />
                <FormControlLabel control={<Checkbox />} label="גינקולוג" />
                <FormControlLabel control={<Checkbox />} label="נוירולוג" />
                <FormControlLabel control={<Checkbox />} label="דֶרמָטוֹלוֹגִיָה" />
                <FormControlLabel control={<Checkbox />} label="אונוקולוגיה" />
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">אזור</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                sx={{
                  p: 1,
                  direction: 'ltr',
                  maxHeight: '300px', // Ограничиваем высоту контейнера
                  overflowY: 'auto', // Добавляем вертикальную прокрутку
                  '& .MuiFormControlLabel-root': {
                    justifyContent: 'space-between',
                  },
                  // Стили для прокрутки
                  '&::-webkit-scrollbar': {
                    width: '8px', // Ширина скроллбара
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#bdbdbd', // Цвет ползунка
                    borderRadius: '4px', // Закругление ползунка
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#9e9e9e', // Цвет ползунка при наведении
                  },
                }}
              >
                {tags.map((item: any) => (
                  <FormControlLabel
                    key={item.id}
                    control={
                      <Checkbox
                        checked={selectedTags.includes(item.id)}
                        onChange={() => handleToggle(item.id)}
                      />
                    }
                    label={item.name}
                  />
                ))}
                {/* <FormControlLabel control={<Checkbox />} label="צפון רחוק" /> */}
                {/* <FormControlLabel control={<Checkbox />} label="חיפה והצפון" /> */}
                {/* <FormControlLabel control={<Checkbox />} label="צפון השרון" /> */}
                {/* <FormControlLabel control={<Checkbox />} label="שרון" /> */}
                {/* <FormControlLabel control={<Checkbox />} label="דרום השרון" /> */}
                {/* <FormControlLabel control={<Checkbox />} label="מרכז" /> */}
                {/* <FormControlLabel control={<Checkbox />} label="שפלה" /> */}
                {/* <FormControlLabel control={<Checkbox />} label="תל אביב" /> */}
                {/* <FormControlLabel */}
                {/*  control={<Checkbox />} */}
                {/*  label="באר שבע והסביבה" */}
                {/* /> */}
                {/* <FormControlLabel control={<Checkbox />} label="דרום רחוק" /> */}
                {/* <FormControlLabel */}
                {/*  control={<Checkbox />} */}
                {/*  label="ירושלים והסביבה" */}
                {/* /> */}
                {/* <FormControlLabel control={<Checkbox />} label="איו''ש" /> */}
                {/* <FormControlLabel */}
                {/*  control={<Checkbox />} */}
                {/*  label="דֶרמָטוֹלוֹגִיָה" */}
                {/* /> */}
                {/* <FormControlLabel control={<Checkbox />} label="אונוקולוגיה" /> */}
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Box
            className="filter-action"
            ref={messagesEndRef1}
            display="flex"
            justifyContent="center"
            py={4}
          >
            <Button variant="contained" color="primary">
              החל
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default OurSpecialists;
