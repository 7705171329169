import { _mock } from './_mock';
import { randomInArray } from '../utils/random';

export const walletProductList = [
  {
    id: '1',
    title: 'Galilean Mansion',
    brand: '/assets/images/happy-gift/wallet/products/brands/1.png',
    image: '/assets/images/happy-gift/wallet/products/1.png',
    type: 'home',
    category: 'home',
    area: 'home',
  },
  {
    id: '2',
    title: 'Villa Galile',
    brand: '/assets/images/happy-gift/wallet/products/brands/2.png',
    image: '/assets/images/happy-gift/wallet/products/2.png',
    type: 'spa',
    category: 'spa',
    area: 'spa',
  },
  {
    id: '3',
    title: 'Holiday Farm-Vered Hagalil',
    brand: '/assets/images/happy-gift/wallet/products/brands/3.png',
    image: '/assets/images/happy-gift/wallet/products/3.png',
    type: 'spa',
    category: 'spa',
    area: 'spa',
  },
  {
    id: '4',
    title: 'Holiday Farm-Vered Hagalil',
    brand: '/assets/images/happy-gift/wallet/products/brands/4.png',
    image: '/assets/images/happy-gift/wallet/products/4.png',
    type: 'home',
    category: 'home',
    area: 'home',
  },
  {
    id: '5',
    title: 'Papa John’s',
    brand: '/assets/images/happy-gift/wallet/products/brands/5.png',
    image: '/assets/images/happy-gift/wallet/products/5.png',
    type: 'spa',
    category: 'spa',
    area: 'spa',
  },
  {
    id: '6',
    title: 'Pizza Hut',
    brand: '/assets/images/happy-gift/wallet/products/brands/6.png',
    image: '/assets/images/happy-gift/wallet/products/6.png',
    type: 'spa',
    category: 'spa',
    area: 'spa',
  },
  {
    id: '7',
    title: 'McDonald`s',
    brand: '/assets/images/happy-gift/wallet/products/brands/7.png',
    image: '/assets/images/happy-gift/wallet/products/7.png',
    type: 'home',
    category: 'home',
    area: 'home',
  },
  {
    id: '8',
    title: 'CaféCafé',
    brand: '/assets/images/happy-gift/wallet/products/brands/8.png',
    image: '/assets/images/happy-gift/wallet/products/8.png',
    type: 'spa',
    category: 'spa',
    area: 'spa',
  },
  {
    id: '9',
    title: 'MAX BRENNER',
    brand: '/assets/images/happy-gift/wallet/products/brands/10.png',
    image: '/assets/images/happy-gift/wallet/products/9.png',
    type: 'spa',
    category: 'spa',
    area: 'spa',
  },
  {
    id: '10',
    title: 'JAPAN JAPAN',
    brand: '/assets/images/happy-gift/wallet/products/brands/9.png',
    image: '/assets/images/happy-gift/wallet/products/10.png',
    type: 'home',
    category: 'home',
    area: 'home',
  },
  {
    id: '11',
    title: 'ShareSpa',
    brand: '/assets/images/happy-gift/wallet/products/brands/11.png',
    image: '/assets/images/happy-gift/wallet/products/11.png',
    type: 'home',
    category: 'home',
    area: 'home',
  },
  {
    id: '12',
    title: 'HoLMES PLACE',
    brand: '/assets/images/happy-gift/wallet/products/brands/11.png',
    image: '/assets/images/happy-gift/wallet/products/11.png',
    type: 'spa',
    category: 'spa',
    area: 'spa',
  },
];

export const _recent_operations = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  code: _mock.number.nativeL(index),
  date: _mock.time(index),
  employee: randomInArray(['Riley Landry', 'Fatma Dyer']),
}));

export const WALLET_STATUS_OPTIONS = [
  { value: 'used', label: 'Used' },
  { value: 'notUsed', label: 'Not used' },
];
