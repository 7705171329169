'use client';

import { useForm } from 'react-hook-form';
import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { fData } from 'src/utils/format-number';

import { useAuthContext } from 'src/auth/hooks';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSwitch, RHFTextField, RHFAutocomplete, RHFUploadAvatar } from 'src/components/hook-form';

import { useTranslate } from '../../../../locales';
import { getAllUsers } from '../../../../api/user';
import Iconify from '../../../../components/iconify';
import { IMerchant } from '../../../../types/merchant';
import { BlockType } from '../../../../types/generator';
import { IUserSocialLink } from '../../../../types/user';
import { useDebounce } from '../../../../hooks/use-debounce';
import { IAttributeValue } from '../../../../types/attribute';
import { useAppContext } from '../../../../contexts/AppContext';
import { getCustomerByPhoneOrMail } from '../../../../api/customers';
import { ICustomerItem } from '../../../../types/customer-citypeople';

type Props = {
  socialLinks: IUserSocialLink;
  currentMerchant: IMerchant;
  block: BlockType | undefined;
  selectedMerchantId: number | null;
  setSelectedMerchantId: Function;
};

export default function AccountGeneral({
                                         socialLinks,
                                         currentMerchant,
                                         block,
                                         selectedMerchantId,
                                         setSelectedMerchantId,
                                       }: Props) {
  const { dispatch, state } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const { users } = state;
  const { networks } = state;
  const { categories } = state;

  const attributes = currentMerchant?.attributes;
  const attributeValues = currentMerchant?.attributeValues;

  const groupedAttributes = attributes && attributes?.map(attribute => {
    const relatedValues = attributeValues && attributeValues?.filter(
      value => value.attributeId === attribute.id,
    );

    return {
      ...attribute,
      values: relatedValues,
    };
  });

  const { t } = useTranslate();
  const { user }: any = useAuthContext();
  const [categorySearch, setCategorySearch] = useState('');
  const [attributesKosher, setAttributesKosher] = useState<IAttributeValue[]>([]);
  const [attributesTypeOfAgreement, setAttributesTypeOfAgreement] = useState<IAttributeValue[]>([]);
  const categorySearchDebounce = useDebounce(categorySearch, 500);
  const [customer, setCustomer] = useState<ICustomerItem[] | any>([]);
  // const UpdateUserSchema = Yup.object().shape({
  //   displayName: Yup.string().required('Name is required'),
  //   email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  //   photoURL: Yup.mixed<any>().nullable().required('Avatar is required'),
  //   phoneNumber: Yup.string().required('Phone number is required'),
  //   country: Yup.string().required('Country is required'),
  //   address: Yup.string().required('Address is required'),
  //   state: Yup.string().required('State is required'),
  //   city: Yup.string().required('City is required'),
  //   zipCode: Yup.string().required('Zip code is required'),
  //   about: Yup.string().required('About is required'),
  //   // not required
  //   isPublic: Yup.boolean(),
  // });
  const defaultValues = useMemo(() => ({
    title: currentMerchant?.title || '',
    companyNumber: currentMerchant?.companyNumber || '',
    active: currentMerchant?.active || true,
    showOnBuilder: currentMerchant?.showOnBuilder || false,
    discount: currentMerchant?.discount || '',
    mainImage: currentMerchant?.mainImageURL || '',
    country: currentMerchant?.country || '',
    city: currentMerchant?.city || '',
    photoOfId: currentMerchant?.photoOfId || '',
    url: currentMerchant?.url || '',
    street: currentMerchant?.street || '',
    house: currentMerchant?.house || '',
    phone: currentMerchant?.phone || '',
    facebook: currentMerchant?.facebook || '',
    instagram: currentMerchant?.instagram || '',
    linkedIn: currentMerchant?.linkedIn || '',
    tags: currentMerchant?.tags || null || [],
    description: currentMerchant?.description || '',
    networkId: '',
    franchiser: currentMerchant?.franchiser || '',
    kosherType: '',
    banner: currentMerchant?.banner || '',
    categoryIds: currentMerchant?.categories?.map((c) => c) || [],
    assigneeIds: [],
    assigneeEmployeeIds: [],
    asterisks: currentMerchant?.asterisks || null,
    projectsDiscount: [],
    bankName: currentMerchant?.bankName || '',
    bankAccountNumber: currentMerchant?.bankAccountNumber || '',
    bankDepartment: currentMerchant?.bankDepartment || '',
    typeOfAgreement: '',
  }), [currentMerchant]);

  const methods = useForm({
    // resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
  } = methods;

  const userCache = useRef<Record<string, { id: string; name: string } | any>>({});

  useEffect(() => {
    if (currentMerchant) {
      reset(defaultValues);

      const allIds = [
        ...(currentMerchant?.assigneeIds || []),
        ...(currentMerchant?.assigneeEmployeeIds || []),
      ];

      const uncachedIds = allIds.filter((id) => !userCache.current[id]);

      if (uncachedIds.length > 0) {
        getAllUsers(dispatch, uncachedIds).then((response) => {
          response.forEach((u: any) => {
            userCache.current[user?.id] = {
              id: u.id,
              name: `${u.firstName || ''} ${u.lastName || ''}`.trim(),
            };
          });

          const assignedUsers: any = currentMerchant.assigneeIds
            ?.map((id) => userCache.current[id])
            .filter(Boolean);

          const assignedEmployeeUsers: any = currentMerchant.assigneeEmployeeIds
            ?.map((id) => userCache.current[id])
            .filter(Boolean);
          setValue('assigneeIds', assignedUsers || []);
          // @ts-ignore
          setValue('assigneeEmployeeIds', assignedEmployeeUsers || []);
        });
      } else {
        const assignedUsers: any = currentMerchant.assigneeIds
          ?.map((id) => userCache.current[id])
          .filter(Boolean);

        const assignedEmployeeUsers: any = currentMerchant.assigneeEmployeeIds
          ?.map((id) => userCache.current[id])
          .filter(Boolean);
        // @ts-ignore
        setValue('assigneeIds', assignedUsers || []);
        setValue('assigneeEmployeeIds', assignedEmployeeUsers || []);
      }
    }
  }, [currentMerchant]);

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    // const file = acceptedFiles[0];
    // const newFile = Object.assign(file, {
    //   preview: URL.createObjectURL(file),
    // });
    // if (file) {
    //   setValue('photoURL', newFile, { shouldValidate: true });
    // }
  }, []);

  useEffect(() => {
    if (user) {
      getCustomerByPhoneOrMail(user.phone, user.email, dispatch).then((response: any) => {
        if (response) {
          setCustomer(response[0]);
        }
      });
    }
  }, [user]);

  const handleCategorySearchChange = (e: React.SyntheticEvent<Element, Event>, value: string) => {
    setCategorySearch(value);
  };


  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: 'center' }}>
            <RHFUploadAvatar
              name="photoURL"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  {`${t('Allowed *.jpeg, *.jpg, *.png, *.gif')}`}
                  <br /> max size of {fData(3145728)}
                </Typography>
              }
            />

            <RHFSwitch
              name="isPublic"
              labelPlacement="start"
              label={`${t('Public Profile')}`}
              sx={{ mt: 5 }}
            />
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="title" label={`*${t('Title')}`} />
              <RHFTextField name="phone" label={`*${t('Phone Number')}`} />
              {/* <RHFTextField name="area" label={`${t('Area')}`} /> */}
            </Box>
            <Box sx={{ my: 2 }}>
              <RHFTextField
                sx={{ width: { sm: '100%', md: '49%' } }}
                name="email"
                label={`*${t('E-mail Address')}`}
              />
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="city" label={`${t('Town / City')}`} />

              <RHFTextField name="country" label={`${t('Country')}`} />

              <RHFTextField name="street" label={`${t('Street')}`} />

              <RHFTextField name="home" label={`${t('Home')}`} />

              <RHFAutocomplete
                name="assigneeIds"
                label={t('Assignee Agent')}
                multiple
                options={users.map((u) => ({ id: u.id, name: `${u.firstName} ${u.lastName}` }))}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  return option?.name;
                }}
                renderOption={(props, option) => (
                  <li {...props} key={typeof option === 'string' ? option : option.id}>
                    {typeof option === 'string' ? option : option.name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={typeof option === 'string' ? option : option.id}
                      label={typeof option === 'string' ? option : option.name}
                      size="small"
                      color="info"
                      variant="soft"
                    />
                  ))
                }
              />

              <RHFAutocomplete
                name="assigneeEmployeeIds"
                label={t('Assignee Employee')}
                multiple
                options={users.map((u) => ({ id: u.id, name: `${u.firstName} ${u.lastName}` }))}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  return option?.name;
                }}
                renderOption={(props, option) => (
                  <li {...props} key={typeof option === 'string' ? option : option.id}>
                    {typeof option === 'string' ? option : option.name}
                  </li>
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={typeof option === 'string' ? option : option.id}
                      label={typeof option === 'string' ? option : option.name}
                      size="small"
                      color="info"
                      variant="soft"
                    />
                  ))
                }
              />

              <RHFAutocomplete
                name="networkId"
                label={t('Network')}
                options={networks}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  return option?.name;
                }}
                renderOption={(props, network) => (
                  <li {...props} key={network.id}>
                    {network?.name}
                  </li>
                )}
              />
              <RHFAutocomplete
                name="categoryIds"
                label={t('Category')}
                placeholder={t('+ Category')}
                multiple
                onInputChange={handleCategorySearchChange}
                options={categories.map((option) => option)}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  return option?.title;
                }}
                renderOption={(props, option) => (
                  <li {...props} key={typeof option === 'string' ? option : option.id}>
                    {typeof option === 'string' ? option : option.title}
                  </li>
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={typeof option === 'string' ? option : option.id}
                      label={typeof option === 'string' ? option : option.title}
                      size="small"
                      color="info"
                      variant="soft"
                    />
                  ))
                }
              />
              <RHFAutocomplete
                name="kosherType"
                label={t('Kosher Type')}
                options={attributesKosher}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  return option?.value;
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, kosher) => (
                  <li {...props} key={kosher.id}>
                    {kosher?.value}
                  </li>
                )}
              />
              <RHFAutocomplete
                name="typeOfAgreement"
                label={t('Type of agreement')}
                options={attributesTypeOfAgreement}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  return option?.value;
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, type) => (
                  <li {...props} key={type.id}>
                    {type.value}
                  </li>
                )}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <RHFTextField rows={4} multiline name="more" label={`${t('Description')}`} />
            </Box>


            <Stack spacing={3} alignItems="flex-start" sx={{ mt: 3 }}>
              {Object.keys(socialLinks).map((link) => (
                <RHFTextField
                  key={link}
                  name={link}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          width={24}
                          icon={
                            (link === 'facebook' && 'eva:facebook-fill') ||
                            (link === 'instagram' && 'ant-design:instagram-filled') ||
                            (link === 'linkedin' && 'eva:linkedin-fill') ||
                            (link === 'twitter' && 'eva:twitter-fill') ||
                            ''
                          }
                          color={
                            (link === 'facebook' && '#1877F2') ||
                            (link === 'instagram' && '#DF3E30') ||
                            (link === 'linkedin' && '#006097') ||
                            (link === 'twitter' && '#1C9CEA') ||
                            ''
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              ))}

              {block?.settings?.enableAttributes && (
                <Stack width={1} justifyContent="center">
                  {groupedAttributes &&
                    groupedAttributes.map((attribute: any) => (
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{
                          maxWidth: '100%',
                          overflowX: 'auto',
                          whiteSpace: 'nowrap',
                          scrollbarWidth: 'thin',
                          '&::-webkit-scrollbar': {
                            height: '8px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '4px',
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555',
                          },
                          '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',
                          },
                        }}
                      >
                        <Typography sx={{ fontSize: '20px', fontWeight: 1000 }}>
                          {`${attribute.name}:`}
                        </Typography>
                        {attribute?.values?.map((value: any) => (
                          <Typography>{`${value.value},`}</Typography>
                        ))}
                      </Stack>
                    ))}
                </Stack>
              )}

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {`${t('Save Changes')}`}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
