'use client';

import { useMemo, useEffect, useReducer, useCallback, createContext } from 'react';

// utils
import axios from 'src/utils/axios';

import { API } from 'src/helpers/api';

import { BOT_ID } from '../../../config-global';
//
import { setSession, isValidToken, checkSession } from './utils';
import { AuthUserType, ActionMapType, AuthStateType, JWTContextType } from './types';

export enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  UPDATE = 'UPDATE',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    customer: AuthUserType;
  };
  [Types.LOGIN]: {
    customer: AuthUserType;
  };
  [Types.REGISTER]: {
    customer: AuthUserType;
  };
  [Types.UPDATE]: {
    customer: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.customer,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.customer,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.customer,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === Types.UPDATE) {
    return {
      ...state,
      user: action.payload.customer,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const accessTokenAccess = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

      if (accessToken &&  isValidToken(accessToken)) {
        setSession(accessToken);

        const { data, status } = await API({
          url: 'sso/user/me',
          method: 'GET',
        });

        checkSession(status);

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            customer: data.user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            customer: null,
          },
        });
        localStorage.setItem('accessToken', '');
      }
      if (accessToken === '' &&  isValidToken(accessToken)) {
        if (typeof window !== 'undefined') {
          window.location.href = '/';
        }
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          customer: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize, accessTokenAccess]);

  // LOGIN BY EMAIL
  const loginByEmail = useCallback(async (email: string, password: string) => {
    const response = await API({
      url: `sso/login`,
      method: 'POST',
      data: {
        emailOrPhone: email,
        password,
      },
    });

    const token = response.data?.token || '';
    const customer = response.data?.user || null;

    setSession(token);

    dispatch({
      type: Types.LOGIN,
      payload: { customer },
    });
  }, []);

  // LOGIN BY PASSPORT
  const loginByPassport = useCallback(async (passport: string, password: string) => {
    const response = await API({
      url: `sso/loginByPassport`,
      method: 'POST',
      data: { passport, password },
    });

    const token = response.data?.token || '';
    const customer = response.data?.user || null;

    setSession(token);

    dispatch({
      type: Types.LOGIN,
      payload: { customer },
    });
  }, []);

  // LOGIN
  const login = useCallback(async (phone: string, activityId?: string) => {
    const testMode = process.env.NEXT_PUBLIC_TEST_MODE ? process.env.NEXT_PUBLIC_TEST_MODE : false;
    console.log('testMode', testMode);
    if (testMode) {
      await API({
        url: `sso/otp/login`,
        method: 'POST',
        data: {
          phone: process.env.NEXT_PUBLIC_NUMBER,
          botId: process.env.NEXT_PUBLIC_BOT_ID,
          test: true,
          activityId,
        },
      });
    } else {
      await API({
        url: `sso/otp/login`,
        method: 'POST',
        data: {
          phone,
          botId: process.env.NEXT_PUBLIC_BOT_ID || 0,
          activityId,
        },
      });
    }
  }, []);

  // CHECK CODE
  const checkCode = useCallback(async (phone: string, code: string, expiration?: string) => {
    const testMode = process.env.NEXT_PUBLIC_TEST_MODE ? process.env.NEXT_PUBLIC_TEST_MODE : false;
    let response = null;

    if (testMode) {
      response = await API({
        url: `sso/otp/verify`,
        method: 'POST',
        data: {
          phone: process.env.NEXT_PUBLIC_NUMBER,
          // phone,
          otp: code,
          exp: expiration,
        },
      });
    } else {
      response = await API({
        url: `sso/otp/verify`,
        method: 'POST',
        data: {
          phone,
          otp: code,
          exp: expiration,
        },
      });
    }

    setSession(response.data.token);

    localStorage.setItem('phone', phone);

    const { data } = await API({
      url: 'sso/user/me',
      method: 'GET',
    });

    dispatch({
      type: Types.INITIAL,
      payload: {
        isAuthenticated: true,
        customer: data.user,
      },
    });
    return data;
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      BotId: string | number,
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      phone: string,
      activityId: string,
    ) => {
      try {
        // Регистрация
        const registerResponse = await axios.post('/sso/register', {
          email,
          password,
          firstName,
          lastName,
          phone: phone || '',
          botId: BOT_ID,
          isClient: false,
          activityId
        });

        if (!registerResponse.data.success) {
          throw new Error('Registration failed');
        }

        // Логин
        // const loginResponse = await axios.post('/sso/login', {
        //   emailOrPhone: email,
        //   password,
        // });

        // const { token, user, accessToken, customer } = loginResponse.data;
        // console.log('loginResponse', loginResponse);
        // localStorage.setItem('accessToken', accessToken || token);
        // setSession(token);

        // const finalUser = customer
        //   ? { ...customer, type: 'customer' }
        //   : { ...user, type: 'user' };
        // console.log('finalUser', finalUser);
        // dispatch({
        //   type: Types.REGISTER,
        //   payload: {
        //     customer: finalUser,
        //   },
        // });
      } catch (error) {
        console.error('Registration or login error:', error);
        throw error;
      }
    },
    []
  );


  // UPDATE USER
  const updateUser = useCallback(async (newData: Record<string, any>, onlyFront?: boolean) => {
    if (onlyFront) {
      dispatch({
        type: Types.UPDATE,
        payload: {
          customer: newData,
        },
      });
    } else {
      const response = await API({
        url: 'auth/update-account',
        method: 'POST',
        data: newData,
      });
      const { customer } = response.data;
      dispatch({
        type: Types.UPDATE,
        payload: {
          customer,
        },
      });
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    // Очищаем сессию
    setSession(null);

    // Уведомляем приложение о выходе пользователя
    dispatch({
      type: Types.LOGOUT,
    });

    // Очищаем данные в localStorage
    localStorage.setItem('accessToken', '');
    localStorage.setItem('logoImage', '');

    // Перенаправляем на главную страницу
    if (typeof window !== 'undefined') {
      window.location.href = '/';
    }
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    const data = { email, botId: +BOT_ID };
    const result = await axios.post('sso/forgot', data);

    return result;
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      loginByEmail,
      loginByPassport,
      updateUser,
      loginWithGoogle: () => {
      },
      loginWithGithub: () => {
      },
      loginWithTwitter: () => {
      },
      register,
      logout,
      checkCode,
      forgotPassword,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      loginByEmail,
      loginByPassport,
      register,
      updateUser,
      checkCode,
      forgotPassword,
    ],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
