import { API } from '../helpers/api';
import { ActionType, ActionTypes } from '../contexts/AppContext';

export const getCustomerByPhoneOrMail = async (
  userPhone: string,
  userEmail: string,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    // Определяем параметры для первого запроса
    const params = userPhone
      ? { phone: userPhone }
      : { email: userEmail };

    // Выполняем первый запрос
    const { data } = await API({
      url: `customer/findCustomers`,
      method: 'GET',
      params,
    });

    // Если данных нет, пробуем локальный формат телефона
    if (!data?.length && userPhone) {
      const localPhone = `0${userPhone.slice(4)}`; // Преобразуем телефон в локальный формат
      const localParams = { phone: localPhone };

      const response = await API({
        url: `customer/findCustomers`,
        method: 'GET',
        params: localParams,
      });

      return response?.data || null;
    }

    return data || null;
  } catch (e: any) {
    // Обрабатываем ошибки и передаем сообщение через dispatch
    if (dispatch) {
      dispatch({
        type: ActionTypes.ERROR_MESSAGE,
        payload: e.message || 'An error occurred',
      });
    }
    console.error(e);
    return null;
  }
};

export const getAllCustomers = async () => {
  try {
    const { data } = await API({
      url: `/admin/customer/getAll`,
      method: 'GET',
    });
    console.log('setAllCustomersdata', data);
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchOneCustomer = async (id: string) => {
  try {
    const { data } = await API({
      url: `/admin/customer/getById`,
      method: 'GET',
      params: {
        id,
        relations: ['family', 'children', 'merchants'],
      },
    });
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
