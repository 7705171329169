'use client';

import React, { FC, useState } from 'react';

import { Box, Button, Container, Typography } from '@mui/material';

import ProductsSquareList from './all-services';
import BestDealsSquareList from './best-deals-squre-list';
// eslint-disable-next-line import/no-cycle
import { ComponentTypeProps } from '../../../../../types/page-generator';
import { useGetProductsTags } from '../../../../../api/product';
import { useGetProducts } from '../../../../../api/shop';
import { useAppContext } from '../../../../../contexts/AppContext';


const MarketPlace: FC<ComponentTypeProps> = ({ block }) => {
  const { state, dispatch } = useAppContext();
  const [activeTab, setActiveTab] = useState(0);
  const [activeId, setActiveId] = useState(0);
  const {searchProduct} = state;
  const { products } = useGetProducts(
    activeId !== 0 ? { tagsIds: [activeId], search: searchProduct } : {search: searchProduct}
  );
  const tabsBtnsArr = [
    { text: 'הכל' },
    { text: 'נקיון' },
    { text: 'חשמל' },
    { text: 'שיפוצים' },
    { text: 'אינסטלציה' },
  ];

  const handleTabChange = (newValue: number, value: number) => {
    setActiveTab(newValue);
    setActiveId(value);
  };

  const { productsTags } = useGetProductsTags({});

  return (
    <Container maxWidth="xs">
      <Box className="page">
        {/* Tabs for categories */}
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto', // добавляем горизонтальную прокрутку
            whiteSpace: 'nowrap', // не переносить кнопки на следующую строку
            '&::-webkit-scrollbar': { display: 'none' }, // скрываем скроллбар для вебкита (Safari, Chrome)
            '-ms-overflow-style': 'none', // скрываем скроллбар для IE и Edge
            'scrollbar-width': 'none', // скрываем скроллбар для Firefox
          }}
        >
          {[{name: 'all', id: 0}].concat(productsTags).map((el: any, i: number) => (
            <Button
              key={i}
              onClick={() => handleTabChange(i,el.id)}
              variant={activeTab === i ? 'contained' : 'text'}
              sx={{
                borderRadius: '5px',
                fontWeight: activeTab === i ? 'bold' : 'normal',
                backgroundColor: activeTab === i ? 'primary.main' : 'transparent',
                color: activeTab === i ? 'white' : 'primary.main',
                marginRight: 1,
                '&:hover': {
                  backgroundColor: activeTab === i ? 'primary.main' : 'transparent',
                },
              }}
            >
              {el.name}
            </Button>
          ))}
        </Box>


        {/* Display content based on selected tab */}
        <Box id="tab1" className="tab-content">
          <ProductsSquareList products={products}/>
        </Box>

        {/* Popular services section */}
        <Typography sx={{ marginBottom: 2 }}>שירותים פופלרים</Typography>
        <Box id="tabs-content">
          <Box id="tab1" className="tab-content" >
            <BestDealsSquareList />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MarketPlace;
