'use client';

import NextLink from 'next/link';
import { useParams } from 'next/navigation';
import { useState, useEffect, useCallback, Key, useMemo } from 'react';

import Box from '@mui/material/Box';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useTranslate } from 'src/locales';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

// components
import Iconify from 'src/components/iconify';

import { ButtonField } from '../../types/generator';
import { BlockType } from '../../types/page-generator';
import { useResponsive } from '../../hooks/use-responsive';
import { Ib2bTableFilter, Ib2bTableFilterValue } from '../../types/b2b';
import { ICategoryItem } from '../../types/product';
import { getContentValueFromProps } from '../generate-page/utils';

type attribute = {
  attributeId: string;
  createdAt: string;
  id: string;
  value: string;
};

type Props = {
  block: BlockType | undefined;
  filters: Ib2bTableFilter | any;
  id?: string;
  canReset: boolean;
  onFilters: (name: string, value: Ib2bTableFilterValue) => void;
  handleResetCategory: Function;
  handleResetType: Function;
  handleResetArea: Function;
  typeOption: attribute[] | any;
  areaOption?: attribute[] | any;
  categoryOption?: ICategoryItem[];
  additionalFilters?: any;
  handleResetAdditionalFilters?: Function;
};

const MAX_SLIDER_VALUE = 30000000;

export default function ProductFilters({
  filters,
  id,
  block,
  canReset,
  onFilters,
  handleResetCategory,
  handleResetType,
  handleResetArea,
  typeOption,
  areaOption,
  categoryOption,
  additionalFilters,
  handleResetAdditionalFilters,
}: Props) {
  const { t } = useTranslate();

  const { dispatch, state } = useAppContext();
  const { title } = useParams();
  const smUp = useResponsive('up', 'sm');
  const isMobile = useResponsive('down', 'sm');
  const getFieldValue = getContentValueFromProps(block);
  const enableButtons = useMemo(() => getFieldValue('settings.enableButtons'), [block]);
  const enablePriceFilter = useMemo(() => getFieldValue('settings.enablePriceFilter'), [block]);
  const enableArea = useMemo(() => getFieldValue('settings.enableArea'), [block]);
  const enableCategory = useMemo(() => getFieldValue('settings.enableCategory'), [block]);
  const enableType = useMemo(() => getFieldValue('settings.enableType'), [block]);
  const enableSearch = useMemo(() => getFieldValue('settings.enableSearch'), [block]);
  const isAlternativeDesign = useMemo(() => getFieldValue('settings.isAlternativeDesign'), [block]);
  const actionButtons = useMemo(() => getFieldValue('actionButtons'), [block]);

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const handleResetFilters = useCallback(() => {
    if (title === 'category') {
      onFilters('name', '');
      onFilters('price', [0, 30000000]);
    } else {
      onFilters('name', '');
      onFilters('price', [0, 30000000]);
      handleResetCategory();
      handleResetType();
      handleResetArea();
      if (Array.isArray(additionalFilters) && handleResetAdditionalFilters) {
        additionalFilters.map((item: any) => handleResetAdditionalFilters(item.name));
      }
    }
  }, []);

  const handleChange = (event: Event, newValue: number | number[]) => {
    handleFilterPrice(event);
  };

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterPrice = useCallback(
    (event: any) => {
      onFilters('price', event.target.value);
    },
    [onFilters]
  );

  const handleFilterCategory = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'category',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterType = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      console.log('event', event);
      onFilters(
        'type',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterArea = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      console.log('event', event);
      onFilters(
        'area',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterChange = useCallback(
    (filterName: string) => (event: SelectChangeEvent<string[]>) => {
      onFilters(
        filterName,
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const calcIsResetFilter = () => {
    const isNotEmpty = title === 'category' ? canReset || filters.name : canReset;
    return isNotEmpty;
  };

  return (
    <Box sx={{ direction: 'rtl' }}>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
          mb: 3,
        }}
      >
        <IconButton onClick={() => handleResetFilters()} id="1212121212121212">
          <Iconify
            icon="basil:trash-solid"
            color={!calcIsResetFilter() ? 'grey.500' : 'secondary.main'}
          />
        </IconButton>
        {enableButtons && !!actionButtons.length && (
          <Stack
            alignItems="center"
            direction={{ xs: 'row-reverse', md: 'row-reverse' }}
            spacing={2}
            sx={{ minWidth: '100px' }}
            width={isMobile ? '100%' : '50%'}
          >
            {actionButtons &&
              actionButtons?.map((menuButton: ButtonField, idx: number) => (
                <Button
                  fullWidth={isMobile}
                  component={menuButton?.link.includes('modal') ? Button : NextLink}
                  variant={menuButton?.variant || 'contained'}
                  color={menuButton?.color || 'primary'}
                  size={menuButton?.size || 'small'}
                  key={`menuButton_${idx}`}
                  sx={{
                    // height: !smUp ? '40px' : '',
                    // fontSize: !smUp ? '16px' : '',
                    // px: !smUp ? '6px' : '',
                    // p: '2px',
                    borderRadius: menuButton?.buttonBorderRadius || '10px',
                    alignItems: { md: 'stretch', xs: 'center' },
                  }}
                  href={
                    (!menuButton?.link.includes('modal') &&
                      !menuButton.isDialogToggler &&
                      menuButton?.link) ||
                    ''
                  }
                  onClick={() => {
                    if (menuButton.isDialogToggler && menuButton.modalId) {
                      setActiveDialog(menuButton.modalId);
                    }
                  }}
                  endIcon={<Iconify sx={{ mx: 0.5 }} icon={menuButton?.icon} />}
                >
                  {menuButton.label || `[${t('no label')}]`}
                </Button>
              ))}
          </Stack>
        )}
        {enablePriceFilter && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 150 },
            }}
          >
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ '& .MuiTypography-root': { color: 'primary.main' } }}
                alignItems='center'
              >
                <Typography sx={{ fontWeight: 800, fontSize: '12px' }}>0 - {MAX_SLIDER_VALUE} {block?.settings?.currencyToDisplay || '₪'}</Typography>
                <Typography>{t('budget')}</Typography>
              </Stack>
              <Slider
                value={[filters.price[0], filters.price[1]]}
                min={0}
                max={MAX_SLIDER_VALUE}
                onChange={handleChange}
                valueLabelDisplay="auto"
              />
            </Stack>
          </FormControl>
        )}
        {enableArea && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 150 },
            }}
          >
            <InputLabel>{t('area')}</InputLabel>

            <Select
              multiple
              value={filters.area}
              onChange={handleFilterArea}
              input={<OutlinedInput label="אזור" />}
              renderValue={(selected) => selected.map((value) => value).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1}>
                <Iconify
                  onClick={() => handleResetArea()}
                  icon="tabler:trash"
                  sx={{
                    cursor: 'pointer',
                    color: filters?.area?.length === 0 ? 'gray' : 'red',
                  }}
                />
                <Typography>נבחר</Typography>
                <Typography>{filters?.area?.length}</Typography>
              </Stack>
              {areaOption &&
                areaOption.map((option: any, ind: Key | null | undefined) => (
                  <MenuItem key={ind} value={option.value}>
                    <Checkbox
                      disableRipple
                      size="small"
                      checked={filters?.area?.includes(option.value)}
                    />
                    {option.value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {title !== 'category' && (
          <>
            {enableCategory && (
              <>
                {!block?.settings?.enableCategoryFilter && (
                  <FormControl
                    sx={{
                      flexShrink: 0,
                      width: { xs: 1, md: 150 },
                    }}
                  >
                    <InputLabel>{t('category')}</InputLabel>

                    <Select
                      multiple
                      value={filters.category}
                      onChange={handleFilterCategory}
                      input={<OutlinedInput label="קטגוריה" />}
                      renderValue={(selected) => selected.map((value) => value).join(', ')}
                      MenuProps={{
                        PaperProps: {
                          sx: { maxHeight: 240 },
                        },
                      }}
                    >
                      <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1}>
                        <Iconify
                          onClick={() => handleResetCategory()}
                          icon="tabler:trash"
                          sx={{
                            cursor: 'pointer',
                            color: filters?.category?.length === 0 ? 'gray' : 'red',
                          }}
                        />
                        <Typography>נבחר</Typography>
                        <Typography>{filters?.category?.length}</Typography>
                      </Stack>
                      {categoryOption &&
                        categoryOption?.map((option: any, idx: number) => (
                          <MenuItem key={idx} value={option?.title}>
                            <Checkbox
                              disableRipple
                              size="small"
                              checked={filters.category.includes(option.title)}
                            />
                            {option?.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </>
        )}
        {enableType && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 150 },
            }}
          >
            <InputLabel>{t('type')}</InputLabel>

            <Select
              multiple
              value={filters.type}
              onChange={handleFilterType}
              input={<OutlinedInput label="סוג" />}
              renderValue={(selected) => selected.map((value) => value).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1}>
                <Iconify
                  onClick={() => handleResetType()}
                  icon="tabler:trash"
                  sx={{
                    cursor: 'pointer',
                    color: filters?.type?.length === 0 ? 'gray' : 'red',
                  }}
                />
                <Typography>נבחר</Typography>
                <Typography>{filters?.type?.length}</Typography>
              </Stack>
              {typeOption &&
                typeOption.map((option: any, idx: number) => (
                  <MenuItem key={idx} value={option.value}>
                    <Checkbox
                      disableRipple
                      size="small"
                      checked={filters.type.includes(option.value)}
                    />
                    {option.value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        {additionalFilters?.length > 0 && (
          <Stack direction={isMobile ? 'column' : 'row'} gap={1} width={1}>
            {additionalFilters.map((item: any) => (
              <FormControl
                key={item.id}
                sx={{
                  flexShrink: 0,
                  width: { xs: 1, md: 100 },
                }}
              >
                <InputLabel>{t(`${item.name}`)}</InputLabel>

                <Select
                  multiple
                  value={filters[item.name] || []}
                  onChange={handleFilterChange(item.name)}
                  input={<OutlinedInput label={`${item.name}`} />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{
                    PaperProps: {
                      sx: { maxHeight: 240 },
                    },
                  }}
                >
                  {filters[item.name]?.length && (
                    <Stack direction="row-reverse" justifyContent="flex-end" gap={1} mx={1}>
                      <Iconify
                        onClick={() =>
                          handleResetAdditionalFilters && handleResetAdditionalFilters(item.name)
                        }
                        icon="tabler:trash"
                        sx={{
                          cursor: 'pointer',
                          color: filters[item.name]?.length === 0 ? 'gray' : 'red',
                        }}
                      />
                      <Typography>נבחר</Typography>
                      <Typography>{filters[item.name]?.length}</Typography>
                    </Stack>
                  )}
                  {item?.attributeValue?.map((option: any) => (
                    <MenuItem key={option.id} value={option.value}>
                      <Checkbox
                        disableRipple
                        size="small"
                        checked={filters[item.name]?.includes(option.value)}
                      />
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
          </Stack>
        )}

        {enableSearch && (
          <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
            <TextField
              sx={{ '& input': { direction: 'ltr' } }}
              fullWidth
              value={filters.name}
              onChange={handleFilterName}
              placeholder={t('Search...')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon={isAlternativeDesign ? 'icon-park-outline:search' : 'eva:search-fill'}
                      sx={{ color: 'text.disabled', mx: 0.5 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
