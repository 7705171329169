'use client';

import { useState } from 'react';

import { Box, Grid, Paper, Alert, Button, TextField, Typography } from '@mui/material';
import { useTranslate } from '../../locales';

type Props = {
  price: number;
  currencyToDisplay?: string;
};

const RentReturnCalculator = ({ price, currencyToDisplay }: Props) => {
  const { t } = useTranslate();
  const [propertyPrice, setPropertyPrice] = useState(price); // Стоимость недвижимости
  const [monthlyRent, setMonthlyRent] = useState(''); // Ежемесячная аренда
  const [annualProfit, setAnnualProfit] = useState<number | null>(null); // Годовая прибыль
  const [breakEvenMonths, setBreakEvenMonths] = useState<number | null>(null); // Срок окупаемости
  const [error, setError] = useState<string | null>(null); // Сообщение об ошибке

  // Функция расчёта прибыли и окупаемости
  const calculateRentProfit = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!monthlyRent || isNaN(+monthlyRent) || +monthlyRent <= 0) {
      setError(`${t('Please enter a valid monthly rent.')}`);
      return;
    }

    setError(null); // Сбрасываем ошибку при корректном вводе

    const annualRentProfit = +monthlyRent * 12; // Годовая прибыль (12 месяцев)
    const breakEven = propertyPrice / +monthlyRent; // Количество месяцев для окупаемости
    setAnnualProfit(+annualRentProfit.toFixed(2));
    setBreakEvenMonths(Math.ceil(breakEven)); // Округление до ближайшего целого числа
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 1,
        borderRadius: 2,
        mx: 'auto',
        width: '100%',
      }}
    >
      <Typography variant="h5" fontWeight="bold" gutterBottom sx={{textAlign: 'end'}}>
        {t('Calculate Rent Profit & Payback')}
      </Typography>
      <Grid container  sx={{ width: '100%' }}>
        {/* <Grid item xs={12}> */}
        {/* <TextField */}
        {/*  label="Property Price (₪)" */}
        {/*  type="number" */}
        {/*  disabled */}
        {/*  value={propertyPrice} */}
        {/*  onChange={(e) => setPropertyPrice(Number(e.target.value))} */}
        {/*  required */}
        {/*  fullWidth */}
        {/* /> */}
        {/* </Grid> */}
        <Grid item xs={12}>
          <TextField
            label={`${t('Monthly Rent')}(${currencyToDisplay})`}
            type="number"
            value={monthlyRent}
            onChange={(e) => setMonthlyRent(e.target.value)}
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Box mt={3}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={calculateRentProfit}
        >
          {t('Calculate')}
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {t(`${error}`)}
        </Alert>
      )}

      {annualProfit !== null && breakEvenMonths !== null && !error && (
        <Box mt={4} p={2} textAlign="center" bgcolor="#f1f1f1" borderRadius={2}>
          <Typography variant="h6">{t('Results:')}</Typography>
          <Typography variant="body1">
            <strong>{t('Annual Profit:')}</strong> {annualProfit}{currencyToDisplay}
          </Typography>
          <Typography variant="body1">
            <strong>{t('Break-Even Period:')}</strong> {breakEvenMonths} {t('months')}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default RentReturnCalculator;
