'use client';

import isEqual from 'lodash/isEqual';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import { useRef, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Collapse } from '@mui/material';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';

import Iconify from '../iconify';
import { useTranslate } from '../../locales';
import { IMerchant } from '../../types/merchant';
import MerchantCard from '../cards/merchant-card';
import { getMerchants } from '../../api/merchant';
import { useBoolean } from '../../hooks/use-boolean';
import { useAppContext } from '../../contexts/AppContext';
import { useResponsive } from '../../hooks/use-responsive';
import WalletToolBar from '../../sections/wallet/components/wallet-tool-bar';
import { IWalletProductTableFilter, IWalletProductTableFilterValue } from '../../types/wallet';

type Props = {
  handleCloseAcceptingCardsModal: Function;
};

export const CanBeUsed = ({ handleCloseAcceptingCardsModal }: Props) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const { state, dispatch } = useAppContext();
  const isMobile = useResponsive('down', 'sm');
  const smUp = useResponsive('up', 'sm');
  const payHere = useBoolean();
  const success = useBoolean();
  const { t } = useTranslate();

  const [page, setPage] = useState(1); // Текущая страница
  const [limit] = useState(6); // Лимит на количество записей на странице

  useEffect(() => {
    getMerchants({ limit, offset: (page - 1) * limit, dispatch });
  }, [page, limit, dispatch]);

  const pagesCount = Math.round(state.merchantCount / limit) || 1; // Количество страниц
  const defaultFilters: IWalletProductTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
  };
  const [filters, setFilters] = useState(defaultFilters);
  const canReset = !isEqual(defaultFilters, filters);

  const dataFiltered = applyFilter({
    inputData: state.merchants,
    filters,
  });

  const handleFilters = (name: string, filterValue: IWalletProductTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const extraButton = (
    <Button
      variant="outlined"
      color="secondary"
      sx={{
        mt: 3,
        borderRadius: '20px',
        color: 'primary.main',
        px: { xs: 1, md: 1 },
      }}
      onClick={payHere.onTrue}
    >
      {t('Pay here')}
    </Button>
  );

  const filterView = (
    <WalletToolBar
      filters={filters}
      onFilters={handleFilters}
      typeOptions={[]}
      categoryOptions={[]}
      areaOptions={[]}
      handleResetFilters={handleResetFilters}
      canReset={canReset}
      handleCloseAcceptingCardsModal={handleCloseAcceptingCardsModal}
      toggleDrawer={setOpen}
    />
  );

  return (
    <Stack direction="column" alignItems="center">
      {isMobile ? (
        <Stack width={1}>
          {!open && <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />}
          <Collapse in={open}>{filterView}</Collapse>
        </Stack>
      ) : (
        <>{filterView}</>
      )}
      <Stack
        flexWrap="wrap"
        flexDirection="row-reverse"
        justifyContent='center'
        gap={3}
      >
        {isMobile ? (
          <Box
            sx={{
              '& .swiper-slide': { width: 'auto', margin: 1 },
              '& .swiper-wrapper': { justifyContent: 'center' },
            }}
          >
            <Box
              sx={{
                width: '90vw',
              }}
            >
              <Swiper
                loop
                speed={500}
                slidesPerView="auto"
                mousewheel={{
                  forceToAxis: true,
                  sensitivity: 1,
                  releaseOnEdges: true,
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >
                {dataFiltered?.map((merchant) => (
                  <SwiperSlide key={merchant.id}>
                    <MerchantCard merchant={merchant} extraButton={extraButton} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
        ) : (
          <>
            {dataFiltered?.map((merchant) => (
              <MerchantCard key={merchant.id} merchant={merchant} extraButton={extraButton} />
            ))}
          </>
        )}
      </Stack>
      <Pagination
        count={pagesCount}
        page={page} // Текущая страница
        onChange={(event, newPage) => setPage(newPage)} // Обработчик для изменения страницы
        color="primary"
        sx={{
          margin: smUp ? '16px auto' : '0px',
          '& ul': { width: smUp ? '100%' : '110%' },
        }}
      />
    </Stack>
  );
};

function applyFilter({
                       inputData,
                       filters,
                     }: {
  inputData: IMerchant[];
  filters: IWalletProductTableFilter;
}) {
  const {
    name,
    // area,
    // type,
    // category,
  } = filters;

  if (name) {
    inputData = inputData.filter(
      (product) => product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  // if (area.length) {
  //   inputData = inputData.filter((product) => area.includes(product.area));
  // }

  // if (type.length) {
  //   inputData = inputData.filter((product) => type.includes(product.type));
  // }

  // if (category.length) {
  //   inputData = inputData.filter((product) => category.includes(product.category));
  // }

  return inputData;
}
