'use client';

// locales
import { useLocales, useTranslate } from 'src/locales';

import { useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// types
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { IWalletHistoryFilters, IWalletHistoryFilterValue } from '../../../../../../../types/wallet';

// ----------------------------------------------------------------------

type Props = {
  filters: IWalletHistoryFilters;
  onFilters: (name: string, value: IWalletHistoryFilterValue) => void;
  //
  budgetOption?: string[];
  sendChannelOption?: string[];
};

export default function HistoryTableToolbar({
  filters,
  onFilters,
  //
  budgetOption,
  sendChannelOption,
}: Props) {
  const { t } = useTranslate();

  const popover = usePopover();

  const handleFilterPOSChannel = useCallback(
    (event: SelectChangeEvent<string>) => {
      onFilters('POSChannel', event.target.value);
    },
    [onFilters],
  );

  const handleFilterBudgetType = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters('budgetType', event.target.value);
    },
    [onFilters],
  );

  const handleFilterSendChannel = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters('sendChannel', event.target.value);
    },
    [onFilters],
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: { xs: 0, md: 1 },
          pr: { xs: 0, md: 1 },
        }}
      >
        {budgetOption && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>{t('Budget Type')}</InputLabel>

            <Select
              multiple
              value={filters.budgetType}
              onChange={handleFilterBudgetType}
              input={<OutlinedInput label={t('Budget Type')} />}
              renderValue={(selected) =>
                selected.map((value) => value).join(', ')
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {budgetOption?.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.budgetType.includes(option)}
                  />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {sendChannelOption && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>{t('Send Channel')}</InputLabel>

            <Select
              multiple
              value={filters.budgetType}
              onChange={handleFilterSendChannel}
              input={<OutlinedInput label={t('Send Channel')} />}
              renderValue={(selected) =>
                selected.map((value) => value).join(', ')
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {budgetOption?.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.sendChanel.includes(option)}
                  />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>{t('POS Channel')}</InputLabel>

          <Select
            value={filters.POSChannel}
            onChange={handleFilterPOSChannel}
            input={<OutlinedInput label={t('Send Channel')} />}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {[
              'MULTIPASS',
              'VALUE_CARD',
              'PRAXELL',
              'B24',
              'STOCK',
              'INTERNAL',
            ].map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.POSChannel === option}
                />
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.POSChannel}
            onChange={handleFilterName}
            placeholder={t('Search...')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack> */}
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          {t('Print')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          {t('Import')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          {t('Export')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
