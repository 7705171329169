'use client';

import * as Yup from 'yup';
import { useMemo, useState } from 'react';
// next
import { useRouter } from 'next/router';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import {
  Grid,
  Card,
  Stack,
  Typography,
  TextField,
  MenuItem,
  Box,
} from '@mui/material';
// locales
import { useLocales, useTranslate } from 'src/locales';
// routes
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import FormProvider, {
  RHFEditor,
} from '../../../../../../../components/hook-form';
import { IBlogNewPost } from '../../../../../../../types/blog';
// @types
// components

// ----------------------------------------------------------------------

export type FormValuesProps = IBlogNewPost;

type Props = {
  selectOptions?: string[];
};

export default function ReportsForm({ selectOptions }: Props) {
  // const { push } = useRouter();

  const { t: translate } = useTranslate();

  const limit = 100;

  const { enqueueSnackbar } = useSnackbar();

  const [openPreview, setOpenPreview] = useState(false);

  const [vFlip, setFlip] = useState<boolean>(false);

  const [modalHeight, setModalHeight] = useState<boolean>(false);

  const [numberOfCharactersFirst, setNumberOfCharactersFirst] = useState(0);

  const [numberOfCharactersSecond, setNumberOfCharactersSecond] = useState(0);

  const NewBlogSchema = Yup.object().shape({
    title: Yup.string().required('נדרשת כותרת'),
    description: Yup.string().required('נדרש תיאור'),
    tags: Yup.array().min(2, 'חייב לכלול לפחות 2 תגים'),
    metaKeywords: Yup.array().min(1, 'יש צורך במטא מילות מפתח'),
    cover: Yup.mixed().required('נדרש כיסוי'),
    // content: Yup.string().required('תוכן נדרש'),
  });

  const defaultValues = {
    title: '',
    description: '',
    content: '',
    cover: null,
    tags: ['The Kid'],
    publish: true,
    comments: true,
    metaTitle: '',
    metaDescription: '',
    metaKeywords: [],
  };

  const methods = useForm<FormValuesProps>({
    // @ts-ignore TODO: разобраться почему выдаёт несовместимые типы
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const showMoreClick = (Flip: boolean, height: boolean) => {
    setFlip(!Flip);
    setModalHeight(!height);
  };

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      handleClosePreview();
      enqueueSnackbar(`${translate('Success in the post!')}`);
      // push(paths.dashboard.blog.posts);
    } catch (error) {
      console.error(error);
    }
  };

  const changeFirstTextField = (e: any) => {
    setNumberOfCharactersFirst(e.target?.value?.length);
  };

  const changeSecondTextField = (e: any) => {
    setNumberOfCharactersSecond(e.target?.value?.length);
  };

  const displayHelperTextFirst = useMemo(
    () => (
      <span style={{ float: 'left' }}>
        {numberOfCharactersFirst}/{limit}
      </span>
    ),
    [numberOfCharactersFirst],
  );

  const displayHelperTextSecond = useMemo(
    () => (
      <span style={{ float: 'left' }}>
        {numberOfCharactersSecond}/{limit}
      </span>
    ),
    [numberOfCharactersSecond],
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div
        style={
          modalHeight
            ? { height: '750px' }
            : { height: '330px', overflow: 'hidden' }
        }
      >
        <TextField
          sx={{ marginTop: 2 }}
          label={`${translate('Recipient name')}`}
          fullWidth
          defaultValue="תומר"
        />
        <TextField
          sx={{ marginTop: 2 }}
          label={`${translate('Recipient mobile number')}`}
          fullWidth
          defaultValue="0526247877"
        />
        <TextField
          sx={{ marginTop: 2 }}
          label={`${translate('Recipient email')}`}
          fullWidth
        />
        {/* <TextField select sx={{ marginTop: 2 }} label="שם מתנה" fullWidth>
          {selectOptions.map((option: any) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField> */}

        <Box
          onClick={() => showMoreClick(vFlip, modalHeight)}
          style={{ cursor: 'pointer' }}
          sx={{ marginTop: 2 }}
        >
          <Icon icon="material-symbols:expand-more" vFlip={vFlip} color="red" />
          <strong
            style={{ color: 'red' }}
          >{`${translate('Adding a new permission')}`}</strong>
        </Box>

        <TextField
          sx={{ marginTop: 2 }}
          label={`${translate('Sender name')}`}
          fullWidth
          defaultValue="tomer digital"
        />
        <TextField
          sx={{ marginTop: 2 }}
          label={`${translate('Email Subject')}`}
          fullWidth
          defaultValue={`${translate('[Name of recipient], you received a gift card from [name of sender]!')}`}
        />
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {`${translate('Content')}`}
        </Typography>

        <RHFEditor simple name="content" />

        {/* <TextareaAutosize /> */}

        <TextField
          sx={{ marginTop: 2 }}
          multiline
          rows={4}
          maxRows={16}
          label={`${translate('SMS wish')}`}
          fullWidth
          defaultValue={`${translate('Especially for you - a gift or experience of your choice that arrives at home')}`}
          inputProps={{ maxLength: 12 }}
          onChange={(e) => changeFirstTextField(e)}
          helperText={displayHelperTextFirst}
        />

        <TextField
          sx={{ marginTop: 2 }}
          multiline
          rows={4}
          maxRows={16}
          label={`${translate('Wishing for the gift')}`}
          fullWidth
          defaultValue={`${translate('Enjoy the voucher!')}`}
          inputProps={{ maxLength: 12 }}
          onChange={(e) => changeSecondTextField(e)}
          helperText={displayHelperTextSecond}
        />
      </div>
    </FormProvider>
  );
}
