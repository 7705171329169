import { useState } from 'react';
import { format } from 'date-fns';

import Table from '@mui/material/Table';
import { Collapse } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import { useBoolean } from 'src/hooks/use-boolean';

import { API } from 'src/helpers/api';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import Label from '../../../../components/label';
import { IOrder } from '../../../../types/order';
import { useTranslate } from '../../../../locales';
import { ITransaction } from '../../../../types/club-cards';
import { fCurrency } from '../../../../utils/format-number';
import { useTable, TableHeadCustom } from '../../../../components/table';
import { getTransactionTypeIcon, getTransactionTypeColor } from '../../../../utils/color-maps';

// import UserQuickEditForm from './user-quick-edit-form';

// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: ITransaction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

const TABLE_HEAD = [
  { id: 'customer', label: 'Customer' },
  { id: 'merchant', label: 'Merchant' },
  { id: 'date', label: 'Date' },
  { id: 'product', label: 'Product' },
  { id: 'price', label: 'Price' },
];

export default function TransactionHistoryTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const [order, srtOrder] = useState<IOrder>();
  const table = useTable();

  const { amount, createdAt, transactionType, orderId } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const fetchOrder = async (id: string) => {
    try {
      const { data } = await API({
        url: `/order`,
        params: {
          orderId: id,
        },
        method: 'GET',
      });
      if (data.payload) {
        srtOrder(data.payload);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <TableRow
        hover
        selected={selected}
        onClick={() => {
          if (row.orderId) {
            setOpen(!open);
            fetchOrder(row.orderId);
          }
        }}
        sx={{ cursor: 'pointer' }}
      >
        {/* <TableCell padding="checkbox"> */}
        {/*  <Checkbox checked={selected} onClick={onSelectRow} /> */}
        {/* </TableCell> */}

        <TableCell sx={{ whiteSpace: 'nowrap', position: 'relative' }}>
          {amount}
          {!orderId && (
            <Typography
              component="span"
              sx={{
                position: 'absolute',
                top: '8px',
                fontSize: '0.55rem',
                color: 'secondary.main',
              }}
            >
              P2P
            </Typography>
          )}
        </TableCell>


        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ListItemText
            primary={format(new Date(createdAt), 'dd.MM.yyyy')}
            secondary={format(new Date(createdAt), 'HH:mm a')}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              fontSize: 12,
              component: 'p',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label
            variant="soft"
            color={getTransactionTypeColor(transactionType)}
            endIcon={<Iconify width={16} icon={getTransactionTypeIcon(transactionType)} />}
          >
            {t(transactionType)}
          </Label>
        </TableCell>
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table size={table.dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={0}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={() => {}}
            />
            <TableBody>
              <TableRow>
                <TableCell>
                  <ListItemText
                    primary={`${order?.customer?.firstName} ${order?.customer?.lastName ? order?.customer?.lastName : ''}`}
                    secondary={`${order?.customer?.email ? order?.customer?.email : ''}`}
                    primaryTypographyProps={{ typography: 'body2' }}
                    secondaryTypographyProps={{
                      fontSize: 12,
                      component: 'p',
                      color: 'text.disabled',
                    }}
                  />
                </TableCell>
                <TableCell>{order?.smbAccount?.merchant?.title}</TableCell>
                <TableCell>
                  <ListItemText
                    primary={
                      order?.createdAt ? format(new Date(order.createdAt), 'dd.MM.yyyy') : '—'
                    }
                    secondary={order?.createdAt ? format(new Date(order.createdAt), 'HH:mm a') : ''}
                    primaryTypographyProps={{ typography: 'body2' }}
                    secondaryTypographyProps={{
                      fontSize: 12,
                      component: 'p',
                      color: 'text.disabled',
                    }}
                  />
                </TableCell>
                <TableCell>
                  {order?.orderItems?.[0]?.product?.title ||
                    // || order?.orderItems?.[0]?.cards?.name
                    '—'}
                </TableCell>
                <TableCell>{fCurrency(order?.total || '')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
