'use client';

// locales
import { useLocales, useTranslate } from 'src/locales';

// @mui
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import ReportsForm from './ReportsForm';
import { IProviderHistory } from '../../../../../../../types/wallet';
import { useBoolean } from '../../../../../../../hooks/use-boolean';
import { ConfirmDialog } from '../../../../../../../components/custom-dialog';
import ConfirmResendingDialog from '../../../../../../../components/custom-dialog/confirm-resending-dialog';
import { useRouter } from '../../../../../../../routes/hooks';
//

// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: IProviderHistory;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  checkId: (row: any) => void;
};

export default function HistoryTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  checkId,
}: Props) {
  const { t } = useTranslate();
  const [openConfirmResending, setOpenConfirmResending] = useState(false);
  const confirm = useBoolean();
  const quickEdit = useBoolean();
  const popover = usePopover();
  const router = useRouter();

  const handleEditRow = useCallback(
    (id: string) => {
      // router.push(paths.dashboard.crm.customers.profile(id));
    },
    [router],
  );

  const onSelect = () => {
    // const idx = '4f445gh6756b';
    setOpenConfirmResending(false);
    // push(`/dashboard/reports/${idx}/details`);
  };

  const callResending = () => {
    setOpenConfirmResending(true);
    popover.onClose();
  };

  const handleCloseConfirmResending = () => {
    setOpenConfirmResending(false);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell
          sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
          onClick={() => checkId(row)}
        >
          {row.provider}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.cardNumber}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.customer?.firstName}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.customer?.lastName}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.customer?.email}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.customer?.phones?.length ? row.customer?.phones[0]?.number : '-'}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>-</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>-</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.action}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.loadType}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.benefitId}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.amount}</TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{t(category)}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{t(sendChannel)}</TableCell> */}

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {format(new Date(row.date), 'dd MMM yyyy')}
        </TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ListItemText
            primary={format(new Date(dateOfUse), 'dd MMM yyyy')}
            secondary={format(new Date(dateOfUse), 'HH:mm a')}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              fontSize: 12,
              component: 'p',
              color: 'text.disabled',
            }}
          />
        </TableCell> */}

        <TableCell sx={{ whiteSpace: 'nowrap' }}>-</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{t('Event')}</TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{t(website)}</TableCell> */}

        {/* <TableCell>
          <Label variant="soft" color={(status === 'used' && 'success') || (status === 'notUsed' && 'error') || 'default'}>
            {t(status)}
          </Label>
        </TableCell> */}

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      {/* <CustomerQuickEditForm */}
      {/*  currentCustomer={row} */}
      {/*  open={quickEdit.value} */}
      {/*  onClose={quickEdit.onFalse} */}
      {/* /> */}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Delete')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('Edit')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            callResending();
            popover.onClose();
          }}
        >
          <Iconify icon="mdi:refresh" sx={{ color: 'success.main' }} />
          {`${t('Resend code')}`}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('Delete')}
          </Button>
        }
      />
      <ConfirmResendingDialog
        open={openConfirmResending}
        onClose={handleCloseConfirmResending}
        title={`${t('Resend')}`}
        content={<ReportsForm />}
        action={
          <Button variant="contained" color="error" onClick={onSelect}>
            {`${t('Send')}`}
          </Button>
        }
      />
    </>
  );
}
