'use client';

// @mui
import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import InputAdornment from '@mui/material/InputAdornment';

// utils
import Iconify from 'src/components/iconify';

import { useTranslate } from '../../locales';
import { fCurrency } from '../../utils/format-number';
import { UsedVirtualCard } from '../../types/checkout';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
  totalSum: number;
  discount?: number;
  subTotal: number;
  shipping?: number;
  //
  onEdit?: VoidFunction;
  onApplyDiscount?: (discount: number) => void;
};

export default function CheckoutSummaryStraightAhead({
  totalSum,
  discount,
  subTotal,
  shipping,
  onEdit,
  onApplyDiscount,
}: Props) {
  const [_discount, setDiscount] = useState('');
  const displayShipping = shipping !== null ? shipping : '-';
  const { t } = useTranslate();
  const { state } = useAppContext();

  const usedVirtualCards = useMemo(
    () => state.checkout.usedVirtualCards,
    [state.checkout.usedVirtualCards]
  );

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader
        title={`${t('Order summary')}`}
      />

      <CardContent>
        <Stack spacing={2}>

          {Array.isArray(usedVirtualCards) &&
            !!usedVirtualCards.length &&
            usedVirtualCards.map(({ id, name, cost }: UsedVirtualCard) => (
              <Stack key={id} direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {name}
                </Typography>
                <Typography variant="subtitle2">{cost ? fCurrency(-cost) : '-'}</Typography>
              </Stack>
            ))}

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1"> {`${t('Total')}`}</Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                {fCurrency(totalSum)}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
