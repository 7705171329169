'use client';

import { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTranslate } from 'src/locales';

import Image from 'src/components/image';

import SanitizeHTML from '../../../../utils/sanitize';
import { getContentValueFromProps } from '../../utils';
import SvgColor from '../../../../components/svg-color';
import { ButtonField } from '../../../../types/generator';
import { BlockType } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

type ShopBannerBlockType = {
  SB_cardImage?: string;
  SB_titleTextStart?: string;
  SB_titleTextButton?: string;
  SB_titleTextEnd?: string;
  SB_subTitle?: string;
  SB_contentText?: string;
  SB_arrowLinkText?: string;
  SB_bottomButtonText?: string;
  modalLogo?: string;
  modalCardImage?: string;
  modalTitle?: string;
  modalSubtitle?: string;
  modalText1?: string;
  modalText2?: string;
  modalText3?: string;
  modalText4?: string;
  modalPhysicalButton?: string;
  modalDigitalButton?: string;
  modalBottomText?: string;
};
type ShopBannerProps = { block?: BlockType & ShopBannerBlockType };

const ShopBanner: FC<ShopBannerProps> = ({ block }) => {
  const { t } = useTranslate();
  const smDown = useResponsive('down', 'sm');
  const getField = getContentValueFromProps(block);
  const actionButtons = (getField('actionButtons') as ButtonField[]) || [];
  const [openModal, setOpenModal] = useState(false);
  const backgroundImage = '';
  const alignItems = 'start';
  const {
    SB_cardImage,
    SB_titleTextStart,
    SB_titleTextButton,
    SB_titleTextEnd,
    SB_subTitle,
    SB_contentText,
    SB_arrowLinkText,
    SB_bottomButtonText,
  } = block || {};
  const cardImage = SB_cardImage ?? '/assets/images/happy-gift/banners/cardholder.png';
  const subtitle = SB_subTitle ?? 'תהינו מהחיים שלכם';
  const titleText = {
    start: SB_titleTextStart ?? 'לראשונה בישראל',
    button: SB_titleTextButton ?? 'הכרטיס',
    end: SB_titleTextEnd ?? 'שמקנה הכל!',
  };
  const contentText =
    SB_contentText ?? 'הפתרון הטוב ביותר עבור העסק שלך, באמצעות המוצר שלנו תקבל הרבה בונוסים';
  const arrowLinkText = SB_arrowLinkText ?? '?איך זה עובד';
  const bottomButtonText = SB_bottomButtonText ?? 'להזמין כרטיס';
  const title = (
    <Typography
      sx={{
        fontWeight: 600,
        color: '#27097A',
        fontSize: smDown ? '25px' : '35px',
        textAlign: 'start',
        // mb: 2,
      }}
    >
      {titleText.start}{' '}
      <Button
        variant="contained"
        sx={{
          height: smDown ? '40px' : '80px',
          py: 0,
          borderRadius: '15px',
          fontWeight: 600,
          fontSize: smDown ? '25px' : '35px',
          bgcolor: '#27097A',
          '&:hover': { bgcolor: '#27097A', color: 'secondary.contrastText' },
        }}
      >
        {titleText.button}
      </Button>{' '}
      {titleText.end}
    </Typography>
  );

  const {
    modalLogo,
    modalCardImage,
    modalTitle,
    modalSubtitle,
    modalText1,
    modalText2,
    modalText3,
    modalText4,
    modalPhysicalButton,
    modalDigitalButton,
    modalBottomText,
  } = block || {};

  const modalData = {
    logo: modalLogo ?? '/assets/images/happy-gift/logo.png',
    cardImage: modalCardImage ?? '/assets/images/happy-gift/cardholder.png',
    title: modalTitle ?? 'איזה כיף לך! קיבלת מתנה שווה במיוחד!',
    subtitle: modalSubtitle ?? 'איזה כיף לך! קיבלת מתנה שווה במיוחד!',
    text1:
      modalText1 ??
      'יפט קארד הניתן למימוש בבתי עסק ואתרי סחר בארץ ובעולם, כולל אפשרות שליחה לעובדים\n',
    text2: modalText2 ?? 'ברחבי העולם.כרטיס המתנות היחיד שעובד בכל העולם',
    text3: modalText3 ?? 'ללא הגבלה במקומות המימוש',
    text4: modalText4 ?? "פשוט כסף שמחכה לך עם חיוך'",
    buttonPhysical: modalPhysicalButton ?? 'כרטיס פיזי',
    buttonDigital: modalDigitalButton ?? 'כרטיס דיגיטלי',
    bottom: modalBottomText ?? 'אנחנו כאן לשירותכם גם בטלפון 03-5106070',
  };

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '20px',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        padding: 5,
      }}
    >
      <Grid container spacing={2} flexDirection="row-reverse" alignItems={alignItems}>
        <Grid item md={4} xs={12}>
          <Box sx={{ position: 'relative' }} id="121212121212">
            <Image src={cardImage} sx={{ width: smDown ? '50%' : '100%' }} />
          </Box>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '50%',
          }}
        >
          <Stack direction="column" alignItems="start" gap={3}>
            <Stack gap={1}>
              {title}
              <Typography
                sx={{
                  fontWeight: 600,
                  color: '#27097A',
                  fontSize: smDown ? '25px' : '35px',
                  textAlign: 'start',
                  lineHeight: 1
                  // m: 2,
                }}
              >
                {subtitle}
              </Typography>
              <Typography
                sx={{
                  color: 'black',
                  fontSize: '14px',
                  mt: 2,
                }}
              >
                <SanitizeHTML html={contentText} />
              </Typography>
            </Stack>

            <Stack direction="row-reverse" spacing={2} py={1}>
              <Button
                variant="text"
                sx={{
                  flex: 1,
                  color: 'black',
                  direction: 'rtl',
                  textDecoration: 'underline',
                  width: '100%',
                  '&:hover': {
                    bgcolor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}
              >
                <SvgColor
                  src="/assets/icons/happy-gift/arrow_1.svg"
                  sx={{ width: 35, mx: 1 }}
                  color="black"
                />
                {arrowLinkText}
              </Button>
              <Button
                onClick={() => setOpenModal(true)}
                variant="contained"
                sx={{
                  borderRadius: '13px',
                  fontWeight: 400,
                  bgcolor: '#27097A',
                  '&:hover': {
                    bgcolor: '#27097A',
                    color: 'secondary.contrastText',
                  },
                }}
              >
                {bottomButtonText}
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack alignItems="center" gap={2}>
            <Image src={modalData.logo} />
            <Typography sx={{ fontSize: '24px', fontWeight: 600, color: 'primary.main' }}>
              {modalData.title}
            </Typography>
            <Image src={modalData.cardImage} />
            <Typography sx={{ fontSize: '24px', fontWeight: 600, color: 'primary.main' }}>
              {modalData.subtitle}
            </Typography>
            <Stack alignItems="center">
              <Typography sx={{ textAlign: 'center', color: 'primary.main' }}>
                {modalData.text1}
              </Typography>
              <Typography sx={{ textAlign: 'center', color: 'primary.main' }}>
                {' '}
                {modalData.text2}
              </Typography>
              <Typography sx={{ textAlign: 'center', color: 'primary.main' }}>
                {' '}
                {modalData.text3}
              </Typography>
              <Typography sx={{ textAlign: 'center', color: 'primary.main' }}>
                {' '}
                {modalData.text4}
              </Typography>
            </Stack>
            <Stack direction="row-reverse" gap={2}>
              <Button
                variant="contained"
                sx={{ borderRadius: '15px' }}
                onClick={() => setOpenModal(false)}
              >
                {modalData.buttonPhysical}
              </Button>
              <Button variant="contained" sx={{ borderRadius: '15px' }} color="primary">
                {modalData.buttonDigital}
              </Button>
            </Stack>
            <Typography sx={{ fontSize: '20px', fontWeight: 400, color: 'primary.main' }}>
              {modalData.bottom}
            </Typography>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default ShopBanner;
