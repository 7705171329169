'use client';

import isEqual from 'lodash/isEqual';
import { useParams } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import { FC, useRef, useMemo, useState, useEffect } from 'react';

import Switch from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';
import Pagination from '@mui/material/Pagination';
import { Box, Grid, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ITag } from '../../../../types/tag';
import { useRouter } from '../../../../routes/hooks';
import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { useAuthContext } from '../../../../auth/hooks';
import ProductFilters from '../../../products/product-filters';
import { useResponsive } from '../../../../hooks/use-responsive';
import { BannerProductCard } from '../../../products/banner-product-card';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { BlockType, ComponentTypeProps } from '../../../../types/page-generator';
import { Ib2bProduct, Ib2bTableFilter, Ib2bTableFilterValue } from '../../../../types/b2b';
import { BannerProductCardCustomMode } from '../../../products/banner-product-card-custom-mode';
import {
  getCategories,
  getBannerProducts,
  getProductsByMerchantId,
  getBannerProductsForMainProducts, useGetProductsCategories,
} from '../../../../api/product';
import { API } from '../../../../helpers/api';
import { getKosher, getLocation } from '../../../../api/attributes';

interface ProductOpenPrice {
  price: number;
  title: string;
  value: string;
}

const BannerProducts: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const swiperRef = useRef<SwiperCore | null>(null);
  const { t } = useTranslation();
  const LIMIT = block?.settings?.limit || 12;
  const params = useParams();
  const router = useRouter();
  const [page, setPage] = useState(1);
  const { dispatch, state } = useAppContext();
  const { user } = useAuthContext();
  const OPTIONS = ['1', '2', '3'];
  const { id, title } = useParams();
  const { cart } = state;
  const combinedBlock: any = block?.products || [];
  const [checked, setChecked] = useState(false);
  const [localCategories, setLocalCategories] = useState([]);
  const [localProductsTags, setLocalProductsTags] = useState([]);
  const [localProducts, setLocalProducts] = useState([]);
  const [mainProducts, setCombinedBlock] = useState([]);
  const { productsCategories } = useGetProductsCategories({});

  useEffect(() => {
    getKosher(dispatch);
    getLocation(dispatch);
  }, []);

  const handleOpenPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    product: Ib2bProduct,
    maxPrice: number,
    minPrice: number
  ) => {
    const { value } = event.target;
    const newPrice: ProductOpenPrice = {
      value: product.id,
      title: product.title,
      price: parseInt(value, 10),
    };

    if (newPrice.price > maxPrice) {
      newPrice.price = maxPrice;
    }

    const localDataString: string | null = typeof window !== 'undefined' ? localStorage.getItem('openPrice') : '';

    if (localDataString) {
      const localData: ProductOpenPrice[] = JSON.parse(localDataString);
      const updated: ProductOpenPrice[] = [
        ...localData.filter((price: ProductOpenPrice) => price.value !== product.id),
        newPrice,
      ];
      localStorage.setItem('openPrice', JSON.stringify(updated));
    } else {
      localStorage.setItem('openPrice', JSON.stringify([newPrice]));
    }
  };

  useEffect(() => {
    if (block?.openPriceProducts) {
      const payload = block?.openPriceProducts;
      dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload });
      dispatch({
        type: ActionTypes.SET_OPEN_PRICE_PRODUCTS_FROM_BUILDER,
        payload,
      });
    }
  }, [block?.openPriceProducts]);

  useEffect(() => {
    getBannerProductsForMainProducts(state.smbAccount.id, {
      limit: 999,
      offset: 0,
      minPrice: 0,
      maxPrice: 10000,
      search: '',
      categoriesIds: [],
      priceType: '',
      dispatch,
    }).then((r) => setLocalProducts(r));
  }, []);

  useEffect(() => {
    const updatedCombinedBlock = combinedBlock?.map((item: any) => {
      let updatedItem = { ...item };
      localProducts?.forEach((product: Ib2bProduct) => {
        if (updatedItem.id === product.id) {
          updatedItem = {
            ...updatedItem,
            prices: product.prices,
          };
        }
      });
      return updatedItem;
    });
    setCombinedBlock(updatedCombinedBlock);
  }, [localProducts]);

  const limit = 1;
  let priceType: any = '';
  let priceChecked: string = '';
  if (block?.settings?.enableSwitch) {
    if (checked) {
      priceChecked = 'business_price';
    } else if (block?.settings?.enableCategoryFilter) {
      priceChecked = '';
    } else priceChecked = 'price';
  }
  if (
    block &&
    block.settings &&
    !block.settings.enableSwitch &&
    block?.settings?.enableCategoryFilter
  ) {
    priceType = {};
  } else {
    priceType = {
      priceType: priceChecked,
    };
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    getCategories(state.smbAccount.id, dispatch);
  }, []);

  useEffect(() => {
    const array: any = [];
    block?.settings?.categories?.map((item: { value: string }) => {
      array.push(item.value);
      return null;
    });
    setLocalCategories(block?.settings?.enableCategoryFilter ? array : []);
  }, [block?.settings?.categories]);

  useEffect(() => {
    const array: any = [];
    block?.settings?.productsTags?.map((item: { value: string }) => {
      array.push(item.value);
      return null;
    });
    setLocalProductsTags(block?.settings?.enableTagsFilter ? array : []);
  }, [block?.settings?.productsTags]);

  const defaultFilters: Ib2bTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
    price: [0, 30000000],
    search: '',
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [categoriesIds, setCategoriesIds] = useState<any[]>(['']);
  const [tagsIds, setTagsIds] = useState<any[]>(['']);
  const [merchantTagsIds, setMerchantTagsIds] = useState<any[]>(['']);
  const canReset = !isEqual(defaultFilters, filters);
  const dataFiltered = applyFilter({
    inputData: state.bannerProducts,
    filters,
  });
  console.log('dataFiltered', dataFiltered);
  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };
  const handleResetCategory = () => {
    setFilters((prevState) => ({
      ...prevState,
      category: [],
    }));
  };
  const handleResetType = () => {
    setFilters((prevState) => ({
      ...prevState,
      type: [],
    }));
  };
  const handleResetArea = () => {
    setFilters((prevState) => ({
      ...prevState,
      area: [],
    }));
  };
  useEffect(() => {
    const array: string[] = [];
    state.categories.map((value) => {
      filters.category.map((item: string) => {
        if (value.title === item) array.push(value.id);
        return true;
      });
      return true;
    });
    setCategoriesIds(array);
  }, [filters.category]);

  useEffect(() => {
    const array: string[] = [];
    state.kosher.map((value: { value: string; id: any; }) => {
      filters.type.map((item: string) => {
        if (value.value === item) array.push(String(value.id));
        return true;
      });
      return true;
    });

    setTagsIds(array);
  }, [filters.type]);

  useEffect(() => {
    const array: string[] = [];
    state.location.map((value: { value: string; id: any; }) => {
      filters.area.map((item: string) => {
        if (value.value === item) array.push(String(value.id));
        return true;
      });
      return true;
    });
    setMerchantTagsIds(array);
  }, [filters.area]);

  const categories = categoriesIds?.length ? categoriesIds : localCategories;
  const tags = tagsIds?.length ? tagsIds : localProductsTags;

  useEffect(() => {
    if (block?.settings?.enableCategoryFilter || block?.settings?.enableTagsFilter) {
      if (params.title === 'merchant') {
        getProductsByMerchantId({
          limit: LIMIT,
          offset: 0,
          dispatch,
          search: filters.name,
          minPrice: filters.price[0],
          maxPrice: filters.price[1],
          id: params.id,
          categoriesIds,
        });
      } else {
        getBannerProducts(state.smbAccount.id, {
          limit: LIMIT,
          offset: (page - 1) * LIMIT || 0,
          dispatch,
          search: filters.name,
          minPrice: filters.price[0],
          maxPrice: filters.price[1],
          categoriesIds: title === 'category' ? [id] : categories,
          tagsIds: tags,
          ...priceType,
        });
      }
    }
  }, [filters, page, tags, categories]);

  useEffect(() => {
    if (!block?.settings?.enableCategoryFilter && !block?.settings?.enableTagsFilter) {
      if (params.title === 'merchant') {
        getProductsByMerchantId({
          limit: LIMIT,
          offset: 0,
          dispatch,
          search: filters.name,
          minPrice: filters.price[0],
          maxPrice: filters.price[1],
          id: params.id,
          categoriesIds,
        });
      } else {
        getBannerProducts(state.smbAccount.id, {
          limit: LIMIT,
          offset: (page - 1) * LIMIT,
          dispatch,
          search: filters.name,
          minPrice: filters.price[0],
          maxPrice: filters.price[1],
          categoriesIds: title === 'category' ? [id] : categories,
          tagsIds: tags,
          merchantTagIds: merchantTagsIds,
          ...priceType,
        });
      }
    }
  }, [
    categories,
    filters,
    state.categoryIdsCollector,
    page,
    params.title,
    params.id,
    checked,
    tags,
    merchantTagsIds,
  ]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const getFieldValue = getContentValueFromProps(block);

  const mainTitle = useMemo(() => getFieldValue('mainTitle'), [block?.mainTitle]);

  const {
    headingTitleFontSize,
    headingTitleFontStyle,
    headingTitleFontWeight,
    headingTitleTextDecoration,
    firstGradientColorStop,
    secondGradientColorStop,
    thirdGradientColorStop,
    firstGradientColor,
    secondGradientColor,
    thirdGradientColor,
    addGradientStyle,
    headingTitleTextColor,
    enableTitle,
    variant,
    enableTimer,
    timerColor,
    timerBgcolor,
  } = block?.settings || {};

  const firstStop = firstGradientColorStop ? `${firstGradientColorStop}%` : '40%';
  const secondStop = secondGradientColorStop ? `${secondGradientColorStop}%` : '80%';
  const thirdStop = secondGradientColorStop ? `${thirdGradientColorStop}%` : '100%';

  const colorTitle = addGradientStyle
    ? {
        background: `linear-gradient(90deg, ${firstGradientColor} ${firstStop}, ${secondGradientColor} ${secondStop}, ${thirdGradientColor} ${thirdStop} )`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
      }
    : { color: headingTitleTextColor };

  const info = (product: Ib2bProduct, localBlock: BlockType) => {
    localStorage.setItem('product', JSON.stringify(product));
    localStorage.setItem('settings', localBlock && JSON.stringify(localBlock));
    router.push(`/product/${product.id}`);
  };

  const filterView = (
    <>
      {block?.settings?.enableFilter &&
        !block?.settings?.customMode &&
        !block?.settings?.enableCategoryFilter &&
        !block?.settings?.enableTagsFilter && (
          <ProductFilters
            filters={filters}
            block={block}
            onFilters={handleFilters}
            canReset={canReset}
            handleResetCategory={handleResetCategory}
            handleResetType={handleResetType}
            handleResetArea={handleResetArea}
            typeOption={state?.kosher}
            areaOption={state?.location}
            categoryOption={productsCategories}
          />
        )}
    </>
  );

  return (
    <Box
      id="products"
      sx={{
        width: '100%',
        borderRadius: '0px',
        backgroundSize: 'cover',
        padding: !isMobile ? 5 : 0,
        // mt: { xs: '-50px' },
      }}
    >
      {enableTitle && (
        <Typography
          sx={{
            textAlign: 'start',
            fontSize: headingTitleFontSize,
            fontWeight: headingTitleFontWeight,
            fontStyle: headingTitleFontStyle,
            textDecoration: headingTitleTextDecoration,
            ...colorTitle,
          }}
        >
          {mainTitle}
        </Typography>
      )}
      {block?.settings?.customMode ? (
        <>
          {!isMobile ? (
            <Stack direction="row" gap={1} justifyContent="center">
              <Grid container spacing={1} justifyContent="center">
                {mainProducts?.map((product: any, ind: number) => {
                  const settings = block?.settings?.generalSettings ? block?.settings : product;
                  const combined = block?.settings?.generalSettings
                    ? { ...product, ...settings }
                    : product;
                  return (
                    <BannerProductCardCustomMode
                      key={ind}
                      block={block}
                      product={combined}
                      mode="custom"
                      handleOpenPriceChange={handleOpenPriceChange}
                      openPriceProducts={block?.openPriceProducts}
                    />
                  );
                })}
              </Grid>
            </Stack>
          ) : (
            <Box sx={{ '& .swiper-slide': { width: '70vw', margin: 1 } }}>
              <Box
                sx={{
                  width: '90vw',
                }}
              >
                <Swiper
                  speed={500}
                  slidesPerView="auto"
                  mousewheel={{
                    forceToAxis: true,
                    sensitivity: 1,
                    releaseOnEdges: true,
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {mainProducts?.map((product: any, ind: number) => {
                    const settings = block?.settings?.generalSettings ? block?.settings : product;
                    const combined = block?.settings?.generalSettings
                      ? { ...product, ...settings }
                      : product;
                    return (
                      <SwiperSlide key={ind}>
                        <BannerProductCardCustomMode
                          block={block}
                          product={combined}
                          mode="custom"
                          handleOpenPriceChange={handleOpenPriceChange}
                          openPriceProducts={block?.openPriceProducts}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          {block?.settings?.enableSwitch && !block?.settings?.customMode && (
            <FormControlLabel
              control={<Switch checked={checked} onChange={handleChange} />}
              label={checked ? 'B2B' : 'B2C'}
            />
          )}
          {isMobile ? (
            <>
              {block?.settings?.enableFilter &&
                !block?.settings?.customMode &&
                !block?.settings?.enableCategoryFilter &&
                !block?.settings?.enableTagsFilter && (
                  <Stack width={1}>
                    <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
                  </Stack>
                )}
              <Drawer open={open} onClose={toggleDrawer(false)}>
                {filterView}
              </Drawer>
            </>
          ) : (
            <>{filterView}</>
          )}
          <>
            {!isMobile ? (
              <Grid container spacing={1} justifyContent="center">
                {dataFiltered?.map((product: any, ind: number) => (
                  <BannerProductCard
                    key={ind}
                    block={block}
                    product={product}
                    mode="notCustom"
                    handleOpenPriceChange={handleOpenPriceChange}
                    openPriceProducts={block?.openPriceProducts}
                  />
                ))}
              </Grid>
            ) : (
              <Box sx={{ '& .swiper-slide': { width: '70vw', margin: 1 } }}>
                <Box
                  sx={{
                    width: '90vw',
                  }}
                >
                  <Swiper
                    speed={500}
                    slidesPerView="auto"
                    mousewheel={{
                      forceToAxis: true,
                      sensitivity: 1,
                      releaseOnEdges: true,
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                  >
                    {dataFiltered?.map((product: any, ind: number) => (
                      <SwiperSlide key={ind}>
                        <BannerProductCard
                          key={ind}
                          block={block}
                          product={product}
                          mode="notCustom"
                          handleOpenPriceChange={handleOpenPriceChange}
                          openPriceProducts={block?.openPriceProducts}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Box>
              </Box>
            )}
          </>
          <br />
          {!block?.settings?.customMode &&
            dataFiltered?.length !== 0 &&
            block?.settings?.enablePagination && (
              <Stack width={1} direction="row" alignItems="center">
                <Pagination
                  onChange={handleChangePage}
                  page={page}
                  count={Math.round(state.bannerProductCount / LIMIT) || 1}
                  color="primary"
                  sx={{ margin: '0 auto' }}
                />
              </Stack>
            )}
          <br />
        </>
      )}
    </Box>
  );
};

function applyFilter({
  inputData,
  filters,
}: {
  inputData: Ib2bProduct[];
  filters: Ib2bTableFilter;
}) {
  const { name, area, price, category } = filters;

  if (name) {
    inputData = inputData?.filter(
      (product) => product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (area.length) {
    inputData = inputData?.filter((product) => area.includes(product.area));
  }

  // if (category.length) {
  //   inputData = inputData?.filter((product) => category.includes(product.category));
  // }

  // if (price) {
  //   inputData = inputData?.filter(
  //     (product) =>
  //       fTimestamp(product.price) >= fTimestamp(price[0]) &&
  //       fTimestamp(product.price) <= fTimestamp(price[1])
  //   );
  // }

  return inputData;
}

export default BannerProducts;
