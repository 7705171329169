'use client';

import React from 'react';
import { Grid, Box } from '@mui/material';
import ProductsList from './products-list';
import { useGetProducts } from '../../../../../api/product';
import { uploadProductsImage } from '../../../../../helpers/uploadProductsImage';
import { IProduct } from '../../../../../types/product';

type Props = {
  products: IProduct[];
}

const ProductsSquareList = ({products}: Props) => {
  const ProductsListArr = [
    {
      img: '/assets/images/marketPlace/product1.jpg',
      title: 'נקיון מהיר של הדירה',
      price: '85$',
      link: '/productdetails',
      productKey: 1,
    },
    {
      img: '/assets/images/marketPlace/product2.jpg',
      title: 'נקיון כולל של הדירה',
      price: '100$',
      link: '/productdetails',
      productKey: 2,
    },
    {
      img: '/assets/images/marketPlace/product3.jpg',
      title: 'נקיון דירה עם חיות מחמד',
      price: '120$',
      link: '/productdetails',
      productKey: 3,
    },
    {
      img: '/assets/images/marketPlace/product4.jpg',
      title: 'פתרון בעיות חיווט',
      price: '150$',
      link: '/productdetails',
      productKey: 4,
    },
  ];

  return (
    <Grid container spacing={2} justifyContent="flex-start" sx={{ direction: 'rtl' }}>
      {products.map((el: any, i: number) => {
        const basePrice = el?.prices.find((e: { type: string }) => e.type === 'base_price')?.value;
        const price = el?.prices.find((it: { type: string }) => it.type === 'price')?.value;

        return (
          <Grid item xs={6} key={i}>
            <ProductsList
              img={uploadProductsImage(el.mainImageURL)}
              title={el.title}
              price={price || basePrice}
              link={el.link}
              productKey={i}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ProductsSquareList;
