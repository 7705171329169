'use client';

// hooks
import { Controller, useFormContext } from 'react-hook-form';
import { useMemo, useState, useEffect, useCallback } from 'react';

// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Card, { CardProps } from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import Paper, { PaperProps } from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

// components
import Iconify from 'src/components/iconify';

import { IVirtualCard } from 'src/types/club-cards';

import ClubCard from './checkout-club-card';
import { useTranslate } from '../../locales';
import { useBoolean } from '../../hooks/use-boolean';
import { BlockType } from '../../types/page-generator';
import { getAllVirtualCards } from '../../api/clubCards';
import MixPaymentModal from './checkout-modal-mix-payment';
import PaymentNewCardDialog from '../payment/payment-new-card-dialog';
import { ModalCheckBalance } from '../wallet/components/modal-check-balance';
import { IBlockClubCard, ICheckoutCardOption, ICheckoutPaymentOption } from '../../types/checkout';

interface Props extends CardProps {
  block: BlockType | undefined;
  options: ICheckoutPaymentOption[] | any;
  cardOptions: ICheckoutCardOption[];
  blockClubCards: IBlockClubCard[];
  total: number;
  totalSum: number;
  showError: (text: string) => void;
}

export default function CheckoutPaymentMethods({
                                                 block,
                                                 options,
                                                 cardOptions,
                                                 blockClubCards,
                                                 total,
                                                 totalSum,
                                                 showError,
                                                 ...other
                                               }: Props) {
  const { control } = useFormContext();
  const { state, dispatch } = useAppContext();
  const { t } = useTranslate();
  const newCard = useBoolean();
  const modal = useBoolean();

  const setClubCard = useCallback((clubCardId: string) => {
    dispatch({ type: ActionTypes.SET_VIRTUAL_CARD, payload: clubCardId });
  }, []);
  const checkoutFirstStep = useCallback(() => {
    dispatch({ type: ActionTypes.CHECKOUT_FIRST_STEP, payload: {} });
  }, []);

  useEffect(() => {
    if (state.virtualCards.length) {
      const firstCardWithMoney = state.virtualCards.find(
        (card: IVirtualCard) => Number(card.balance) >= total,
      );
      setClubCard(firstCardWithMoney?.id || '');
    }
  }, []);

  return (
    <>
      <CardHeader
        title={`${t('Payment')}`}
        titleTypographyProps={{ variant: 'h3', color: 'black' }}
        subheader="בחר אמצעי התשלום"
        subheaderTypographyProps={{ fontSize: '24px', color: 'black' }}
      />
      <Card {...other}>
        <Controller
          name="payment"
          control={control}
          render={({ field, fieldState: { error } }: any) => {
            if (!Array.isArray(options)) return <></>;

            return (
              <>
                <Stack sx={{ px: 3, pb: 3 }}>
                  {options.map((option: ICheckoutPaymentOption) => (
                    <OptionItem
                      block={block}
                      key={option.label}
                      openModal={modal.onTrue}
                      option={option}
                      onOpen={newCard.onTrue}
                      cardOptions={cardOptions}
                      selected={field.value === option.value}
                      isCredit={option.value === 'credit' && field.value === 'credit'}
                      isPoint={option.value === 'point' && field.value === 'point'}
                      isMix={option.value === 'mix' && field.value === 'mix'}
                      isMixMustClub={
                        option.value === 'mixMustClub' && field.value === 'mixMustClub'
                      }
                      onClick={() => {
                        if (['credit', 'point'].includes(option.value)) {
                          dispatch({ type: ActionTypes.CLEAR_USED_VIRTUAL_CARDS, payload: {} });
                        }
                        field.onChange(option.value);
                      }}
                      virtualCards={state.virtualCards}
                      blockClubCards={blockClubCards}
                      setClubCard={setClubCard}
                      totalSum={totalSum}
                      total={total}
                      showError={showError}
                    />
                  ))}
                </Stack>

                {!!error && (
                  <FormHelperText error sx={{ pt: 1, px: 2 }}>
                    {error.message}
                  </FormHelperText>
                )}
              </>
            );
          }}
        />
      </Card>

      <MixPaymentModal open={modal.value} onClose={modal.onFalse} />

      <PaymentNewCardDialog open={newCard.value} onClose={newCard.onFalse} />
    </>
  );
}

// ----------------------------------------------------------------------

type OptionItemProps = PaperProps & {
  block: BlockType | undefined;
  option: ICheckoutPaymentOption;
  cardOptions: ICheckoutCardOption[];
  virtualCards: IVirtualCard[];
  selected: boolean;
  openModal: () => void;
  isCredit: boolean;
  isPoint: boolean;
  isMix: boolean;
  isMixMustClub: boolean;
  onOpen: VoidFunction;
  setClubCard: (id: string) => void;
  total: number;
  totalSum: number;
  showError: (text: string) => void;
  blockClubCards: IBlockClubCard[];
};

const Styles = {
  wrapper: {
    pt: 2.5,
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    gap: '1px',
    backgroundColor: '#fff',
    border: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
    paddingRight: '8px',
    marginBottom: '16px',
  },
};

const CLUB_CARD_HEADER_TEXT = 'You don\'t have any cards.';

function OptionItem({
                      block,
                      option,
                      cardOptions,
                      setClubCard,
                      virtualCards,
                      selected,
                      openModal,
                      isCredit,
                      isPoint,
                      isMix,
                      isMixMustClub,
                      onOpen,
                      total,
                      totalSum,
                      showError,
                      blockClubCards,
                      ...other
                    }: OptionItemProps) {
  const checkBalance = useBoolean();
  const { value, label, description } = option;
  const { state, dispatch } = useAppContext();
  const currentCheckoutCardId = state.checkout.clubCardId;
  const [localVirtualCards, setLocalVirtualCards] = useState<IVirtualCard[]>(
    state.localVirtualCards,
  );

  useEffect(() => {
    getAllVirtualCards(dispatch, state.smbAccount.id);
  }, []);

  const { t } = useTranslate();
  const handleToggleCard = useCallback(
    (cardId: string) => {
      if (currentCheckoutCardId === cardId) setClubCard('');
      else setClubCard(cardId);
    },
    [currentCheckoutCardId],
  );
  const disabledCardTypesList: string[] = useMemo(
    () =>
      blockClubCards.length
        ? blockClubCards.map((card: IBlockClubCard) => (card.isEnable ? '' : card.id))
        : [],
    [blockClubCards],
  );

  const ClubCardHeader = useMemo(
    () => (
      <Box sx={Styles.header} component="div">
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>
          <bdi>{t(CLUB_CARD_HEADER_TEXT)}</bdi>
        </Typography>
        <Box sx={{ height: '40px', width: '70px' }} component="div">
          <CardMedia
            sx={{ width: '100%', height: '100%' }}
            image="assets/icons/happy-gift/gift-cards.png"
          />
        </Box>
      </Box>
    ),
    [],
  );

  const ClubCardPaymentComponent = useMemo(
    () => (
      <Box sx={Styles.wrapper} component="div">
        {!localVirtualCards.length && !virtualCards?.length && <>{ClubCardHeader}</>}
        {block?.addCard && (
          <ModalCheckBalance
            checkBalance={checkBalance}
            setLocalVirtualCards={setLocalVirtualCards}
          />
        )}
        {!!localVirtualCards.length && (
          <>
            {localVirtualCards
              .filter(
                (c: IVirtualCard) => !disabledCardTypesList.includes(c.cardTypeId) && +c.balance > 0,
              )
              .map((card: IVirtualCard) => (
                <ClubCard
                  key={card.id}
                  card={card}
                  isMix
                  isMixMustClub={isMixMustClub}
                  // openModal={openModal}
                  handleToggleCard={() => {
                  }}
                  isChecked={card.id === currentCheckoutCardId}
                  isEnoughMoney={Number(card.balance) >= total}
                  totalSum={totalSum}
                  showError={showError}
                  isDisabled={
                    disabledCardTypesList.includes(card.cardTypeId) || Number(card.balance) <= 0
                  }
                />
              ))}
          </>
        )}
        {virtualCards
          .filter(
            (c: IVirtualCard) => !disabledCardTypesList.includes(c.cardTypeId) && +c.balance > 0,
          )
          .map((card: IVirtualCard) => (
            <ClubCard
              key={card.id}
              card={card}
              isMix={false}
              isMixMustClub={isMixMustClub}
              // openModal={openModal}
              handleToggleCard={() => handleToggleCard(card.id)}
              isChecked={card.id === currentCheckoutCardId}
              isEnoughMoney={
                card?.cardType?.provider !== 'INTERNAL' ? true : Number(card.balance) >= total
              }
              totalSum={totalSum}
              showError={showError}
              isDisabled={
                disabledCardTypesList.includes(card.cardTypeId) || Number(card.balance) <= 0
              }
            />
          ))}
      </Box>
    ),
    [totalSum, openModal, virtualCards, handleToggleCard, currentCheckoutCardId, localVirtualCards],
  );

  const MixPaymentComponent = useMemo(
    () => (
      <Box sx={Styles.wrapper} component="div">
        {!localVirtualCards.length && !virtualCards?.length && <>{ClubCardHeader}</>}
        {virtualCards
          .filter(
            (c: IVirtualCard) => !disabledCardTypesList.includes(c.cardTypeId) && +c.balance > 0
          )
          .map((card: IVirtualCard) => (
            <ClubCard
              key={card.id}
              card={card}
              isMix
              isMixMustClub={isMixMustClub}
              // openModal={openModal}
              handleToggleCard={() => {
              }}
              isChecked={card.id === currentCheckoutCardId}
              isEnoughMoney={Number(card.balance) >= total}
              totalSum={totalSum}
              showError={showError}
              isDisabled={
                disabledCardTypesList.includes(card.cardTypeId) || Number(card.balance) <= 0
              }
            />
          ))}
      </Box>
    ),
    [virtualCards, openModal, totalSum, currentCheckoutCardId],
  );

  return (
    <Paper
      variant="outlined"
      key={value}
      sx={{
        p: 2.5,
        mt: 2.5,
        cursor: 'pointer',
        ...(selected && {
          boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
        }),
      }}
      {...other}
    >
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={{ flexGrow: 1 }}>
              {`${t(`${label}`)}`}
            </Box>
            <Stack spacing={1} direction="row" alignItems="center">
              {value === 'credit' && (
                <>
                  <Iconify icon="logos:mastercard" width={24} />,
                  <Iconify icon="logos:visa" width={24} />
                </>
              )}
              {value === 'paypal' && <Iconify icon="logos:paypal" width={24} />}
              {value === 'cash' && <Iconify icon="solar:wad-of-money-bold" width={32} />}
            </Stack>
          </Stack>
        }
        secondary={`${t(`${description}`)}`}
        primaryTypographyProps={{ typography: 'subtitle1', mb: 0.5 }}
        secondaryTypographyProps={{ typography: 'body2' }}
      />
      {/* Temporarily disabled saved cards */}
      {isPoint && ClubCardPaymentComponent}
      {(isMix || isMixMustClub) && MixPaymentComponent}
    </Paper>
  );
}
