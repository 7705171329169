'use client';

import Slider from 'react-slick';
import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Dialog, Divider,
  Accordion,
  IconButton,
  Typography,
  DialogContent,
  AccordionDetails,
  AccordionSummary, DialogActions, Button,
} from '@mui/material';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { useRouter } from '../../../../routes/hooks';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

export const SalesCarousel: FC<ComponentTypeProps> = ({ block }) => {
  const actionButtons: any = block?.actionButtons || [];
  let sliderRef: Slider | any;
  const text: string = block?.text as string;
  const size = block?.settings?.enableDouble ? 5 : 8;
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();
  const saleModal = useBoolean();
  const [currentSale, setCurrentSale] = useState(0);

  const array = [
    { image: '/assets/images/b-smart/sales/Sale 1.png' },
    { image: '/assets/images/b-smart/sales/Sale 2.png' },
    { image: '/assets/images/b-smart/sales/Sale 3.png' },
    { image: '/assets/images/b-smart/sales/Sale 1.png' },
    { image: '/assets/images/b-smart/sales/Sale 2.png' },
    { image: '/assets/images/b-smart/sales/Sale 4.png' },
  ];

  const openSaleModal = (id: number) => {
    setCurrentSale(id);
    saleModal.onTrue();
  };

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon="ep:arrow-left-bold" color="rgba(14, 23, 50, 1)" />
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      <Iconify icon="ep:arrow-right-bold" color="rgba(14, 23, 50, 1)" />
    </Box>
  );

  const handlePrevClick = () => {
    sliderRef.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.slickNext();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: !isMobile,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: <CustomPrevArrow onClick={handlePrevClick} />,
    prevArrow: <CustomNextArrow onClick={handleNextClick} />,
  };

  return (
    <Box
      sx={{
        '& .slick-track': { mt: '10px' },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 800 }}>
          {text}
        </Typography>
        {actionButtons.length > 0 && (
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1, cursor: 'pointer' }}
                        onClick={() => router.push(`${actionButtons[0].link}`)}>{actionButtons[0]?.label}</Typography>
          </Stack>
        )}
      </Stack>
      <Divider sx={{ my: 2 }} />

      <Slider {...settings}>
        {array?.map((item: { image: string }, id: number) => (
          <Image src={item.image} onClick={() => openSaleModal(id)} sx={{ cursor: 'pointer' }} />
        ))}
      </Slider>

      <Dialog open={saleModal.value} onClose={() => saleModal.onFalse()}>
        <DialogContent>
          <Box sx={{ position: 'relative', mt: 2 }}>
            <Image src="/assets/images/b-smart/sales/info.png" sx={{ borderRadius: '10px' }} />
            <IconButton size="small" onClick={() => saleModal.onFalse()} sx={{ position: 'absolute', top: 0, left: 0 }}>
              <Iconify icon="eva:close-fill" width={18} sx={{ color: 'white', width: '25px', height: '25px' }} />
            </IconButton>
            <Typography sx={{ my: 1 }}>זוג מגבות מטבח בפוקס הום ב-19.90 ₪</Typography>
            <Accordion sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" component="p" gutterBottom>
                  כללי
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                זוג מגבות מטבח איכותיות מבית פוקס הום. המגבות אל-אלרגיות, קלות, רכות ונעימות במיוחד הניתנות לכביסה
                במכונה. גודל המגבות: 40 על 15. צבע: לבן שלג
                המידע המפורט לעיל התקבל מהספק ובאחריותו המלאה.
                לאופן מימוש ההטבה יש לעבור ללשונית ייאופן מימושיי
                <br />
                <a style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>תקנון</a>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" component="p" gutterBottom>
                  אופן השימוש
                </Typography>
              </AccordionSummary>
              <AccordionDetails />
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" component="p" gutterBottom>
                  הגבלות
                </Typography>
              </AccordionSummary>
              <AccordionDetails />
            </Accordion>
          </Box>
        </DialogContent>
        <DialogActions sx={{my: 0, pt: 0}}>
          <Button>
            לדף העסק {'>'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
