import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';


interface GetWalletProps {
  skip: number;
  limit: number;
  sortBy: string;
  supplierId: string | number | null;
  provider?: string;
  action?: string;
}

export const getHistoryProvider = async ({
                                           skip,
                                           limit,
                                           sortBy,
                                           supplierId,
                                           provider,
                                           action,
                                         }: GetWalletProps) => {
  try {
    const response = await API({
      url: `/providers/history`,
      method: 'GET',
      params: {
        botId: BOT_ID,
        skip,
        limit,
        sortBy,
        supplierId,
        provider,
        action,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw new Error('Error');
  }
};
