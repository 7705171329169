'use client';

import { useMemo, useState, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import { useActiveLink } from 'src/routes/hooks/use-active-link';

import { NavItem } from './nav-item';
import { NavListProps } from '../types';
import Iconify from '../../../../components/iconify';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

// ----------------------------------------------------------------------

export default function NavList({ data, isChild, burgerLinksPopups, handleCloseMenu }: NavListProps) {
  const active = useActiveLink(data.path, !!data.children);
  const { dispatch } = useAppContext();

  // Определяем, является ли текущий элемент popup
  const isPopup = useMemo(
    () => burgerLinksPopups?.find((item: { label: string }) => item.label === data.title),
    [burgerLinksPopups, data.title]
  );

  const [openMenu, setOpenMenu] = useState(false);

  const handleToggleMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu((prev) => !prev);
    }
  }, [data.children]);

  return (
    <>
      {isPopup ? (
        <Stack direction="row">
          <Typography
            onClick={(event: { preventDefault: () => void }) => {
              event.preventDefault();
              if (handleCloseMenu) handleCloseMenu();
              dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: isPopup?.modalId });
            }}
            sx={{
              cursor: 'pointer',
              px: 4,
              py: 1,
              color: 'primary.main',
              fontSize: '16px',
              fontWeight: 600,
              opacity: 0.75,
            }}
          >
            {isPopup?.label}
          </Typography>
          <Iconify icon={isPopup?.icon} sx={{ mx: 1, width: '55px' }} />
        </Stack>
      ) : (
        <>
          <NavItem
            open={openMenu}
            onClick={handleToggleMenu}
            title={data.title}
            path={data.path}
            icon={data.icon}
            hasChild={!!data.children}
            externalLink={data.path.includes('http')}
            active={active}
            isChild={isChild}
          />

          {!!data.children && (
            <Collapse in={openMenu} unmountOnExit>
              {data.children[0].items.map((list) => (
                <NavList key={list.title} data={list} isChild />
              ))}
            </Collapse>
          )}
        </>
      )}
    </>
  );
}
